import { useLazyQuery } from '@apollo/client';
import _cloneDeep from 'lodash.clonedeep';
import Grid from 'components/Grid';
import { decoder } from 'helpers/graphQL_Encoder';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_CLAIMS_BY_PROJECT,
  GET_CLUSTERS_BY_PROJECT,
  GET_DOCS_BY_CLUSTER,
} from 'services/GraphQL/Queries';
import {
  defaultResourceMenu,
  selectCurrentProjectId,
  selectCurrentResourceName,
  selectProjectMemberStatusTypes,
} from 'store/Redux/slices/projectsSlice';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import { resources } from 'resource';
import { DataManagementCards } from 'containers/Views/DataMangementCards';
import { setSnackbarError } from 'store/Redux/slices/snackbarsSlice';

const Document = () => {
  const dispatch = useDispatch();
  const currentProjectId = useSelector(selectCurrentProjectId);
  const resourceMenu = useSelector(selectCurrentResourceName);
  const projectMemberStatus = useSelector(selectProjectMemberStatusTypes);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const clustersLabel = `${selectedResources.ANY_PAGES.CLUSTER}s containing 'Cluster'`;
  const documentLabel = 'Document';
  const clusterDefaultSearchName = 'Cluster';
  const headers = [
    'pageName',
    'parentDocumentName',
    'clusterName',
    'clusterConfirmed',
    'createdBy',
    'createdOn',
    'totalValueElementsExtracted',
    'documentStatus',
  ];
  const clusterChartCount = 9999;
  const defaultSortOrder = [{ isTemplate: 'DESC', isComplete: 'ASC' }];

  const [documents, setDocuments] = useState({});
  const [dateCategories, setDateCategories] = useState([]);
  const [docFilters, setDocFilters] = useState(null);
  const [chartOptions, setChartOptions] = useState([
    {
      id: 0,
      name: 'Cluster Radial Bar',
      label: 'Clusters containing Cluster',
      radial: true,
      selected: true,
    },
  ]);
  const [selectedOption, setSelectedOption] = useState(
    chartOptions?.find((c) => c.selected)
  );

  //#region graphQL
  const [getClusters, { loading: chartClustersLoading, data: chartClusters }] =
    useLazyQuery(GET_CLUSTERS_BY_PROJECT, {
      variables: {
        first: clusterChartCount,
        filters: {
          totalDocuments: { gt: 0 },
          projectId: { eq: decoder(currentProjectId) },
          name: { startsWith: clusterDefaultSearchName.trim() },
        },
      },
      onError: (e) => {
        dispatch(setSnackbarError(e.message));
      },
      fetchPolicy: 'no-cache',
    });

  const [
    getDocuments,
    { loading: docsLoading, fetchMore: fetchMoreDocuments },
  ] = useLazyQuery(GET_DOCS_BY_CLUSTER, {
    onCompleted: (data) => {
      if (data?.documents?.nodes) {
        const formattedDocs = handleMapDocsForTable(data.documents);
        setDocuments({ ...data.documents, nodes: formattedDocs });
      }
    },
    onError: (e) => {
      dispatch(setSnackbarError(e.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [getProjectMembers, { loading: pmsLoading }] = useLazyQuery(
    GET_CLAIMS_BY_PROJECT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getDefaultDocuments = () => {
    getDocuments({
      variables: {
        sortOrder: defaultSortOrder,
        filters: {
          projectId: { eq: decoder(currentProjectId) },
        },
      },
    }).then(() => {
      setDocFilters(null);
    });
  };

  const refreshDocumentPageData = () => {
    let scrollArea = document.getElementById('data-list');
    scrollArea?.scrollTo(0, 0);

    getClusters().then(() => {
      getDefaultDocuments();
    });
  };

  const handleFetchMoreDocuments = () => {
    fetchMoreDocuments({
      variables: {
        filters: docFilters
          ? docFilters
          : {
              projectId: { eq: decoder(currentProjectId) },
            },
        sortOrder: defaultSortOrder,
        cursor: documents?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return documents;
        let newResult = _cloneDeep(fetchMoreResult);
        let formattedDocs = handleMapDocsForTable(newResult.documents);
        formattedDocs = [...documents.nodes, ...formattedDocs];
        setDocuments({ ...newResult.documents, nodes: formattedDocs });
      },
    });
  };

  const handleRefetchDocumentSearch = (queryValue) => {
    if (queryValue) {
      let noPM = 'N/A';
      let notNull = queryValue.toLowerCase() !== noPM.toLowerCase();
      getDocuments({
        variables: {
          sortOrder: defaultSortOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            or: [
              { originalName: { contains: queryValue.trim() } },
              {
                projectMember: {
                  originalId: notNull
                    ? { contains: queryValue.trim() }
                    : { eq: null },
                },
              },
              { cluster: { name: { contains: queryValue.trim() } } },
              parseInt(queryValue)
                ? { totalValueElements: { eq: parseInt(queryValue) } }
                : {},
              { createdBy: { contains: queryValue.trim() } },
            ],
          },
        },
      });
    } else {
      getDefaultDocuments();
    }
  };

  const handleMapDocsForTable = (docs) => {
    let tableDocuments = docs?.nodes.map((d) => {
      return {
        id: d.cluster?.id,
        pageName: d.originalName,
        parentDocumentName: d.projectMember?.originalId || 'N/A',
        clusterName: d.cluster?.name,
        clusterConfirmed: d.isConfirmed,
        createdBy: d.createdBy,
        createdOn: d.createdOn,
        totalValueElementsExtracted: d.totalValueElements,
        documentStatus: d.isComplete,
      };
    });
    return tableDocuments;
  };
  //#endregion graphQL

  //#region component functions

  const handleChartOptionChange = (e) => {
    const name = e.target.value;
    const updatedChartOptions = chartOptions?.map((option) => {
      if (option.name === name) {
        const updatedOption = { ...option, selected: true };
        setSelectedOption(updatedOption);
        return updatedOption;
      }
      return { ...option, selected: false };
    });
    setChartOptions(updatedChartOptions);
  };

  const filterDocumentDataByChart = (cell) => {
    let userId = cell.serieId || cell.groupId;
    let user = dateCategories?.find((u) => u.assignedTo === userId);
    let ids = user.dates[0][cell.data.x].map((i) => {
      return decoder(i.id);
    });

    if (user && ids?.length > 0) {
      let dataFilters = {
        projectId: { eq: decoder(currentProjectId) },
        clusterId: { in: ids },
      };
      setDocFilters(dataFilters);

      getDocuments({
        variables: {
          sortOrder: defaultSortOrder,
          filters: dataFilters,
        },
      });
    }
  };
  //#endregion component functions

  //#region useEffects
  useEffect(() => {
    if (docFilters) {
      getDocuments();
    }
  }, [chartOptions]);

  useEffect(() => {
    if (currentProjectId) {
      getClusters().then(() => {
        getDefaultDocuments();
      });
    }
  }, [currentProjectId]);
  //#endregion useEffects

  return (
    <ProjectSelectedTemplate>
      <Grid container alignItems='center' spacing={2}>
        <DataManagementCards
          chartHeaderLabel={clustersLabel}
          tableHeaderLabel={documentLabel}
          selectedOption={selectedOption}
          handleChartOptionChange={handleChartOptionChange}
          chartOptions={chartOptions}
          setChartOptions={setChartOptions}
          defaultSortOrder={defaultSortOrder}
          chartData={chartClusters?.clusters}
          tableData={documents}
          setData={setDocuments}
          getData={getClusters}
          getDefaultData={getDefaultDocuments}
          refreshPageData={refreshDocumentPageData}
          currentProjectId={currentProjectId}
          tableFilters={docFilters}
          chartLoading={chartClustersLoading}
          allLoading={docsLoading || pmsLoading}
          headers={headers}
          handleOnChartClick={filterDocumentDataByChart}
          setDateCategories={setDateCategories}
          handleFetchMoreData={handleFetchMoreDocuments}
          handleRefetchDataSearch={handleRefetchDocumentSearch}
          projectMemberStatus={projectMemberStatus}
          selectedResources={selectedResources}
          getPMsForDocumentView={getProjectMembers}
        />
      </Grid>
    </ProjectSelectedTemplate>
  );
};

export default Document;
