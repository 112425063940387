import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Card from 'components/Card';
import Grid from 'components/Grid';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import TextFieldAnalysis from 'containers/ReactTable/TableComponents/TextFieldAnalysis';
import ReactTable from 'containers/ReactTable';
import CurrentDocument from 'containers/Views/CurrentDocument';
import { decoder, getGuid } from 'helpers/graphQL_Encoder';
import { useDispatch, useSelector } from 'react-redux';
import {
  RECONCILE_VALUE_ELEMENT,
  UPDATE_VALUE_ELEMENT_VALUE,
  EXPORT_PROJECTMEMBER_DATA,
  UPDATE_SENT_PROJECTMEMBER_ISEXPORTED_STATUS,
  EXPORT_PROJECT_DATA_EXCEL,
  UPDATE_PROJECT_MEMBER_AUTOFLOW,
  UPDATE_ASSIGNMENT_PROJECT_MEMBER,
  EXPORT_PROJECT_DATA_ELEMENT_EXCEL,
  EXPORT_PROJECT_DOCUMENT_DATA_EXCEL,
} from 'services/GraphQL/Mutations';
import {
  GET_MEMBER_DETAILS_BY_ID,
  GET_PROJECT_MEMBERS,
  GET_PROJECT_MEMBERS_TO_BE_UPLOADED,
  GET_PROJECT_DETAIL,
  GET_PRESIGNED_URLS_S3,
} from 'services/GraphQL/Queries';
import {
  reconcileElement,
  selectChoiceIndex,
  selectCurrentDataChoice,
  selectCurrentElement,
  selectCurrentMember,
  selectDataElements,
  selectSourceIndex,
  setChoiceIndex,
  setCurrentElementIndex,
  setCurrentMember,
  setDataElements,
  setSourceIndex,
  updateChoice,
} from 'store/Redux/slices/analysisSlice';
import {
  selectCurrentProjectId,
  selectReadToken,
  selectValueElementStatusTypes,
  selectCurrentOrganizationId,
  selectCurrentResourceName,
  defaultResourceMenu,
  selectProjectMemberStatusTypes,
} from 'store/Redux/slices/projectsSlice';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DownloadFile } from 'helpers/export';
import axios from 'axios';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from 'icons/Mui/Close';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import { getAuthHeader, getApiUrl } from 'authConfig';
import { resources } from 'resource';
import ProjectMemberStatusCell from 'containers/ReactTable/TableComponents/ProjectMemberStatusCell';
import {
  applyAWSTokenToDocument,
  handleDecodeURL,
} from 'helpers/UpdateAWSDocumentUriTokens';
import { displayElementType, statusToEnum } from 'helpers/enumToType';
import ProjectMemberAssignedOnFormatter from 'containers/ReactTable/TableComponents/ProjectMemberAssignedOnFormatter';
import { isPast24Hours } from 'helpers/DateComparer';
import _cloneDeep from 'lodash.clonedeep';
import StatusJustificationModal from 'containers/Modals/StatusJustificationModal';

//#region Styled Components
const SCard = styled(Card)`
  padding: 20px;
  overflow: auto;
`;

const MemberIdCell = styled.div`
  text-align: left;
  border-bottom: none;
  & td {
    padding: 0;
  }
`;

const CenteredCell = styled.div`
  text-align: left;
`;

const OverflowCell = styled.div`
  overflow: auto;
`;

const SDoneIcon = styled(DoneIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const SCloseIcon = styled(CloseIcon)`
  color: rgba(244, 67, 54, 1);
`;
//#endregion Styled Components

export default function Analysis(props) {
  const analysisSortBy = 'ReactTable_analysis_SortBy';
  const sortByDesc = 'Desc_';
  const resourceMenu = useSelector(selectCurrentResourceName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const projectMembersHeader = `${selectedResources.ANY_PAGES.PROJECT_MEMBER}s`;
  const projectMemberDataElementsHeader = `${selectedResources.ANY_PAGES.PROJECT_MEMBER} Data Elements`;

  const dispatch = useDispatch();
  const _ = require('lodash');

  //Selectors
  const currentMember = useSelector(selectCurrentMember);
  const dataElements = useSelector(selectDataElements);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const readToken = useSelector(selectReadToken);
  const choiceIndex = useSelector(selectChoiceIndex);
  const sourceIndex = useSelector(selectSourceIndex);
  const currentElement = useSelector(selectCurrentElement);
  const currentDataChoice = useSelector(selectCurrentDataChoice);
  const [IsProjectMemberFile, setIsProjectMemberFile] = useState(true);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);

  const [projectMembers, setProjectMembers] = useState();
  const [pmSet, setPmSet] = useState();
  const [projectDetail, setProjectDetail] = useState();
  const valueElementStatus = useSelector(selectValueElementStatusTypes);
  const projectMemberStatus = useSelector(selectProjectMemberStatusTypes);
  const emailName = useSelector(selectCurrentUserEmail);

  //State
  const [currentImage, setCurrentImage] = useState('');
  const [expanded, setExpanded] = useState(undefined);
  const [inReconciledStatus, setInReconciledStatus] = useState(false);
  const [isReadOnly, toggleIsReadOnly] = useState(false);
  const [toggleRefetch, setToggleRefetch] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  const editableStatuses = [
    projectMemberStatus.RECONCILIATION_FLAGGED_FOR_REVIEW,
    projectMemberStatus.RECONCILIATION_ESCALATED_FOR_REVIEW,
  ];

  const statusColumnOrder = [
    projectMemberStatus.RECONCILIATION_FLAGGED_FOR_REVIEW,
    projectMemberStatus.RECONCILIATION_ESCALATED_FOR_REVIEW,
    projectMemberStatus.COMPLETE,
    projectMemberStatus.REJECTED,
    projectMemberStatus.OFF_RAMPED,
    projectMemberStatus.RECONCILIATION_SENT_CLAIM_TO_PROCESSING_FOR_VALIDATION,
    projectMemberStatus.DATA_ENTRY_IN_PROGRESS,
    projectMemberStatus.DATA_ENTRY_OFF_RAMPED_FOR_REVIEW,
  ];
  const defaultPmOrder = [{ status: 'DESC' }, { createdOn: 'ASC' }];

  const onAnalysisPage = location.pathname === '/analysis';
  const awsObject = 'amazonaws';

  //#region Table Columns
  const memberColumns = [
    {
      Header: `${selectedResources.ANY_PAGES.PROJECT_MEMBER}s ID`,
      accessor: 'originalId',
      Cell: ({ value }) => <MemberIdCell component='td'>{value}</MemberIdCell>,
    },
    {
      Header: 'Sent to Business Rules Engine',
      accessor: 'isExported',
      sortType: 'basic',
      Cell: ({ value }) => (
        <CenteredCell>
          {value === 'INCOMPLETE' ? <SCloseIcon /> : <SDoneIcon />}
        </CenteredCell>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <ProjectMemberStatusCell
          editing={editing}
          setEditing={setEditing}
          isStatusEditable={editableStatuses.includes(row.original.status)}
          initialValue={row.original.status}
          rowIsSelected={row.isSelected}
          isReadOnly={isReadOnly}
          currentMemberLoading={currentMemberLoading}
        />
      ),
    },
    {
      Header: 'Assigned To',
      accessor: 'assignedTo',
    },
    {
      Header: 'Assigned On',
      accessor: 'assignedOn',
      Cell: ({ row }) =>
        row?.original?.assignedOn ? (
          <ProjectMemberAssignedOnFormatter
            assignedOn={row.original.assignedOn}
          />
        ) : null,
    },
    {
      Header: 'Created On',
      accessor: 'createdOn',
      Cell: ({ row }) =>
        row?.original?.createdOn ? (
          <ProjectMemberAssignedOnFormatter
            assignedOn={row.original.createdOn}
          />
        ) : null,
    },
  ];

  const elementColumns = [
    {
      id: 'expander',
      Header: () => null,
      Cell: ({ row }) =>
        inReconciledStatus ? (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </span>
        ) : null,
      maxWidth: 30,
    },
    {
      Header: 'Data Element',
      accessor: 'name',
      Cell: ({ value }) => <CenteredCell>{value}</CenteredCell>,
    },
    {
      Header: 'Value',
      accessor: (row) => row.selectedChoice?.value,
      Cell: ({ value }) => <OverflowCell>{value}</OverflowCell>,
    },
    {
      Header: 'Source',
      accessor: (row) => row.selectedChoice?.source,
      Cell: ({ value }) => <OverflowCell>{value}</OverflowCell>,
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: (row) => getRowStatus(row),
      sortType: 'basic',
      Cell: ({ value }) => <CenteredCell>{value}</CenteredCell>,
    },
  ];

  const choiceColumns = [
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ({ row }) => (
        <TextFieldAnalysis
          initialValue={row.original.value}
          handleUpdate={handleValueElementUpdate}
          isReadOnly={isReadOnly}
          {...row}
        />
      ),
    },
    {
      id: 'source',
      Header: <CenteredCell>Document</CenteredCell>,
      accessor: (row, index) => {
        if (choiceIndex === index) return row.source[sourceIndex];
        return row.source[0];
      },
    },
  ];

  //#endregion Table Columns

  //#region GraphQL
  const [getProjectMembers, { fetchMore: fetchMoreProjectMembers, loading }] =
    useLazyQuery(GET_PROJECT_MEMBERS, {
      onCompleted: (data) => {
        if (data?.projectMembers?.nodes) {
          sortProjectMembersByStatus(data.projectMembers);
          if (data?.projectMembers?.nodes.length === 1) {
            getMember({
              variables: { id: data?.projectMembers?.nodes[0]?.id },
            });
          } else {
            setMemberAndElements({}, []);
          }
        }
      },
      fetchPolicy: 'no-cache',
    });

  const [getMember, { loading: currentMemberLoading }] = useLazyQuery(
    GET_MEMBER_DETAILS_BY_ID,
    {
      onCompleted: (data) => {
        if (data?.projectMemberById) {
          dispatch(setCurrentMember(data.projectMemberById));
          collectDocsForSigning(data.projectMemberById);
        }
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [reconcileValueElement] = useMutation(RECONCILE_VALUE_ELEMENT, {
    onCompleted: (data) => {
      if (data?.updateProjectMemberReconciliation) {
        const autoFlowInput = {
          projectMemberId: decoder(currentMember?.id),
          fileName: `DataEntryComplete/Reconciled_${getGuid(
            decoder(currentProjectId)
          )}_${currentMember?.originalId}.json`,
        };
        updateProjectMemberAutoFlow({ variables: { input: autoFlowInput } });
      }
    },
    onError: (error) => {
      dispatch(setSnackbarError(`Reconciliation Error: ${error.message}`));
    },
  });

  const [updateValueElement] = useMutation(UPDATE_VALUE_ELEMENT_VALUE);

  const [handleProjectMemberDataReportExport] = useMutation(
    EXPORT_PROJECTMEMBER_DATA,
    {
      variables: {
        input: {
          projectId: decoder(currentProjectId),
        },
      },
      onCompleted: (data) => {
        if (data?.exportProjectMemberData) {
          convertBase64ToExcel(data.exportProjectMemberData);
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(`${error.message}`));
      },
    }
  );

  const [updateSentProjectMemberIsExportedStatus] = useMutation(
    UPDATE_SENT_PROJECTMEMBER_ISEXPORTED_STATUS
  );

  const [handleTableDataReportExport] = useMutation(EXPORT_PROJECT_DATA_EXCEL, {
    variables: {
      input: {
        projectId: decoder(currentProjectId),
      },
    },
    onCompleted: (data) => {
      if (data?.exportProjectDataToExcel) {
        convertBase64ToExcel(data.exportProjectDataToExcel);
      }
    },
    onError: (error) => {
      dispatch(setSnackbarError(`${error.message}`));
    },
  });

  const [handleProjectDataElementReportExport] = useMutation(
    EXPORT_PROJECT_DATA_ELEMENT_EXCEL,
    {
      variables: {
        input: {
          projectId: decoder(currentProjectId),
        },
      },
      onCompleted: (data) => {
        if (data?.exportProjectDataElementExcel) {
          convertBase64ToExcel(data.exportProjectDataElementExcel);
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(`${error.message}`));
      },
    }
  );

  const [handleProjectDocumentDataReportExport] = useMutation(
    EXPORT_PROJECT_DOCUMENT_DATA_EXCEL,
    {
      variables: {
        projectId: currentProjectId,
      },
      onCompleted: (data) => {
        if (data?.exportProjectDocumentDataToExcel) {
          convertBase64ToExcel(data.exportProjectDocumentDataToExcel);
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(`${error.message}`));
      },
    }
  );

  const [getPreSignedUrlList] = useLazyQuery(GET_PRESIGNED_URLS_S3);

  const [updateProjectMemberAutoFlow] = useMutation(
    UPDATE_PROJECT_MEMBER_AUTOFLOW,
    {
      onCompleted: (data) => {
        if (data.updateProjectMemberAutoFlow) {
          getMember({ variables: { id: currentMember?.id } })
            .then((response) => {
              const updatedProjectMemberList = projectMembers?.nodes?.map(
                (p) => {
                  if (p.id === response.data.projectMemberById.id) {
                    return response.data.projectMemberById;
                  }
                  return p;
                }
              );
              setProjectMembers({
                ...projectMembers,
                nodes: updatedProjectMemberList,
              });
              const status = displayElementType(
                response?.data.projectMemberById.status
              );
              dispatch(
                setSnackbarSuccess(
                  `Reconciliation Complete. Project Member status has been moved to: ${status}`
                )
              );
              handleClose();
            })
            .catch((error) => {
              dispatch(setSnackbarError(error.message));
            });
        }
      },
      onError: (error) => {
        dispatch(
          setSnackbarError(`Error updating Project Member: ${error.message}`)
        );
      },
    }
  );

  const [updateAssignmentForProjectMember] = useMutation(
    UPDATE_ASSIGNMENT_PROJECT_MEMBER,
    {
      onCompleted: (data) => {
        if (data?.updateAssignmentForProjectMember) {
          let updatedProjectMemberList = [...projectMembers.nodes];

          const index = projectMembers?.nodes?.findIndex(
            (p) => p.id === data.updateAssignmentForProjectMember.id
          );

          if (index >= 0) {
            updatedProjectMemberList[index] =
              data.updateAssignmentForProjectMember;
          }
          setProjectMembers({
            ...projectMembers,
            nodes: updatedProjectMemberList,
          });
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
    }
  );
  //#endregion GraphQL

  //#region useEffect

  useEffect(() => {
    if (currentElement?.dataChoices) {
      dispatch(setSourceIndex(0));
    }
  }, [choiceIndex]);

  useEffect(() => {
    if (currentProjectId) {
      let state = props?.location?.state;
      if (state?.optionData) {
        setIsDataFiltered(true);
        setProjectMembers(state.optionData.projectMembers);
      } else {
        getProjectMembers({
          variables: {
            sortOrder: defaultPmOrder,
            filters: {
              projectId: { eq: decoder(currentProjectId) },
            },
          },
        });
      }

      getProjectDetail({ variables: { id: currentProjectId } });
      getProjectMemberstoBeUploaded({ variables: { id: currentProjectId } });
    }
    setMemberAndElements({}, []);
    dispatch(setChoiceIndex(undefined));
  }, [currentProjectId]);

  useEffect(() => {
    if (currentElement?.dataChoices) {
      setCurrentImage(
        currentElement?.dataChoices[choiceIndex]?.image[sourceIndex]?.includes(
          awsObject
        )
          ? currentElement?.dataChoices[choiceIndex]?.image[sourceIndex]
          : `${currentElement?.dataChoices[choiceIndex]?.image[sourceIndex]}?${readToken}`
      );
    } else {
      setCurrentImage('');
    }
  }, [choiceIndex, sourceIndex, currentElement]);

  useEffect(() => {
    setExpanded(undefined);
    dispatch(setChoiceIndex(undefined));

    let checkStatus =
      currentMember.status ===
        projectMemberStatus.RECONCILIATION_FLAGGED_FOR_REVIEW ||
      currentMember.status ===
        projectMemberStatus.RECONCILIATION_ESCALATED_FOR_REVIEW ||
      currentMember.status === projectMemberStatus.COMPLETE;
    setInReconciledStatus(checkStatus);
  }, [currentMember]);
  //#endregion useEffect

  //#region Functions
  const getRowStatus = (row) => {
    let status = '';

    if (row.selectedChoice) {
      status = row.selectedChoice.isReconciled ? 'Reconciled' : 'Unreconciled';
      return status;
    } else {
      let valuesEmpty = row.dataChoices.every((d) => !d.value.length);

      if (valuesEmpty) {
        status = 'Not Applicable';
        return status;
      }
      status = 'Unreconciled';
      return status;
    }
  };

  const collectDocsForSigning = (projectMember) => {
    let docs = projectMember.valueElements.map((v) => {
      return v.document;
    });
    let decodedDocs = handleDecodeURL(docs);
    handlePreSignedUrlDocuments(projectMember, decodedDocs);
  };

  const handlePreSignedUrlDocuments = (member, documents) => {
    const documentUris = documents.map((d) => {
      return d.documentUri;
    });
    getPreSignedUrlList({ variables: { urlList: documentUris } }).then(
      (res) => {
        const updatedDocs = applyAWSTokenToDocument(
          res.data.preSignedUrlList,
          documents
        );
        let updatedValueElements = member?.valueElements?.map((v) => {
          let doc = updatedDocs.find((x) => x.name === v?.document?.name);
          if (doc) {
            return {
              ...v,
              document: { ...v.document, documentUri: doc.documentUri },
            };
          }
          return v;
        });
        if (member.supplementalDatas.length) {
          const { supplementalDatas } = member;
          const suppData = supplementalDatas.map((element) => {
            return {
              id: element.id,
              value: element.value,
              name: element.elementName,
              document: {
                cluster: {
                  name: 'Supplemental Data',
                },
              },
              documentId: undefined,
              isReconciled: false,
            };
          });
          updatedValueElements = [...updatedValueElements, ...suppData];
        }

        let updatedCurrentMember = {
          ...member,
          valueElements: updatedValueElements,
        };
        dispatch(setCurrentMember(updatedCurrentMember));
        dispatch(setDataElements(updatedValueElements));
      }
    );
  };

  const sortProjectMembersByStatus = (pms) => {
    let pmList = pms.nodes;
    var sortedProjectMembers = _.sortBy(pmList, function (pm) {
      return _.indexOf(statusColumnOrder, pm.status);
    });
    setProjectMembers({ ...pms, nodes: sortedProjectMembers });
  };

  const handleFetchMoreProjectMembers = () => {
    fetchMoreProjectMembers({
      variables: {
        filters: {
          projectId: { eq: decoder(currentProjectId) },
        },
        cursor: projectMembers.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // we are not using prev because we don't use graphQL cache for our data
        if (!fetchMoreResult) return projectMembers;
        let newResult = _cloneDeep(fetchMoreResult);
        newResult.projectMembers.nodes = [
          ...projectMembers.nodes,
          ...newResult.projectMembers.nodes,
        ];
        sortProjectMembersByStatus(newResult.projectMembers);
      },
    });
  };

  const handleRefetchProjectMembersSearch = (queryValue) => {
    setIsDataFiltered(false);
    if (queryValue) {
      let statuses = statusToEnum(queryValue.trim(), projectMemberStatus);
      getProjectMembers({
        variables: {
          sortOrder: defaultPmOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            or: [
              { originalId: { contains: queryValue.trim() } },
              statuses
                ? {
                    status: {
                      in: statuses,
                    },
                  }
                : {},
              { assignedTo: { contains: queryValue.trim() } },
            ],
          },
        },
      });
    } else {
      getProjectMembers({
        variables: {
          sortOrder: defaultPmOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
          },
        },
      });
    }
  };

  const convertBase64ToExcel = (file) => {
    var contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    var excel = b64toBlob(file, contentType);
    var excelUrl = URL.createObjectURL(excel);

    window.open(excelUrl);
  };

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const setMemberAndElements = (member, elements) => {
    dispatch(setCurrentMember(member));
    dispatch(setDataElements(elements));
  };

  const handleParticipantRowSelected = (row) => {
    getMember({
      variables: { id: row.original.id },
    });

    if (currentMember?.valueElements?.length) {
      setDataElements(currentMember?.valueElements);
    }

    dispatch(setCurrentElementIndex(undefined));
  };

  const handleReconcile = () => {
    reconcileValueElement({
      variables: {
        input: {
          projectMemberId: decoder(currentMember?.id),
          valueElementId: decoder(currentDataChoice?.id[sourceIndex]),
          currentUser: emailName,
        },
      },
    }).then((response) => {
      handleClose();
      dispatch(
        reconcileElement(
          response.data.updateProjectMemberReconciliation.valueElement
        )
      );
      dispatch(setSnackbarSuccess(`Successfully Reconciled Element`));
    });
  };

  const handleProjectMemberExport = () => {
    setIsProjectMemberFile(false);
    handleProjectMemberDataReportExport({
      variables: {
        input: {
          projectId: currentProjectId,
        },
      },
    }).then(({ data }) => {
      const resp = data.exportProjectMemberData;
      DownloadFile(resp.content, resp.name, resp.type);
      setIsProjectMemberFile(true);
    });
  };

  const handleValueElementUpdate = (newValue, ids, index) => {
    const id = decoder(ids[index]);

    updateValueElement({
      variables: {
        input: {
          valueElementId: id,
          updatedValue: newValue,
          status: valueElementStatus.COMPLETE_NO_REVIEW_REQUIRED,
        },
      },
    }).then(() =>
      dispatch(
        updateChoice({
          newValue,
        })
      )
    );
  };

  const handleExpand = (row, id, index) => {
    dispatch(setSourceIndex(0));
    setExpanded({ [id]: true });
    dispatch(setCurrentElementIndex(index));
    const expired = isPast24Hours(currentMember?.assignedOn);
    if (emailName !== currentMember?.assignedTo) {
      if (expired || currentMember.assignedTo === null) {
        toggleIsReadOnly(false);
        updateAssignmentForProjectMember({
          variables: {
            input: {
              projectMemberId: decoder(currentMember?.id),
              username: emailName,
            },
          },
        });
      } else {
        toggleIsReadOnly(true);
      }
    }
  };

  const handleClose = () => {
    setExpanded(undefined);
  };

  const handleChoiceSelected = (row) => {
    dispatch(setChoiceIndex(row.index));
  };

  const toggleStatusChangeModal = () => {
    setEditing(!editing);
    setToggleRefetch(!toggleRefetch);
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      return (
        <ReactTable
          data={row.original.dataChoices}
          columns={choiceColumns}
          hasSearchBar={false}
          hasPagination={false}
          onSelected={handleChoiceSelected}
          actions={{
            chooseValue: handleReconcile,
          }}
          isReadOnly={isReadOnly}
        />
      );
    },
    [currentMember, currentDataChoice, choiceIndex, sourceIndex]
  );

  const [getProjectDetail] = useLazyQuery(GET_PROJECT_DETAIL, {
    variables: {
      id: currentProjectId,
    },
    onCompleted: (data) => {
      if (data) {
        setProjectDetail(data);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [getProjectMemberstoBeUploaded] = useLazyQuery(
    GET_PROJECT_MEMBERS_TO_BE_UPLOADED,
    {
      variables: { id: currentProjectId },
      onCompleted: (data) => {
        if (data) {
          setPmSet(data);
        }
      },
      fetchPolicy: 'no-cache',
    }
  );

  const handleSendProjectMembersToBeUploaded = async () => {
    const data = new FormData();
    let finaldata = JSON.stringify(Object.assign(projectDetail, pmSet));
    data.append('OrganizationId', currentOrganizationId);
    data.append('ProjectId', decoder(currentProjectId));
    const dataFile = new Blob([finaldata], {});
    data.append('files', dataFile);
    data.append('IsProjectMemberFile', IsProjectMemberFile);
    try {
      await axios
        .post(`${getApiUrl()}file/upload`, data, getAuthHeader())
        .then(() => {
          updateSentProjectMemberIsExportedStatus({
            variables: {
              id: currentProjectId,
            },
          }).then(() => {
            dispatch(
              setSnackbarSuccess(
                `Project members successfully uploaded and respective statuses are marked COMPLETE.`
              )
            );
          });
        });
    } catch (err) {
      console.error(err);
    }
  };
  //#endregion Functions

  return (
    <ProjectSelectedTemplate currentProjectId={currentProjectId}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SCard>
            <Grid container spacing={1}>
              <Grid item xs={12} className='analysisPage-projectMembersTable'>
                {projectMembers?.nodes && (
                  <ReactTable
                    id='projectMembers-table'
                    header={projectMembersHeader}
                    subheader={currentMember?.originalId}
                    onSelected={handleParticipantRowSelected}
                    columns={memberColumns}
                    data={projectMembers?.nodes}
                    hasHoverHighlight={true}
                    hasExport={true}
                    exportButtonName='Export'
                    handleExport={handleProjectMemberExport}
                    handleProjectMemberDataReportExport={
                      handleProjectMemberDataReportExport
                    }
                    handleSendProjectMembers={
                      handleSendProjectMembersToBeUploaded
                    }
                    handleTableDataReportExport={handleTableDataReportExport}
                    handleProjectDataElementReportExport={
                      handleProjectDataElementReportExport
                    }
                    handleProjectDocumentDataReportExport={
                      handleProjectDocumentDataReportExport
                    }
                    useSkipPageReset={true}
                    hasPagination={false}
                    tableHeight={'30vh'}
                    fetchMore={handleFetchMoreProjectMembers}
                    refetch={handleRefetchProjectMembersSearch}
                    toggleRefetch={toggleRefetch}
                    hasNextPage={projectMembers?.pageInfo?.hasNextPage}
                    totalData={projectMembers?.totalCount}
                    isTableResetOnSelectedRow={false}
                    dataLoading={loading}
                    isTableResetOnSort={false}
                    pageName={'analysis'}
                    defaultSortColumn={localStorage.getItem(analysisSortBy)}
                    isDescending={
                      'true' ==
                      localStorage.getItem(
                        analysisSortBy +
                          sortByDesc +
                          localStorage.getItem(analysisSortBy)
                      )
                    }
                    isDataFiltered={isDataFiltered}
                  />
                )}
              </Grid>
            </Grid>
          </SCard>
        </Grid>
        <Grid container item spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            className='analysisPage-projectMemberDataElements'
          >
            <SCard>
              {dataElements && (
                <ReactTable
                  id='data-elements-table'
                  header={projectMemberDataElementsHeader}
                  renderRowSubComponent={renderRowSubComponent}
                  isExpandable={inReconciledStatus}
                  columns={elementColumns}
                  data={dataElements}
                  hasSearchBar={false}
                  hasAllRowsExpand={inReconciledStatus}
                  onExpand={handleExpand}
                  expandedId={expanded}
                  onClose={handleClose}
                  hasHoverHighlight={false}
                  isTableResetOnSort={false}
                  pageName={'analysis_DE'}
                  defaultSortColumn={'status'}
                  isDescending={true}
                  useSkipPageReset={true}
                />
              )}
            </SCard>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className='analysisPage-projectMemberCurrentDocument'
          >
            {currentImage.substring(0, 9) !== 'undefined' && (
              <CurrentDocument
                image={currentImage}
                currentPage='Analysis'
                totalDocs={currentDataChoice?.image?.length}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <StatusJustificationModal
        isOpen={editing}
        onAnalysisPage={onAnalysisPage}
        handleModalClose={toggleStatusChangeModal}
        projectMembers={projectMembers}
        setProjectMembers={setProjectMembers}
        editableStatuses={editableStatuses}
      />
    </ProjectSelectedTemplate>
  );
}

Analysis.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
};
