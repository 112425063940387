import MLinearProgress from '@mui/material/LinearProgress';
import { rgba } from 'polished';
import styled from 'styled-components';

const SLinearProgress = styled(MLinearProgress)`
  background-color: ${(props) => rgba(props.theme.palette.primary.main, 0.5)};
  & .MuiLinearProgress-bar {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default function LinearProgress(props) {
  return <SLinearProgress {...props} />;
}
