/**
 *
 * @param {*} date: This is the date that is passed in from tables mostly, but can be any, it is converted into Date object within function
 * @param {*} includeTime : This is a boolean, if TRUE, date will include timestamp as MM/DD/YYYY hh:mm:sss . if FALSE, only returns date as MM/DD/YYYY
 * @returns A date that is formatted to look better than the standard datetime stamp
 */

export function dateTimeFormatter(date, includeTime) {
  if (date) {
    var options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    if (includeTime) {
      options.hour = 'numeric';
      options.minute = 'numeric';
      options.second = 'numeric';
    }

    return new Date(date).toLocaleDateString('en-US', options);
  }
  return date;
}

/**
 *  This method compares the createdOn and modifiedOn date, so we can show the most recent update and when it was updated and not have both created and modified
 * @param {*} createdOn : can be any date format, it is converted into Date object
 * @param {*} modifiedOn : same as above
 * @returns the most recent date to display within tables, components, etc.
 */
export function compareDates(createdOn, modifiedOn) {
  var date1 = new Date(createdOn);
  var date2 = new Date(modifiedOn);

  if (date1 > date2) {
    return date1;
  } else if (date1 < date2) {
    return date2;
  } else return date1;
}
