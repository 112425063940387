import { useState } from 'react';
import CardContent from 'components/CardContent';
import Dialog from 'components/Dialog';
import Fade from 'components/Fade';
import FormControl from 'components/FormControl';
import Grid from 'components/Grid';
import InputLabel from 'components/InputLabel';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { displayTaskStatus, displayTaskType } from 'helpers/enumToType';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//#region Styled Components
const PaddedGrid = styled(Grid)`
  padding: 8px;
`;

const AddTaskContainer = styled('div')`
  width: 550px;
`;

const InputContainer = styled(CardContent)`
  padding: 16px;
  height: auto;
`;

const SInputLabel = styled(InputLabel)`
  &&.Mui-focused {
    color: ${(props) => props.theme.palette.background.lighter};
  }
`;

const STextField = styled(TextField)`
  label.Mui-focused {
    color: ${(props) => props.theme.palette.background.lighter};
  }
`;
//#endregion Styled Components

export default function AddTaskModal(props) {
  const {
    handleModalClose,
    handleModalConfirm,
    isOpen,
    statusDropdownOptions = [],
    typeDropdownOptions = [],
  } = props;

  const initialForm = {
    type: '',
    status: '',
    description: '',
  };

  const [form, setForm] = useState(initialForm);

  const onSelectType = (e) => {
    setForm({ ...form, type: e.target.value });
  };

  const onSelectStatus = (e) => {
    setForm({ ...form, status: e.target.value });
  };

  const onChangeDescription = (e) => {
    setForm({ ...form, description: e.target.value });
  };

  const onClickConfirm = () => {
    handleModalConfirm(form);
    setForm(initialForm);
  };

  const onClickCancel = () => {
    setForm(initialForm);
    handleModalClose();
  };

  const taskTypeDropdown = (
    <FormControl variant='outlined' fullWidth>
      <SInputLabel>Type</SInputLabel>
      <Select
        value={form.type}
        onChange={onSelectType}
        label='Type'
        disableAnimation
      >
        {typeDropdownOptions.map((p) => (
          <MenuItem key={p.id} value={p}>
            {displayTaskType(p)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const taskStatusDropdown = (
    <FormControl variant='outlined' fullWidth>
      <SInputLabel>Status</SInputLabel>
      <Select
        value={form.status}
        onChange={onSelectStatus}
        label='Status'
        disableAnimation
      >
        {statusDropdownOptions.map((s) => (
          <MenuItem key={s} value={s}>
            {displayTaskStatus(s)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Dialog
      open={isOpen}
      closeAfterTransition
      onClose={onClickCancel}
      title='Add New Task'
      hasActions
      cancelLabel='Cancel'
      handleCancel={onClickCancel}
      submitLabel='Confirm'
      handleSubmit={onClickConfirm}
    >
      <Fade in={isOpen}>
        <AddTaskContainer>
          <Grid container direction='column' spacing={2}>
            <InputContainer>
              <PaddedGrid item lg={12} xs={12}>
                {taskTypeDropdown}
              </PaddedGrid>
              <PaddedGrid item lg={12}>
                {taskStatusDropdown}
              </PaddedGrid>
              <PaddedGrid item lg={12}>
                <STextField
                  fullWidth
                  multiline
                  rows={15}
                  label='Description'
                  value={form.description}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant='outlined'
                  onChange={onChangeDescription}
                />
              </PaddedGrid>
            </InputContainer>
          </Grid>
        </AddTaskContainer>
      </Fade>
    </Dialog>
  );
}

AddTaskModal.propTypes = {
  handleModalClose: PropTypes.func,
  handleModalConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  statusDropdownOptions: PropTypes.array,
  typeDropdownOptions: PropTypes.array,
};
