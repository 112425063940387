import { useSelector } from 'react-redux';
import { selectHighlightedValue } from 'store/Redux/slices/dataEntrySlice';
import styled from 'styled-components';
import PropTypes from 'prop-types';

//#region Styled Components
const SHighlighter = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  background-color: ${(props) => getBackgroundColor(props)};

  opacity: ${(props) => (props.coordinates ? '.5' : null)};
  left: ${(props) =>
    props.coordinates && !props.currentDocument.isComplete
      ? `${props.coordinates[2]}px`
      : null};
  top: ${(props) =>
    props.coordinates && !props.currentDocument.isComplete
      ? `${props.coordinates[3]}px`
      : null};
  width: ${(props) =>
    props.coordinates && !props.currentDocument.isComplete
      ? `${props.coordinates[4]}px`
      : null};
  height: ${(props) =>
    props.coordinates && !props.currentDocument.isComplete
      ? `${props.coordinates[5]}px`
      : null};
`;
//#endregion Styled Components

export default function Highlighter(props) {
  const { currentDocument } = props;
  const highlightedValue = useSelector(selectHighlightedValue);
  const handleClick = (highlightedValue) => () => {
    const element = document.getElementById(highlightedValue?.coordinates);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      element.focus({ preventScroll: true });
    }
  };
  return (
    <>
      {highlightedValue?.map((highlightedValue, index) => (
        <SHighlighter
          key={index}
          coordinates={highlightedValue?.coordinates}
          checkboxType={
            highlightedValue?.type === 'CHECKBOX' ||
            highlightedValue?.type === 'RADIO'
          }
          tableType={highlightedValue?.type === 'TABLE'}
          isCheckboxChecked={highlightedValue?.value === 'y'}
          yellowHighlight={highlightedValue?.yellowHighlight === 'Yes'}
          currentDocument={currentDocument}
          onClick={handleClick(highlightedValue)}
          value={highlightedValue?.value}
        />
      ))}
    </>
  );
}

const getBackgroundColor = (props) => {
  if (props.coordinates && !props.currentDocument.isComplete) {
    if (props.checkboxType && !props.yellowHighlight) {
      if (props.isCheckboxChecked) {
        return 'green';
      } else {
        return 'red';
      }
    } else if (props.tableType && !props.yellowHighlight) {
      switch (props.value.toLowerCase()) {
        case 'original':
          return '#FF5733 '; //orange - original
        case 'added':
          return '#0033FF'; //blue - added
        case 'modified':
          return '#8E33FF '; //purple - modified
        case 'removed':
          return '#FF00FF'; //pink - removed
      }
    } else {
      return 'yellow';
    }
    return 'white';
  }
};

Highlighter.propTypes = {
  currentDocument: PropTypes.object,
};
