import Grid from 'components/Grid';
import Button from 'components/Button';
import AcknowledgementDialog from 'components/Dialog/AcknowledgementDialog';
import { config } from 'config';
import ConnectIcon from 'icons/ConnectIcon';
import ConnectTile from 'icons/ConnectTile';
import ModifyIcon from 'icons/ModifyIcon';
import ModifyTile from 'icons/ModifyTile';
import PinpointIcon from 'icons/PinpointIcon';
import PinpointTile from 'icons/PinpointTile';
import ValidateIcon from 'icons/ValidateIcon';
import ValidateTile from 'icons/ValidateTile';
import Rectangle1 from 'icons/Rectangle1';
import Rectangle2 from 'icons/Rectangle2';
import Rectangle3 from 'icons/Rectangle3';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Typography from 'components/Typography';

//#region Styled Components
const GridRoot = styled(Grid)`
  ${(props) => props.theme.typography.reading};
  min-height: 800px;
  color: white;
  flex-grow: 1;
  max-width: none;
`;

const Hero = styled(Grid)`
  justify-content: center;
`;

const TopSection = styled(Grid)`
  height: 700px;

  @media screen and (min-width: 1536px) {
    align-content: center;
  }
`;

const IconGrid = styled(Grid)`
  justify-content: center;

  @media screen and (min-width: 1800px) {
    padding: 0 1%;
  }
  @media screen and (min-width: 670px) and (max-width: 800px) {
    padding: 0 20%;
  }
  @media screen and (min-width: 600px) and (max-width: 669px) {
    padding: 0 10%;
  }
`;

const IconItem = styled(Grid)`
  min-width: 200px;
  max-width: 200px;
  min-height: 200px;
  max-height: 200px;
  display: flex;
  flex-grow: 1;
  padding: 6px;
  align-items: center;
  & svg {
    width: 180px;
    height: 180px;
    margin: 20px;
  }
`;

const TextContainer = styled(Grid)`
  max-height: 400px;
  max-width: 700px;
  margin-left: 50px;
`;

const LandingHeader = styled.h2`
  ${(props) => props.theme.typography.header};
  font-size: 32px;
`;

const Header = styled.h1`
  ${(props) => props.theme.typography.header}
  font-weight: 400;
  font-size: 40px;
  padding-left: 100px;
  margin: 50px 0px 50px 150px;
`;

const SubHeader = styled.p`
  font-size: 28px;
  font-weight: 400;
`;

const StartButton = styled(Button)`
  background: ${(props) => props.theme.palette.secondary.main};
  color: white;
  width: 360px;
  height: 50p;
  border-radius: 25px;
  margin: 0 50px;
  &:hover {
    background: ${(props) => props.theme.palette.secondary.darker};
    color: white;
  }
`;

const SectionContainer = styled(Grid)`
  flex-direction: column;
  margin-bottom: 40px;
  & svg {
    width: 180px;
    height: 180px;
    margin: 20px;
  }
  & p {
    max-width: 750px;
    font-size: 18px;
  }
`;

const Info = styled(Grid)`
  max-width: 80%;
  margin: 40px auto 0px auto;
  color: rgba(1, 33, 105, 1);

  @media screen and (min-width: 1536px) {
    margin: 180px auto 0 auto;
  }
  @media screen and (min-width: 2250px) {
    margin: 250px auto 0 auto;
  }
  @media screen and (max-width: 800px) {
    margin: 180px auto 0 auto;
  }
`;

const TeamHeader = styled.h3`
  color: rgba(0, 124, 176, 1);
`;

const Team = styled(Grid)`
  color: rgba(1, 33, 105, 1);
`;

const Footer = styled.footer`
  margin: 30px auto 0px auto;
  text-align: center;
  font-size: 14px;
  color: white;
  background: rgba(0, 124, 176, 1);
  width: 100%;
`;

const Background1 = styled(Rectangle1)`
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;

  @media screen and (max-width: 1400px) {
    height: 650px;
  }
  @media screen and (max-width: 800px) {
    height: 900px;
  }
`;

const Background2 = styled(Rectangle2)`
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;

  @media screen and (max-width: 1400px) {
    height: 650px;
  }
  @media screen and (max-width: 800px) {
    height: 900px;
  }
`;

const Background3 = styled(Rectangle3)`
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;

  @media screen and (max-width: 1400px) {
    height: 650px;
  }
  @media screen and (max-width: 800px) {
    height: 900px;
  }
`;

const ButtonContainer = styled(Grid)`
  @media screen and (max-width: 1250px) {
    justify-content: center;
  }
`;

const Notice = styled(Typography)`
  padding-top: 10px;
  font-size: 12px;
`;
//#endregion Styled Components
const devAcknowledgement = () => {
  return (
    <Notice>
      <b>Notice:</b> In accordance with{' '}
      <a href='https://deloittenet.deloitte.com/About/Policies/Admin/Pages/910_Privacy_Policy_US.aspx'>
        Privacy Policy APR 910
      </a>
      , the Notice Principle states: The U.S. Firms should provide notice
      describing how they Process and protect PII. In the event that PII of an
      individual will be used or disclosed in a manner that is not consistent
      with the uses and disclosures described in the original or any subsequent
      notice provided to the Data Subject, then additional notice should be
      provided to the Data Subject and, if required, consent should be obtained
      from the Data Subject (see section .003C Consent Principle for when
      consent is required).
    </Notice>
  );
};

const prodAcknowledgement = () => {
  return (
    <Notice>
      <div>
        <b>Notice:</b> In accordance with{' '}
        <a href='https://deloittenet.deloitte.com/About/Policies/Admin/Pages/910_Privacy_Policy_US.aspx'>
          Privacy Policy APR 910
        </a>
        , the Notice Principle states: The U.S. Firms should provide notice
        describing how they process and protect Personally Identifiable
        Information (PII). In the event that PII of an individual will be used
        or disclosed in a manner that is not consistent with the uses and
        disclosures described in the original or any subsequent notice provided
        to the Data Subject, then additional notice should be provided to the
        Data Subject and, if required, consent should be obtained from the Data
        Subject (see section .003C Consent Principle for when consent is
        required).
      </div>
      <br></br>
      <div>
        <b>User also acknowledges the following:</b>
        <ol>
          <li>
            No client data should be uploaded to SmarText without prior approval
            from project leadership. Accordingly, it is the user’s
            responsibility to confirm that client data is authorized to be
            uploaded to the SmarText environment.
          </li>
          <li>
            Protected Health Information (PHI) data, including PHI within
            documents, is NOT permitted to be uploaded to SmarText.
          </li>
        </ol>
      </div>
    </Notice>
  );
};

export default function LandingPage() {
  return (
    <>
      <AcknowledgementDialog>
        {config.ENVIRONMENT == 'production'
          ? prodAcknowledgement()
          : devAcknowledgement()}
      </AcknowledgementDialog>
      <Background1 />
      <Background2 />
      <Background3 />
      <GridRoot container spacing={2}>
        <TopSection container alignContent='flex-start'>
          <Grid container alignItems='center'>
            <Grid item xs={12}>
              <Header>{config.APPNAME}</Header>
            </Grid>
          </Grid>
          <Hero container>
            <IconGrid container>
              <IconItem item xs={6}>
                <ConnectTile />
              </IconItem>
              <IconItem item xs={6}>
                <PinpointTile />
              </IconItem>
              <IconItem item xs={6}>
                <ModifyTile />
              </IconItem>
              <IconItem item xs={6}>
                <ValidateTile />
              </IconItem>
            </IconGrid>

            <TextContainer container item xs={8} alignItems='center'>
              <Grid item xs={12}>
                <LandingHeader>
                  Manage your documents and data like never before
                </LandingHeader>
              </Grid>
              <Grid item xs={12}>
                <SubHeader>
                  Millions of documents? Not a problem. With our solution,
                  you&apos;ll never have to worry about searching for
                  information by hand again.
                </SubHeader>
              </Grid>
              <ButtonContainer item container xs={12} justifyContent='flex-end'>
                <StartButton
                  data-cy='get-started'
                  to={userType === 'Admin' ? '/records' : '/clusters'}
                  variant='contained'
                  component={Link}
                >
                  Get Started
                </StartButton>
              </ButtonContainer>
            </TextContainer>
          </Hero>
        </TopSection>

        <Info container>
          <SectionContainer
            item
            container
            direction='row-reverse'
            justifyContent='flex-start'
            alignItems='center'
          >
            <ConnectIcon />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </SectionContainer>

          <SectionContainer
            item
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            <PinpointIcon />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </SectionContainer>

          <SectionContainer
            item
            container
            direction='row-reverse'
            alignItems='center'
          >
            <ModifyIcon />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </SectionContainer>

          <SectionContainer item container direction='row' alignItems='center'>
            <ValidateIcon />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </SectionContainer>
        </Info>

        <Team container direction='column' alignItems='center'>
          <Grid item>
            <TeamHeader>{config.APPNAME} Team Members</TeamHeader>
          </Grid>
          <Grid item>Mitchell Bernard</Grid>
          <Grid item>Michael Chang</Grid>
          <Grid item>Luis Manriquez</Grid>
          <Grid item>Madhuri Singh</Grid>
          <Grid item>Sakdina Vindubrahmanakul</Grid>
        </Team>

        <Footer>
          <p>The {config.APPNAME} application is property of Deloitte</p>
        </Footer>
      </GridRoot>
    </>
  );
}

// const userType = 'Admin';
const userType = 'User';
