import { config } from 'config';
import Dialog from 'components/Dialog';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slide from 'components/Slide';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export default function CookiesNoticeDialog(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.isClosed}
      keepMounted
      title={`Here's How ${config.APPNAME} Uses Cookies`}
      TransitionComponent={Transition}
      aria-labelledby='cookie-dialog-title'
      aria-describedby='cookie-dialog-description'
      handleCancel={props.isClosed}
      submitLabel='I Understand'
      handleSubmit={props.isClosed}
      requiredSubmit
      hasActions
    >
      {config.APPNAME} uses strictly necessary cookies and similar technologies
      to operate this website and to provide you with a more personalized user
      experience.
      {props.children}
    </Dialog>
  );
}

CookiesNoticeDialog.propTypes = {
  children: PropTypes.any,
  isClosed: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
