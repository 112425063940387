/* eslint-disable react/boolean-prop-naming */
import ReactTable from 'containers/ReactTable';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNotReviewed,
  toggleModalOpen,
} from 'store/Redux/slices/dataEntrySlice';
import { selectValueElementStatusTypes } from 'store/Redux/slices/projectsSlice';
import DataEntryCell from './DataEntryCell';
import { decoder } from 'helpers/graphQL_Encoder';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { ImageCropIconArea } from 'containers/Cropper';

function DataEntryTable(props) {
  const {
    currentDocument,
    dataElements,
    getSortedDataElements,
    inputVal,
    inputValue,
    isExtractionLoading,
    isTableTypePortalOpen,
    selectedElementField,
    supplementalData,
    /* props just for cell */
    checkInputs,
    handleSuppButtonClick,
    handleType,
    isTableButtonDisabled,
    onClickTableButton,
    /* for cropping */
    crop,
    croppedCoordinates,
    isCropDisabled,
    onClickCrop,
    onClickRemoveCrop,
    onConfirmCrop,
    onExitCropMode,
    isReadOnly,
    valueElementsLoading,
  } = props;
  const dispatch = useDispatch();
  const open = useSelector(toggleModalOpen);
  const valueElementStatus = useSelector(selectValueElementStatusTypes);

  const numberOfColumns = 100 / Object.keys(dataElements).length;
  const confidenceWarninglevel = 0.8;
  const headerWidth = getHeaderWidth();

  const generateColumns = () => {
    const elements = getSortedDataElements();
    let columns = [];

    for (let element of elements) {
      let suppCopy = {};
      let vElement = inputVal?.find(
        (x) => x.dataElementId == decoder(element.id)
      );
      let belowConfidence = vElement?.confidenceLevel < confidenceWarninglevel;
      let value = inputValue(element);

      if (
        belowConfidence &&
        vElement.status == valueElementStatus.REVIEW_REQUIRED
      ) {
        dispatch(setNotReviewed(true));
      }
      const suppData = supplementalData?.find(
        (supp) => supp.elementName === element.name
      );

      if (suppData) {
        suppCopy = { ...suppData };
        suppCopy.dataElementId = decoder(element.id);
      }

      const inputIndex =
        inputVal?.findIndex(
          (i) => i.dataElementId === suppCopy?.dataElementId
        ) === -1
          ? undefined
          : inputVal.findIndex(
              (i) => i.dataElementId === suppCopy?.dataElementId
            );
      if (vElement?.status == valueElementStatus.REVIEW_REQUIRED) {
        columns.unshift(
          dataEntryTableColumnObject(
            element,
            value,
            belowConfidence,
            vElement,
            inputIndex,
            suppData
          )
        );
      } else {
        columns.push(
          dataEntryTableColumnObject(
            element,
            value,
            belowConfidence,
            vElement,
            inputIndex,
            suppData
          )
        );
      }
    }
    return columns;
  };

  /* For now only takes into account for ONE row; in the future if we want to add more rows may need to be fixed */
  const generateTableData = () => {
    let data = [];
    let tempData = {};
    let nonTableValues = inputVal.filter((val) => !val.tableTypeColumnId);
    let dataElements = getSortedDataElements();

    for (let value of nonTableValues) {
      let name = dataElements.find(
        (elem) => decoder(elem.id) === value.dataElementId
      )?.name;
      Object.assign(tempData, { [name]: value.value });
    }

    data.push(tempData);
    data.push({ supplementalButton: <button>hi</button> });
    return data;
  };

  function getHeaderWidth() {
    const columnsLength = dataElements.length;
    let headerWidth;
    if (columnsLength <= 6 && columnsLength > 2) {
      headerWidth = `${numberOfColumns.toString()}%`;
    } else if (columnsLength < 3) {
      headerWidth = '25%';
    } else {
      headerWidth = '16.66%';
    }
    return headerWidth;
  }

  const dataEntryTableColumnObject = (
    element,
    value,
    belowConfidence,
    vElement,
    inputIndex,
    suppData
  ) => {
    const column = {
      accessor: element.name,
      Header: () => {
        return (
          <>
            <Grid container>
              <Grid item xs={isCropDisabled ? 10 : 6}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  noWrap
                  title={element.name}
                >
                  {element.name}
                </Typography>
              </Grid>
              <ImageCropIconArea
                crop={crop}
                croppedCoordinates={croppedCoordinates}
                isCropDisabled={isCropDisabled || isTableTypePortalOpen || open}
                isDocComplete={currentDocument?.isComplete}
                item={element}
                onClickCrop={onClickCrop}
                onClickRemoveCrop={onClickRemoveCrop}
                onConfirmCrop={onConfirmCrop}
                onExitCropMode={onExitCropMode}
                selectedElementField={selectedElementField}
                value={value}
                isReadOnly={isReadOnly}
                currentDocument={currentDocument}
              />
            </Grid>
          </>
        );
      },
      Cell: ({ row, value }) => {
        return (
          <DataEntryCell
            belowConfidence={belowConfidence}
            cellValue={value}
            checkInputs={checkInputs}
            element={element}
            vElement={vElement}
            handleSuppButtonClick={handleSuppButtonClick}
            handleType={handleType}
            inputIndex={inputIndex}
            inputVal={inputVal}
            isExtractionLoading={isExtractionLoading}
            isTableButtonDisabled={isTableButtonDisabled}
            onClickTableButton={onClickTableButton}
            row={row}
            suppData={suppData}
            value={value}
            isReadOnly={isReadOnly}
          />
        );
      },
    };
    return column;
  };

  return (
    <ReactTable
      columns={generateColumns()}
      data={generateTableData()}
      isSortDisabled={true}
      hasDisableSelectHighlight={true}
      hasPagination={false}
      hasSearchBar={false}
      headerWidth={headerWidth}
      hasHoverHighlight={false}
      selectedRow={{}}
      hasHighlightCell={true}
      hasMinWidth={`${dataElements.length * 250}px`}
      onDataEntry={true}
      dataLoading={valueElementsLoading}
    />
  );
}

DataEntryTable.propTypes = {
  checkInputs: PropTypes.func,
  crop: PropTypes.object,
  croppedCoordinates: PropTypes.any,
  currentDocument: PropTypes.object,
  dataElements: PropTypes.array,
  getSortedDataElements: PropTypes.func,
  handleSuppButtonClick: PropTypes.func,
  handleType: PropTypes.func,
  inputVal: PropTypes.array,
  inputValue: PropTypes.func,
  isCropDisabled: PropTypes.bool,
  isExtractionLoading: PropTypes.bool,
  isTableButtonDisabled: PropTypes.bool,
  isTableTypePortalOpen: PropTypes.bool,
  onClickCrop: PropTypes.func,
  onClickRemoveCrop: PropTypes.func,
  onClickTableButton: PropTypes.func,
  onConfirmCrop: PropTypes.any,
  onExitCropMode: PropTypes.any,
  row: PropTypes.object,
  selectedElementField: PropTypes.object,
  supplementalData: PropTypes.array,
  value: PropTypes.string,
  isReadOnly: PropTypes.bool,
};
export default DataEntryTable;
