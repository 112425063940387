import { forwardRef } from 'react';
import MTextField from '@mui/material/TextField';
import styled from 'styled-components';

const STextField = styled(MTextField)`
  .MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.primary.main} !important;
  }
  .Mui-focused {
    & fieldset {
      border: 2px solid ${(props) => props.theme.palette.primary.main} !important;
    }
  }
  .Mui-focused:after {
    border-bottom: ${(props) =>
      `2px solid ${props.theme.palette.primary.main}  !important`};

    & > fieldset {
      border: ${(props) =>
        `2px solid ${props.theme.palette.primary.main}  !important`};
    }
  }
`;

const TextField = forwardRef(function TextField(props, ref) {
  return <STextField ref={ref} {...props} />;
});

export default TextField;
