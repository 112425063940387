import { useState } from 'react';
import {
  LOAD_CLUSTER_PROJECT_BY_ID,
  LOAD_COMPLETED_CLUSTER,
} from 'services/GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';
import { setSnackbarInfo } from 'store/Redux/slices/snackbarsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentProjectId,
  setClusterDone,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setSnackbarWarn,
} from 'store/Redux/slices/snackbarsSlice';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import { decoder } from 'helpers/graphQL_Encoder';

const useClusterer = (props) => {
  const { getClusters } = props;
  const dispatch = useDispatch();
  const currentProjectId = useSelector(selectCurrentProjectId);

  const [statusUnclustered, setUnclustered] = useState(false);
  const [clusteringInProgress, setClusteringInProgress] = useState(false);
  const [clusteringComplete, setClusteringComplete] = useState(false);
  const [clusteringResult, setRepullClusteringResult] = useState(false);
  const [clusteringError, setClusteringError] = useState(false);
  const [reclusterStarted, setReclusterStarted] = useState();
  const [numberOfTries, setNumberOfTries] = useState(0);

  const emailName = useSelector(selectCurrentUserEmail);

  const defaultNumOfTries = 10;
  const defaultClusterOrder = [{ totalDataElements: 'DESC' }, { name: 'ASC' }];

  const [loadClusterProjectById] = useLazyQuery(LOAD_CLUSTER_PROJECT_BY_ID, {
    onCompleted: (data) => {
      setReclusterStarted(data?.clusterProjectById);
    },
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [loadCompletedCluster, { startPolling, stopPolling }] = useLazyQuery(
    LOAD_COMPLETED_CLUSTER,
    {
      variables: { id: currentProjectId, username: emailName },
      onCompleted: (data) => {
        setReclusterStarted(data?.completedCluster.progress);
        checkForClusteringProgress(
          data.completedCluster.progress,
          data.completedCluster.status
        );
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  const checkForClusteringProgress = (progress, status) => {
    if (progress && status) {
      if (numberOfTries < defaultNumOfTries) {
        dispatch(setSnackbarInfo(progress));

        if (progress === 'successful' || status === 'successful') {
          onReturnSuccess();
        } else if (progress.includes('Error') || status.includes('failed')) {
          let errorMessage =
            'Clustering was not successful. Contact SmarText Admins for more details.';
          let noFileError = 'FileNotFoundError';
          let blobNotFound = 'BlobNotFound';

          if (status.includes(noFileError)) {
            errorMessage =
              'No new documents found. Please upload new documents to recluster.';
          } else if (status.includes(blobNotFound)) {
            errorMessage =
              'The specified blob was not found or does not exist.';
          }
          onReturnError(errorMessage);
        } else {
          setNumberOfTries((numberOfTries) => numberOfTries + 1);
        }
      } else if (numberOfTries === defaultNumOfTries) {
        onExceedTries();
      }
    }
  };

  const onReturnSuccess = () => {
    stopPolling();
    getClusters({
      variables: {
        sortOrder: defaultClusterOrder,
        filters: {
          projectId: { eq: decoder(currentProjectId) },
          totalDocuments: { gt: 0 },
        },
      },
    });
    dispatch(setClusterDone(true));
    switchButtonToClustered();
    dispatch(setSnackbarSuccess(`Clustering is complete`));
  };

  const onReturnError = (error) => {
    stopPolling();
    switchButtonToError();
    dispatch(setSnackbarError(error));
  };

  const onExceedTries = () => {
    stopPolling();
    setNumberOfTries(0);
    switchButtonToPullClusterStatus();
    dispatch(
      setSnackbarWarn(
        `Exceeded load wait time; please check status again later`
      )
    );
  };

  const setClusteringStateToFalse = () => {
    setUnclustered(false);
    setClusteringInProgress(false);
    setClusteringComplete(false);
    setRepullClusteringResult(false);
  };

  const switchButtonToUnclustered = () => {
    setClusteringStateToFalse();
    setUnclustered(true);
    setClusteringError(false);
  };

  const switchButtonToInProgress = () => {
    setClusteringStateToFalse();
    setClusteringInProgress(true);
    setClusteringError(false);
  };

  const switchButtonToClustered = () => {
    setClusteringStateToFalse();
    setClusteringComplete(true);
    setClusteringError(false);
  };

  const switchButtonToPullClusterStatus = () => {
    setClusteringStateToFalse();
    setRepullClusteringResult(true);
    setClusteringError(false);
  };

  const switchButtonToError = () => {
    setClusteringStateToFalse();
    setClusteringError(true);
  };

  const onClickClusterButton = () => setClusterDialog(true);
  const onClickCancelDialog = () => setClusterDialog(false);

  const onClickRepullButton = () => {
    if (currentProjectId) {
      loadCompletedCluster();
      startPolling(1500);
      switchButtonToInProgress();
      dispatch(setSnackbarInfo(`Pulling cluster status`));
    }
  };

  const onClickConfirmDialog = () => {
    if (currentProjectId) {
      setClusterDialog(false);
      loadClusterProjectById({ variables: { id: currentProjectId } }).then(
        () => {
          switchButtonToInProgress();
          dispatch(setSnackbarInfo(`Clustering initiated`));
          loadCompletedCluster();
          startPolling(1500);
        }
      );
    }
  };

  const [clusterDialogOpen, setClusterDialog] = useState(false);

  const currentStatus = {
    unclustered: statusUnclustered,
    inProgress: clusteringInProgress,
    complete: clusteringComplete,
    repull: clusteringResult,
    error: clusteringError,
  };

  return {
    clusterDialogOpen,
    reclusterStarted,
    currentStatus,
    numberOfTries,
    onClickCancelDialog,
    onClickClusterButton,
    onClickConfirmDialog,
    onClickRepullButton,
    switchButtonToClustered,
    switchButtonToPullClusterStatus,
    switchButtonToUnclustered,
  };
};

export default useClusterer;
