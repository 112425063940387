import { memo, useEffect, useState } from 'react';
import Button from 'components/Button';
import TextField from 'components/TextField';
import CheckIcon from 'icons/Mui/Check';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDocument,
  setNotReviewed,
  setHighlightedValue,
} from 'store/Redux/slices/dataEntrySlice';
import styled from 'styled-components';
import Grid from 'components/Grid';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Stack } from '@mui/material';
import InputAdornment from 'components/InputAdornment';
import { ImageCropIconArea } from 'containers/Cropper';

//#region Styled Components
const STextField = styled(TextField)`
  width: 100%;
  background-color: ${({
    currentCell,
    belowConfidence,
    document,
    valueElementStatus,
  }) =>
    currentCell &&
    belowConfidence &&
    currentCell.status == valueElementStatus.REVIEW_REQUIRED &&
    !document?.isComplete
      ? 'yellow'
      : null};
  box-shadow: ${(props) =>
    props.selected
      ? `0px 0px 0px 2px ${props.theme.palette.primary.main} inset`
      : null};
  & fieldset {
    top: 0;
  }
  & legend {
    display: none;
  }
  & span {
    width: ${(props) => (props.showCellShiftButtons ? '50% !important' : null)};
  }
  & MuiInputBase-root-MuiOutlinedInput-root {
    & span {
      width: 50%;
    }
  }
`;

const ArrowButtons = styled(Button)`
  max-height: 25px;
  border: 1px solid white;
`;
const ConfirmButton = styled(Button)`
  max-height: 25px;
  background-color: ${(props) => `${props.theme.palette.primary.main}`};
  :hover {
    background-color: ${(props) => `${props.theme.palette.primary.darker}`};
  }
`;

const CellShiftButtonsContainer = styled(Stack)`
  width: 50%;
  display: ${(props) =>
    props.isHidden ? 'none' : props.hasIcon ? 'flex' : null};
`;
//#endregion Styled Components

function TableDialogCell(props) {
  const {
    valueElementStatus,
    cell,
    column,
    isComplete,
    onChangeTableValueInput,
    onClickCroppedTextbox,
    row,
    selectedColumn,
    selectedElementField,
    selectedRow,
    showCrop,
    isTableTypePortalOpen,
    tableValues,
    setTableValues,
    getColumnIndex,
    onClickActivateCellShift,
    showCellShiftButtons,
    /* For image cropping */
    crop,
    isCropDisabled,
    onClickCrop,
    onClickRemoveCrop,
    onConfirmCrop,
    onExitCropMode,
    isReadOnly,
    currentDocument,
  } = props;
  const dispatch = useDispatch();
  const confidenceWarninglevel = 0.8;
  const originalValue =
    row?.original?.values?.find((r) => r.tableTypeColumnId === column.id)
      ?.value || '';
  let currentCell = row.original.values.find(
    (v) => v.tableTypeColumnId === column.id
  );
  const belowConfidence = currentCell?.confidenceLevel < confidenceWarninglevel;
  const nonClickedCell = !(
    currentCell?.tableTypeColumnId === selectedColumn.current?.id &&
    row.original.id === selectedRow?.current?.id
  );

  const [input, setInput] = useState(originalValue);
  const [confirmOverride, setConfirmOverride] = useState(null);
  const document = useSelector(selectDocument);

  const getUniqueKey = () => {
    return `${row.original.id}_${currentCell?.tableTypeColumnId}`;
  };
  const key = getUniqueKey();

  useEffect(() => {
    setConfirmOverride(null);
  }, [nonClickedCell]);

  const onChange = (e) => {
    setInput(e.target.value);
  };

  const onBlur = (e) => {
    dispatch(setHighlightedValue([]));
    onChangeTableValueInput(row, cell, column, e.target.value);
  };

  const confirmValueElementStatus = (input) => {
    setInput(input);
    if (currentCell.value === input) {
      currentCell.status = valueElementStatus.REVIEWED_NO_CHANGES_MADE;
    } else {
      currentCell.status = valueElementStatus.REVIEWED_CHANGES_MADE;
    }
    onChangeTableValueInput(row, currentCell, column, input);
  };

  const checkForExistingValue = (direction) => {
    const colIndex = getColumnIndex(currentCell.rowIndex, column.id);
    let targetCell;

    if (direction === -1) {
      targetCell =
        tableValues[currentCell.rowIndex + direction]?.values[colIndex];
    } else {
      targetCell = tableValues[tableValues.length - direction].values[colIndex];
    }

    if (targetCell.value) {
      setConfirmOverride({
        showConfirm: true,
        direction: direction,
        valueToOverride: targetCell.value,
      });
    } else {
      shiftCells(direction);
    }
  };
  const shiftCells = (direction) => {
    const colIndex = getColumnIndex(currentCell.rowIndex, column.id);

    if (direction === -1) {
      for (let i = currentCell.rowIndex; i <= tableValues.length; i++) {
        let targetCell = tableValues[i + direction]?.values[colIndex];
        let previousCell = tableValues[i]?.values[colIndex];
        if (previousCell) {
          targetCell.value = previousCell.value;
        } else if (i === currentCell.rowIndex + 1) {
          targetCell.value = null;
        }
      }
    } else if (direction === 1) {
      for (let i = tableValues.length - 1; i >= currentCell.rowIndex; i--) {
        let targetCell = tableValues[i - direction]?.values[colIndex];
        let previousCell = tableValues[i]?.values[colIndex];
        if (targetCell && i !== currentCell.rowIndex) {
          previousCell.value = targetCell.value;
        } else if (i === currentCell.rowIndex) {
          previousCell.value = null;
        }
      }
    }

    setTableValues(tableValues);
  };

  const renderAdornment = () => {
    const isTargetCell =
      row?.index === selectedRow?.current?.id &&
      column?.id === selectedColumn?.current?.id;

    if (
      currentCell?.status == valueElementStatus.REVIEW_REQUIRED &&
      belowConfidence
    )
      dispatch(setNotReviewed(true));
    let adornment = null;

    if (showCrop && isTargetCell) {
      adornment = (
        <ImageCropIconArea
          crop={crop}
          croppedCoordinates={{ field: 'TableTypeColumn' }}
          isCellMode={true}
          isCropDisabled={isCropDisabled}
          isDocComplete={isComplete}
          isIconHidden={nonClickedCell}
          isWindowMode={isTableTypePortalOpen}
          item={column}
          onClickCrop={onClickCrop}
          onClickRemoveCrop={onClickRemoveCrop}
          onConfirmCrop={onConfirmCrop}
          onExitCropMode={onExitCropMode}
          selectedElementField={selectedElementField}
          value={currentCell?.value}
          currentDocument={currentDocument}
        />
      );
    } else if (
      !document?.isComplete &&
      belowConfidence &&
      currentCell?.status == valueElementStatus.REVIEW_REQUIRED
    ) {
      adornment = (
        <Button
          disabled={currentCell.status !== valueElementStatus.REVIEW_REQUIRED}
          primary
          variant='contained'
          onClick={() => confirmValueElementStatus(currentCell?.value)}
        >
          <CheckIcon />
        </Button>
      );
    } else if (
      showCellShiftButtons &&
      isTargetCell &&
      currentCell.value !== null
    ) {
      adornment = (
        <CellShiftButtonsContainer
          isHidden={
            column?.id !== selectedColumn?.current?.id || nonClickedCell
          }
          hasIcon={!nonClickedCell}
        >
          <Grid item>
            {confirmOverride?.showConfirm &&
            confirmOverride?.direction === -1 ? (
              <ConfirmButton
                variant='contained'
                title={`Confirm shifting all the values from the current row up and overriding of the very first row on this column: ${confirmOverride?.valueToOverride}`}
                fullWidth
                size='small'
                onClick={() => shiftCells(confirmOverride.direction)}
              >
                <CheckIcon />
              </ConfirmButton>
            ) : (
              <ArrowButtons
                primary
                title='Shift up'
                variant='contained'
                fullWidth
                size='small'
                disabled={row?.index === 0}
                onClick={() => {
                  checkForExistingValue(-1);
                }}
              >
                <KeyboardArrowUp />
              </ArrowButtons>
            )}
          </Grid>
          <Grid item>
            {confirmOverride?.showConfirm &&
            confirmOverride?.direction === 1 ? (
              <ConfirmButton
                title={`Confirm shifting all the values from the current row down and overriding of the very last row on this column: ${confirmOverride?.valueToOverride}`}
                fullWidth
                size='small'
                onClick={() => shiftCells(confirmOverride.direction)}
              >
                <CheckIcon />
              </ConfirmButton>
            ) : (
              <ArrowButtons
                primary
                title='Shift down'
                variant='contained'
                fullWidth
                size='small'
                disabled={row?.index === tableValues.length - 1}
                onClick={() => {
                  checkForExistingValue(1);
                }}
              >
                <KeyboardArrowDown />
              </ArrowButtons>
            )}
          </Grid>
        </CellShiftButtonsContainer>
      );
    }
    return <>{adornment}</>;
  };

  return (
    <STextField
      id={currentCell?.coordinates}
      currentCell={currentCell}
      valueElementStatus={valueElementStatus}
      belowConfidence={belowConfidence}
      document={document}
      key={key}
      autofocus={true}
      value={input}
      showCellShiftButtons={showCellShiftButtons}
      onClick={(e) =>
        showCrop || isComplete
          ? onClickCroppedTextbox(e, row, column, originalValue)
          : showCellShiftButtons
          ? onClickActivateCellShift(row, column)
          : false
      }
      onChange={onChange}
      onBlur={(e) => {
        if (originalValue !== input) {
          onBlur(e);
        }
      }}
      onFocus={() => {
        dispatch(
          setHighlightedValue([
            {
              value: currentCell?.value,
              coordinates: currentCell?.coordinates?.split(','),
              type: column?.type,
              yellowHighlight: 'Yes',
            },
          ])
        );
        //setConfirmOverride(confirmOverride);
      }}
      fullWidth
      disabled={isComplete || isReadOnly}
      variant='outlined'
      InputProps={{
        inputProps: {
          component: column,
          readOnly: showCrop,
        },
        style: {
          borderRadius: '0',
          paddingRight: showCellShiftButtons ? '5px' : null,
        },
        disableUnderline: true,
        endAdornment: <InputAdornment>{renderAdornment()}</InputAdornment>,
      }}
    />
  );
}

TableDialogCell.propTypes = {
  cell: PropTypes.any,
  column: PropTypes.object,
  crop: PropTypes.object,
  isComplete: PropTypes.func,
  isCropDisabled: PropTypes.func,
  onChangeTableValueInput: PropTypes.func,
  onClickCrop: PropTypes.any,
  onClickCroppedTextbox: PropTypes.func,
  onClickRemoveCrop: PropTypes.func,
  onConfirmCrop: PropTypes.func,
  onExitCropMode: PropTypes.func,
  row: PropTypes.object,
  selectedColumn: PropTypes.object,
  selectedElementField: PropTypes.any,
  selectedRow: PropTypes.object,
  showCrop: PropTypes.any,
  isTableTypePortalOpen: PropTypes.any,
  selectedTableId: PropTypes.any,
  emailName: PropTypes.any,
  valueElementStatus: PropTypes.object,
  saveCell: PropTypes.func,
  tableValues: PropTypes.array,
  setTableValues: PropTypes.func,
  getColumnIndex: PropTypes.func,
  onClickActivateCellShift: PropTypes.func,
  showCellShiftButtons: PropTypes.bool,
};

const MemoizedTableDialogCell = memo(TableDialogCell);
export default MemoizedTableDialogCell;
