import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from 'components/Button';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import styled from 'styled-components';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import SendIcon from '@mui/icons-material/Send';
import Typography from 'components/Typography';
import Grid from 'components/Grid';

//#region Styled Components

const STypography = styled(Typography)`
  margin-left: 10px;
`;
//#endregion Styled Components

function ExportButton({
  handleProjectMemberDataReportExport,
  exportButtonName,
  handleSendProjectMembers,
  handleTableDataReportExport,
  handleProjectDataElementReportExport,
  handleProjectDocumentDataReportExport,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12}>
      <Button
        size='small'
        variant='contained'
        primary
        onClick={handleExportClick}
        endIcon={<KeyboardArrowDownIcon />}
        className='analysisPage-exportButton'
      >
        {exportButtonName}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleSendProjectMembers();
            handleClose();
          }}
          disableRipple
        >
          <SendIcon style={{ padding: '2px' }} />
          <STypography>Send Project Members</STypography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleProjectMemberDataReportExport();
            handleClose();
          }}
          disableRipple
        >
          <SystemUpdateAltIcon style={{ padding: '2px' }} />
          <STypography>Export Project Member Data</STypography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTableDataReportExport();
            handleClose();
          }}
          disableRipple
        >
          <SystemUpdateAltIcon style={{ padding: '2px' }} />
          <STypography>Export Project Table Data</STypography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleProjectDataElementReportExport();
            handleClose();
          }}
          disableRipple
        >
          <SystemUpdateAltIcon style={{ padding: '2px' }} />
          <STypography>Export Project Data Element Data</STypography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleProjectDocumentDataReportExport();
            handleClose();
          }}
          disableRipple
        >
          <SystemUpdateAltIcon style={{ padding: '2px' }} />
          <STypography>Export Project Document Data</STypography>
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default ExportButton;
