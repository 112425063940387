import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import emailMask from 'text-mask-addons/dist/emailMask';
import Tooltip from 'components/Tooltip';
import dayjs from 'dayjs';

const minimumYear = 1899;
const dateFormatMask = 'mm/dd/yyyy';
const dateFormatMoment = 'MM/DD/YYYY';
const emptyString = '';
const containsChar = /[a-zA-Z]/;
const correctEmailFormat = /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i;
const correctRadioFormat = /^(?:Y\b|y\b|N\b|n\b)/;
const correctSSNFormat = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const correctDateFormat = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const MaskedInputComponent = (props) => {
  const { component: item, ...other } = props;
  return (
    <Tooltip title={props.value ? props.value : '<Please enter the value>'}>
      {/*This tooltip is being used for mouse hover event*/}
      <Tooltip disableHoverListener title={props.value ? props.value : ''}>
        {/*This tooltip is being used for onfocus event*/}
        <span style={{ width: '100%' }}>
          <MaskedInput
            {...other}
            {...(item.type === 'DATE_TIME'
              ? {
                  mask: getMaskFromElementType(item, props.value),
                  pipe: getPipeFromElementType(item, props.value),
                  keepCharPositions: true,
                  placeholderChar: '\u2000',
                }
              : item.type === 'EMAIL'
              ? {
                  mask: getMaskFromElementType(item, props.value),
                  pipe: getPipeFromElementType(item, props.value),
                  placeholderChar: '\u2000',
                }
              : {
                  mask: getMaskFromElementType(item, props.value),
                  placeholderChar: '\u2000',
                })}
            guide={true}
          />
        </span>
      </Tooltip>
    </Tooltip>
  );
};

MaskedInputComponent.displayName = 'MaskedInputComponent';
export default MaskedInputComponent;

function isCoordinatedValue(value) {
  return value !== undefined && value?.startsWith('[') && value?.endsWith(']');
}

function checkValidMask(regExp, value) {
  let testExp = new RegExp(regExp);
  return testExp.test(value);
}

function getPipeFromElementType(element, value) {
  switch (element.type) {
    case 'DATE_TIME':
      return isDateValid(value)
        ? createAutoCorrectedDatePipe(dateFormatMask, { minYear: minimumYear })
        : null;
    case 'EMAIL':
      return isCoordinatedValue(value) ||
        !checkValidMask(correctEmailFormat, value)
        ? null
        : emailMask.pipe;
    default:
      return null;
  }
}

function getMaskFromElementType(element, value) {
  if (isCoordinatedValue(value)) {
    // This is a crop coordinate value
    return createMaskForText();
  }
  switch (element.type) {
    case 'DATE_TIME':
      if (isDateValid(value)) {
        return correctDateFormat;
      }
      return createMaskForText();
    case 'EMAIL':
      if (checkValidMask(correctEmailFormat, value)) {
        return emailMask.mask;
      }
      return createMaskForText();
    case 'DATA_PRESENT':
    case 'CHECKBOX':
    case 'RADIO':
    case 'WET_SIGNATURE':
      if (checkValidMask(correctRadioFormat, value)) {
        return [correctRadioFormat];
      }
      return createMaskForText();
    case 'CURRENCY':
      return createNumberMask({
        prefix: '$',
        allowDecimal: true,
        allowNegative: true,
      });
    case 'NUMBER':
      return createNumberMask({
        prefix: '',
        allowNegative: true,
        allowLeadingZeroes: true,
        includeThousandsSeparator: false,
      });
    case 'DECIMAL':
      return createNumberMask({
        prefix: '',
        allowDecimal: true,
        allowNegative: true,
      });
    case 'SSN':
      if (checkValidMask(correctSSNFormat, value)) {
        return correctSSNFormat;
      }
      return createMaskForText();
    default:
      return createMaskForText();
  }
}

function isDateValid(value) {
  if (value !== undefined &&
      value !== null) {
    if (!dayjs(value, dateFormatMoment, true).isValid()) {
      if (
        !dayjs(
          `${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(
            4,
            8
          )}`,
          dateFormatMoment,
          true
        ).isValid()
      ) {
        return false;
      }
    }
    if (containsChar.test(value)) {
      //Contains string e.g. Jan, Feb, etc.
      return false;
    }
    if (value.substring(4, 8) < minimumYear) {
      //Invalid year
      return false;
    }
    const s = value.split('/');
    if (s.length > 0 && s[0].length < 2) {
      //Invalid month
      return false;
    }
    if (s.length > 1 && s[1].length < 2) {
      //Invalid date
      return false;
    }
    if (s.length > 2 && s[2].length < 4) {
      //Invalid year
      return false;
    }
    if (s.length > 2 && s[2].length > 8) {
      return false;
    }
    const sp = value.split(' ');
    if (sp.length > 0 && sp[0].length < 2) {
      //Invalid month
      return false;
    }
    if (sp.length > 1 && sp[1].length < 2) {
      //Invalid date
      return false;
    }
    if (sp.length > 2 && sp[2].length < 4) {
      //Invalid year
      return false;
    }
  }
  return true;
}

function createMaskForText() {
  function textMask(rawValue = emptyString) {
    const mask = convertToMask(rawValue);
    return mask;
  }
  textMask.instanceOf = 'createMaskForText';
  return textMask;
}

function convertToMask(str) {
  const anyNonWhitespaceRegExp = /[^\s]/;
  const anyWhitespaceRegExp = /[\s]/;
  const space = ' ';
  const ret = str
    .split(emptyString)
    .map((char) =>
      char === space || char === '\n'
        ? anyWhitespaceRegExp
        : anyNonWhitespaceRegExp
    );
  return ret;
}
