import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';

const FormatClusterName = (value, isEditing) => {
    var words = value?.includes('_') ? value.split('_') : value;
    var displayName = value?.includes('_') && words[words.length - 1]?.length >= 32  // Only display ... at the end when the name ends with GUID
        ? words.slice(0, -1).join('_') + "_" + words[words.length - 1].replace("...", "").substring(0, 4) + '...'
        : value;
  return isEditing ? (
    value
  ) : (
    <Tooltip title={value}>
      <span>{displayName}</span>
    </Tooltip>
  );
};

export default FormatClusterName;

FormatClusterName.propTypes = {
  value: PropTypes.string,
  isEditing: PropTypes.bool,
};
