import { useMutation, useLazyQuery } from '@apollo/client';
import Grid from 'components/Grid';
import { statusToEnum } from 'helpers/enumToType';
import { decoder } from 'helpers/graphQL_Encoder';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BULK_UPDATE_ASSIGNMENT_PROJECT_MEMBER } from 'services/GraphQL/Mutations';
import {
  GET_CLAIMS_BY_PROJECT,
  GET_PROJECT_MEMBERS_FOR_CHARTS,
} from 'services/GraphQL/Queries';
import {
  defaultResourceMenu,
  selectCurrentProject,
  selectCurrentResourceName,
  selectProjectMemberStatusTypes,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import { selectMsGroupData } from 'store/Redux/slices/usersSlice';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import _cloneDeep from 'lodash.clonedeep';
import { resources } from 'resource';
import { DataManagementCards } from 'containers/Views/DataMangementCards';

const Assign = () => {
  const dispatch = useDispatch();
  const msGroupData = useSelector(selectMsGroupData);
  const currentProject = useSelector(selectCurrentProject);
  const projectMemberStatus = useSelector(selectProjectMemberStatusTypes);
  const resourceMenu = useSelector(selectCurrentResourceName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const projectMembersLabel = `${selectedResources.ANY_PAGES.PROJECT_MEMBER}`;

  const [projectMembers, setProjectMembers] = useState({});
  const [membersToAssign, setMembersToAssign] = useState([]);
  const [currentProjectUsers, setCurrentProjectUsers] = useState([]);
  const [chosenUser, setChosenUser] = useState(null);
  const [pmFilters, setPmFilters] = useState(null);
  const [open, setOpen] = useState(false);
  const [dateCategories, setDateCategories] = useState([]);
  const [useInReviewData, toggleUserInReviewData] = useState(false);
  const [chartOptions, setChartOptions] = useState([
    {
      id: 0,
      name: 'Radial Bar',
      radial: true,
      selected: true,
      subCharts: [
        {
          id: 0,
          name: 'Time Since Created',
          label: 'Days Since Creation',

          dateGroup: 'createdOn',
          useInReviewData: false,
          selected: true,
        },
        {
          id: 1,
          name: 'Time Since Assigned',
          label: 'Summary of Assigned Privacy Act Requests by HITL',

          dateGroup: 'assignedOn',
          useInReviewData: false,
          selected: false,
        },
        {
          id: 2,
          name: 'Requests Under Review',
          label: 'Summary of Privacy Act Requests Requiring Review',
          dateGroup: 'createdOn',
          useInReviewData: true,
          selected: false,
        },
      ],
    },
    {
      id: 1,
      name: 'Heat Map',
      label: 'Days Since Creation',
      radial: false,
      selected: false,
    },
  ]);
  const [selectedOption, setSelectedOption] = useState(
    chartOptions?.find((c) => c.selected)
  );

  const headers = [
    'originalId',
    'status',
    'assignedTo',
    'assignedOn',
    'createdOn',
  ];
  const projectMemberChartCount = 9999;
  const defaultSortOrder = [{ status: 'ASC' }, { createdOn: 'ASC' }];
  //#region graphql calls
  const [
    getChartProjectMembers,
    { loading: chartPmsLoading, data: chartProjectMembers },
  ] = useLazyQuery(GET_PROJECT_MEMBERS_FOR_CHARTS, {
    variables: {
      first: projectMemberChartCount,
      filters: {
        projectId: { eq: decoder(currentProject?.id) },
        status: useInReviewData
          ? {
              in: [
                projectMemberStatus.DATA_ENTRY_OFF_RAMPED_FOR_REVIEW,
                projectMemberStatus.RECONCILIATION_FLAGGED_FOR_REVIEW,
                projectMemberStatus.RECONCILIATION_ESCALATED_FOR_REVIEW,
              ],
            }
          : {
              nin: [
                projectMemberStatus.COMPLETE,
                projectMemberStatus.REJECTED,
                projectMemberStatus.OFF_RAMPED,
              ],
            },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getProjectMembers,
    { loading: allLoading, fetchMore: fetchMoreProjectMembers },
  ] = useLazyQuery(GET_CLAIMS_BY_PROJECT, {
    onCompleted: (data) => {
      if (data?.projectMembers?.nodes) {
        setProjectMembers(data.projectMembers);
        setMembersToAssign([]);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [updateAssignmentForProjectMemberList] = useMutation(
    BULK_UPDATE_ASSIGNMENT_PROJECT_MEMBER,
    {
      onCompleted: (data) => {
        if (data?.updateAssignmentForProjectMemberList) {
          let updatedProjectMemberList = [...projectMembers.nodes];

          data.updateAssignmentForProjectMemberList.forEach((pm) => {
            const index = projectMembers?.nodes?.findIndex(
              (p) => p.id === pm.id
            );

            if (index >= 0) {
              updatedProjectMemberList[index] = pm;
            }
          });
          setProjectMembers({
            ...projectMembers,
            nodes: updatedProjectMemberList,
          });
          getChartProjectMembers();
          dispatch(setSnackbarSuccess('Assignment(s) successful.'));
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
    }
  );
  //#endregion graphql calls

  //#region component functions
  const onClickSingleRow = (member) => {
    const newMembersToAssign = [...membersToAssign];
    const memberIds = membersToAssign.map((item) => item.id);
    const elementIndex = memberIds.indexOf(member.id);

    elementIndex === -1
      ? newMembersToAssign.push(member)
      : newMembersToAssign.splice(elementIndex, 1);

    setMembersToAssign(newMembersToAssign);
  };

  const onShiftClickRow = (member) => {
    if (membersToAssign) {
      const lastMemId = member.id;

      let selectedMembers;

      const firstMemIndex = projectMembers?.nodes?.indexOf(membersToAssign[0]);
      const lastMemIndex = projectMembers?.nodes?.findIndex(
        (mem) => mem.id === lastMemId
      );

      if (lastMemIndex > firstMemIndex) {
        selectedMembers = projectMembers?.nodes?.slice(
          firstMemIndex,
          lastMemIndex + 1
        );
      } else if (lastMemIndex < firstMemIndex) {
        selectedMembers = projectMembers?.nodes?.slice(
          lastMemIndex,
          firstMemIndex + 1
        );
      } else {
        selectedMembers = [projectMembers?.nodes[firstMemIndex]];
      }

      setMembersToAssign(selectedMembers);
    }
  };

  const onClickSelectAll = () => {
    setMembersToAssign(projectMembers?.nodes);
  };

  const onClose = () => {
    setOpen(false);
  };

  const openAssignUsers = () => {
    setOpen(true);
  };

  const confirmAssignUser = () => {
    let chosenUserName = null;
    if (chosenUser?.mail !== 'Unassign') {
      chosenUserName = chosenUser.mail.substring(
        0,
        chosenUser.mail.lastIndexOf('@')
      );
    }

    let membersPayload = membersToAssign.map((m) => {
      return {
        projectMemberId: decoder(m.id),
        username: chosenUserName,
      };
    });
    updateAssignmentForProjectMemberList({
      variables: { inputs: membersPayload },
    });
    onClose();
    setMembersToAssign([]);
    setChosenUser(null);
  };

  const handleChartOptionChange = (e) => {
    const name = e.target.value;
    const updatedChartOptions = chartOptions?.map((option) => {
      if (option.name === name) {
        const updatedOption = { ...option, selected: true };
        setSelectedOption(updatedOption);
        return updatedOption;
      }
      return { ...option, selected: false };
    });
    setChartOptions(updatedChartOptions);
  };

  const getDefaultProjectMembers = () => {
    getProjectMembers({
      variables: {
        sortOrder: defaultSortOrder,
        includeDocInfo: false,
        filters: {
          projectId: { eq: decoder(currentProject?.id) },
        },
      },
    }).then(() => {
      setPmFilters(null);
    });
  };

  const refreshAssignPageData = () => {
    let scrollArea = document.getElementById('data-list');
    scrollArea?.scrollTo(0, 0);

    getChartProjectMembers().then(() => {
      getDefaultProjectMembers();
    });
  };

  const filterProjectMemberDataByChart = (cell) => {
    let userId = cell.serieId || cell.groupId;
    let user = dateCategories?.find((u) => u.assignedTo === userId);
    let ids = user.dates[0][cell.data.x].map((i) => {
      return decoder(i.id);
    });

    if (user && ids?.length > 0) {
      let dataFilters = {
        projectId: { eq: decoder(currentProject?.id) },
        assignedTo:
          user.assignedTo === 'unassigned'
            ? { eq: null }
            : { eq: user.assignedTo },
        id: { in: ids },
      };
      setPmFilters(dataFilters);

      getProjectMembers({
        variables: {
          sortOrder: defaultSortOrder,
          includeDocInfo: false,
          filters: dataFilters,
        },
      });
    }
  };

  const handleFetchMoreProjectMembers = () => {
    fetchMoreProjectMembers({
      variables: {
        filters: pmFilters
          ? pmFilters
          : {
              projectId: { eq: decoder(currentProject?.id) },
            },
        sortOrder: defaultSortOrder,
        cursor: projectMembers.pageInfo.endCursor,
        includeDocInfo: false,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // we are not using prev because we don't use graphQL cache for our data
        if (!fetchMoreResult) return projectMembers;
        let newResult = _cloneDeep(fetchMoreResult);
        newResult.projectMembers.nodes = [
          ...projectMembers.nodes,
          ...newResult.projectMembers.nodes,
        ];
        setProjectMembers(newResult.projectMembers);
      },
    });
  };

  const handleRefetchProjectMembersSearch = (queryValue) => {
    if (queryValue) {
      let statuses = statusToEnum(queryValue.trim(), projectMemberStatus);
      let searchFilters = {
        projectId: { eq: decoder(currentProject?.id) },

        or: [
          { originalId: { contains: queryValue.trim() } },
          statuses
            ? {
                status: {
                  in: statuses,
                },
              }
            : {},
          { assignedTo: { contains: queryValue.trim() } },
        ],
      };
      setPmFilters(searchFilters);
      getProjectMembers({
        variables: {
          sortOrder: defaultSortOrder,
          includeDocInfo: false,
          filters: searchFilters,
        },
      });
    } else {
      setPmFilters(null);

      getProjectMembers({
        variables: {
          sortOrder: defaultSortOrder,
          includeDocInfo: false,
          filters: {
            projectId: { eq: decoder(currentProject?.id) },
          },
        },
      });
    }
  };

  //#endregion component functions

  //#region useEffects

  useEffect(() => {
    if (currentProject) {
      refreshAssignPageData();
      if (msGroupData?.length > 0) {
        let projectUsers = msGroupData.find(
          (g) => g.displayName === currentProject?.name
        )?.members;
        if (projectUsers) {
          let membersAndNull = [...projectUsers];
          membersAndNull.unshift({ displayName: 'Unassign', mail: 'Unassign' });
          setCurrentProjectUsers(membersAndNull);
        }
      }
    }
  }, [currentProject]);

  useEffect(() => {
    getChartProjectMembers();
  }, [useInReviewData]);

  useEffect(() => {
    if (pmFilters) {
      getDefaultProjectMembers();
    }
  }, [chartOptions]);
  //#endregion useEffects

  //#region JSX Elements
  //#endregion JSX Elements

  return (
    <ProjectSelectedTemplate>
      <Grid container alignItems='center' spacing={2}>
        <DataManagementCards
          chartHeaderLabel={projectMembersLabel}
          tableHeaderLabel={projectMembersLabel}
          selectedOption={selectedOption}
          handleChartOptionChange={handleChartOptionChange}
          chartOptions={chartOptions}
          setChartOptions={setChartOptions}
          defaultSortOrder={defaultSortOrder}
          chartData={chartProjectMembers?.projectMembers}
          tableData={projectMembers}
          setData={setProjectMembers}
          getPMsForDocumentView={getProjectMembers}
          currentProjectId={currentProject?.id}
          tableFilters={pmFilters}
          membersToAssign={membersToAssign}
          refreshPageData={refreshAssignPageData}
          chartLoading={chartPmsLoading}
          allLoading={allLoading}
          onClickSelectAll={onClickSelectAll}
          setMembersToAssign={setMembersToAssign}
          getDefaultData={getDefaultProjectMembers}
          headers={headers}
          onShiftClickRow={onShiftClickRow}
          onClickSingleRow={onClickSingleRow}
          handleFetchMoreData={handleFetchMoreProjectMembers}
          handleRefetchDataSearch={handleRefetchProjectMembersSearch}
          chosenUser={chosenUser}
          setChosenUser={setChosenUser}
          currentProjectUsers={currentProjectUsers}
          confirmAssignUser={confirmAssignUser}
          openAssignUsers={openAssignUsers}
          onClose={onClose}
          open={open}
          toggleUserInReviewData={toggleUserInReviewData}
          handleOnChartClick={filterProjectMemberDataByChart}
          dateCategories={dateCategories}
          setDateCategories={setDateCategories}
          projectMemberStatus={projectMemberStatus}
          selectedResources={selectedResources}
        />
      </Grid>
    </ProjectSelectedTemplate>
  );
};

export default Assign;
