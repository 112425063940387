import { gql } from '@apollo/client';
import {
  CORE_CLUSTER_FIELDS,
  CORE_DELEMENT_FIELDS,
  CORE_DOC_FIELDS,
  CORE_PROJECTMEMBER_FIELDS,
  CORE_PROJECT_FIELDS,
  CORE_SUPPLEMENTALDATA_FIELDS,
  CORE_TABLEDATA_FIELDS,
  CORE_TASKITEM_FIELDS,
  CORE_VELEMENT_FIELDS,
} from './Fragments';

// Application queries
export const LOAD_GLOBAL_ELEMENTS = gql`
  ${CORE_DELEMENT_FIELDS}
  query LoadGlobalElements {
    dataElements(first: 500) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      nodes {
        ...CoreDElementFields
      }
    }
  }
`;

export const LOAD_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      id
      name
    }
  }
`;

export const LOAD_PROJECTS_BY_ORGANIZATION_ID = gql`
  ${CORE_PROJECT_FIELDS}
  query GetProjectsByOrganizationId($id: ID!) {
    organizationById(id: $id) {
      id
      name
      projects {
        ...CoreProjectFields
      }
    }
  }
`;

export const GET_PROJECT_BY_NAME_AND_ORGANIZATION_ID = gql`
  query GetProjectByNameAndOrganizationId(
    $organizationId: ID!
    $name: String!
  ) {
    projectByNameAndOrganizationId(
      organizationId: $organizationId
      projectName: $name
    ) {
      id
      name
      organizationId
      isComplete
      clusteringId
      isClusteringComplete
      createdOn
      lastModifiedOn
      resourceName
    }
  }
`;

export const LOAD_USER_PROJECTS = gql`
  ${CORE_PROJECT_FIELDS}
  query GetProjectsByUserGroups($groups: [UserGroupProjectInput!]) {
    projectsByUserGroups(groups: $groups) {
      ...CoreProjectFields
    }
  }
`;

export const LOAD_PROJECT_TASKS = gql`
  ${CORE_TASKITEM_FIELDS}
  query GetProjectTasks($id: ID!) {
    projectById(id: $id) {
      taskList {
        id
        totalTasks
        tasks {
          ...CoreTaskItemFields
        }
      }
    }
  }
`;

export const GET_CLUSTERS_BY_PROJECT = gql`
  query GetClustersByProject(
    $first: Int = 50
    $cursor: String
    $sortOrder: [ClusterSortInput!]
    $filters: ClusterFilterInput
  ) {
    clusters(
      first: $first
      after: $cursor
      where: $filters
      order: $sortOrder
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        name
        isArchived
        projectId
        dataExtractionId
        totalDataElements
        totalDocuments
        totalCompleteDocuments
        createdOn
        lastModifiedOn
        documents {
          id
          isConfirmed
          projectMemberId
        }
      }
    }
  }
`;

export const GET_PROJECT_MEMBERS_FOR_CHARTS = gql`
  query GetProjectMembersForCharts(
    $filters: ProjectMemberFilterInput
    $first: Int
  ) {
    projectMembers(where: $filters, first: $first) {
      nodes {
        id
        assignedTo
        createdOn
        assignedOn
      }
    }
  }
`;

export const GET_CLAIMS_BY_PROJECT = gql`
  ${CORE_DOC_FIELDS}
  ${CORE_PROJECTMEMBER_FIELDS}
  query GetClaimsByProject(
    $first: Int = 50
    $cursor: String
    $sortOrder: [ProjectMemberSortInput!]
    $includeDocInfo: Boolean!
    $filters: ProjectMemberFilterInput
  ) {
    projectMembers(
      first: $first
      after: $cursor
      order: $sortOrder
      where: $filters
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...CoreProjectMemberFields
        documents @include(if: $includeDocInfo) {
          ...CoreDocFields
          projectMember {
            ...CoreProjectMemberFields
          }
          cluster {
            id
            name
            totalDataElements
            totalDocuments
            totalCompleteDocuments
          }
        }
      }
    }
  }
`;

export const GET_CLAIMS_BY_PROJECT_STATUS_FILTER = gql`
  ${CORE_DOC_FIELDS}
  ${CORE_PROJECTMEMBER_FIELDS}
  query GetClaimsByProjectByStatus(
    $id: Uuid!
    $statuses: [ProjectMemberStatusType!]!
    $includeDocInfo: Boolean!
    $sortOrder: [ProjectMemberSortInput!]
  ) {
    projectMembers(
      where: {
        and: [{ projectId: { eq: $id } }, { status: { in: $statuses } }]
      }
      order: $sortOrder
    ) {
      ...CoreProjectMemberFields
      documents @include(if: $includeDocInfo) {
        ...CoreDocFields
        projectMember {
          ...CoreProjectMemberFields
        }
        cluster {
          id
          name
          totalDocuments
        }
      }
    }
  }
`;

export const GET_DOCS_BY_CLAIM = gql`
  ${CORE_DOC_FIELDS}
  ${CORE_PROJECTMEMBER_FIELDS}
  query getDocsByClaim(
    $first: Int = 50
    $cursor: String
    $sortOrder: [DocumentSortInput!]
    $filters: DocumentFilterInput
  ) {
    documents(
      first: $first
      after: $cursor
      order: $sortOrder
      where: $filters
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      nodes {
        ...CoreDocFields
        projectMember {
          ...CoreProjectMemberFields
        }
        cluster {
          id
          name
          dataExtractionId
        }
      }
    }
  }
`;

export const GET_DOCS_BY_CLUSTER = gql`
  ${CORE_DOC_FIELDS}
  query GetDocumentsByCluster(
    $first: Int = 50
    $cursor: String
    $sortOrder: [DocumentSortInput!]
    $filters: DocumentFilterInput
  ) {
    documents(
      first: $first
      after: $cursor
      order: $sortOrder
      where: $filters
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      nodes {
        ...CoreDocFields
        projectMember {
          id
          originalId
          status
        }
        cluster {
          id
          name
          dataExtractionId
          totalDataElements
        }
      }
    }
  }
`;

export const GET_DOCS_BY_CLUSTER_STATUS_FILTER = gql`
  ${CORE_DOC_FIELDS}
  ${CORE_PROJECTMEMBER_FIELDS}
  query GetDocumentsByClusterStatus(
    $id: Uuid!
    $statuses: [ProjectMemberStatusType!]!
    $sortOrder: [DocumentSortInput!]
  ) {
    documents(
      where: {
        or: [
          {
            and: [
              { projectMember: { status: { in: $statuses } } }
              { clusterId: { eq: $id } }
            ]
          }
          {
            and: [{ projectMemberId: { eq: null } }, { clusterId: { eq: $id } }]
          }
        ]
      }
      order: $sortOrder
    ) {
      ...CoreDocFields
      projectMember {
        ...CoreProjectMemberFields
      }
    }
  }
`;

export const GET_DATA_ELEMENTS_BY_CLUSTER = gql`
  ${CORE_DELEMENT_FIELDS}
  query GetDataElementsByCluster($id: ID!) {
    clusterById(id: $id) {
      dataElements(order: { createdOn: ASC }) {
        ...CoreDElementFields
      }
    }
  }
`;

export const GET_DATA_ELEMENTS_BY_PROJECT = gql`
  ${CORE_DELEMENT_FIELDS}
  query GetDataElementsByProject($id: Uuid!, $cursor: String) {
    projects(where: { id: { eq: $id } }) {
      dataElements(first: 500, after: $cursor) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
        nodes {
          ...CoreDElementFields
        }
      }
    }
  }
`;

export const GET_CURRENT_PROJECT = gql`
  ${CORE_PROJECT_FIELDS}
  ${CORE_CLUSTER_FIELDS}
  ${CORE_DOC_FIELDS}
  ${CORE_DELEMENT_FIELDS}
  ${CORE_TABLEDATA_FIELDS}
  query GetCurrentProject($id: ID!) {
    projectById(id: $id) {
      ...CoreProjectFields
      clusters {
        ...CoreClusterFields
        dataElements {
          ...CoreDElementFields
          tableData {
            ...CoreTableDataFields
          }
        }
        documents {
          ...CoreDocFields
        }
      }
      unclusteredDocuments {
        ...CoreDocFields
      }
      dataElements {
        ...CoreDElementFields
        tableData {
          ...CoreTableDataFields
        }
      }
    }
  }
`;

export const GET_PROJECT_DOCUMENTS = gql`
  ${CORE_DOC_FIELDS}
  query GetUnclusteredDocuments(
    $first: Int = 50
    $id: ID!
    $cursor: String
    $filters: DocumentFilterInput
  ) {
    projectById(id: $id) {
      unclusteredDocuments(first: $first, after: $cursor, where: $filters) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
        }
        nodes {
          ...CoreDocFields
        }
      }
    }
  }
`;

export const LOAD_CLUSTER_PROJECT_BY_ID = gql`
  query GetClusterByProject($id: ID!) {
    clusterProjectById(id: $id)
  }
`;

export const LOAD_COMPLETED_CLUSTER = gql`
  query GetCompletedCluster($id: ID!, $username: String!) {
    completedCluster(id: $id, username: $username) {
      progress
      status
    }
  }
`;

export const LOAD_DOCUMENTS_ELEMENTS_BY_ID = gql`
  query GetDocumentElementsByProject($id: ID!) {
    projectById(id: $id) {
      name
      clusteringId
      isClusteringComplete
      clusters {
        dataElements {
          id
          name
          tableData {
            id
            name
            type
            dataElementId
          }
        }
        documents {
          id
          isComplete
          valueElements {
            id
            name
            value
          }
        }
      }
    }
  }
`;

export const REQUEST_CLUSTER = gql`
  query RequestCluster($id: ID!) {
    projectById(id: $id) {
      name
      clusteringId
      isClusteringComplete
    }
  }
`;

export const GET_PROJECT_DOC_NAMES = gql`
  query GetDocumentNamesByProject($id: ID!) {
    projectById(id: $id) {
      documents {
        originalName
      }
    }
  }
`;

export const LOAD_PROJECTS_ELEMENTS_BY_ID = gql`
  query GetProjectElements($id: ID!) {
    projectById(id: $id) {
      id
      name
      isComplete
      createdOn
      dataElements {
        id
        name
        description
        type
        tableData {
          id
          name
          type
          dataElementId
        }
      }
    }
    dataElements {
      id
      name
      description
      type
      tableData {
        id
        name
        type
        dataElementId
      }
    }
  }
`;

export const GET_PROJECT_MEMBERS = gql`
  ${CORE_PROJECTMEMBER_FIELDS}
  query GetProjectMembers(
    $first: Int = 50
    $cursor: String
    $sortOrder: [ProjectMemberSortInput!]
    $filters: ProjectMemberFilterInput
  ) {
    projectMembers(
      first: $first
      after: $cursor
      order: $sortOrder
      where: $filters
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      nodes {
        ...CoreProjectMemberFields
      }
    }
  }
`;

export const GET_PROJECT_MEMBER_BY_ID = gql`
  ${CORE_PROJECTMEMBER_FIELDS}
  query GetProjectMemberById($id: ID!) {
    projectMemberById(id: $id) {
      ...CoreProjectMemberFields
    }
  }
`;

export const GET_MEMBER_DETAILS_BY_ID = gql`
  ${CORE_PROJECTMEMBER_FIELDS}
  query GetMemberDetailsById($id: ID!) {
    projectMemberById(id: $id) {
      ...CoreProjectMemberFields
      valueElements {
        id
        value
        dataElementId
        dataElement {
          name
        }
        documentId
        document {
          name
          originalName
          documentUri
          cluster {
            name
          }
        }
        isReconciled
      }
      supplementalDatas {
        id
        elementName
        value
      }
    }
  }
`;

export const GET_DOCUMENT_MEMBER = gql`
  ${CORE_SUPPLEMENTALDATA_FIELDS}
  ${CORE_PROJECTMEMBER_FIELDS}
  query DocumentProjectMember($id: ID!) {
    documentById(id: $id) {
      projectMember {
        ...CoreProjectMemberFields
        supplementalDatas {
          ...CoreSupplementalDataFields
        }
      }
    }
  }
`;

// #region Storage Tokens
export const READ_TOKEN = gql`
  query SASReadToken($id: ID!) {
    storageAccountReadToken(organizationId: $id)
  }
`;

export const GET_PRESIGNED_URLS_S3 = gql`
  query GetPreSignedUrlList($urlList: [String!]!) {
    preSignedUrlList(urlList: $urlList) {
      key
      value
    }
  }
`;
// #endregion

// #region Preprocessing
export const START_DATA_EXTRACTION = gql`
  query StartDataElementExtraction(
    $extractionInput: [DataElementExtractionInput]!
  ) {
    startDataElementExtraction(extractionInput: $extractionInput)
  }
`;

export const PULL_DATA_EXTRACTION = gql`
  query PullDataElementExtraction($clusterId: Uuid!, $currentUser: String!) {
    dataElementExtractionValues(
      clusterId: $clusterId
      currentUser: $currentUser
    )
  }
`;
// #endregion

//#region Enum Queries
export const LOAD_ELEMENT_TYPE_ENUMS = gql`
  query GetElementTypeEnums {
    elementTypeEnums {
      name
      enumValues {
        name
      }
    }
  }
`;

export const LOAD_ITEM_STATUS_ENUMS = gql`
  query GetItemStatusEnums {
    itemStatusEnums {
      name
      enumValues {
        name
      }
    }
  }
`;

export const LOAD_TASK_TYPE_ENUMS = gql`
  query GetTaskTypeEnums {
    taskTypeEnums {
      name
      enumValues {
        name
      }
    }
  }
`;

export const LOAD_VALUE_ELEMENT_STATUS_TYPE_ENUMS = gql`
  query GetValueElementStatusEnums {
    valueElementStatusTypeEnums {
      name
      enumValues {
        name
      }
    }
  }
`;

export const LOAD_PROJECT_MEMBER_STATUS_TYPE_ENUMS = gql`
  query GetProjectMemberStatusEnums {
    projectMemberStatusTypeEnums {
      name
      enumValues {
        name
      }
    }
  }
`;

export const LOAD_STATUS_JUSTIFICATION_TYPE_ENUMS = gql`
  query GetStatusJustificationEnums {
    statusJustificationTypeEnums {
      name
      enumValues {
        name
      }
    }
  }
`;
// #endregion

export const LOAD_VALUE_ELEMENTS_BY_DOC_ID = gql`
  ${CORE_VELEMENT_FIELDS}
  query GetValueElementsByDocument($id: ID!) {
    documentById(id: $id) {
      id
      name
      valueElements(order: { status: DESC }) {
        ...CoreVElementFields
      }
    }
  }
`;

export const GET_TABLEDATA_BY_DATAELEMENT_ID = gql`
  ${CORE_TABLEDATA_FIELDS}
  query GetTableDataByDataElement($id: ID!) {
    dataElementById(id: $id) {
      id
      name
      type
      tableData(order: { createdOn: ASC }) {
        ...CoreTableDataFields
      }
    }
  }
`;

export const LOAD_VALUE_ELEMENTS_BY_TABLE_ID = gql`
  ${CORE_VELEMENT_FIELDS}
  query GetValueElementsByTableType($tableTypeColumnId: ID!) {
    valueElementByTableId(tableTypeColumnId: $tableTypeColumnId) {
      ...CoreVElementFields
      document {
        originalName
        projectId
      }
    }
  }
`;

export const LOAD_TABLE_VALUE_ELEMENTS = gql`
  ${CORE_VELEMENT_FIELDS}
  query GetTableTypeValueElementsByDocument(
    $tableTypeColumnId: ID!
    $documentId: ID!
  ) {
    tableValueElements(
      tableTypeColumnId: $tableTypeColumnId
      documentId: $documentId
    ) {
      ...CoreVElementFields
    }
  }
`;

export const CHECK_VALUE_ELEMENTS_NEED_REVIEW = gql`
  query CheckValueElementsNeedReview($id: ID!) {
    valueElementsNeedReviewExist(id: $id)
  }
`;

export const GET_PROJECT_DETAIL = gql`
  query GetProjectDetail($id: ID!) {
    projectById(id: $id) {
      id
      name
      organizationId
    }
  }
`;

export const GET_PROJECT_MEMBERS_TO_BE_UPLOADED = gql`
  query GetProjectMembersToBeUploaded($id: ID!) {
    projectMembersToBeUploaded(id: $id) {
      originalId
      documents {
        documentUri
        originalName
        cluster {
          name
        }
        valueElements {
          dataElementId
          value
          tableTypeColumnId
          rowIndex
        }
      }
    }
  }
`;

export const TEMPLATE_EXISTS_IN_CLUSTER = gql`
  query TemplateExistsInCluster($clusterId: Uuid!) {
    templateExistsInCluster(clusterId: $clusterId)
  }
`;

export const WAS_EXTRACTION_REQUESTED = gql`
  query WasExtractionRequested($clusterIds: [Uuid!]!) {
    wasExtractionRequested(clusterIds: $clusterIds) {
      key
      value
    }
  }
`;
