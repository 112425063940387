export function encoder(type, id) {
  let s = `${type}\ng${id}`;
  return btoa(unescape(encodeURIComponent(s)));
}

export function decoder(encodedString) {
  if (!encodedString || encodedString==='UNCLUSTERED' ) return null;
  let string = decodeURIComponent(escape(atob(encodedString)));
  return string.substring(string.indexOf('\ng') + 2);
}

//export function encoder(type, id) {
//  let string = `${type}\ng${id}`;
//  return Buffer.from(string, 'utf8').toString('base64');
//}

//export function decoder(encodedString) {
//  if (!encodedString) return null;
//  let string = Buffer.from(encodedString, 'base64').toString('utf8');
//  return string.substring(string.indexOf('\ng') + 2);
//}

export function getGuid(str) {
  return (
    str.slice(0, 8) +
    '-' +
    str.slice(8, 12) +
    '-' +
    str.slice(12, 16) +
    '-' +
    str.slice(16, 20) +
    '-' +
    str.slice(20, str.length + 1)
  ).toUpperCase();
}
