import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  currentMember: {},
  dataElements: [],
  sourceIndex: 0,
  choiceIndex: undefined,
  currentElementIndex: undefined,
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setCurrentMember(state, action) {
      state.currentMember = action.payload;
    },
    setDataElements(state, action) {
      const valueElements = action.payload;
      const elementMap = new Map();
      //array builder
      function getChoiceArray(e, choice = null) {
        return [
          {
            id: choice ? [...choice.id, e.id] : [e.id],
            dataElementId: choice
              ? [...choice.dataElementId, e.dataElementId]
              : [e.dataElementId],
            name: choice
              ? [...choice.name, e?.dataElement?.name]
              : [e?.dataElement?.name],
            value: choice ? choice.value : e.value,
            source: choice
              ? [...choice.source, e.document.cluster.name]
              : [e.document.cluster.name],
            document: choice
              ? [...choice.document, e.document.name]
              : [e.document.name],
            image: choice
              ? [...choice.image, e.document?.documentUri]
              : [e.document?.documentUri],
            status: choice
              ? [...choice.status, e.isReconciled]
              : e.value?.length > 0
              ? [e.isReconciled]
              : 'Not Applicable',
          },
        ];
      }

      valueElements?.forEach((element) => {
        let elementName = element?.dataElement?.name || element?.name;
        if (elementMap.has(elementName)) {
          const currentChoices = elementMap.get(elementName);

          const valueAlreadyExists = currentChoices.some(
            (e) => e?.value?.toLowerCase() === element?.value?.toLowerCase()
          );

          const notSupplemental =
            element?.document?.cluster?.name !== 'Supplemental Data';

          if (valueAlreadyExists && notSupplemental) {
            const existingChoice = currentChoices.filter(
              (c) => c?.value?.toLowerCase() === element?.value?.toLowerCase()
            )[0];
            const existingChoiceIndex = currentChoices.indexOf(existingChoice);
            const updatedChoice = getChoiceArray(element, existingChoice);
            currentChoices[existingChoiceIndex] = updatedChoice[0];
            elementMap.set(elementName, currentChoices);
          } else {
            const newChoice = getChoiceArray(element);
            const updatedChoices = [...currentChoices, ...newChoice];
            elementMap.set(elementName, updatedChoices);
          }
        } else {
          const dataChoices = getChoiceArray(element);
          elementMap.set(elementName, dataChoices);
        }
      });

      const elementArray = Array.from(elementMap, ([key, value]) => ({
        name: key,
        dataChoices: value,
      }));

      //check for reconciled valueElements
      elementArray.forEach((element, index) => {
        element.dataChoices.forEach((choice) => {
          if (choice.status.includes(true)) {
            //find index of true in source array
            const reconciledIndex = choice.status.indexOf(true);

            elementArray[index].selectedChoice = {
              id: choice.id[reconciledIndex],
              dataElementId: choice.dataElementId,
              name: element.name,
              value: choice.value,
              isReconciled: true,
              source: choice.source[reconciledIndex],
              document: {
                name: choice.document[reconciledIndex],
              },
            };
          }
        });
      });

      state.dataElements = elementArray;
    },
    reconcileElement(state, action) {
      const dataObject = {
        id: action.payload.id,
        dataElementId: action.payload.dataElementId,
        name: action.payload.dataElement?.name,
        value: action.payload.value,
        isReconciled: true,
        document: action.payload.document,
        source: action.payload.document.cluster.name,
      };

      state.dataElements[state.currentElementIndex].selectedChoice = dataObject;
    },
    setCurrentElementIndex(state, action) {
      state.currentElementIndex = action.payload;
    },
    setChoiceIndex(state, action) {
      state.choiceIndex = action.payload;
    },
    setSourceIndex(state, action) {
      state.sourceIndex = action.payload;
    },
    updateChoice(state, action) {
      const { newValue } = action.payload;

      const currentElement = state.dataElements[state.currentElementIndex];

      const currentChoice = current(
        state.dataElements[state.currentElementIndex]?.dataChoices[
          state.choiceIndex
        ]
      );

      const valueAlreadyExists = currentElement.dataChoices.some(
        (e) => e.value.toLowerCase() === newValue.toLowerCase()
      );

      if (currentChoice.id.length === 1 && !valueAlreadyExists) {
        const updatedChoice = {
          id: currentChoice.id,
          dataElementId: currentChoice.dataElementId,
          name: currentChoice.name,
          value: newValue,
          source: currentChoice.source,
          document: currentChoice.document,
          image: currentChoice.image,
          status: currentChoice.status,
        };

        state.dataElements[state.currentElementIndex].dataChoices.splice(
          state.choiceIndex,
          1,
          updatedChoice
        );

        //if the current choice is also the selected choice - update that value
        if (
          state.dataElements[state.currentElementIndex].selectedChoice?.id ===
          currentChoice.id[0]
        ) {
          state.dataElements[state.currentElementIndex].selectedChoice.value =
            newValue;
        }
      } else {
        const newChoice = {
          id: [currentChoice.id[state.sourceIndex]],
          dataElementId: [currentChoice.dataElementId[state.sourceIndex]],
          name: [currentChoice.name[state.sourceIndex]],
          value: newValue,
          source: [currentChoice.source[state.sourceIndex]],
          document: [currentChoice.document[state.sourceIndex]],
          image: [currentChoice.image[state.sourceIndex]],
          status: [currentChoice.status[state.sourceIndex]],
        };
        let updatedChoice = newChoice;

        //If the current input value already exists in the table...
        if (
          currentElement.dataChoices.some((choice) => choice.value === newValue)
        ) {
          //Find existing choice to append changed choice to
          const choice = current(
            currentElement.dataChoices.find(
              (choice) => choice.value === newValue
            )
          );
          const choiceIndex = currentElement.dataChoices.findIndex(
            (item) => item.value === choice.value
          );

          const notSupplemental = !choice.source.includes('Supplemental Data');

          if (notSupplemental) {
            updatedChoice = {
              id: choice.id.concat(currentChoice.id),
              dataElementId: choice.dataElementId.concat(
                currentChoice.dataElementId
              ),
              name: choice.name.concat(currentChoice.name),
              value: choice.value,
              source: choice.source.concat(currentChoice.source),
              document: choice.document.concat(currentChoice.document),
              image: choice.image.concat(currentChoice.image),
              status: choice.status.concat(currentChoice.status),
            };

            state.dataElements[state.currentElementIndex].dataChoices.splice(
              choiceIndex,
              1,
              updatedChoice
            );
            state.dataElements[state.currentElementIndex].dataChoices.splice(
              state.choiceIndex,
              1
            );
          }
          state.dataElements[state.currentElementIndex].dataChoices.splice(
            state.choiceIndex,
            1,
            updatedChoice
          );
        } else if (newValue !== currentChoice.value) {
          currentChoice.source.splice(state.sourceIndex, 1);

          const updatedChoice = {
            id: currentChoice.id.filter((id) => id !== newChoice.id[0]),
            dataElementId: currentChoice.dataElementId.filter(
              (dataElementId) => dataElementId !== newChoice.dataElementId[0]
            ),
            name: currentChoice
              .name(currentChoice.name)
              .filter((name) => name !== newChoice.name[0]),
            value: currentChoice.value,
            source: currentChoice.source,
            document: currentChoice.document.filter(
              (doc) => doc !== newChoice.document[0]
            ),
            image: currentChoice.image.filter(
              (img) => img !== newChoice.image[0]
            ),
            status: currentChoice.status.filter(
              (stat) => stat !== newChoice.status[0]
            ),
          };

          state.dataElements[state.currentElementIndex].dataChoices.splice(
            state.choiceIndex,
            1,
            updatedChoice
          );
          state.dataElements[state.currentElementIndex].dataChoices.push(
            newChoice
          );

          state.sourceIndex = 0;
        }
      }
    },
  },
});

//Selectors
export const selectCurrentMember = (state) => state.analysis.currentMember;
export const selectDataElements = (state) => state.analysis.dataElements;
export const selectChoiceIndex = (state) => state.analysis.choiceIndex;
export const selectSourceIndex = (state) => state.analysis.sourceIndex;
export const selectCurrentElementIndex = (state) =>
  state.analysis.currentElementIndex;
export const selectCurrentElement = (state) =>
  state.analysis.dataElements[state.analysis.currentElementIndex] || {};
export const selectCurrentDataChoice = (state) =>
  state.analysis.dataElements[state.analysis.currentElementIndex]?.dataChoices[
    state.analysis.choiceIndex
  ] || {};

//Action Creators
export const {
  setSourceIndex,
  setDataElements,
  setCurrentElementIndex,
  setChoiceIndex,
  reconcileElement,
  setCurrentMember,
  updateChoice,
} = analysisSlice.actions;

export default analysisSlice.reducer;
