import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import Button from 'components/Button';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Typography from 'components/Typography';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//#region Styled Components
const DescriptionContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopUpButton = styled(Button)`
  visibility: ${(props) => (props.hidden ? 'hidden' : null)};
  float: right;
`;

const PopUpMenu = styled.div`
  text-align: center;
  cursor: pointer;
  line-height: normal;
`;

const Availability = styled.div`
  display: ${(props) => (props.assignedTo ? 'none' : null)};
  position: sticky;
`;

const ClaimButton = styled(Button)`
  position: initial;
  border: 1px solid grey;
  color: ${(props) => props.theme.palette.background.lighter};
`;

const SMenuItem = styled(MenuItem)`
  padding: 5px 15px 5px 5px;

  & svg {
    margin: 0 10px;
  }
`;
//#endregion Styled Components

//#region Description
function PopupMenu(props) {
  const {
    row,
    currentUserRole,
    toggleUpdateTaskModal,
    toggleAssignUserModal,
    unassignUserFromTask,
    toggleDeleteConfirmModal,
  } = props;

  return (
    <PopUpMenu>
      {currentUserRole === 'Admin' && (
        <PopupState variant='popover'>
          {(popupState) => (
            <>
              <PopUpButton
                primary
                variant='contained'
                {...bindTrigger(popupState)}
              >
                <MoreHorizIcon style={{ fontSize: 20 }} />
              </PopUpButton>
              <Menu {...bindMenu(popupState)}>
                <SMenuItem onClick={toggleAssignUserModal}>
                  <AssignmentIndIcon />
                  <Typography> Assign To</Typography>
                </SMenuItem>
                <SMenuItem
                  onClick={unassignUserFromTask}
                  disabled={!row?.assignedTo}
                >
                  <WorkOffIcon />
                  <Typography> Unassign task</Typography>
                </SMenuItem>
                <SMenuItem onClick={toggleUpdateTaskModal}>
                  <BorderColorIcon /> <Typography> Edit Task</Typography>
                </SMenuItem>
                <SMenuItem onClick={toggleDeleteConfirmModal}>
                  <DeleteForeverIcon />
                  <Typography> Delete</Typography>
                </SMenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      )}
    </PopUpMenu>
  );
}

PopupMenu.propTypes = {
  currentUserRole: PropTypes.string,
  unassignUserFromTask: PropTypes.any,
  toggleAssignUserModal: PropTypes.any,
  toggleDeleteConfirmModal: PropTypes.func,
  toggleUpdateTaskModal: PropTypes.func,
  row: PropTypes.object,
};

export function DescriptionCell({ value, rowIndexBeingEdited, row, ...rest }) {
  rest = { row: row.original, ...rest };
  return (
    <DescriptionContents>
      <Typography>{value}</Typography>
      {rowIndexBeingEdited === row.index ? <PopupMenu {...rest} /> : null}
    </DescriptionContents>
  );
}

DescriptionCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  rowIndexBeingEdited: PropTypes.number,
};
//#endregion Description

//#region Availability
export function AvailabilityCell({
  row,
  index,
  rowIndexBeingEdited,
  toggleClaimModal,
}) {
  return (
    <Availability assignedTo={row.assignedTo}>
      {index === rowIndexBeingEdited ? (
        <ClaimButton primary disableRipple onClick={toggleClaimModal}>
          <Typography>Claim</Typography>
        </ClaimButton>
      ) : (
        <ClaimButton disabled>
          <Typography>Unclaimed</Typography>
        </ClaimButton>
      )}
    </Availability>
  );
}

AvailabilityCell.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  rowIndexBeingEdited: PropTypes.number,
  toggleClaimModal: PropTypes.func,
};
//#endregion Availability
