import { useEffect, useState } from 'react';
import Autocomplete from 'components/Autocomplete';
import DialogContent from 'components/DialogContent';
import DialogTitle from 'components/DialogTitle';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import CancelIcon from 'icons/Mui/Cancel';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCluster,
  selectDocument,
  setSelectedDocument,
} from 'store/Redux/slices/dataEntrySlice';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import Paper from 'components/Paper';
import { decoder } from 'helpers/graphQL_Encoder';
import { displayTitleCase } from 'helpers/enumToType';
import ProjectMemberStatusCell from 'containers/ReactTable/TableComponents/ProjectMemberStatusCell';
import ProjectMemberAssignedOnFormatter from 'containers/ReactTable/TableComponents/ProjectMemberAssignedOnFormatter';
import { displayElementType } from 'helpers/enumToType';
import Button from 'components/Button';
import { Description } from '@mui/icons-material';
import InputAdornment from 'components/InputAdornment';
import StatusJustificationModal from 'containers/Modals/StatusJustificationModal';

const ContentRoot = styled(DialogContent)`
  height: 100%;
`;

const SDialogTitle = styled(DialogTitle)`
  cursor: move;
  max-height: 10dvh;
  width: 100%;
  padding: 4%;
`;

const NavigationPaper = styled(Paper)`
  height: 50dvh;
  width: 25dvw;
  display: flex;
  position: absolute;
  top: 10dvh;
  margin: 0;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  z-index: 1300;
  :hover {
    border: 2px solid ${(props) => props.theme.palette.primary.darker};
  }
  padding: 1% 0 0 1%;
`;

const HeaderGrid = styled(Grid)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

const CancelButton = styled(IconButton)`
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const TemplateDocIcon = styled(Description)`
  color: ${(props) => props.theme.palette.secondary.main};
`;
//#endregion Styled Components

export default function DialogBody(props) {
  const {
    documents = [],
    clusters = [],
    handleClose = () => null,
    setDocIndex = () => null,
    isOpen = false,
    currentMember = {},
    projectMemberIdentificationHeader = '',
    isReadOnly = false,
    openReassignModal = false,
    setOpenReassignModal = () => null,
    projectMemberStatuses = [],
    projectMembers = {},
    setProjectMembers = () => null,
  } = props;

  const dispatch = useDispatch();
  const currentDocument = useSelector(selectDocument);
  const currentCluster = useSelector(selectCluster);

  const editableStatuses = [
    projectMemberStatuses.DATA_ENTRY_IN_PROGRESS,
    projectMemberStatuses.DATA_ENTRY_OFF_RAMPED_FOR_REVIEW,
  ];

  const [editing, setEditing] = useState(false);

  const filtersToHide = ['__typename', 'supplementalDatas', 'id'];
  const [val, setVal] = useState('');

  useEffect(() => {
    let hasDocuments = documents?.some(
      (x) => x.clusterId == decoder(currentCluster?.id)
    )
      ? true
      : false;
    if (hasDocuments) {
      let newIndex = documents?.findIndex(
        ({ id }) => id === currentDocument?.id
      );
      setVal(documents[newIndex]);
    }
  }, [documents, currentDocument, clusters, currentCluster]);

  const updateDocument = (doc) => {
    let newIndex = documents?.indexOf(doc);
    setDocIndex(newIndex);
    setVal(documents[newIndex]);
    dispatch(setSelectedDocument(doc));
  };

  const toggleStatusChangeModal = () => {
    setEditing(!editing);
  };

  const templateDocComponent = (doc) => (
    <>
      {doc?.isTemplate && (
        <Tooltip title='Template Document'>
          <TemplateDocIcon fontSize='small' />
        </Tooltip>
      )}
    </>
  );

  const renderOption = (renderProps, option) => (
    <li {...renderProps}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <Typography>{option.originalName}</Typography>
        </Grid>
        <Grid item xs={1}>
          {templateDocComponent(option)}
        </Grid>
      </Grid>
    </li>
  );

  const renderInput = (params) => (
    <TextField
      {...params}
      size='small'
      label='Page'
      variant='outlined'
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment>
            {templateDocComponent(currentDocument)}
            {params.InputProps.endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <>
      {isOpen && (
        <Draggable handle='#draggable-dialog-title'>
          <NavigationPaper aria-labelledby='draggable-dialog-title'>
            <Grid container spacing={1} sx={{ overflow: 'auto' }}>
              <HeaderGrid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SDialogTitle id='draggable-dialog-title'>
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <Tooltip title={currentCluster?.name} placement='top'>
                            <Typography
                              noWrap
                              style={{ fontWeight: 'bold' }}
                              variant='h6'
                            >
                              {projectMemberIdentificationHeader} Details
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={1} style={{ paddingTop: 0 }}>
                          <CancelButton
                            onClick={handleClose}
                            style={{
                              padding: '0px',
                              width: '100%',
                              height: '100%',
                            }}
                            size='large'
                          >
                            <CancelIcon fontSize='small' color='primary' />
                          </CancelButton>
                        </Grid>
                      </Grid>
                    </SDialogTitle>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingX: '2%' }}>
                    <Autocomplete
                      disableClearable
                      value={val}
                      onChange={(event, value) => updateDocument(value)}
                      options={documents}
                      getOptionLabel={(documents) => documents?.originalName}
                      renderOption={renderOption}
                      renderInput={renderInput}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ padding: '2%' }}>
                    <Button
                      variant='contained'
                      secondary
                      fullWidth
                      disabled={isReadOnly}
                      onClick={() => setOpenReassignModal(!openReassignModal)}
                    >
                      Reassign Current Document
                    </Button>
                  </Grid>
                </Grid>
              </HeaderGrid>

              <Grid item xs={12}>
                <ContentRoot>
                  {Object.entries(currentMember).length === 0 ? (
                    <Grid container justifyContent='center'>
                      <Grid item>
                        <Typography>
                          No {projectMemberIdentificationHeader} Details
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={1}>
                      {Object.entries(currentMember)
                        .filter(([key]) => !filtersToHide.includes(key))
                        .map(([key, val]) => (
                          <>
                            <Grid item xs={6}>
                              <Typography sx={{ fontWeight: 'bold' }} key={key}>
                                {displayTitleCase(key)}:{' '}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {key === 'status' ? (
                                <ProjectMemberStatusCell
                                  setEditing={setEditing}
                                  isStatusEditable={editableStatuses.includes(
                                    val
                                  )}
                                  initialValue={val}
                                  rowIsSelected={true}
                                  isReadOnly={isReadOnly}
                                />
                              ) : key === 'justification' && val ? (
                                <Typography key={val} noWrap title={val}>
                                  {displayElementType(val)}
                                </Typography>
                              ) : key === 'assignedOn' ||
                                (key === 'createdOn' && val) ? (
                                <ProjectMemberAssignedOnFormatter
                                  assignedOn={val}
                                />
                              ) : (
                                <Typography key={val} noWrap title={val}>
                                  {val === null ? 'N/A' : String(val)}
                                </Typography>
                              )}
                            </Grid>
                          </>
                        ))}
                    </Grid>
                  )}
                </ContentRoot>
              </Grid>
            </Grid>
          </NavigationPaper>
        </Draggable>
      )}
      <StatusJustificationModal
        isOpen={editing}
        onAnalysisPage={false}
        handleModalClose={toggleStatusChangeModal}
        projectMembers={projectMembers}
        setProjectMembers={setProjectMembers}
        editableStatuses={editableStatuses}
      />
    </>
  );
}

DialogBody.propTypes = {
  documents: PropTypes.array,
  clusters: PropTypes.array,
  handleClose: PropTypes.func,
  setDocIndex: PropTypes.func,
  isOpen: PropTypes.bool,
  currentMember: PropTypes.object,
  projectMemberIdentificationHeader: PropTypes.any,
  isReadOnly: PropTypes.bool,
  openReassignModal: PropTypes.bool,
  setOpenReassignModal: PropTypes.any,
  projectMemberStatuses: PropTypes.any,
  projectMembers: PropTypes.object,
  setProjectMembers: PropTypes.func,
};
