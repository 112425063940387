import Typography from 'components/Typography';
import PropTypes from 'prop-types';
import { displayElementType } from 'helpers/enumToType';
import IconButton from 'components/IconButton';
import Edit from 'components/Edit';
import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';

const SIconButton = styled(IconButton)`
  display: ${(props) => (props.isHidden ? 'none' : null)};
  & svg {
    color: ${(props) => (props.disabled ? 'gray !important' : null)};
  }
`;

const SInfoIcon = styled(InfoIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const ProjectMemberStatusCell = (props) => {
  const {
    editing = false,
    setEditing = () => null,
    isStatusEditable = false,
    initialValue = '',
    rowIsSelected = false,
    isReadOnly = false,
    currentMemberLoading = false,
  } = props;

  return (
    <div>
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        {displayElementType(initialValue)}
        <SIconButton
          title={
            rowIsSelected
              ? 'Click to Edit Status'
              : 'Click Row to Enable Edit Button'
          }
          disabled={!rowIsSelected || currentMemberLoading}
          isHidden={isReadOnly}
          onClick={() => setEditing(!editing)}
        >
          {isStatusEditable ? <Edit /> : <SInfoIcon />}
        </SIconButton>
      </Typography>
    </div>
  );
};

export default ProjectMemberStatusCell;

ProjectMemberStatusCell.propTypes = {
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
  initialValue: PropTypes.string,
  rowIsSelected: PropTypes.bool,
  updateProjectMemberStatus: PropTypes.func,
  currentMemberLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isStatusEditable: PropTypes.bool,
};
