import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Telegram,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import Button from 'components/Button';
import ListItemText from 'components/ListItemText';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Typography from 'components/Typography';
import { decoder } from 'helpers/graphQL_Encoder';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';

const ViewOnPageButton = styled(Button)`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const SMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.palette.secondary.main};
`;

export const ViewOnPageButtons = (props) => {
  const {
    pageData = {},
    getData = () => null,
    getPMsForDocumentView = () => null,
    dataFilters = null,
    statuses = [],
    currentProjectId = '',
    loading = false,
    selectedResources = {},
  } = props;

  const history = useHistory();
  const defaultPageOptions = [
    {
      id: 3,
      name: 'Status',
      page: '/analysis',
      type: 'projectMembers',
      pageStatuses: [
        statuses.RECONCILIATION_FLAGGED_FOR_REVIEW,
        statuses.RECONCILIATION_ESCALATED_FOR_REVIEW,
      ],
      projectMembers: {},
    },
    {
      id: 2,
      name: `Data Exceptions - ${selectedResources.ANY_PAGES.PROJECT_MEMBER}s`,
      page: '/select',
      type: 'projectMembers',
      pageStatuses: [
        statuses.DATA_ENTRY_IN_PROGRESS,
        statuses.DATA_ENTRY_OFF_RAMPED_FOR_REVIEW,
      ],
      projectMembers: {},
    },
    {
      id: 1,
      name: `Data Exceptions - ${selectedResources.ANY_PAGES.CLUSTER}s`,
      page: '/select',
      type: 'clusters',
      clusters: {},
    },
    {
      id: 0,
      name: 'Classification',
      page: '/clusters',
      type: 'clusters',
      clusters: {},
    },
  ];

  const [optionSelected, setOptionSelected] = useState({});
  const [pageOptions, setPageOptions] = useState(defaultPageOptions);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePageOptionsClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (dataFilters && pageData?.nodes) {
      setUpPageInfo(pageData);
    } else {
      setPageOptions(defaultPageOptions);
    }
  }, [pageData]);

  useEffect(() => {
    jumpToPage(optionSelected);
  }, [optionSelected]);

  const setUpPageInfo = (data) => {
    let updatedPageOptions = pageOptions.map((option) => {
      let filteredData = [];
      data?.nodes?.forEach((d) => {
        if (d.status && option?.pageStatuses?.includes(d.status)) {
          filteredData.push(d);
        } else if (d.parentDocumentName && d.parentDocumentName !== 'N/A') {
          filteredData.push(d);
        } else if (d.pageName && option?.clusters) {
          filteredData.push(d);
        }
      });
      option[option.type] = {
        ...data,
        nodes: filteredData,
        totalCount: filteredData?.length,
      };
      return option;
    });
    setPageOptions(updatedPageOptions);
  };

  const handleOptionSelected = (option) => {
    setOptionSelected(option);
    handlePageOptionsClose();
  };

  const jumpToPage = (option) => {
    const dataType = option.type;

    if (option[dataType]?.nodes?.length > 0) {
      let variables = {};
      let filters = {
        id: { in: option[dataType].nodes.map((p) => decoder(p.id)) },
        projectId: { eq: decoder(currentProjectId) },
      };
      let fetchPageData = getData;

      if (option?.pageStatuses) {
        variables = {
          includeDocInfo: !!option.id,
        };
        filters = {
          originalId: {
            in: option[dataType].nodes.map(
              (p) => p.parentDocumentName || p.originalId
            ),
          },
          status: {
            in: option.pageStatuses,
          },
          projectId: { eq: decoder(currentProjectId) },
        };
        fetchPageData = getPMsForDocumentView;
      }

      fetchPageData({
        variables: {
          ...variables,
          filters: filters,
        },
      }).then((res) => {
        option[dataType] = res.data[dataType];
        history.push(option.page, {
          optionData: option,
        });
      });
    }
  };

  return (
    <>
      <Stack
        direction='row'
        justifyContent={{ md: 'flex-end', xs: 'center' }}
        alignItems='center'
        spacing={1}
      >
        <ViewOnPageButton
          size='small'
          visible={dataFilters}
          variant='text'
          primary
          onClick={(e) => setAnchorEl(e.currentTarget)}
          startIcon={<Telegram fontSize='small' />}
          endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        >
          <Typography variant='subtitle'>
            {loading ? 'Preparing Data...' : 'View On Page List'}
          </Typography>
        </ViewOnPageButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handlePageOptionsClose}
          disableScrollLock
        >
          {pageOptions?.map((option, i) => (
            <>
              {option?.projectMembers?.nodes?.length > 0 ||
              option?.clusters?.nodes?.length > 0 ? (
                <SMenuItem key={i} onClick={() => handleOptionSelected(option)}>
                  <ListItemText>{option.name}</ListItemText>
                </SMenuItem>
              ) : null}
            </>
          ))}
        </Menu>
      </Stack>
    </>
  );
};

ViewOnPageButtons.propTypes = {
  pageData: PropTypes.object,
  dataFilters: PropTypes.any,
  statuses: PropTypes.any,
  defaultSortOrder: PropTypes.array,
  currentProjectId: PropTypes.string,
  loading: PropTypes.bool,
  getData: PropTypes.func,
  getPMsForDocumentView: PropTypes.func,
  selectedResources: PropTypes.object,
};
