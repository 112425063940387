function SvgPinpointIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.94 10A8.994 8.994 0 0012 2.06V0h-2v2.06A8.994 8.994 0 002.06 10H0v2h2.06A8.994 8.994 0 0010 19.94V22h2v-2.06A8.994 8.994 0 0019.94 12H22v-2h-2.06zM11 18c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
        fill="#007CB0"
      />
      <path d="M10 10V8h2v2h2v2h-2v2h-2v-2H8v-2h2z" fill="#007CB0" />
    </svg>
  );
}

export default SvgPinpointIcon;
