function RightArrowDisabled() {
  return (
    <svg
      width="24"
      height="42"
      viewBox="0 0 24 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25015 5.62474L16.718 21.0928L1.25015 36.5606C-0.812246 38.623 1.25003 40.6855 1.25003 40.6855C1.25003 40.6855 3.31255 42.7478 5.37494 40.6854L22.9052 23.1553C23.9364 22.1241 23.9364 20.0617 22.9052 19.0304L5.37494 1.49995C3.31255 -0.562448 1.25003 1.50001 1.25003 1.50001C1.25003 1.50001 -0.812248 3.56228 1.25015 5.62474Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

export default RightArrowDisabled;
