import { useEffect, useRef } from 'react';
import { config } from './config';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { StyledEngineProvider } from '@mui/material/styles';
import MetlSnackbar from 'components/Snackbar';
import Layout from 'layout/main';
import AnalysisPage from 'pages/Analysis';
import AnalysisReports from 'pages/AnalysisReports';
import AssignPage from 'pages/Assign';
import DocumentPage from 'pages/Document';
import ClusterPage from 'pages/Cluster';
import DataEntryPage from 'pages/DataEntry';
import DataEntrySelect from 'pages/DataEntrySelect';
import ElementAssignPage from 'pages/ElementAssignment';
import ImportPage from 'pages/Import';
import LandingPage from 'pages/LandingPage';
import RecordsPage from 'pages/Records';
import SettingPage from 'pages/Settings';
import SummaryPage from 'pages/Summary';
import TasklistPage from 'pages/TaskList';
import TimeoutPage from 'pages/Timeout/TimeoutPage';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import {
  setSelectedProjectMember,
  setSelectedCluster,
  setSelectedDocument,
  setSelectedTab,
} from 'store/Redux/slices/dataEntrySlice';
import {
  defaultResourceMenu,
  selectCurrentResourceName,
  setProjectMemberDocuments,
  setProjectMemberElements,
} from 'store/Redux/slices/projectsSlice';
import {
  setUserGroups,
  setIsLoggedIn,
  setMsUserData,
  setUserProfilePhoto,
  setMsGroupData,
  setGroupMembers,
  setOrgMembers,
  setCurrentUserEmail,
} from 'store/Redux/slices/usersSlice';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import { loginRequest } from './authConfig';
import {
  getNextPage,
  userMemberOf,
  userMsGraphRequest,
  userPhotoMsGraphRequest,
  getGroupMembers,
} from './graph';
import { useIdleTimer } from 'react-idle-timer';
import { resources } from 'resource';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const resourceMenu = useSelector(selectCurrentResourceName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];

  //#region MSAL
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  instance.setActiveAccount(accounts[0]);
  const activeAccount = instance.getActiveAccount();
  const accessToken = useRef('');

  const accessTokenRequest = {
    ...loginRequest,
    account: activeAccount,
  };

  useEffect(() => {
    if (isAuthenticated && activeAccount) {
      // handleRedirectPromise returns after a redirect promise
      instance
        .handleRedirectPromise()

        // if user is authenticated and an active account has been set, get token silently
        .then(() =>
          instance
            .acquireTokenSilent(accessTokenRequest)
            .then((response) => {
              accessToken.current = response.accessToken;
            })

            // if token fails to fetch silently, reattempt to get token with a popup
            .catch(() => {
              instance
                .acquireTokenPopup(accessTokenRequest)
                .then((response) => {
                  accessToken.current = response.accessToken;
                })
                .catch(() => {
                  handleOnIdle();
                });
            })
        )

        // after obtaining access token, call graph api function
        .then(callMsGraphApi)
        .catch((error) => console.error(error));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && activeAccount && inProgress === 'none') {
      userMemberOf()
        .then((response) => {
          // if user has groups, map the values
          if (response?.value.length) {
            // do something here with user groups
            let buffer = [];
            response.value.forEach((group) => {
              let splitGroupName = group.displayName.split('_');
              let parsedGroupName = splitGroupName[splitGroupName.length - 1];
              buffer.push(parsedGroupName);
            });

            // dispatch the groups to the store
            dispatch(setMsGroupData(response.value));
            dispatch(setUserGroups(buffer));
          } else {
            // if user has no groups, dispatch an empty array
            dispatch(setUserGroups([]));
          }

          // in case of overage, returns the field '@data.nextLink'
          if (response['@odata.nextLink']) {
            handleNextPage(response['@odata.nextLink']);
          }

          return response.value;
        })
        //Get the members for each group
        .then((res) => {
          res.forEach(async (group, index) => {
            const members = await getGroupMembers(group.id);
            dispatch(
              setGroupMembers({
                index,
                members: members.value,
              })
            );

            dispatch(setOrgMembers(members.value));
          });
        })
        .catch((error) => console.error(error));
    }
    //NOTE "inProgress" is a built-in prop of the MSAL library that is used to determine if the user is currently logging in
  }, [inProgress]);

  function callMsGraphApi() {
    let userGraphApiObj;

    if (accessToken.current) {
      // fetch current user graph api object
      userMsGraphRequest(accessToken.current)
        .then((response) => {
          userGraphApiObj = response;
        })
        // also get user photo
        .then(() =>
          userPhotoMsGraphRequest(
            accessToken.current,
            userGraphApiObj.userPrincipalName
          )
        )
        .then((response) => {
          // if response is returned as success but no photo is available, set 'isValid' as false
          response?.error
            ? dispatch(setUserProfilePhoto('false'))
            : dispatch(setUserProfilePhoto(response));
        })
        .then(() => {
          // dispatch user info and toggle 'isLoggedIn' to true in Redux store
          dispatch(setMsUserData(userGraphApiObj));
          findCurrentUserEmail(userGraphApiObj);
          dispatch(setIsLoggedIn(true));
        })
        .catch((error) => console.error(error));
    }
  }

  function handleOnIdle() {
    history.push('/timeout');
  }

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
  });

  // eslint-disable-next-line no-unused-vars
  const handleNextPage = (nextPage) => {
    getNextPage(nextPage).then((response) => {
      // if we see this property in the response object, we know we have even more groups to fetch
      if (response['@odata.nextLink']) {
        handleNextPage(response['@odata.nextLink']);
      }
    });
  };

  const findCurrentUserEmail = (currentUser) => {
    if (currentUser.mail) {
      const emailName = currentUser.mail.substring(
        0,
        currentUser.mail.lastIndexOf('@')
      );
      dispatch(setCurrentUserEmail(emailName));
    }
  };
  //#endregion MSAL

  useEffect(() => {
    if (location.pathname !== '/select' && location.pathname !== '/dataentry') {
      dispatch(setSelectedCluster(null));
      dispatch(setSelectedDocument(null));
      dispatch(setSelectedProjectMember(null));
      dispatch(setSelectedTab(selectedResources.ANY_PAGES.DEFAULT_ENTRY_TAB));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (location.pathname !== '/records') {
      dispatch(setProjectMemberDocuments([]));
      dispatch(setProjectMemberElements([]));
    }
  }, [location, dispatch]);

  if (config.ENVIRONMENT === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/timeout' component={TimeoutPage} />
          <Layout>
            <LastLocationProvider>
              <Switch>
                <Route exact path='/summary' component={SummaryPage} />
                <Route exact path='/tasklist' component={TasklistPage} />
                <Route exact path='/import' component={ImportPage} />
                <Route exact path='/assign' component={AssignPage} />
                <Route exact path='/document' component={DocumentPage} />
                <Route exact path='/clusters' component={ClusterPage} />
                <Route exact path='/dataentry' component={DataEntryPage} />
                <Route exact path='/analysis' component={AnalysisPage} />
                <Route exact path='/settings' component={SettingPage} />
                <Route exact path='/reports' component={AnalysisReports} />
                <Route exact path='/select' component={DataEntrySelect} />
                <Route exact path='/records' component={RecordsPage} />
                <Route
                  exact
                  path='/elementassign'
                  component={ElementAssignPage}
                />
              </Switch>
            </LastLocationProvider>
            <MetlSnackbar />
          </Layout>
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
App.propTypes = {
  accountInfo: PropTypes.any,
  logout: PropTypes.func,
};
