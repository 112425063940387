import Box from 'components/Box';
import PropTypes from 'prop-types';
import SideNav from './sidenav';
import styled from 'styled-components';
import TopMenuItems from 'components/Navigation/TopMenuItems';
import Toolbar from '@mui/material/Toolbar';

const Main = styled.main`
  margin-left: 80px;
  flex-grow: 1;
  padding: 24px;
  height: 100vh;
  background-color: ${(props) => props.theme.palette.background.main};
`;

export default function Layout(props) {
  const { children, logout } = props;

  return (
    <Box className='mainLayout'>
      <TopMenuItems logout={logout} />
      <SideNav />
      <Main>
        <Toolbar />
        {children}
      </Main>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.array,
  logout: PropTypes.func,
};
