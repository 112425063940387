import styled from 'styled-components';
import ImageListItem from 'components/ImageListItem';
import Card from 'components/Card';
import { ErrorOutline, ZoomInMapOutlined } from '@mui/icons-material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageListItemBar from 'components/ImageListItemBar';
import Box from 'components/Box';
import ImageList from 'components/ImageList';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import CurrentDocumentCard from 'containers/Views/CurrentDocument';
import Grid from 'components/Grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const SImageListGallery = styled(ImageList)`
   background: ${(props) => props.theme.palette.background.lighter};
  grid-template-columns: repeat(auto-fill, [col-start] minmax(120px, 1fr)) [col-end] !important;
  padding: 2%;
  width: 100%;
  height: ${(props) => props.height};

  &::-webkit-scrollbar {
    width: 0.6em;
    height: 0.6em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 3px solid transparent;
    background-clip: content-box;
  },
`;

const ImageListGalleryItem = styled(ImageListItem)`
  height: 20dvh !important;
  width: 100%;
`;

const SZoomInMapOutlined = styled(ZoomInMapOutlined)`
  cursor: pointer;
  display: ${(props) => (props.showZoom ? 'inline-block' : 'none')};
  background-color: ${(props) => (props.hovered ? 'transparent' : 'white')};
  font-size: 2dvh;
  position: absolute;
  bottom: 3%;
  right: 3%;
  color: ${(props) => props.theme.palette.primary.main};

  &:hover {
    scale: 1.1;
  }
`;

const ImageCard = styled(Card)`
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  border-color: ${(props) =>
    props.reclusterMode
      ? props.recluster
        ? props.theme.palette.primary.main
        : null
      : props.selected
      ? props.theme.palette.primary.main
      : null};
  border-width: 3px;
  border-style: solid;
  border-radius: 8px;
  display: grid;
  place-items: center;
  padding: 1%;
`;

const UnconfirmedIcon = styled(ErrorOutline)`
  display: ${(props) =>
    props.isConfirmed || props.unclusteredDoc ? 'none' : 'inline-block'};
  font-size: 2dvh;
  background-color: white;
  border-radius: 20px;
  color: goldenrod;
  position: absolute;
  top: 3%;
  right: 3%;
`;

const PdfObject = styled.object`
  height: 100%;
  width: 100%;
  border-radius: 8px;
`;

const ImageListGalleryItemBar = styled(ImageListItemBar)`
  height: 100%;
  & .MuiImageListItemBar-title {
    overflow: hidden;
    white-space: initial;
    font-size: 1.5dvh;
  }
`;

export const ImageListGallery = (props) => {
  const {
    galleryDocuments,
    onClickImageCard = () => null,
    hasNextPage = false,
    awsObject,
    reclusterMode,
    reclusterDocs = [],
    docIndex = 0,
    readToken,
    handleScroll = () => null,
    height = '45dvh',
    key,
    showZoom,
    openZoom,
    setOpenZoom,
    anchorEl,
    setAnchorEl,
    image,
    rightDocIndex = null,
    setImage,
    setDocIndex = () => null,
    setRightDocIndex = () => null,
  } = props;

  const [hoveredLeftThumbnail, setHoveredLeftThumbnail] = useState(-1);
  const popoverId = openZoom ? 'popover-id' : undefined;
  const dispatch = useDispatch();

  const onZoomIconClick = (e, doc, index) => {
    e.stopPropagation();
    setImage(
      doc?.documentUri?.includes(awsObject)
        ? `${doc?.documentUri}`
        : `${doc?.documentUri}?${readToken}`
    );
    setOpenZoom(true);
    setAnchorEl(e.currentTarget);
    if (rightDocIndex === null) {
      dispatch(setDocIndex(index));
    } else {
      setRightDocIndex(rightDocIndex);
    }
  };

  const showLeftThumbnailHandler = (i) => {
    setHoveredLeftThumbnail(i);
  };

  const hideLeftThumbnailHandler = () => {
    setHoveredLeftThumbnail(-1);
  };

  return (
    <SImageListGallery
      key={key}
      height={height}
      id='scrollGallery'
      gap={20}
      onScroll={(e) => handleScroll(e, hasNextPage)}
    >
      {galleryDocuments?.nodes?.map((doc, index) => (
        <ImageListGalleryItem key={index}>
          <ImageCard
            onClick={(e) => onClickImageCard(e, doc, index, reclusterMode)}
            onMouseLeave={hideLeftThumbnailHandler}
            onMouseEnter={() => showLeftThumbnailHandler(index)}
            selected={index === docIndex}
            recluster={reclusterDocs?.includes(doc?.id)}
            reclusterMode={reclusterMode}
          >
            <UnconfirmedIcon
              isConfirmed={doc?.isConfirmed}
              unclusteredDoc={doc?.clusterId === null}
            />
            {doc?.originalName?.split('.').pop() === 'pdf' ? (
              <PdfObject
                data={
                  doc?.documentUri?.includes(awsObject)
                    ? `${doc?.documentUri}`
                    : `${doc?.documentUri}?${readToken}`
                }
                type='application/pdf'
              />
            ) : (
              <LazyLoadImage
                height='100%'
                width='100%'
                src={
                  doc?.documentUri?.includes(awsObject)
                    ? `${doc?.documentUri}`
                    : `${doc?.documentUri}?${readToken}`
                }
                importance='high'
              />
            )}
            {hoveredLeftThumbnail === index && !openZoom ? (
              <Box title={doc?.originalName}>
                <ImageListGalleryItemBar title={doc?.originalName} />
              </Box>
            ) : null}
            <SZoomInMapOutlined
              fontSize='small'
              onClick={(e) => onZoomIconClick(e, doc, index)}
              showZoom={showZoom}
              hovered={hoveredLeftThumbnail === index}
            />
          </ImageCard>
        </ImageListGalleryItem>
      ))}
      <Popover
        id={popoverId + docIndex}
        open={openZoom}
        onClose={(e) => {
          hideLeftThumbnailHandler();
          e.stopPropagation();
          setAnchorEl(null);
          setOpenZoom(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            width: '600px',
            height: '800px',
          }}
        >
          <Grid container>
            <div className='clustersPage-viewSelectedDocument'>
              <CurrentDocumentCard
                key={docIndex}
                image={image}
                currentPage='Analysis'
                totalDocs={1}
              />
            </div>
          </Grid>
        </div>
      </Popover>
    </SImageListGallery>
  );
};

ImageListGallery.propTypes = {
  reclusterMode: PropTypes.bool,
  reclusterDocs: PropTypes.array,
  galleryDocuments: PropTypes.object,
  onClickImageCard: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hoveredLeftThumbnail: PropTypes.any,
  hideLeftThumbnailHandler: PropTypes.func,
  showLeftThumbnailHandler: PropTypes.func,
  docIndex: PropTypes.number,
  readToken: PropTypes.any,
  handleScroll: PropTypes.func,
  height: PropTypes.string,
  awsObject: PropTypes.any,
  image: PropTypes.string,
  openZoom: PropTypes.bool,
  setOpenZoom: PropTypes.func,
  key: PropTypes.any,
  showZoom: PropTypes.any,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  rightDocIndex: PropTypes.any,
  setImage: PropTypes.any,
  setDocIndex: PropTypes.func,
  setRightDocIndex: PropTypes.func,
};
