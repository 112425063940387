import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Card from 'components/Card';
import Grid from 'components/Grid';
import { decoder } from 'helpers/graphQL_Encoder';
import 'pages/Cluster/Cluster.css';
import React, { useEffect, useRef, useState } from 'react';
import Tooltip from 'components/Tooltip';
import styled from 'styled-components';
import Typography from 'components/Typography';
import { useDispatch } from 'react-redux';
import {
  setModalOpen,
  setSelectedCluster,
} from 'store/Redux/slices/dataEntrySlice';
import { setDocIndex } from 'store/Redux/slices/projectsSlice';
import Fade from 'components/Fade';
import Dialog from 'components/Dialog';
import DialogContentText from 'components/DialogContentText';
import { ClusterAutoComplete } from '../cards/ImageGallery/ClusterAutoComplete';
import { useLazyQuery } from '@apollo/client';
import {
  GET_DOCS_BY_CLUSTER,
  GET_PRESIGNED_URLS_S3,
} from 'services/GraphQL/Queries';
import {
  applyAWSTokenToDocument,
  handleDecodeURL,
} from 'helpers/UpdateAWSDocumentUriTokens';
import { ImageListGallery } from '../cards/ImageGallery/ImageListGallery';
import { ImageGalleryToolbar } from '../cards/ImageGallery/ImageGalleryToolbar';
import PropTypes from 'prop-types';
import CardContent from 'components/CardContent';
import Checkbox from 'components/Checkbox';
import { InfoRounded } from '@mui/icons-material';

const STypography = styled(Typography)`
  font-size: 1.8dvh;
  display: ${(props) => (props.showUnclusteredInfo ? 'block' : 'none')};
  color: white;
  width: 100%;
  background: #c94c4c;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 1px;
`;

const SInfoTypography = styled(Typography)`
  font-size: 1.8dvh;
  width: 100%;
  display: block;
  color: white;
  background: #03aed2;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 1px;
`;

const SDiv = styled.div`
  display: ${(props) => (props.showUnclusteredInfo ? 'none' : 'block')};
`;

function not(a, b) {
  return a?.filter((value) => b?.indexOf(value) === -1);
}

function intersection(a, b) {
  return a?.filter((value) => b?.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function ReassignClusterModal(props) {
  const {
    submitRecluster = () => null,
    reclusterDocs = [],
    setReclusterDocs = () => null,
    modalOpen = false,
    documents = { totalCount: 1, nodes: [], pageInfo: {} },
    setDocuments = () => null,
    awsObject = '',
    readToken = '',
    currentCluster = {},
    clusterSelected = {},
    hasNextPage = false,
    handleScroll = () => null,
    setNewCluster = () => null,
    setClusterValues = () => null,
    showLeftThumbnailHandler = () => null,
    hoveredLeftThumbnail = -1,
    setHoveredLeftThumbnail = () => null,
    docIndex = 0,
    onClickImageCard = () => null,
    reclusterMode = true,
    setReclusterMode = () => null,
    setOpenReassignModal = () => null,
    filtersToolbar = false,
    filterOptions = [],
    toggleFiltersToolbar = () => null,
    setFilterOptions = () => null,
    docQueryFilters = null,
    setDocQueryFilters = () => null,
    clearFilters = () => null,
    onDataEntry = false,
    currentProjectId = '',
    defaultClusterOrder = [],
    image = '',
    setImage = () => null,
    checkClusterNameExists = () => null,
  } = props;

  const dispatch = useDispatch();

  const [checked, setChecked] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [unclusteredDocuments, setUnclusteredDocuments] = useState([]);
  const [reassignClusterDialog, setReassignClusterDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openZoom, setOpenZoom] = useState(Boolean(anchorEl));
  const [hoveredRightThumbnail, setHoveredRightThumbnail] = useState(-1);
  const [rightDocIndex, setRightDocIndex] = useState(0);
  const [openChangeClusterModal, setOpenChangeClusterModal] = useState(false);
  const rightGalleryDirty = useRef(null);

  const viewUnclusteredDocs = useRef(null);
  const autoComplete = useRef(null);
  const numberOfChecked = (items) => intersection(checked, items)?.length;

  var leftChecked = intersection(checked, documents?.nodes);

  useEffect(() => {
    setRight([]);
    setModalOpen(false);
  }, [modalOpen]);

  useEffect(() => {
    if (docQueryFilters) {
      setChecked([]);
    }
  }, [docQueryFilters]);

  useEffect(() => {
    setChecked(
      documents?.nodes?.filter((d) => reclusterDocs?.includes(d.id)) || []
    );
  }, [reclusterDocs]);

  const [getRightDocuments] = useLazyQuery(GET_DOCS_BY_CLUSTER, {
    onCompleted: (data) => {
      if (data?.documents?.nodes) {
        prepareDocumentsForPreSign(data.documents);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [getPreSignedUrlList] = useLazyQuery(GET_PRESIGNED_URLS_S3);

  const prepareDocumentsForPreSign = (documents) => {
    let decodedDocs = handleDecodeURL(documents?.nodes);

    let documentsObject = { ...documents, nodes: decodedDocs };
    handlePreSignedUrlDocuments(false, documentsObject);
  };

  const handlePreSignedUrlDocuments = (unclusteredDocs, newDocs) => {
    let docs = newDocs?.nodes ? newDocs?.nodes : newDocs;
    let alreadySignedDocs = docs.filter((d) =>
      d.documentUri.includes('AWSAccessKeyId')
    );
    const documentUris = docs
      .filter((d) => !alreadySignedDocs.includes(d))
      .map((d) => {
        return d.documentUri;
      });
    getPreSignedUrlList({ variables: { urlList: documentUris } }).then(
      (res) => {
        const updatedDocs = applyAWSTokenToDocument(
          res.data.preSignedUrlList,
          docs.filter((d) => !alreadySignedDocs.includes(d))
        );
        let fullDocs = alreadySignedDocs?.concat(updatedDocs);
        unclusteredDocs
          ? setUnclusteredDocuments({
              ...newDocs,
              nodes: fullDocs,
            })
          : setRight({ ...newDocs, nodes: fullDocs });
      }
    );
  };

  const clusterSelect = (row) => {
    if (reclusterDocs?.length > 0) resetLeftDocs();
    const id = row?.id;
    if (id === 'UNCLUSTERED') {
      viewUnclusteredDocs.current = true;
      setRight({ ...right, nodes: unclusteredDocuments?.nodes });
      dispatch(setDocIndex(0));
      setRightDocIndex(0);
    } else if (id) {
      setRightDocIndex(0);
      getRightDocuments({
        variables: {
          filters: { clusterId: { eq: decoder(row?.id) } },
          first: 5,
        },
      });
    }
    resetForm();
  };

  const onClickLeftImageCard = (e, doc, index, reclusterMode) => {
    if (!openZoom) {
      onClickImageCard(e, doc, index, reclusterMode);
      handleToggle(doc);
    }
  };

  const resetForm = () => {
    leftChecked = intersection(checked, documents?.nodes);
  };

  const closeModal = () => {
    if (rightGalleryDirty.current) {
      handleOpenClusterModal();
    } else {
      resetLeftDocs();
      setRight([]);
      setOpenReassignModal(false);
      setDocQueryFilters(null);
      setSelectedCluster([]);
      setChecked([]);
      setReclusterMode(false);
      setClusterValues(null);
      setReclusterDocs([]);
      setChecked([]);
      leftChecked = intersection(checked, documents?.nodes);
      autoComplete?.current
        ?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]
        ?.click();
    }
  };

  const showRightThumbnailHandler = (i) => {
    setHoveredRightThumbnail(i);
  };

  const handleMoveAll = () => {
    leftChecked = documents?.nodes;
    setReclusterDocs(leftChecked.map((l) => l.id));
    handleCheckedRight();
  };

  const handleToggleAll = (items) => {
    let docs;
    if (numberOfChecked(items) === items?.length) {
      docs = not(checked, items);
      setChecked(docs);
      setReclusterDocs(docs.map((d) => d.id));
    } else {
      docs = union(checked, items);
      setChecked(docs);
      setReclusterDocs(docs.map((d) => d.id));
    }
  };

  const handleCheckedRight = () => {
    rightGalleryDirty.current = true;
    let rightDocs = right?.nodes
      ? right?.nodes?.concat(leftChecked)
      : leftChecked;
    let newTotal = documents?.totalCount - leftChecked?.length;
    setRight({ ...right, nodes: rightDocs });
    setDocuments({
      ...documents,
      nodes: not(documents?.nodes, leftChecked),
      totalCount: newTotal,
    });
    setChecked(not(checked, leftChecked));
  };

  const resetLeftDocs = () => {
    rightGalleryDirty.current = false;
    let docsToReturn = right?.nodes?.filter((r) =>
      reclusterDocs?.find((d) => d === r.id)
    );
    if (docsToReturn) {
      setDocuments({
        ...documents,
        nodes: documents?.nodes?.concat(docsToReturn),
        totalCount: documents?.totalCount + docsToReturn?.length,
      });
      setRight({ ...right, nodes: not(right?.nodes, docsToReturn) });
      setChecked(docsToReturn);
      clearFilters();
    }
  };

  const hideRightThumbnailHandler = () => {
    setHoveredRightThumbnail(-1);
  };

  const hideLeftThumbnailHandler = () => {
    setHoveredLeftThumbnail(-1);
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSubmitRecluster = () => {
    clearFilters();
    setClusterValues(null);
    setRight([]);
    setChecked([]);
    setReclusterDocs([]);
    setReassignClusterDialog(false);
    rightGalleryDirty.current = false;
    leftChecked = intersection(checked, documents?.nodes);
    autoComplete?.current
      ?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]
      ?.click();
  };

  const onChangeCluster = (value) => {
    if (rightGalleryDirty.current) {
      handleOpenClusterModal();
    } else {
      setClusterValues(value);
      clusterSelect(value);
      if (value === null) {
        setRight([]);
      }
    }
  };

  const handleOpenClusterModal = () => {
    setOpenChangeClusterModal(true);
    rightGalleryDirty.current = true;
  };

  const onChangeClusterConfirm = () => {
    rightGalleryDirty.current = false;
    setOpenChangeClusterModal(false);
    submitRecluster();
    setReclusterDocs([]);
    handleSubmitRecluster();
  };

  const onChangeClusterCancel = () => {
    rightGalleryDirty.current = false;
    resetLeftDocs();
    setOpenChangeClusterModal(false);
    onChangeCluster(null);
  };

  const closeReassignClusterDialog = () => {
    if (reassignClusterDialog) setReassignClusterDialog(false);
  };

  const reassignClusterConfirmationDialog = (
    <Dialog
      open={reassignClusterDialog}
      title='Reassign'
      hasActions
      cancelLabel='Cancel'
      handleCancel={() => closeReassignClusterDialog(false)}
      submitLabel='Confirm'
      handleSubmit={() => {
        submitRecluster();
        handleSubmitRecluster();
      }}
    >
      <DialogContentText>
        Are you sure you want to reassign the documents?
      </DialogContentText>
    </Dialog>
  );

  const changeClusterConfirmationDialog = (
    <Dialog
      open={openChangeClusterModal}
      title='Reassign'
      submitLabel='Save'
      handleSubmit={() => {
        onChangeClusterConfirm();
      }}
      cancelLabel='Revert'
      handleCancel={() => {
        onChangeClusterCancel();
      }}
      hasActions
    >
      Cancelling or changing the cluster will reset your changes. Instead, if
      you would like to save your reassignment changes, please select save.
    </Dialog>
  );

  const leftList = (
    <Card sx={{ height: '70dvh' }}>
      <SDiv
        showUnclusteredInfo={
          currentCluster?.id === 'UNCLUSTERED' && documents?.nodes?.length === 1
        }
      >
        <CardHeader
          avatar={
            <Checkbox
              onClick={() => handleToggleAll(documents?.nodes)}
              checked={
                numberOfChecked(documents?.nodes) ===
                  documents?.nodes?.length && documents?.nodes?.length !== 0
              }
              indeterminate={
                numberOfChecked(documents?.nodes) !==
                  documents?.nodes?.length &&
                numberOfChecked(documents?.nodes) !== 0
              }
              disabled={
                documents?.nodes?.length === 0 ||
                (currentCluster?.id === 'UNCLUSTERED' &&
                  documents?.nodes?.length === 1)
              }
              inputProps={{
                'aria-label': 'all items selected',
              }}
              style={{
                display:
                  currentCluster?.id === 'UNCLUSTERED' ? 'none' : 'block',
              }}
            />
          }
          title={`Select all documents from ${currentCluster?.name}`}
          subheader={`${checked?.length}/${documents?.nodes?.length} selected`}
        />
      </SDiv>
      <STypography
        showUnclusteredInfo={
          currentCluster?.id === 'UNCLUSTERED' && documents?.nodes?.length === 1
        }
      >
        <InfoRounded size='small' />
        Can not reassign documents to another cluster. There must remain at
        least one document in an unclustered cluster.
      </STypography>
      <ImageGalleryToolbar
        openReassignModal={true}
        currentCluster={currentCluster}
        filtersToolbar={filtersToolbar}
        filterOptions={filterOptions}
        toggleFiltersToolbar={toggleFiltersToolbar}
        setFilterOptions={setFilterOptions}
        docQueryFilters={docQueryFilters}
        setDocQueryFilters={setDocQueryFilters}
        clearFilters={clearFilters}
        onDataEntry={onDataEntry}
      />

      <ImageListGallery
        key={documents?.totalCount}
        galleryDocuments={documents}
        onClickImageCard={onClickLeftImageCard}
        hasNextPage={hasNextPage}
        awsObject={awsObject}
        reclusterMode={reclusterMode}
        reclusterDocs={reclusterDocs}
        hoveredLeftThumbnail={hoveredLeftThumbnail}
        hideLeftThumbnailHandler={hideLeftThumbnailHandler}
        showLeftThumbnailHandler={showLeftThumbnailHandler}
        docIndex={docIndex}
        readToken={readToken}
        handleScroll={handleScroll}
        setDocIndex={setDocIndex}
        openZoom={openZoom}
        setOpenZoom={setOpenZoom}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showZoom={true}
        setImage={setImage}
        image={image}
      />
      <Grid item xs={12} textAlign='end' paddingRight='1%'>
        Document Count: {documents?.nodes?.length} of {documents?.totalCount}
      </Grid>
    </Card>
  );

  const rightList = (
    <Card sx={{ height: '70dvh' }}>
      <CardContent>
        <Grid item xs={12} padding='2%'>
          <ClusterAutoComplete
            currentProjectId={currentProjectId}
            defaultClusterOrder={defaultClusterOrder}
            clusterSelected={clusterSelected}
            currentCluster={currentCluster}
            setNewCluster={setNewCluster}
            setClusterValues={setClusterValues}
            fullWidth={true}
            onChange={onChangeCluster}
            ref={autoComplete}
            checkClusterNameExists={checkClusterNameExists}
          />
        </Grid>
      </CardContent>
      <SInfoTypography>
        <InfoRounded size='small' />
        Please note that the documents below are a sample of the top 5 documents
        in the cluster.
      </SInfoTypography>
      <ImageListGallery
        key={right?.totalCount}
        galleryDocuments={right}
        awsObject={awsObject}
        reclusterMode={reclusterMode}
        reclusterDocs={reclusterDocs}
        hoveredLeftThumbnail={hoveredRightThumbnail}
        hideLeftThumbnailHandler={hideRightThumbnailHandler}
        showLeftThumbnailHandler={showRightThumbnailHandler}
        readToken={readToken}
        handleScroll={handleScroll}
        setDocIndex={setRightDocIndex}
        openZoom={openZoom}
        setOpenZoom={setOpenZoom}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showZoom={true}
        image={image}
        setImage={setImage}
        rightDocIndex={rightDocIndex}
      />
    </Card>
  );
  return (
    <Fade>
      <Dialog
        open={modalOpen}
        closeAfterTransition
        onClose={() => closeModal()}
        title='Reassign Cluster Pages'
        handleCancel={closeModal}
        cancelLabel='Cancel'
        submitLabel='Reassign'
        submitDisabled={
          !clusterSelected ||
          reclusterDocs?.length === 0 ||
          !rightGalleryDirty.current
        }
        handleSubmit={() => setReassignClusterDialog(true)}
        hasActions
        PaperProps={{
          sx: { width: '100%', maxWidth: 'none' },
        }}
      >
        <Grid
          container
          alignItems='center'
          padding='2%'
          height='100%'
          spacing={2}
        >
          <Grid item xs={5.5}>
            {leftList}
          </Grid>
          <Grid item xs={1}>
            <Grid container direction='column' alignItems='center'>
              <Tooltip
                disabled={checked?.length > 0 || clusterSelected}
                title={'Select documents and a destination cluster'}
              >
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={() => handleCheckedRight()}
                  disabled={
                    checked?.length === 0 ||
                    !clusterSelected ||
                    checked?.length === documents?.nodes?.length ||
                    (currentCluster?.id === 'UNCLUSTERED' &&
                      documents?.nodes?.length === 1)
                  }
                  aria-label='move selected right'
                >
                  &gt;
                </Button>
              </Tooltip>
              <Button
                sx={{ my: 0.5 }}
                variant='outlined'
                size='small'
                onClick={() => handleMoveAll()}
                disabled={
                  documents?.nodes?.length === 0 ||
                  !clusterSelected?.id ||
                  (checked?.length < documents?.nodes?.length &&
                    checked?.length > 0) ||
                  currentCluster?.id === 'UNCLUSTERED'
                }
                aria-label='move selected left'
                className='clustersPage-imageGalleryReassignButton'
              >
                &gt;&gt;
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={5.5}>
            {rightList}
          </Grid>
        </Grid>
        {changeClusterConfirmationDialog}
        {reassignClusterDialog && reassignClusterConfirmationDialog}
      </Dialog>
    </Fade>
  );
}

ReassignClusterModal.propTypes = {
  currentProjectId: PropTypes.any,
  documents: PropTypes.object,
  setDocuments: PropTypes.any,
  currentCluster: PropTypes.object,
  docIndex: PropTypes.any,
  clusterSelected: PropTypes.any,
  readToken: PropTypes.any,
  reclusterDocs: PropTypes.object,
  reclusterMode: PropTypes.any,
  setDocIndex: PropTypes.func,
  setNewCluster: PropTypes.func,
  setReclusterDocs: PropTypes.func,
  setReclusterMode: PropTypes.func,
  submitRecluster: PropTypes.any,
  awsObject: PropTypes.string,
  hasNextPage: PropTypes.bool,
  docQueryFilters: PropTypes.any,
  setDocQueryFilters: PropTypes.any,
  modalOpen: PropTypes.any,
  handleInstantSearch: PropTypes.func,
  listboxProps: PropTypes.any,
  galleryClusters: PropTypes.object,
  setGalleryClusters: PropTypes.any,
  loading: PropTypes.bool,
  handleScroll: PropTypes.func,
  setClusterValues: PropTypes.any,
  showLeftThumbnailHandler: PropTypes.func,
  hoveredLeftThumbnail: PropTypes.any,
  setHoveredLeftThumbnail: PropTypes.any,
  onClickImageCard: PropTypes.func,
  setOpenReassignModal: PropTypes.any,
  filtersToolbar: PropTypes.bool,
  filterOptions: PropTypes.any,
  toggleFiltersToolbar: PropTypes.any,
  setFilterOptions: PropTypes.any,
  setNewClusterOpen: PropTypes.any,
  clearFilters: PropTypes.func,
  onDataEntry: PropTypes.bool,
  defaultClusterOrder: PropTypes.any,
  image: PropTypes.any,
  setImage: PropTypes.func,
  setRightDocIndex: PropTypes.func,
  rightDocIndex: PropTypes.any,
};
