import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLazyQuery } from '@apollo/client';
import BackupIcon from '@mui/icons-material/Backup';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import DialogContentText from 'components/DialogContentText';
import Grid from 'components/Grid';
import List from 'components/List';
import ListItem from 'components/ListItem';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import { rgba } from 'polished';
import Typography from 'components/Typography';
import { decoder } from 'helpers/graphQL_Encoder';
import { FileUploadRow } from 'pages/Import/views';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PROJECT_DOC_NAMES } from 'services/GraphQL/Queries';
import {
  selectCurrentOrganizationId,
  selectCurrentProjectId,
  selectFileCompleted,
  selectFilesAreUploading,
  selectUploadedCount,
  selectUploadingFiles,
  setFileCompleted,
  setFilesAreUploading,
  setUploadedCount,
  setUploadingFiles,
} from 'store/Redux/slices/projectsSlice';
import styled from 'styled-components';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import { getAuthHeader, getApiUrl } from 'authConfig';
import { config } from 'config';
import { setSnackbarError } from 'store/Redux/slices/snackbarsSlice';
import PropTypes from 'prop-types';

//#region Styled Components
const BoldTypography = styled(Typography)`
  font-weight: bold;
`;
const ErrorTypography = styled(Typography)`
  color: red;
`;
const WarningTextTypography = styled(Typography)`
  font-weight: bold;
  text-align: center;
`;
const DropZoneBox = styled(Box)`
  border-style: dashed;
  border-radius: 8px;
  background: ${(props) => rgba(props.theme.palette.secondary.main, 0.1)};
  height: calc(65vh);
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isUploading === 1 ? 'no-drop' : 'pointer')};
`;
const ScrollingBox = styled(Box)`
  max-height: calc(65vh);
  min-height: 250px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const DialogContentInvisiScroll = styled(DialogContent)`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
//#endregion Styled Components

function DropZoneText(props) {
  const { isUploading } = props;
  return (
    <>
      {isUploading === 1 ? (
        <Typography>Upload in Progress...</Typography>
      ) : (
        <Typography>
          <List>
            <ListItem style={{ padding: '0px' }}>
              1. Click anywhere in the box to select a folder (image files
              only)!
            </ListItem>
            <ListItem style={{ padding: '0px' }}>
              2. Drag and Drop individual or multiple image files!
            </ListItem>
          </List>
          <ErrorTypography>
            <WarningTextTypography>
              By adding files, you are confirming that you are not adding any
              client related files or documents.
            </WarningTextTypography>
          </ErrorTypography>
        </Typography>
      )}
    </>
  );
}
DropZoneText.propTypes = {
  isUploading: PropTypes.bool,
};

export default function Import() {
  const dispatch = useDispatch();
  const [completedFiles, setCompletedFiles] = useState([]);
  const [projectDocuments, setProjectDocuments] = useState([]);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [fileRejections, setFileRejections] = useState([]);
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [open, setOpen] = useState(false);

  const emailName = useSelector(selectCurrentUserEmail);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const isUploading = useSelector(selectFilesAreUploading); //0 = false, 1 = true, 2 = done
  const fileCompleted = useSelector(selectFileCompleted);
  const uploadingFiles = useSelector(selectUploadingFiles);
  const uploaded = useSelector(selectUploadedCount);

  const handleClose = () => {
    setOpen(false);
    setFileRejections([]);
    setRejectedCount(0);
  };

  const reset = () => {
    setCompletedFiles(new Map());
    dispatch(setUploadingFiles([]));
    dispatch(setFilesAreUploading(0));
    dispatch(setFileCompleted([]));
    dispatch(setUploadedCount(0));
    setAcceptedFiles([]);
    setAcceptedCount(0);
    setFileRejections([]);
    setRejectedCount(0);
  };

  const [getProjectDocs] = useLazyQuery(GET_PROJECT_DOC_NAMES, {
    variables: {
      id: currentProjectId,
    },
    onCompleted: (data) => {
      const docSet = new Set(data?.projectById?.documents);
      setProjectDocuments(docSet);
    },
    fetchPolicy: 'no-cache',
  });

  //#region useEffect
  let uploadComplete = isUploading == 2;
  useEffect(() => {
    getProjectDocs();
  }, [currentProjectId, uploadComplete]);

  useEffect(() => {
    setCompletedFiles(new Map());
  }, [currentProjectId]);

  useEffect(() => {
    if (!fileCompleted) return;
    let filesToUpload = [...uploadingFiles];
    fileCompleted.forEach((file) => {
      setCompletedFiles((prev) => prev.set(file.name, file));
      filesToUpload = filesToUpload.filter((x) => x.name !== file?.name);
    });
    dispatch(setUploadingFiles(filesToUpload));
  }, [fileCompleted]);
  //#endregion useEffect

  const fileRejectionItems = fileRejections.map((file) => {
    return (
      <ListItem key={file.name} disablePadding>
        <ErrorTypography>
          <span style={{ fontWeight: 'bold' }}>{file.name}</span> {file.message}
        </ErrorTypography>
      </ListItem>
    );
  });

  const uploadFiles = async (acceptedFiles) => {
    let uploadingFiles = [];
    acceptedFiles.forEach((file) => {
      uploadingFiles.push(file);
    });
    dispatch(setUploadingFiles(uploadingFiles));
    const data = new FormData();
    acceptedFiles.forEach((file) => {
      data.append('files', file);
    });
    data.append('OrganizationId', currentOrganizationId);
    data.append('ProjectId', decoder(currentProjectId));
    data.append('Username', emailName);
    try {
      await axios
        .post(
          `${getApiUrl()}${config.APISTORAGENAME}/upload`,
          data,
          getAuthHeader()
        )
        .then(() => {
          dispatch(setFileCompleted(acceptedFiles));
        })
        .catch((error) => {
          console.error(error.response.data);
          throw error;
        });
    } catch (err) {
      dispatch(setSnackbarError(err.message));
      throw err;
    }
  };

  const findProjectFiles = (file) => {
    return Array.from(projectDocuments).find((x) => {
      return x.originalName === file.name;
    });
  };

  const fileValidation = (file) => {
    if (file.kind === 'directory') {
      setFileRejections((prev) => [
        ...prev,
        {
          name: `${file.name}`,
          code: `sub-directories-not-accepted`,
          message: ` - subfolder found: files within will not be uploaded`,
        },
      ]);
      return false;
    }

    if (findProjectFiles(file)) {
      setFileRejections((prev) => [
        ...prev,
        {
          name: `${file.name}`,
          code: `file-already-exists`,
          message: ` - already exists in the current Project`,
        },
      ]);
      return false;
    } else if (file.type.includes('image') == false) {
      setFileRejections((prev) => [
        ...prev,
        {
          name: `${file.name}`,
          code: `only-images-allowed`,
          message: ` - only image types are allowed`,
        },
      ]);
      return false;
    } else if (`${file.name}`.split('.').pop().toUpperCase() === 'TIF') {
      setFileRejections((prev) => [
        ...prev,
        {
          name: `${file.name}`,
          code: `TIF-files-not-allowed`,
          message: ` - TIF files not allowed`,
        },
      ]);
      return false;
    } else {
      setAcceptedFiles((prev) => [...prev, file]);
    }
    return true;
  };

  // Validation for select to upload
  const folderUpload = async () => {
    reset();

    await window
      .showDirectoryPicker()
      .then(function (dirHandle) {
        handleDirectoryEntry(dirHandle);
      })
      .catch(function (e) {
        return console.log(e);
      });
  };

  const handleDirectoryEntry = async (dirHandle) => {
    let valid = 0;
    let invalid = 0;
    try {
      for await (const f of dirHandle.values()) {
        if (f.kind === 'file') {
          const fileHandle = await dirHandle.getFileHandle(f.name);
          const file = await fileHandle.getFile();
          if (fileValidation(file)) {
            valid++;
          } else {
            invalid++;
          }
        } else if (f.kind === 'directory') {
          const newHandle = await dirHandle.getDirectoryHandle(f.name, {
            create: false,
          });
          fileValidation(newHandle);
          invalid++;
        }
      }
    } catch (err) {
      dispatch(setSnackbarError(err.message));
    }

    setAcceptedCount(valid);
    setRejectedCount(invalid);
  };

  // Validation for drag and drop to upload
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      reset();
      let valid = 0;
      let invalid = 0;
      const filesToRemove = [];
      const subFolders = new Set();

      //Check if file is nested in subfolder
      files.forEach((file) => {
        const subStrs = file.path.split('/');
        //remove empty space at beginning
        subStrs.shift();
        if (subStrs.length > 2) {
          //get subfolder name
          const subFolderName = subStrs[1];
          if (!subFolders.has(subFolderName)) {
            subFolders.add(subFolderName);
          }
          filesToRemove.push(file);
        }
      });

      const updatedFiles = files.filter(
        (file) => !filesToRemove.includes(file)
      );

      updatedFiles.forEach((file) => {
        if (fileValidation(file)) {
          valid++;
        } else {
          invalid++;
        }
      });

      setAcceptedCount(valid);
      setRejectedCount(invalid);

      subFolders.forEach((s) => {
        setFileRejections((prev) => [
          ...prev,
          {
            name: `${s}`,
            code: `sub-directories-not-accepted`,
            message: ` - subfolder found: files within will not be uploaded`,
          },
        ]);

        invalid++;
      });

      setAcceptedCount(valid);
      setRejectedCount(invalid);
    },
    noClick: true,
    noKeyboard: true,
    disabled: isUploading === 1,
  });

  const rejectionDialog = (
    <Dialog
      open={open}
      onClose={handleClose}
      title={
        <ErrorTypography>
          <BoldTypography variant='h6'>
            {rejectedCount} file(s) rejected
          </BoldTypography>
        </ErrorTypography>
      }
      handleCancel={handleClose}
    >
      <DialogContentInvisiScroll>
        <DialogContentText>{fileRejectionItems}</DialogContentText>
      </DialogContentInvisiScroll>
    </Dialog>
  );

  const filesChunksToUpload = async (files) => {
    dispatch(setFilesAreUploading(1));

    let chunkProgress = 0;
    const chunkSize = 20;
    for (let i = 0; i < files.length; i += chunkSize) {
      const chunk = files.slice(i, i + chunkSize);
      await uploadFiles(chunk)
        .then(() => {})
        .catch(() => {
          reset();
          dispatch(setFilesAreUploading(3));
          throw 'Upload failed!';
        });
      chunkProgress += chunk.length;
      dispatch(setUploadedCount(chunkProgress));
    }
    dispatch(setFilesAreUploading(2));
    chunkProgress = 0;
  };

  return (
    <ProjectSelectedTemplate>
      <Card>
        <CardHeader title='Import' />
        <Grid container padding={2} spacing={2}>
          <Grid item xs={6}>
            <div className='importPage-fileConverter'>
              <Typography variant='body'>
                Only image files are accepted. For pdfs, use our conversion
                site:{' '}
                <Button
                  variant='contained'
                  primary
                  style={{ color: 'white' }}
                  href={config.FILECONVERTERSITE}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Go to File-converter
                </Button>
              </Typography>
            </div>
            <BoldTypography>
              Please upload your image files below
            </BoldTypography>
            <div className='importPage-importDropZone'>
              <DropZoneBox
                isUploading={isUploading}
                sx={{ flexGrow: 1 }}
                {...getRootProps({ onClick: () => folderUpload() })}
              >
                <input {...getInputProps()} />
                <BackupIcon fontSize='large' />
                <DropZoneText isUploading={isUploading} />
              </DropZoneBox>
            </div>
          </Grid>
          <Grid item xs={6} className='importPage-uploadedFiles'>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography variant='h5'>
                  {`Files - ${
                    acceptedCount > 0
                      ? `${acceptedCount} File(s) Accepted`
                      : `None Selected`
                  }`}
                </Typography>
              </Grid>
              <Grid>
                {acceptedCount > 0 && isUploading !== 2 ? (
                  <Button
                    onClick={() => filesChunksToUpload(acceptedFiles)}
                    variant='contained'
                    primary
                    disabled={isUploading}
                    style={{ marginLeft: '10px' }}
                  >
                    {`Upload ${acceptedCount} File(s)`}
                  </Button>
                ) : null}
                {rejectedCount > 0 ? (
                  <Button
                    onClick={() => setOpen(true)}
                    variant='contained'
                    color='error'
                    style={{ marginLeft: '10px' }}
                  >
                    {`${rejectedCount} Error(s)`}
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid>
              <Typography variant='subheading'>
                {isUploading === 1
                  ? `Uploaded - ${uploaded} of ${
                      uploaded + uploadingFiles?.length
                    }`
                  : isUploading === 2
                  ? `Upload Complete`
                  : isUploading === 3
                  ? `Upload Failed`
                  : null}
              </Typography>
            </Grid>
            <ScrollingBox sx={{ flexGrow: 1 }}>
              <List>
                {uploadingFiles.map((file, index) => (
                  <FileUploadRow
                    key={index}
                    fileName={file.name}
                    size={file.size}
                    isUploading={true}
                    isSuccessful={file.isSuccessful}
                  />
                ))}
                {Array.from(completedFiles, ([key, value]) => ({
                  key,
                  value,
                })).map((file, index) => (
                  <FileUploadRow
                    key={index}
                    fileName={file.key}
                    size={file.value.size}
                    isUploading={false}
                    isSuccessful={true}
                  />
                ))}
              </List>
            </ScrollingBox>
          </Grid>
          {rejectionDialog}
        </Grid>
      </Card>
    </ProjectSelectedTemplate>
  );
}
