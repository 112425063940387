import { createSlice } from '@reduxjs/toolkit';
import dataEntryData from './dataEntryData';
import { dateTimeFormatter } from 'helpers/dateTimeFormatter';

const initialState = dataEntryData;

const dataEntrySlice = createSlice({
  name: 'dataEntry',
  initialState,
  reducers: {
    setSelectedCluster(state, action) {
      state.selectedCluster = action.payload;
    },
    setSelectedDocument(state, action) {
      state.selectedDocument = action.payload;
    },
    setSelectedProjectMember(state, action) {
      state.selectedProjectMember = action.payload;
    },
    setStoreClusters(state, action) {
      state.clusters = action.payload;
    },
    setProjectMembers(state, action) {
      let pms = action.payload;
      pms = pms.map((c) => {
        return { ...c, createdOn: dateTimeFormatter(c?.createdOn, true) };
      });
      state.projectMembers = pms;
    },
    setStoreDocuments(state, action) {
      state.documents = action.payload;
    },
    setStoreDataElements(state, action) {
      state.dataElements = action.payload;
    },
    setStoreTableData(state, action) {
      state.tableData = action.payload;
    },
    setSupplementalData(state, action) {
      state.supplementalData = action.payload;
    },
    setStoreProjectDataElements(state, action) {
      state.projectDataElements = action.payload;
    },
    setStoreUnclustedDocuments(state, action) {
      state.unclusteredDocuments = action.payload;
    },
    setDocumentMember(state, action) {
      state.documentMember = action.payload;
    },
    setModalOpen(state, action) {
      state.modalOpen = action.payload;
    },
    setTableColumnsModalOpen(state, action) {
      state.tableColumnsModal = action.payload;
    },
    setWindowTableOpen(state, action) {
      state.tableWindowPortal = action.payload;
    },
    setTableCoordinates(state, action) {
      state.tableCoordinates = action.payload;
    },
    setExtractedData(state, action) {
      state.extractedData = action.payload;
    },
    setExtractionDone(state, action) {
      state.extractionDone = action.payload;
    },
    setNotReviewed(state, action) {
      state.notReviewed = action.payload;
    },
    setAddSpacing(state, action) {
      state.addSpacing = action.payload;
    },
    setCurrentOpenTable(state, action) {
      state.currentOpenTable = action.payload;
    },
    setCurrentClusterFilterText(state, action) {
      state.currentClusterFilterText = action.payload;
    },
    setCurrentFilteredClusterData(state, action) {
      state.currentFilteredClusterData = action.payload;
    },
    setHighlightedValue(state, action) {
      state.highlightedValue = action.payload;
    },
    setTableCoordinatesCopy(state, action) {
      state.tableCoordinatesCopy = action.payload;
    },
    setOnClassificationGroupsTab(state, action) {
      state.onClassificationGroupsTab = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
  },
});

//Selectors
export const selectCluster = (state) => state.dataEntry.selectedCluster;
export const selectDocument = (state) => state.dataEntry.selectedDocument;
export const selectProjectMember = (state) =>
  state.dataEntry.selectedProjectMember;
export const selectClusters = (state) => state.dataEntry.clusters;
export const selectProjectMembers = (state) => state.dataEntry.projectMembers;
export const selectClustersWithDocs = (state) =>
  state.dataEntry.clustersWithDocs;
export const selectDataElements = (state) => state.dataEntry.dataElements;
export const selectDocuments = (state) => state.dataEntry.documents;
export const selectTableData = (state) => state.dataEntry.tableData;
export const selectProjectDataElements = (state) =>
  state.dataEntry.projectDataElements;
export const selectUnclusteredDocuments = (state) =>
  state.dataEntry.unclusteredDocuments;
export const selectDocumentMember = (state) => state.dataEntry.documentMember;
export const toggleModalOpen = (state) => state.dataEntry.modalOpen;
export const selectTableColumnsModalOpen = (state) =>
  state.dataEntry.tableColumnsModal;
export const selectTableWindowPortalOpen = (state) =>
  state.dataEntry.tableWindowPortal;
export const selectSupplementalData = (state) =>
  state.dataEntry.supplementalData;
export const selectTableCoordinates = (state) =>
  state.dataEntry.tableCoordinates;
export const selectExtractedData = (state) => state.dataEntry.extractedData;
export const selectExtractionDone = (state) => state.dataEntry.extractionDone;
export const selectNotReviewed = (state) => state.dataEntry.notReviewed;
export const selectAddSpacing = (state) => state.dataEntry.addSpacing;
export const selectCurrentOpenTable = (state) =>
  state.dataEntry.currentOpenTable;
export const selectCurrentClusterFilterText = (state) =>
  state.dataEntry.currentClusterFilterText;
export const selectCurrentFilteredClusterData = (state) =>
  state.dataEntry.currentFilteredClusterData;
export const selectHighlightedValue = (state) =>
  state.dataEntry.highlightedValue;
export const selectTableCoordinatesCopy = (state) =>
  state.dataEntry.tableCoordinatesCopy;
export const selectOnClassificationGroupsTab = (state) =>
  state.dataEntry.onClassificationGroupsTab;
export const selectCurrentTab = (state) => state.dataEntry.selectedTab;
//Action Creators
export const {
  setSelectedCluster,
  setSelectedDocument,
  setSelectedProjectMember,
  setStoreClusters,
  setProjectMembers,
  setStoreDocuments,
  setStoreDataElements,
  setStoreTableData,
  setSupplementalData,
  setStoreProjectDataElements,
  setStoreUnclustedDocuments,
  setDocumentMember,
  setModalOpen,
  setTableColumnsModalOpen,
  setWindowTableOpen,
  setTableCoordinates,
  setExtractedData,
  setExtractionDone,
  setNotReviewed,
  setAddSpacing,
  setCurrentOpenTable,
  setCurrentClusterFilterText,
  setCurrentFilteredClusterData,
  setHighlightedValue,
  setTableCoordinatesCopy,
  setOnClassificationGroupsTab,
  setSelectedTab,
} = dataEntrySlice.actions;

export default dataEntrySlice.reducer;
