import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Divider from 'components/Divider';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  selectCurrentMsUser,
  selectUserProfilePhoto,
} from 'store/Redux/slices/usersSlice';
import styled from 'styled-components';
import Avatar from 'components/Avatar';

//#region Styled Components
const UserDataGrid = styled(Grid)`
  padding: 20px;
  align-items: center;
`;

const SCard = styled(Card)`
  height: fit-content;
`;

const SAvatar = styled(Avatar)`
  height: 100px;
  width: 100px;
  margin-right: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const InfoSection = styled(Grid)`
  flex-direction: column;
`;

const ContactInfo = styled(Grid)`
  margin-left: 40px;
`;
//#endregion Styled Components

export default function UserProfile() {
  const user = useSelector(selectCurrentMsUser);
  const photo = useSelector(selectUserProfilePhoto);
  const {
    displayName,
    mail,
    officeLocation,
    jobTitle,
    businessPhones,
    mobilePhone,
  } = user;

  return (
    <SCard>
      <CardHeader title='User Profile' />
      <Divider style={{ paddingBottom: '1px' }} />
      {Object.keys(user).length ? (
        <UserDataGrid container>
          <InfoSection item md={2}>
            <SAvatar src={photo.isValid ? photo.image : ''} />
          </InfoSection>
          <Grid container item xs={10}>
            <InfoSection item>
              <Typography>
                <Bold>Name: </Bold>
                {displayName}
              </Typography>
              <Typography>
                <Bold>Job Title: </Bold>
                {jobTitle}
              </Typography>
              <Typography>
                <Bold>Location: </Bold>
                {officeLocation}
              </Typography>
            </InfoSection>
            <ContactInfo item>
              <Typography>
                <Bold>Email: </Bold>
                {mail}
              </Typography>
              <Typography>
                <Bold>Business Phone: </Bold>
                {businessPhones[0]}
              </Typography>
              {mobilePhone && (
                <Typography>
                  <Bold>Mobile: </Bold>
                  {mobilePhone}
                </Typography>
              )}
            </ContactInfo>
          </Grid>
        </UserDataGrid>
      ) : (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <Typography variant='h6'> Not signed in</Typography>
        </div>
      )}
    </SCard>
  );
}

UserProfile.propTypes = {
  userImage: PropTypes.string,
  userName: PropTypes.string,
  userRole: PropTypes.string,
  userTasks: PropTypes.array,
};
