import { config } from './config';

// const query = `query{
//     clientSecret
//   }`;

/* On 11/21/2022, Tried to get clientId and TenantId secret from backend using below mentioned async method, it did not work as async function 
 * limits the variable value within the scope of async block only which prevented assigning the return value to clientId    */

//TODO backend request to get client secret; commented out for now but will need to implemented in the future
//TODO query is already set up, just need to configure backend to return the correct client secret
//TODO you can actually see clientSecret query in banana cake pop
// async function fetchSecretClient() {
//   const response = await fetch('https://metl-api.azurewebsites.net/graphql/', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json',
//     },
//     body: JSON.stringify({ query }),
//   });
//   const secret = await response.json();
//   return secret.data.clientSecret;
// }

/*NOTE For deployment -- DO NOT DELETE please -- connected to metl app on Deloitte AD -- */
export const msalConfig = {
  auth: {
    clientId: config.AZURECLIENTID,
    authority: `https://login.microsoftonline.com/${config.AZURETENANTID}`,
    redirectUri: config.ENVIRONMENT == config.SMARTEXTFEURL,
    postLogoutRedirectUri: config.ENVIRONMENT == config.SMARTEXTFEURL,

    // TODO we probably should get these values from .env -> config.js later, was not able to test out yet
    // clientId: config.AZURE_CLIENT_ID,
    // authority: `https://login.microsoftonline.com/${config.AZURE_TENANT_ID}`,

    // clientSecret: fetchSecretClient(), //TODO uncomment this line and remove the above line once we're able to fetch client secret from backend
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const apiRequest = {
    scopes: [`api://${config.APIAZURECLIENTID}/SmarTextApi.Read`, `api://${config.APIAZURECLIENTID}/SmarTextApi.ReadWrite`,`api://${config.APIAZURECLIENTID}/SmarTextApi.offline_access`],
};

const authHeader = {
    headers: {
        authorization: '',
    }
};

export function getAuthHeader() {
    const token = localStorage.getItem('SMARText_AccessToken');
    authHeader.headers.authorization = token ? `Bearer ${token}` : '';
    return authHeader;
}

export function getGraphQlApiUrl() {
    return config.ENVIRONMENT === 'local' ?
        (config.SMARTEXTAPIURL.includes('local') ? `${config.SMARTEXTAPIURL}graphql/` :
            `${config.SMARTEXTAPIURL}graphiql/`) :
        `${config.SMARTEXTAPIURL}graphiql/`;
}

export function getApiUrl() {
    return `${config.SMARTEXTAPIURL}`;
}