import { memo } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//#region Styled Components
const Accordion = styled(MuiAccordion)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: none;
  margin: 0px !important;
  :not(:last-child) {
    border-bottom: 0;
  }

  :before {
    display: none;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: -1px;
  padding: 0px 0px;
  && {
    min-height: ${(props) => (props.$isExpanded ? '50px' : null)};
  }

  .MuiAccordionSummary-content {
    margin: 0px;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
  max-height: ${(props) => (props.$isExpanded ? '200px' : '0')};
  transition: all 0.3s ease-out;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
//#endregion Styled Components

function AccordionList(props) {
  const { Summary, Details, isExpanded, Id, accordionClick, Index } = props;

  return (
    <Accordion
      square
      expanded={isExpanded}
      onChange={accordionClick(Id, Index)}
      TransitionProps={{ timeout: 0 }}
    >
      <AccordionSummary $isExpanded={isExpanded}>{Summary}</AccordionSummary>
      <AccordionDetails $isExpanded={isExpanded}>{Details}</AccordionDetails>
    </Accordion>
  );
}

AccordionList.propTypes = {
  Details: PropTypes.element,
  Id: PropTypes.string,
  Index: PropTypes.any,
  Summary: PropTypes.element,
  accordionClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};

const MemoizedAccordion = memo(AccordionList);

export default MemoizedAccordion;
