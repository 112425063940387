import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Box from 'components/Box';
import Button from 'components/Button';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import Dialog from 'components/Dialog';
import FormControl from 'components/FormControl';
import FormHelperText from 'components/FormHelperText';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import { TableColumnEditModal } from 'containers/Modals';
import { displayElementType } from 'helpers/enumToType';
import { decoder } from 'helpers/graphQL_Encoder';
import useWindowSize from 'hooks/useWindowSize';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_DATA_ELEMENT_DESCRIPTION } from 'services/GraphQL/Mutations';
import {
  setTableColumnsModalOpen,
  selectCluster,
  setStoreTableData,
  selectTableData,
} from 'store/Redux/slices/dataEntrySlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled, { css } from 'styled-components';
import { GET_TABLEDATA_BY_DATAELEMENT_ID } from 'services/GraphQL/Queries';

//#region Styled Components
const EditButton = styled(IconButton)`
  float: right;
  color: white;
  background-color: ${(props) =>
    props.isEditMode
      ? props.theme.palette.error.main
      : props.theme.palette.secondary.main};
  &:hover {
    background-color: ${(props) =>
      props.isEditMode
        ? props.theme.palette.error.darker
        : props.theme.palette.secondary.darker};
  }
`;

const SCardActions = styled(CardActions)`
  padding: 16px 16px 0 0;
  justify-content: flex-end;
  display: ${(props) => (props.$userRole === 'Admin' ? null : 'none')};
`;

const SFormHelperText = styled(FormHelperText)`
  display: ${(props) => (props.$editing ? null : 'none')};
`;

const FinishButton = styled(Button)`
  float: right;
  margin: 0 5px 5px 5px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 15px;
  }
`;

const EditTableButton = styled(Grid)`
  display: ${(props) =>
    props.$userRole === 'Admin' || props.$editing ? null : 'none'};
  padding: 8px;
  & Button {
    width: 200px;
  }
`;

const DetailsCard = styled(CardContent)`
  padding: 8px;
  max-height: 55vh;
  @media screen and (max-width: 1200px) {
    max-height: 30vh;
  }
`;

const FinishButtonGrid = styled(Grid)`
  float: right;
  margin: 0 5px 5px 5px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 15px;
  }
`;

const InputGrid = styled(Grid)`
  padding: 8px;
`;

const SCardHeader = styled(CardHeader)`
  & span {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 1200px) {
    & span {
      font-size: 1.1rem;
    }
  }
`;

const DescriptionInput = styled(TextField)`
  ${(props) => {
    if (props.description) {
      return null;
    } else {
      return css`
        .Mui-focused {
          color: green;
        }
        .MuiInput-underline:after {
          border-bottom-color: green;
        }
        .MuiOutlinedInput-root {
          & fieldset {
            border-color: red;
          }
          &.Mui-focused fieldset {
            border-color: green;
          }
        }
      `;
    }
  }}

  label.Mui-focused {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const DisplayFlex = styled.div`
  @media screen and (max-width: 1200px) {
    display: flex;
  }
`;
//#endregion

function AdminEditButton(props) {
  const { userRole, isEditMode, onClickEditExit, onClickEditEnter } = props;
  return (
    <SCardActions $userRole={userRole}>
      <EditButton
        size='normal'
        onClick={isEditMode ? onClickEditExit : onClickEditEnter}
        className='elementAssignmentPage-editDataElementButton'
        isEditMode={isEditMode}
      >
        {isEditMode ? <CloseIcon /> : <EditIcon />}
      </EditButton>
    </SCardActions>
  );
}

function DataTitleSection(props) {
  const { form, isEditMode } = props;

  return (
    <FormControl fullWidth variant='outlined'>
      <TextField
        disabled
        label='Title'
        value={form.name}
        InputProps={{
          readOnly: !isEditMode,
        }}
        variant='outlined'
      />
      <SFormHelperText $editing={isEditMode ? true : false}>
        * Title field cannot be edited
      </SFormHelperText>
    </FormControl>
  );
}

function DataTypeSection(props) {
  const { form, isEditMode } = props;

  return (
    <FormControl fullWidth variant='outlined'>
      <TextField
        disabled
        label='Data Type'
        value={displayElementType(form?.type)}
        InputProps={{
          readOnly: !isEditMode,
        }}
        variant='outlined'
      />
      <SFormHelperText $editing={isEditMode ? true : false}>
        * Data type field cannot be edited
      </SFormHelperText>
    </FormControl>
  );
}

function DataDescriptionInputArea(props) {
  const { form, isEditMode, onChangeDescription } = props;
  const size = useWindowSize();

  return (
    <DescriptionInput
      description={form?.description}
      inputRef={(input) => input && input.focus()}
      disabled={!isEditMode}
      fullWidth
      multiline
      rows={size.width > 1200 ? 6 : 2}
      label='Description'
      value={form?.description}
      onChange={onChangeDescription}
    />
  );
}

function FinishEditButton(props) {
  const { isDisabled, onClickConfirmEdit } = props;
  return (
    <FinishButton
      primary
      variant='contained'
      onClick={onClickConfirmEdit}
      disabled={isDisabled}
      className='elementAssignmentPage-finishEditingDataElement'
    >
      Finish editing
    </FinishButton>
  );
}

export default function ElementDetails(props) {
  const {
    selectedElement,
    dataTypeList,
    prevSelectedElement,
    setCurrentlySelectedElement,
  } = props;
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditMode, setEditModeToggle] = useState(false);
  const [form, setForm] = useState({
    name: '',
    type: '',
    description: '',
  });
  const currentCluster = useSelector(selectCluster);
  const currentDataElementTableData = useSelector(selectTableData);
  const [isEditColumnsDisabled, setIsEditColumnsDisabled] = useState(true);

  // Mutation
  const [updateDataElement] = useMutation(UPDATE_DATA_ELEMENT_DESCRIPTION, {
    onCompleted: (data) => {
      if (data?.updateDataElementDescription) {
        let updatedSelectedElement = {
          ...selectedElement,
          description: data.updateDataElementDescription.description,
        };
        setCurrentlySelectedElement(updatedSelectedElement);
        setEditModeToggle(false);
        dispatch(setSnackbarSuccess(`Element description updated!`));
      }
    },
    onError: (error) =>
      dispatch(setSnackbarError(`An error occurred : ${error.message}`)),
  });

  const [getDataElementTableData] = useLazyQuery(
    GET_TABLEDATA_BY_DATAELEMENT_ID,
    {
      onCompleted: (data) => {
        if (data?.dataElementById) {
          dispatch(setStoreTableData(data.dataElementById.tableData));
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    let newForm = {
      id: selectedElement.id,
      name: selectedElement.name,
      type: selectedElement.type,
      description: selectedElement.description
        ? selectedElement.description
        : '',
    };
    setForm(newForm);
    setEditModeToggle(false);
    setIsEditColumnsDisabled(true);

    if (selectedElement.type === 'TABLE') {
      getDataElementTableData({ variables: { id: newForm.id } });
    } else {
      dispatch(setStoreTableData(null));
    }
  }, [selectedElement]);

  /* #region  Events */
  const onChangeDescription = (e) =>
    setForm({ ...form, description: e.target.value });

  const onClickEditEnter = () => {
    setEditModeToggle(!isEditMode);
    setIsEditColumnsDisabled(false);
  };

  const onClickEditExit = () => {
    if (selectedElement.description === form.description) {
      setEditModeToggle(!isEditMode);
    } else {
      setDialogOpen(!dialogOpen);
    }
    setIsEditColumnsDisabled(true);
  };

  const onClickConfirmEdit = () => {
    const decodedId = decoder(form.id);
    const payload = {
      dataElementId: decodedId,
      description: form.description,
    };
    updateDataElement({
      variables: {
        input: payload,
      },
    });
  };

  const onClickConfirmDialog = () => {
    setDialogOpen(false);
    setForm(prevSelectedElement);
    setEditModeToggle(!isEditMode);
  };

  const onClickCancelDialog = () => setDialogOpen(false);

  const editTableButton = (
    <EditTableButton $userRole={userRole} $editing={isEditMode ? true : false}>
      <Button
        variant='contained'
        primary
        onClick={() => dispatch(setTableColumnsModalOpen(true))}
        disabled={
          isEditColumnsDisabled ||
          !currentDataElementTableData?.some(
            (x) => x.dataElementId === decoder(selectedElement.id)
          )
        }
        className='elementAssignmentPage-editDataElementColumnsButton'
      >
        Edit Columns
      </Button>
    </EditTableButton>
  );

  /* #endregion */

  return (
    <Box>
      <Grid container>
        <Grid item xs={10}>
          <SCardHeader title='Data Element Details' />
        </Grid>
        <Grid item xs={2}>
          <AdminEditButton
            userRole={userRole}
            isEditMode={isEditMode}
            onClickEditExit={onClickEditExit}
            onClickEditEnter={onClickEditEnter}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction='column'
        spacing={1}
        sx={{ flexWrap: 'nowrap', overflowY: 'auto' }}
      >
        <Grid item xs={12}>
          <DetailsCard>
            <DisplayFlex>
              <InputGrid item lg={12} xs={6}>
                <DataTitleSection form={form} isEditMode={isEditMode} />
              </InputGrid>
              <InputGrid item lg={12} xs={6}>
                <DataTypeSection
                  form={form}
                  isEditMode={isEditMode}
                  dataTypeList={dataTypeList}
                />
              </InputGrid>
            </DisplayFlex>
            <InputGrid item lg={12} md={12}>
              <DataDescriptionInputArea
                form={form}
                isEditMode={isEditMode}
                onChangeDescription={onChangeDescription}
              />
            </InputGrid>
            {form.type === 'TABLE' ? editTableButton : false}
            <CardActions>
              <FinishButtonGrid item xs={12}>
                {isEditMode && (
                  <FinishEditButton
                    onClickConfirmEdit={onClickConfirmEdit}
                    isDisabled={selectedElement === form}
                  />
                )}
              </FinishButtonGrid>
            </CardActions>
          </DetailsCard>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        title='Exit without saving changes?'
        cancelLabel='Cancel'
        handleCancel={onClickCancelDialog}
        submitLabel='Confirm'
        handleSubmit={onClickConfirmDialog}
        hasActions
      >
        Are you sure you want to exit?
      </Dialog>

      {currentDataElementTableData && (
        <TableColumnEditModal
          selectedElement={selectedElement}
          cluster={currentCluster}
        />
      )}
    </Box>
  );
}

ElementDetails.propTypes = {
  dataTypeList: PropTypes.array,
  selectedElement: PropTypes.object,
  prevSelectedElement: PropTypes.object,
  setCurrentlySelectedElement: PropTypes.func,
};

AdminEditButton.propTypes = {
  isEditMode: PropTypes.any,
  onClickEditEnter: PropTypes.func,
  onClickEditExit: PropTypes.func,
  userRole: PropTypes.string,
};
DataTitleSection.propTypes = {
  isEditMode: PropTypes.bool,
  form: PropTypes.object,
};

DataTypeSection.propTypes = {
  dataTypeList: PropTypes.array,
  isEditMode: PropTypes.any,
  form: PropTypes.object,
};

DataDescriptionInputArea.propTypes = {
  isEditMode: PropTypes.any,
  form: PropTypes.object,
  onChangeDescription: PropTypes.func,
};

FinishEditButton.propTypes = {
  isDisabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClickConfirmEdit: PropTypes.func,
};

const userRole = 'Admin';
