const offsets = new Map([
  [0.75, () => 'less than a minute'],
  [1.5, () => 'a minute'],
  [45, (x) => `${x.totalMinutes.toFixed(0)} minutes`],
  [90, () => 'an hour'],
  [1440, (x) => `${x.totalHours.toFixed(0)} hours`],
  [2880, () => 'a day'],
  [43200, (x) => `${x.totalDays.toFixed(0)} days`],
  [86400, () => 'a month'],
  [525600, (x) => `${(x.totalDays / 30).toFixed(0)} months`],
  [1051200, () => 'a year'],
  [Number.MAX_VALUE, (x) => `${(x.totalDays / 365).toFixed(0)} years`],
]);

function toRelativeDateHelper(input) {
  const now = new Date();
  const x = now - input;
  const suffix = x > 0 ? ' ago' : ' from now';
  const absX = Math.abs(x);
  const timespan = {
    totalMinutes: absX / (1000 * 60),
    totalHours: absX / (1000 * 60 * 60),
    totalDays: absX / (1000 * 60 * 60 * 24),
  };

  for (const [key, value] of offsets) {
    if (timespan.totalMinutes < key) {
      return value(timespan) + suffix;
    }
  }
}

export function toRelativeDate(input) {
  return toRelativeDateHelper(input || new Date());
}

export function isPast24Hours(assignedOn) {
  const now = new Date();
  const date = new Date(assignedOn);

  const oneDay = 60 * 60 * 24 * 1000;
  return now - date > oneDay;
}
