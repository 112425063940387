import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import store from 'store/Redux/store';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { config } from 'config';
import { MsalProvider } from '@azure/msal-react';
import {
  msalConfig,
  apiRequest,
  getAuthHeader,
  getGraphQlApiUrl,
} from './authConfig';
import {
  PublicClientApplication,
  InteractionStatus,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { setGlobalLoading } from 'store/Redux/slices/projectsSlice';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'pages/Error/ErrorPage';
import { ConfirmProvider } from 'material-ui-confirm';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useLocation } from 'react-router-dom';
export const msalInstance = new PublicClientApplication(msalConfig);
const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();
const REFRESH_THRESHOLD = 300; // 5 minutes in seconds
const TOKEN_CHECK_INTERVAL = 60000; // 1 minute in milliseconds


const errorLink = onError(({ graphqlErrors }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message }) => {
      alert(`GraphQL Error ${message}`);
    });
  }
});

const Fallback = ({ error }) => {
  return <ErrorPage error={error} />;
};

function GlobalLoadingIndicator() {
  const history = useHistory();
  const status = useApolloNetworkStatus();
  const dispatch = useDispatch();
  const location = useLocation();
  const { instance, inProgress, accounts } = useMsal();
  const apiAccessTokenRequest = {
    ...apiRequest,
    account: accounts[0],
  };

  const interval = useRef(null);
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      // handleRedirectPromise returns after a redirect promise
      instance
        .handleRedirectPromise()
        .then(() =>
          // When we have to access multiple resources, initiate a separate token request for each api
          instance
            .acquireTokenSilent(apiAccessTokenRequest)
            .then((response) => {
              localStorage.setItem(
                'SMARText_AccessToken',
                response.accessToken
              );
              if (config.ENVIRONMENT === 'local') {
                console.log(getAuthHeader().headers.authorization);
              }
            })
            // if token fails to fetch silently, reattempt to get token with a popup
            .catch(() => {
              instance
                .acquireTokenPopup(apiAccessTokenRequest)
                .then((response) => {
                  localStorage.setItem(
                    'SMARText_AccessToken',
                    response.accessToken
                  );
                })
                .catch(() => {
                  handleOnIdle();
                });
            })
        )
        .catch((error) => console.error(error));
    }
  }, [instance, accounts, inProgress]);

  const acquireTokenWithRefreshToken = async () => {
    try {
      if (accounts.length && instance) {
        const response = await instance.acquireTokenSilent({
          account: accounts[0],
          scopes: [
            `api://${config.APIAZURECLIENTID}/SmarTextApi.Read`,
            `api://${config.APIAZURECLIENTID}/SmarTextApi.ReadWrite`,
            `api://${config.APIAZURECLIENTID}/SmarTextApi.offline_access`,
          ],
        });
        localStorage.setItem('SMARText_AccessToken', response.accessToken);
        localStorage.getItem('SMARText_AccessToken');
      }
    } catch (error) {
      console.log('Error refreshing token', error); // Handle token refresh error
    }
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      const backendAccessToken = localStorage.getItem('SMARText_AccessToken');
      if (backendAccessToken && location.pathname != '/timeout') {
        const decodeToken = jwtDecode(backendAccessToken);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeUntilExpiry = decodeToken.exp - currentTime;
        if (timeUntilExpiry <= REFRESH_THRESHOLD) {
          // Token is about to expire or has expired, refresh it
          acquireTokenWithRefreshToken();
        }
      }
    };
    interval.current = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
    checkTokenExpiry(); // Check token expiry immediately after mounting
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (status.numPendingQueries > 0 || status.numPendingMutations > 0) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [status]);

  function handleOnIdle() {
    history.push('/timeout');
  }

  const DataDomainScriptsAzure =
    config.ENVIRONMENT === 'production' &&
    config.SMARTEXTFEURL === config.SMARTEXTFEPRODURLAZURE
      ? '7ec69a58-89ae-42f6-a401-5ae787869dad'
      : '7ec69a58-89ae-42f6-a401-5ae787869dad-test';

  const DataDomainScriptsAWS =
    config.ENVIRONMENT === 'production' &&
    config.SMARTEXTFEURL === config.SMARTEXTFEPRODURLAWS
      ? '10d56f0a-6258-4bd0-8ad6-cbc2012775f7'
      : '7ec69a58-89ae-42f6-a401-5ae787869dad-test';

  useEffect(() => {
    const cookieBannerScript = document.createElement('script');

    cookieBannerScript.src =
      'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    cookieBannerScript.type = 'text/javascript';
    // charset is deprecated
    //cookieBannerScript.charset = 'UTF-8';
    cookieBannerScript.setAttribute('async', 'false');
    cookieBannerScript.setAttribute(
      'data-domain-script',
      DataDomainScriptsAzure
    );
    cookieBannerScript.setAttribute('data-domain-script', DataDomainScriptsAWS);
    document.head.appendChild(cookieBannerScript);
  }, []);
}

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext(getAuthHeader());
  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link.concat(
    authLink.concat(
      from([
        (errorLink,
        createHttpLink({
          uri: ({ operationName }) => {
            return `${getGraphQlApiUrl()}${operationName}`;
          },
          fetchOptions: {
            //mode: 'no-cors'
          },
        })),
      ])
    )
  ),
  connectToDevTools: true,
});

const authRequest = {
  scopes: ['openid', 'profile'],
};

//NOTE: Changes were made in React 18 to strict mode that simulate concurrent updates (new feature in React 18).
//This prevents us from moving from the Landing Page to the Dashboard.
//Will attempt to find solution and reimplement Strict Mode in the future.

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  /*<React.StrictMode>*/
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {' '}
      <Provider store={store}>
        <ErrorBoundary FallbackComponent={Fallback}>
          <Router>
            <ApolloProvider client={client}>
              <GlobalLoadingIndicator />
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: { autoFocus: true },
                }}
              >
                <App />
              </ConfirmProvider>
            </ApolloProvider>
          </Router>
        </ErrorBoundary>
      </Provider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
  /*</React.StrictMode>*/
);

reportWebVitals();
registerServiceWorker();
