import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import { config } from 'config';
import MuiCardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import AcknowledgementDialog from 'components/Dialog/AcknowledgementDialog';
import FormControl from 'components/FormControl';
import Grid from 'components/Grid';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Typography from 'components/Typography';
import { decoder } from 'helpers/graphQL_Encoder';
import { AddProjectModal, ImportModal } from 'pages/Records/modals';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ARCHIVE_PROJECT,
  // Commented out restore functionality until further notice
  // RESTORE_PROJECT,
  //UPDATE_PROJECT_NAME,
} from 'services/GraphQL/Mutations';
import { LOAD_GLOBAL_ELEMENTS } from 'services/GraphQL/Queries';
import {
  archiveProject,
  loadDataElements,
  // Commented out restore functionality until further notice
  // restoreProject,
  selectActiveProjects,
  selectArchivedProjects,
  selectCurrentElementId,
  selectCurrentProjectId,
  selectElements,
  selectProjectMemberDocuments,
  selectProjectMemberElements,
  setCurrentElementId,
  setCurrentProjectId,
  setCurrentProjectIndex,
  //updateProjectName,
  setProjectMemberDocuments,
  setProjectMemberElements,
  setProjectTableIndex,
  selectProjectsTableIndex,
  selectProjectsList,
  selectCurrentResourceName,
  defaultResourceMenu,
} from 'store/Redux/slices/projectsSlice';
import styled from 'styled-components';
import ReactTable from 'containers/ReactTable/index';
import { dateTimeFormatter, compareDates } from 'helpers/dateTimeFormatter';
//import { setSnackbarError } from 'store/Redux/slices/snackbarsSlice';
import { displayElementType } from 'helpers/enumToType';
import { resources } from 'resource';

//#region Styled Components
const SCard = styled(Card)`
  padding: 10px;
`;

const Spacing1 = styled(Grid)`
  padding: 8px;
`;

const Spacing2 = styled(Grid)`
  padding: 16px;
`;

const ArchiveButton = styled(Button)`
  margin-left: auto;
  float: right;
`;

const SFormControl = styled(FormControl)`
  min-width: 120px;
`;

const SupplementaryData = styled(MuiCardContent)`
  height: auto;
  min-height: auto;
  overflow: visible;
  padding: 0 5px;
`;

const CardContent = styled(MuiCardContent)`
  overflow-x: visible;
  padding: 0 5px;
`;

const DataElement = styled(Grid)`
  background: ${(props) =>
    props.selected ? `${props.theme.palette.primary.main}` : null};
`;

const DropDown = styled(Select)`
  & fieldset {
    top: 0;
  }

  & legend {
    display: none;
  }
`;

const GlobalList = styled(CardContent)`
  height: auto;
  min-height: auto;
  overflow: visible;
  padding: 0 5px;
`;
//#endregion Styled Components

//#region  Columns
const projectMemberDocColumns = [
  {
    Header: 'Original Project Member ID',
    accessor: 'originalId',
  },
  {
    Header: 'Document Name',
    accessor: 'originalName',
  },
  {
    Header: 'Project Name',
    accessor: 'projectName',
  },
];

const projectMemberElemColumns = [
  {
    Header: 'Project Member Id',
    accessor: 'originalProjectMemberId',
  },
  {
    Header: 'Data Element Name',
    accessor: 'elementName',
  },
  {
    Header: 'Value',
    accessor: 'value',
  },
  {
    Header: 'Created On',
    accessor: (row) => dateTimeFormatter(row.createdOn, true),
  },
  {
    Header: 'Last Modified On',
    accessor: (row) => dateTimeFormatter(row.lastModifiedOn, true),
  },
];

const globalDataElementsColumns = [
  {
    Header: 'Element Name',
    accessor: 'name',
  },
  {
    Header: 'Element Type',
    accessor: (row) => displayElementType(row.type),
  },
  {
    Header: 'Date Uploaded',
    accessor: (row) => compareAndFormatDate(row.createdOn, row.lastModifiedOn),
  },
];
//#endregion

//Page Global Function
const compareAndFormatDate = (createdOn, modifiedOn) => {
  var updatedDate = compareDates(createdOn, modifiedOn);
  if (updatedDate) {
    return dateTimeFormatter(updatedDate, true);
  } else {
    return new Date().toLocaleDateString('en-US');
  }
};

//#region Components
const sortByDesc = 'Desc_';
const recordsSortBy = 'ReactTable_records_SortBy';
const recordsDocSortBy = 'ReactTable_records_Doc_SortBy';
const recordsDESortBy = 'ReactTable_records_DE_SortBy';
const recordsGDESortBy = 'ReactTable_records_GDE_SortBy';
function DataElementRowItem(props) {
  const currentElementId = useSelector(selectCurrentElementId);
  const { Id } = props;

  return (
    <DataElement container selected={currentElementId === Id}>
      <Spacing2 item xs={8}>
        <Typography>{props.Name}</Typography>
      </Spacing2>
      <Spacing1 item xs={4}>
        <Typography variant='body2'>
          <Box fontWeight='fontWeightBold' textAlign='right'>
            Date Uploaded
          </Box>
        </Typography>
        <Typography variant='body2'>
          <Box textAlign='right'>{props.Date}</Box>
        </Typography>
      </Spacing1>
    </DataElement>
  );
}

function DataElementRowDetails(props) {
  return (
    <Spacing2 container>
      <Grid item xs={12}>
        <Typography>
          <b>Type: </b> {props.Type}
        </Typography>
        <Typography>
          <b>Description: </b>
          {props.Description}
        </Typography>
      </Grid>
    </Spacing2>
  );
}

function GlobalDataElementsCard(props) {
  const { openImportModal, elements } = props;

  return (
    <SCard
      style={{ minHeight: 457 }}
      className='recordsPage-globalDataElementsTable'
    >
      <GlobalList>
        <ReactTable
          header='Global Data Elements'
          isExpandable={true}
          columns={globalDataElementsColumns}
          data={elements}
          hasSearchBar={false}
          hasImportButton={true}
          openImportModal={openImportModal}
          pageName={'records_GDE'}
          defaultSortColumn={localStorage.getItem(recordsGDESortBy)}
          isDescending={
            'true' ==
            localStorage.getItem(
              recordsGDESortBy +
                sortByDesc +
                localStorage.getItem(recordsGDESortBy)
            )
          }
        />
      </GlobalList>
    </SCard>
  );
}

function SupplementaryDatabaseCard({
  openImportModal,
  projectMemberDocuments,
  projectMemberElements,
  projectMemberHeader,
}) {
  const isDataAvailable =
    (projectMemberDocuments && projectMemberDocuments.length > 0) ||
    (projectMemberElements && projectMemberElements.length > 0);

  return (
    <Card>
      <CardHeader
        title='Supplementary Database'
        action={
          <Button
            data-cy='import-pm-button'
            variant='contained'
            primary
            className='recordsPage-supplementaryDatabaseButton'
            onClick={() => openImportModal('supplemental')}
          >
            Import
          </Button>
        }
      />
      {isDataAvailable && (
        <SupplementaryData>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {projectMemberDocuments?.length > 0 ? (
                <ReactTable
                  header={`${projectMemberHeader} Documents`}
                  data={projectMemberDocuments}
                  columns={projectMemberDocColumns}
                  hasSearchBar={false}
                  pageName={'records_Doc'}
                  defaultSortColumn={localStorage.getItem(recordsDocSortBy)}
                  isDescending={
                    'true' ==
                    localStorage.getItem(
                      recordsDocSortBy +
                        sortByDesc +
                        localStorage.getItem(recordsDocSortBy)
                    )
                  }
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {projectMemberElements?.length > 0 ? (
                <ReactTable
                  header={`${projectMemberHeader} Data Elements`}
                  data={projectMemberElements}
                  columns={projectMemberElemColumns}
                  hasSearchBar={false}
                  pageName={'records_DE'}
                  defaultSortColumn={localStorage.getItem(recordsDESortBy)}
                  isDescending={
                    'true' ==
                    localStorage.getItem(
                      recordsDESortBy +
                        sortByDesc +
                        localStorage.getItem(recordsDESortBy)
                    )
                  }
                />
              ) : null}
            </Grid>
          </Grid>
        </SupplementaryData>
      )}
    </Card>
  );
}

SupplementaryDatabaseCard.propTypes = {
  openImportModal: PropTypes.func,
  projectMemberDocuments: PropTypes.array,
  projectMemberElements: PropTypes.array,
  projectMemberHeader: PropTypes.any,
};

function ProjectsCard({ addedProject, openNewProject }) {
  const dispatch = useDispatch();

  const activeProjects = useSelector(selectActiveProjects);
  const archiveProjects = useSelector(selectArchivedProjects);
  const projects = useSelector(selectProjectsList);
  const pageIndex = useSelector(selectProjectsTableIndex);
  const currentProjectId = useSelector(selectCurrentProjectId);

  const [selectedRow, setSelectedRow] = useState({});
  const [updatingProjects, setUpdatingProjects] = useState(false);
  const [displayProjects, setDisplayProjects] = useState(activeProjects);
  const [displayProjectsType, setDisplayProjectsType] = useState('Active');

  const selectedProject = projects.find((p) => p.id === currentProjectId);

  const projectListTypes = {
    Active: 'Active',
    Archive: 'Archive',
  };
  //#region Table Columns
  const projectTableColumns = [
    {
      Header: 'Project Name',
      accessor: 'name',
      //Cell: (props) => <EditableCell {...props} updateValue={handleRename} />,
    },
    {
      Header: 'Date Updated',
      accessor: (row) =>
        compareAndFormatDate(row.createdOn, row.lastModifiedOn),
    },
  ];

  //#region GraphQL
  const [archiveProjectMutation] = useMutation(ARCHIVE_PROJECT);
  // Commented out restore functionality until further notice
  // const [restoreProjectMutation] = useMutation(RESTORE_PROJECT);
  //const [updateProjectNameMutation] = useMutation(UPDATE_PROJECT_NAME);
  //#endregion GraphQL

  //#region useEffect
  useEffect(() => {
    if (displayProjectsType === 'All') {
      setDisplayProjects(projects);
    } else if (displayProjectsType === 'Archive') {
      setDisplayProjects(archiveProjects);
    } else {
      setDisplayProjects(activeProjects);
    }
  }, [displayProjectsType, activeProjects, archiveProjects, projects]);

  useEffect(() => {
    setSelectedRow({ [currentProjectId]: true });
  }, [currentProjectId]);

useEffect(() => {
    if (addedProject) {
      let newIndex = displayProjects.findIndex((x) => x.id == addedProject.id);
      dispatch(setCurrentProjectId(addedProject.id));
      dispatch(setCurrentProjectIndex(newIndex));
    }
  }, [addedProject]);
  //#endregion useEffect

  //#region Functions
  const handleProjectChange = (row) => {
    dispatch(setCurrentProjectId(row.original.id));
    const index = projects.findIndex((p) => p.id === row.original.id);
    dispatch(setCurrentProjectIndex(index));
    setSelectedRow({ [row.id]: true });
  };

  const handlePageChange = (index) => {
    dispatch(setProjectTableIndex(index));
  };

  const displayProjectChange = (event) => {
    const displayProjects = event.target.value;
    switch (displayProjects) {
      case projectListTypes.Active:
        setDisplayProjects(activeProjects);
        setDisplayProjectsType(projectListTypes.Active);
        break;
      case projectListTypes.Archive:
        setDisplayProjects(archiveProjects);
        setDisplayProjectsType(projectListTypes.Archive);
        break;
      default:
        setDisplayProjects(activeProjects);
    }
  };

  function handleArchive() {
    archiveProjectMutation({
      variables: {
        id: decoder(currentProjectId),
      },
    }).then(() => dispatch(archiveProject(currentProjectId)));
    setUpdatingProjects(!updatingProjects);
  }

  // Commented out restore functionality until further notice
  // function handleRestore() {
  //   restoreProjectMutation({
  //     variables: {
  //       id: decoder(currentProjectId),
  //     },
  //   }).then((res) => dispatch(restoreProject(res.data.restoreProject.id)));
  //   setUpdatingProjects(!updatingProjects);
  // }

 /*function handleRename(oldName, newName) {
    const projectNames = projects?.map((p) => p.name.toUpperCase().trim());
    if (projectNames.indexOf(newName.toUpperCase().trim()) == -1) {
      updateProjectNameMutation({
        variables: {
          input: {
            name: newName,
            projectId: decoder(currentProjectId),
          },
        },
      }).then(() =>
        dispatch(
          updateProjectName({
            newName,
          })
        )
      );
    } else {
      dispatch(setSnackbarError('A project with this name already exists'));
    }
  }*/
  //#endregion Functions

  return (
    <SCard
      style={{ minHeight: 457 }}
      className='recordsPage-projectsTable'
      data-cy='projects-table'
    >
      <CardContent>
        {displayProjects?.length && (
          <ReactTable
            header='Projects'
            columns={projectTableColumns}
            data={displayProjects}
            hasSearchBar={false}
            onSelected={handleProjectChange}
            hasAddRow={true}
            actions={{
              addRow: openNewProject,
            }}
            useActionsColumn={false}
            currentPageIndex={pageIndex}
            setCurrentPageIndex={handlePageChange}
            initialSelected={selectedRow}
            useRowId
            pageName={'records'}
            defaultSortColumn={localStorage.getItem(recordsSortBy)}
            isDescending={
              'true' ==
              localStorage.getItem(
                recordsSortBy + sortByDesc + localStorage.getItem(recordsSortBy)
              )
            }
            hasNoDefaultSort={true}
            isRowSelectionDisabled={
              displayProjectsType == projectListTypes.Archive
            }
          />
        )}
      </CardContent>
      <CardActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SFormControl>
              <DropDown
                value={displayProjectsType}
                onChange={displayProjectChange}
              >
                <MenuItem value={projectListTypes.Active}>Active</MenuItem>
                <MenuItem value={projectListTypes.Archive}>Archive</MenuItem>
              </DropDown>
            </SFormControl>
          </Grid>
          <Grid item xs={6}>
            {displayProjectsType == projectListTypes.Active ? (
              <ArchiveButton
                data-cy='archive-button'
                primary
                variant='contained'
                disabled={!selectedProject}
                onClick={handleArchive}
              >
                {'Archive'}
              </ArchiveButton>
            ) : null}
          </Grid>
        </Grid>
      </CardActions>
    </SCard>
  );
}

ProjectsCard.propTypes = {
  addedProject: PropTypes.object,
  openNewProject: PropTypes.func,
};
//#endregion Components

export default function Records() {
  const dispatch = useDispatch();
  //Selectors
  const elements = useSelector(selectElements);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const projectMemberDocuments = useSelector(selectProjectMemberDocuments);
  const projectMemberElements = useSelector(selectProjectMemberElements);
  const resourceMenu = useSelector(selectCurrentResourceName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const projectMemberHeader = `${selectedResources.ANY_PAGES.PROJECT_MEMBER}`;
    //State
  const [dataElementExpanded, setDataElementExpanded] = useState();
  const [isNewProjectOpen, setNewProjectOpen] = useState(false);
const [addedProject, setAddedProject] = useState();
  const [importOpen, setImportOpen] = useState(false);
  const [modalType, setModalType] = useState(false);

  //#region GraphQL
  useQuery(LOAD_GLOBAL_ELEMENTS, {
    onCompleted: (data) => {
      if (data?.dataElements?.nodes) {
        dispatch(loadDataElements(data?.dataElements));
      }
    },
  });
  //#endregion GraphQL

  //#region useEffect
  useEffect(() => {
    dispatch(setProjectTableIndex(0));
  }, []);

  useEffect(() => {
    dispatch(setProjectMemberDocuments([]));
    dispatch(setProjectMemberElements([]));
  }, [currentProjectId]);

  //#endregion useEffect

  //#region Functions
  const openNewProject = () => setNewProjectOpen(true);

  const closeNewProject = () => setNewProjectOpen(false);

  const handleElementChange = (panel) => (event, newExpanded) => {
    setDataElementExpanded(newExpanded ? panel : false);
    dispatch(setCurrentElementId(panel));
  };

  const openImportModal = (type) => {
    if (type == 'supplemental') {
      setModalType(type);
    } else {
      setModalType('element');
    }
    setImportOpen(true);
  };
  //#endregion Functions

  return (
    <>
      <Grid container spacing={2}>
        {config.ENVIRONMENT !== 'production' ? <AcknowledgementDialog /> : null}
        <Grid item xs={12}>
          <div className='recordsPage-supplementaryDatabaseTable'>
            <SupplementaryDatabaseCard
              openImportModal={openImportModal}
              projectMemberDocuments={projectMemberDocuments}
              projectMemberElements={projectMemberElements}
              projectMemberHeader={projectMemberHeader}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectsCard
            addedProject={addedProject}
            openNewProject={openNewProject}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {elements?.nodes?.length && (
            <GlobalDataElementsCard
              openImportModal={openImportModal}
              elements={elements?.nodes}
              dataElementExpanded={dataElementExpanded}
              handleElementChange={handleElementChange}
            />
          )}
        </Grid>
      </Grid>

      <ImportModal
        importOpen={importOpen}
        setImportOpen={setImportOpen}
        modalType={modalType}
      />
      <AddProjectModal
        isNewProjectOpen={isNewProjectOpen}
        setNewProjectOpen={setNewProjectOpen}
        setAddedProject={setAddedProject}
        closeNewProject={closeNewProject}
      />
    </>
  );
}

DataElementRowItem.propTypes = {
  Date: PropTypes.string,
  Name: PropTypes.string,
  Id: PropTypes.string,
};

DataElementRowDetails.propTypes = {
  Type: PropTypes.string,
  Description: PropTypes.string,
};

GlobalDataElementsCard.propTypes = {
  dataElementExpanded: PropTypes.any,
  elements: PropTypes.array,
  handleElementChange: PropTypes.any,
  openImportModal: PropTypes.func,
};
