import MFormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';

const SFormControlLabel = styled(MFormControlLabel)`
.MuiFormControlLabel-label {
 font-weight: bolder;
}
.MuiSwitch-track {
  background-color: ${(props) =>
    props.checked ? `${props.theme.palette.primary.main}` : 'grey'} !important;
}
.MuiSwitch-thumb {
  color: ${(props) =>
    props.checked ? `${props.theme.palette.primary.main}` : 'grey'} !important;
`;

export default function FormControlLabel(props) {
  return <SFormControlLabel {...props} />;
}
