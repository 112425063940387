import { defaultResourceMenu } from 'store/Redux/slices/projectsSlice';
import { resources } from 'resource';

const resourceMenu = defaultResourceMenu;
const selectedResources = resources[resourceMenu === null || resourceMenu === undefined ? defaultResourceMenu : resourceMenu];
const projectMember = `${selectedResources.ANY_PAGES.PROJECT_MEMBER}`;
const cluster = `${selectedResources.ANY_PAGES.CLUSTER}`;

export const IntroJsSteps = {
    landingPage: [
        {
            intro: `Hello! 👋 Welcome to the SmarText app tutorial! <br/><br/> <b>Navigation:</b> <ul><li><u>Next Step</u>: <b>Next</b> Button or right-arrow (→) key </li><li><u>Previous Step</u>: <b>Back</b> Button or Left-arrow (←) key </li><li> <u>Exit</u>: <b>x</b> Button or <b>Esc</b> Key </li></ul>`,
        },
        {
            element: '.landingPage-projectDropDown',
            intro: `This dropdown contains a list of projects within the organization. Please select a project to continue.`,
        },
    ],
    recordsPage: [
        {
            intro: `This is the Admin Records page.`,
        },
        {
            element: '.recordsPage-supplementaryDatabaseTable',
            intro: `This is the Supplementary Database table.`,
        },
        {
            element: '.recordsPage-supplementaryDatabaseButton',
            intro: `Here, users can import CSV files to either map: <br><br> <ul><li><b>${projectMember}s to Documents:</b> Update or create ${projectMember} IDs or names to existing document names.</li><li><b>${projectMember}s to Data Elements:</b> Update or attribute existing data elements to existing ${projectMember}s. <ul><li>Examples of data elements include, DOB (Date of Birth), Address, FirstName, etc.</li><li>supplemental data values will be utilized later as part of data extraction validation and review within the Data Exceptions page.</li></ul></li></ul>`,
        },
        {
            element: '.recordsPage-projectsTable',
            intro: `Here, users can view, sort, filter, add, and archive projects within the organization. Projects selected or created here will select the project in the top menu bar.`,
        },
        {
            element: '.recordsPage-globalDataElementsTable',
            intro: `Here, users can view all data elements within the organization and import multiple data elements at once.`,
        },
        {
            element: '.recordsPage-globalDataElementsButton',
            intro: `Clicking the <b>Import</b> button will open up a window with instructions on how to import data elements.<ul><li><b>Note:</b> Importing data elements here can <u>ONLY</u> import multiple data elements. To import <u>a single</u> data element, please navigate to the <b>Assign Elements</b> page under the <b>Classification</b> tab.</li></ul>`,
        },
        {
            element: '.sidebar-assign',
            intro: `To view users within the organization, please use this sidebar to navigate to the <b>Assign</b> page.`,
        },
    ],
    assignPage: [
        {
            intro: `This is the Admin Assign page.`,
        },
        {
            element: '.assignPage-assignTable',
            intro: `This table shows all the users within the organization.`,
        },
        {
            element: '.assignPage-assignTable',
            intro: `Users can edit a user's assigned project and permissions.`,
        },
        {
            element: '.sidebar-projects',
            intro: `To upload documents, use this sidebar to navigate to the <b>Import</b> page under <b>Projects</b>.`,
        },
    ],
    importPage: [
        {
            intro: `This is the Projects Import page.`,
        },
        {
            intro: `This is where documents can be uploaded to the currently selected project.`,
            position: 'middle',
        },
        {
            element: '.importPage-importDropZone',
            intro: `This is where image files can be uploaded. Folders or individually selected documents can be uploaded.`,
        },
        {
            element: '.importPage-uploadedFiles',
            intro: `When importing is complete, uploaded files will be shown here.`,
        },
        {
            element: '.importPage-fileConverter',
            intro: `If documents are not a valid format, use this file converter to convert PDFs to the compatible file type.`,
        },
        {
            element: '.sidebar-classification',
            intro: `Once files are uploaded, users can view and manipulate documents in <b>Classification</b>.`,
        },
    ],
    clustersPage: [
        {
            intro: `By default, users will arrive on the <b>${cluster}s</b> page.`,
        },
        {
            intro: `Documents of the same type are organized into ${cluster}s. Clustering documents makes it easier to view and extract data.`,
        },
        {
            element: '.clustersPage-clustersTable',
            intro: `This table allows users to view, search, and sort ${cluster} data within the selected project.`,
        },
        {
            element: '.clustersPage-clustersTableClusterButton',
            intro: `Unclustered pages will be displayed in the top row; users can select the {<b>Start Clustering</b>} (<b>Re-cluster</b> if already clustered before) button to group new pages. <br><br> <b>Note:</b> Should a clustering job be in process but is not complete yet, the button label will say <b>Pull Cluster Status</b>.`,
        },
        {
            element: '.clustersPage-clustersTable',
            intro: `Select a ${cluster} to continue.`,
        },
        {
            element: '.clustersPage-imageGalleryTab',
            intro: `The image gallery tab will be selected by default.`,
        },
        {
            element: '.clustersPage-imageGalleryAndDataElements',
            intro: `Once a ${cluster} is selected, users can view all the document thumbnails associated to the ${cluster} here.`,
        },
        {
            element: '.clustersPage-imageGalleryReassignButton',
            intro: `Should a document be in the wrong ${cluster}, a user can click this <b>REASSIGN</b> button to put a selected document into another (new or existing) ${cluster}.`,
        },
        {
            element: '.clustersPage-viewSelectedDocument',
            intro: `Selected documents from the image gallery are shown here.`,
        },
        {
            element: '.clustersPage-dataElementAttributionTab',
            intro: `Select the <b>Data Elements Attribution</b> tab to select data elements–values that are common across all documents within a ${cluster}. </br></br> Here users have the ability to search, add, and delete data elements from the ${cluster}.`,
        },
        {
            element: '.sidebar-assignElements',
            intro: `Unable to see data elements to add? Use this sidebar to navigate to <b>Assign Elements</b> and add data elements to the selected project!`,
        },
    ],
    assignElementsPage: [
        {
            intro: `This is the <b>Element Assignment</b> page where a user can view and edit a project's data elements.`,
        },
        {
            element: '.elementAssignmentPage-elementDropdownCard',
            intro: `Here, select data elements to attribute to the currently selected project.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-elementDropdownBox',
            intro: `Click this dropdown to view and select all data elements available to attribute to the currently selected project. A user can <ul><li>type or scroll to search for the desired data element(s).</li><li>select just one data element or multiple.</li></ul> <br> <b>Note:</b> Press the escape key or click out of the dropdown menu to close out of it.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-addElementButton',
            intro: `If a data element does not appear in the dropdown, click here to add a single, new data element (contact an administrator to add multiple at once). A window will appear where a user can add a required name, a required type, and an optional description. <br><br> <b>Note:</b> once created, data elements <u>CANNOT</u> be deleted.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-chipsSection',
            intro: `Selected data elements will appear here. Hover over a data element to reveal an <b>x</b>, which can be clicked to remove the data element from this section.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-stageButton',
            intro: `Click here to add them under <b>Assigned Elements</b>. Doing so will <b><u>NOT</u></b> add them to the project just yet.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-assignedElements',
            intro: `Data elements already assigned to the currently selected project or data elements just added from the element selection card (the window to the left) will appear here.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-assignButton',
            intro: `If the list of data elements above contains any elements that have not been added to the project yet, this button will be enabled (not gray). Click it to add the data elements shown above to the project. <ul><li>Only the new data elements will be added.</li><li>Elements already added to the project will be ignored.</li><li>Once new elements are added, this button will turn gray again.</li></ul>`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-assignedElements',
            intro: `Select a data element from this list to open a 3rd card (to the right) where a user can view and/or edit an element's details.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-viewDataElement',
            intro: `Once a data element is selected, view its name (<b>Title</b>), type (<b>Data Type</b>), and description (<b>Description</b>) here.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-editDataElementColumnsButton',
            intro: `If the data element selected is a table type, an <b>Edit Table</b> button will be available that, when clicked, allows the user to edit a data element's columns.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-editDataElementButton',
            intro: `If the user is an administrator, a pen icon will be available that, when clicked, allows the user to edit a data element's description.`,
            position: 'middle',
        },
        {
            element: '.elementAssignmentPage-finishEditingDataElement',
            intro: `When the data element's description is changed, the user can click the <b>Finish Editing</b> button.`,
            position: 'middle',
        },
        {
            element: '.sidebar-dataExceptions',
            intro: `To continue the tour, use this sidebar to select <b>Data Exceptions</b>.`,
        },
    ],
    selectPage: [
        {
            intro: `This is the Data Exceptions <b>Select</b> page.`,
        },
        {
            intro: `This page allows users to select specific ${cluster}s and documents to view in the <b>Entry</b> page.`,
            position: 'middle',
        },
        {
            element: '.selectPage-clusterSelect',
            intro: `Here, users can search, sort, view, and select ${cluster}s to find a specific document, which can then be viewed in the <b>Entry</b> page.`,
        },
        {
            element: '.selectPage-clusterSelectTabs',
            intro: `View documents based on <b>${cluster}</b> or based on <b>Claim</b> using these tabs.`,
        },
        {
            element: '.selectPage-clusterSelect',
            intro: `Select a ${cluster} to continue.`,
        },
        {
            element: '.selectPage-documentSelect',
            intro: `Once a ${cluster} is selected, users can see all the documents within the ${cluster} (or claim) selected.`,
        },
        {
            element: '.selectPage-documentSelect',
            intro: `Search and/or sort through documents before selecting one to view in the <b>Entry</b> page.`,
        },
        {
            element: '.selectPage-documentSelect',
            intro: `To continue, select a document and click the <b>Go to Data Entry</b> button that appears at the bottom.<ul><li><b>Note:</b> This button will only appear once <u>both</u> a claim and a document are selected.</li></ul>`,
        },
    ],
    dataEntryPage: [
        {
            intro: `This is the Data Exceptions <b>Entry</b> page where users can view the document selected.`,
        },
        {
            intro: `Users have the ability to resolve incomplete data, extract data, view data elements, and more.`,
        },
        {
            element: '.dataEntryPage-extractDataButton',
            intro: `Click the <b>Extract Data</b> button to autofill in values for this ${cluster} of documents.`,
        },
        {
            element: '.dataEntryPage-controlPanel',
            intro: `This document control panel allows a user to zoom, rotate, or transform a document for easier viewing.`,
        },
        {
            element: '.dataEntryPage-clusterDropDown',
            intro: `This dropdown shows the list of ${cluster}s from the <b>Select</b> page. Select one to view documents from other ${cluster}s in the project without returning to the <b>Select</b> page.`,
        },
        {
            element: '.dataEntryPage-pageNavigator',
            intro: `Navigate through documents within the selected ${cluster} using these arrow buttons.`,
        },
        {
            element: '.dataEntryPage-pageNavigator',
            intro: `Additionally, this toggle button will mark a document as <b>Complete</b> or <b>Incomplete</b>.`,
        },
        {
            element: '.dataEntryPage-pageNavigator',
            intro: `When data extracted from a document has been detected as potentially inacurrate (Incomplete), it will need to be manually verified and updated (Complete).`,
        },
        {
            element: '.dataEntryPage-additionalDocumentOptions',
            intro: `The blue button allows users to view all document data. In the window that pops up, users can click the dropdown list of documents and navigate directly to a document by selecting it.`,
        },
        {
            element: '.dataEntryPage-additionalDocumentOptions',
            intro: `View the document in a separate window by clicking the pop-out icon for easier viewing.`,
        },
        {
            element: '.dataEntryPage-additionalDocumentOptions',
            intro: `Lastly, the triple-dot icon button to change the view from grid view to form view.`,
        },
        {
            element: '.dataEntryPage-documentEditor',
            intro: `In the table below, users can view, edit, and complete document data.`,
        },
        {
            element: '.sidebar-status',
            intro: `Once data entry is complete, use this sidebar to navigate to the <b>Status</b> page to review and reconcile any potential data discrepancies.`,
        },
    ],
    validationPage: [
        {
            intro: `This is the Status Validation page.`,
        },
        {
            element: '.analysisPage-claimsTable',
            intro: `This is the <u>Claims</u> table, where users can view, search, and sort all the claim IDs associated with entities (person, company, etc.) within the current project.`,
            position: 'middle',
        },
        {
            element: '.analysisPage-exportButton',
            intro: `This export button gives users 3 options: <ul><li><b>Download .TSV:</b> Downloads a .TSV file of all data associated with the claims seen in the table.</li><li><b>Send ${projectMember}s:</b> Once every document associated to a particular claim is completed, that claim (or multiple other claims) can be sent to a business rules engine. The status of this can be seen in the third column of the table.</li><li><b>Export Project Table Data:</b> Should a claim contain table data, that table data will be downloaded as a .xlsx file.</li></ul>`,
            position: 'middle',
        },
        {
            element: '.analysisPage-claimDataElements',
            intro: `Use this table to view the data elements associated to a selected claim from the above table. Clicking on a data element will expand the row to show the value of the data element. <ul><li>If there should be any errors with a data element's value, users can edit and update values.</li><li>If a data element for a claim comes from multiple different documents, users can select the value that is 'the source of truth' by clicking the <b>Choose Value</b> button under the <b>Actions</b> column.</li></ul>`,
            position: 'middle',
        },
        {
            element: '.analysisPage-claimCurrentDocument',
            intro: `When selecting a value for a data element, the document where the data element and value comes from will be visible here.`,
            position: 'middle',
        },
    ],
    error: [
        {
            intro: `Invalid page detected, please navigate to a valid page and try again!`,
        },
    ],
};

