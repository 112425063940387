import MSelect from '@mui/material/Select';
import styled from 'styled-components';

const StyledSelect = styled(MSelect)`
  &.Mui-focused {
    fieldset {
      border: ${(props) =>
        `2px solid ${props.theme.palette.primary.main}  !important`};
    }
  }
`;

export default function Select(props) {
  return <StyledSelect {...props} />;
}
