import { useEffect, useRef } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Card from 'components/Card';
import MuiCardContent from 'components/CardContent';
import Grid from 'components/Grid';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TASK_STATUS } from 'services/GraphQL/Mutations';
import {
  LOAD_ITEM_STATUS_ENUMS,
  LOAD_PROJECT_TASKS,
} from 'services/GraphQL/Queries';
import { selectCurrentProjectId } from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setSnackbarWarn,
} from 'store/Redux/slices/snackbarsSlice';
import {
  changeTaskStatus,
  mountInitialTask,
  selectTasks,
} from 'store/Redux/slices/taskSlice';
import styled from 'styled-components';
import { TaskListCard, UserProfileCard } from './cards';

//#region Styled Components

const CardContent = styled(MuiCardContent)`
  padding: 0;
`;

const SCardHeader = styled(CardContent)`
  .MuiCardHeader-title {
    @media screen and (max-width: 1360px) {
      font-size: 1.3em;
    }
  }
`;
//#endregion Styled Components

export default function Summary() {
  const dispatch = useDispatch();
  const taskList = useSelector(selectTasks);
  const currentProjectId = useSelector(selectCurrentProjectId);

  const currentUserTasks = useRef([]);
  currentUserTasks.current = taskList?.tasks;

  //#region graphQL
  /* fetches current project tasks */
  const [fetchTasks, { data: gqlTaskData }] = useLazyQuery(LOAD_PROJECT_TASKS, {
    onCompleted: () => onFetchTasksSuccess(),
    onError: () =>
      dispatch(
        setSnackbarError(
          `Error fetching tasks; please try loading the page again`
        )
      ),
    fetchPolicy: 'no-cache',
  });

  function onFetchTasksSuccess() {
    if (gqlTaskData?.projectById) {
      const taskList = gqlTaskData?.projectById?.taskList;
      dispatch(mountInitialTask(taskList));
    }
  }

  /* fetches task status enums */
  const { data: itemStatusEnums } = useQuery(LOAD_ITEM_STATUS_ENUMS, {
    onError: () =>
      dispatch(
        setSnackbarWarn(
          `Some data did not load properly, please reload the page`
        )
      ),
  });

  /* task update mutation */
  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
    update(cache, { data: updatedTaskStatus }) {
      dispatch(
        changeTaskStatus({
          taskId: updatedTaskStatus.updateTaskItemStatus.id,
          taskStatus: updatedTaskStatus.updateTaskItemStatus.status,
        })
      );
      dispatch(setSnackbarSuccess(`Task status updated`));
    },
    onError: () =>
      dispatch(setSnackbarError(`Update was unsuccessful, please try again`)),
  });
  //#endregion graphQL

  useEffect(() => {
    if (currentProjectId) {
      fetchTasks({ variables: { id: currentProjectId } });
    }
  }, [currentProjectId]);

  const dashboardHeader = (
    <SCardHeader
      title={<div>User Dashboard</div>}
      subheader={`${date}, ${formatAMPM(new Date())}`}
    />
  );

  const dashboardCard = <Card>{dashboardHeader}</Card>;

  return (
    <ProjectSelectedTemplate currentProjectId={currentProjectId}>
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserProfileCard />
            </Grid>
            <Grid item xs={12}>
              {dashboardCard}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} lg={4} md={12} xs={12}>
          <TaskListCard
            currentUserTasks={currentUserTasks}
            itemStatusEnums={itemStatusEnums}
            updateTaskStatus={updateTaskStatus}
          />
        </Grid>
      </Grid>
    </ProjectSelectedTemplate>
  );
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const today = new Date();

const date = `${
  months[today.getMonth()]
} ${today.getDate()}, ${today.getFullYear()}`;

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  var hour = ampm === 'pm' ? hours - 12 : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hour + ':' + minutes + ' ' + ampm;
  return strTime;
}
