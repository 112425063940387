import Button from 'components/Button';
import Grid from 'components/Grid';
import PropTypes from 'prop-types';

export default function ManualFetchMoreButton(props) {
  const { hasNextPage, fetchMore } = props;
  return (
    <Grid item xs={12}>
      <Button
        id='fetch-more-button'
        disabled={!hasNextPage}
        variant='contained'
        primary
        onClick={() => fetchMore()}
      >
        Load more rows
      </Button>
    </Grid>
  );
}

ManualFetchMoreButton.propTypes = {
  hasNextPage: PropTypes.bool,
  fetchMore: PropTypes.func,
};
