import theme from 'theme.js';

function SvgRectangle1(props) {
  return (
    <svg
      // width="1em"
      // height="1em"
      viewBox='0 0 1280 529'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1280 0H0v528.512c288-96.946 336 50.349 720.889-28.146 195.62-39.895 297.781-77.244 559.111-37.215V0z'
        fill='url(#Rectangle1_svg__paint0_linear)'
      />
      <defs>
        <linearGradient
          id='Rectangle1_svg__paint0_linear'
          x1={1280}
          y1={-39.404}
          x2={411.477}
          y2={1044.59}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.lightest} />
          <stop offset={1} stopColor={theme.palette.secondary.lightest} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgRectangle1;
