import { useInView } from 'react-intersection-observer';

export default function VirtualScrollChild(props) {
  const { children } = props;
  const [ref, inView] = useInView();

  const style = {
    height: '100%',
    width: '100%',
    minHeight: '35px',
    overflow: 'hidden',
    borderBottom: '1px solid rgba(221, 221, 221, 1)',
  };

  return (
    <div style={style} ref={ref}>
      {inView ? children : null}
    </div>
  );
}
