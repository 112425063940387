import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import Grid from 'components/Grid';
import LinearProgress from 'components/LinearProgress';
import ListItem from 'components/ListItem';
import Typography from 'components/Typography';
import formatBytes from 'helpers/formatBytes';
import ErrorIcon from 'icons/Mui/Error';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//#region Styled Components
const LinearProgressBar = styled(LinearProgress)`
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.background.lighter};
  .MuiLinearProgress-bar {
    background-color: rgba(26, 144, 255, 1)};
    border-radius: 5px;
  }
`;

const SuccessLinearProgressBar = styled(LinearProgress)`
height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.background.lighter};
  .MuiLinearProgress-bar {
    background-color: green};
    border-radius: 5px;
  }
`;
//#endregion Styled Components

export default function FileUploadRow(props) {
  const { fileName, size, isUploading, isSuccessful } = props;
  return (
    <ListItem key={fileName}>
      <Grid container>
        <Grid item xs={1}>
          <InsertDriveFileIcon />
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid container justifyContent='space-between'>
              <Grid item style={isSuccessful ? { color: 'green' } : null}>
                {fileName}
                {!isUploading && isSuccessful ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ErrorIcon />
                )}
              </Grid>
              <Grid>{formatBytes(size)}</Grid>
            </Grid>
            <Grid item xs={12}>
              {isUploading ? (
                <LinearProgressBar />
              ) : (
                <SuccessLinearProgressBar
                  variant='determinate'
                  value={isSuccessful ? 100 : 0}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export function FileExistsRow(props) {
  const { file, retry, hasRetry } = props;
  return (
    <ListItem>
      <Grid container>
        <Grid item xs={1}>
          <InsertDriveFileIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography>{file.path}</Typography>
          <Typography style={{ fontWeight: 600 }}>{file.error}</Typography>
        </Grid>
        <Grid item xs={1}>
          {hasRetry ? (
            <IconButton value={file} onClick={() => retry(file)} size='large'>
              <ReplayIcon />
            </IconButton>
          ) : (
            false
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
}

export function FileExistsRowWithError(props) {
  const { file, retry, hasRetry, error } = props;
  file.error = error[0].message;
  return <FileExistsRow file={file} retry={retry} hasRetry={hasRetry} />;
}

FileUploadRow.propTypes = {
  fileName: PropTypes.string,
  size: PropTypes.number,
  isUploading: PropTypes.bool,
  isSuccessful: PropTypes.bool,
};

FileExistsRowWithError.propTypes = {
  file: PropTypes.object,
  retry: PropTypes.func,
  hasRetry: PropTypes.bool,
  error: PropTypes.array,
};

FileExistsRow.propTypes = {
  file: PropTypes.object,
  retry: PropTypes.func,
  hasRetry: PropTypes.bool,
};
