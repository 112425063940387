import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from 'components/Autocomplete';
import CardContent from 'components/CardContent';
import Checkbox from 'components/Checkbox';
import { DeletableChip } from 'components/Chip';
import Grid from 'components/Grid';
import TextField from 'components/TextField';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from 'icons/Mui/Close';
import { AddElementModal } from 'pages/ElementAssignment/modals';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_GLOBAL_DATA_ELEMENTS } from 'services/GraphQL/Mutations';
import {
  loadDataElements,
  selectCurrentOrganizationId,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled from 'styled-components';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import Button from 'components/Button';
import { ArrowRight } from '@mui/icons-material';

//#region Styled Components
const DropDown = styled(Autocomplete)`
  font-style: 'Roboto';
  color: black;
  font-size: 15px;
  padding: 5px;
`;

const ChipsContainer = styled(CardContent)`
  max-height: 60vh;
  padding: 0;
  overflow: auto;
  @media screen and (max-height: 1280px) {
    max-height: 25vh;
  }
`;
//#endregion Styled Components

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function DropdownMenu(props) {
  const { isSelected, option, p } = props;

  return (
    <li key={p.id} {...p}>
      <Checkbox
        size='small'
        icon={icon}
        checkedIcon={checkedIcon}
        checked={isSelected}
      />
      {option}
    </li>
  );
}

function ChipsSection(props) {
  const { handleChipDeletion, selectedOptions } = props;

  return (
    <Grid item lg={12} xs={12} className='elementAssignmentPage-chipsSection'>
      {selectedOptions.map((s) => {
        return (
          <DeletableChip
            key={s.name}
            elementName={s.name}
            isDeletable
            onDeleteChip={handleChipDeletion}
          />
        );
      })}
    </Grid>
  );
}

function ElementAddButton(props) {
  const { onClickAddIcon } = props;

  return (
    <Button
      title='Add New Element'
      primary
      sx={{ width: '50%' }}
      variant='contained'
      onClick={onClickAddIcon}
      className='elementAssignmentPage-addElementButton'
    >
      <AddIcon />
    </Button>
  );
}

export default function ElementDropdown(props) {
  const {
    dataTypeList,
    options = [],
    globalElements,
    selectedOptions,
    handleSelectedOptions,
    currentProjectElements,
    isDetailsWindowOpen,
    clusterElementDialogOpen,
    handleAssignDataElements,
  } = props;
  const dispatch = useDispatch();
  const currentOrgId = useSelector(selectCurrentOrganizationId);
  const emailName = useSelector(selectCurrentUserEmail);

  const [open, setOpen] = useState(false);
  const [unassignedElementsList, setUnassignedElementsList] = useState([]);

  const [addGlobalElement] = useMutation(ADD_GLOBAL_DATA_ELEMENTS, {
    onError: (error) => dispatch(setSnackbarError(`Error: ${error.message}`)),
    onCompleted: (data) => {
      if (data?.addDataElement) {
        data.addDataElement.forEach((element) => {
          const options = [...selectedOptions, element];
          dispatch(
            loadDataElements({
              ...globalElements,
              nodes: [...globalElements.nodes, element],
            })
          );
          handleSelectedOptions(options);
        });
        dispatch(setSnackbarSuccess(`Element added to list!`));
      }
    },
  });

  useEffect(() => {
    const uniqueOptions = options.filter(
      ({ id: id1 }) =>
        !currentProjectElements?.some(({ id: id2 }) => id2 === id1)
    );
    setUnassignedElementsList(uniqueOptions);
  }, [currentProjectElements, options]);

  const handleModalAdd = (newElementObject) => {
    Object.assign(newElementObject, {
      orgId: currentOrgId,
      username: emailName,
    });
    if (newElementObject) {
      addGlobalElement({ variables: { inputs: newElementObject } });
    }
  };

  const handleChipDeletion = (chipsValues) =>
    handleSelectedOptions(
      selectedOptions.filter((s) => s.name !== chipsValues)
    );

  const onItemSelect = (e, values) => {
    handleSelectedOptions(values);
  };
  const handleModalExit = () => setOpen(false);
  const onClickAddIcon = () => setOpen(true);

  return (
    <>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        sx={{ padding: '1vh' }}
      >
        <Grid item lg={clusterElementDialogOpen ? 12 : 8} md={12} xs={12}>
          <div className='elementAssignmentPage-elementDropdownBox'>
            <DropDown
              onChange={onItemSelect}
              value={selectedOptions}
              disabled={isDetailsWindowOpen}
              multiple
              autoComplete
              renderTags={() => {}}
              disableCloseOnSelect
              clearIcon={<CloseIcon fontSize='small' />}
              clearText={'Remove all items'}
              options={unassignedElementsList}
              getOptionLabel={(options) => options.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Data Elements List'
                  variant='outlined'
                  margin='dense'
                />
              )}
              renderOption={(props, option, { selected }) => {
                return (
                  <DropdownMenu
                    p={props}
                    option={option.name}
                    isSelected={selected}
                  />
                );
              }}
            />
          </div>
        </Grid>
        {!clusterElementDialogOpen && (
          <Grid item lg={4} md={12} xs={12} textAlign='center'>
            <ElementAddButton onClickAddIcon={onClickAddIcon} />
          </Grid>
        )}

        <Grid item xs={12}>
          <ChipsContainer>
            <ChipsSection
              selectedOptions={selectedOptions}
              handleChipDeletion={handleChipDeletion}
            />
          </ChipsContainer>
        </Grid>
      </Grid>
      {selectedOptions?.length ? (
        <Grid container sx={{ padding: '1vh' }}>
          <Grid item xs={12}>
            <Button
              fullWidth
              primary
              variant='contained'
              onClick={handleAssignDataElements}
            >
              Assign To {clusterElementDialogOpen ? 'Cluster' : 'Project'}
              <ArrowRight />
            </Button>
          </Grid>
        </Grid>
      ) : null}

      <AddElementModal
        isOpen={open}
        existingElementNames={options.map((o) => o.name)}
        dataTypeList={dataTypeList}
        onModalExitClick={handleModalExit}
        onAddElement={handleModalAdd}
      />
    </>
  );
}

DropdownMenu.propTypes = {
  isSelected: PropTypes.bool,
  option: PropTypes.any,
  p: PropTypes.any,
};

ChipsSection.propTypes = {
  handleChipDeletion: PropTypes.func,
  selectedOptions: PropTypes.any,
};

ElementAddButton.propTypes = {
  onClickAddIcon: PropTypes.func,
};

ElementDropdown.propTypes = {
  handleSelectedOptions: PropTypes.func,
  currentProjectElements: PropTypes.array,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  isDetailsWindowOpen: PropTypes.bool,
  dataTypeList: PropTypes.array,
};
