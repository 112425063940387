/**
 * @param {string} content This content that will be written in the body of the file
 * @param {string} fileName Name of the File
 * @param {string} type What the file type is e.g: 'text/csv', 'text/txt', ...
 *
 * NOTE - If file is to big think about using iframe instead
 */
export function DownloadFile(content, fileName, type) {
  content = content.replace(/\n/g, '');
  const url = window.URL.createObjectURL(
    new File([content], fileName, { type: type })
  );
  const link = document.createElement('a'); // create attribute tag
  link.href = url; // set to document content
  link.setAttribute('download', fileName); // Adds download attribute to tag with filename
  document.body.appendChild(link);
  link.click(); // force focus
  link.parentNode.removeChild(link); // removes from page
}
