import FormControlLabel from 'components/FormControlLabel';
import Grid from 'components/Grid';
import Switch from 'components/Switch';
import Typography from 'components/Typography';
import PropTypes from 'prop-types';

export default function InfiniteScrollToggle(props) {
  const { toggleInfiniteScroll, setToggleInfiniteScroll } = props;
  return (
    <Grid item xs={12}>
      <FormControlLabel
        checked={toggleInfiniteScroll}
        control={
          <Switch
            checked={toggleInfiniteScroll}
            onChange={() => setToggleInfiniteScroll(!toggleInfiniteScroll)}
          />
        }
        label={
          <Typography>
            Infinite Scroll:
            <b>{toggleInfiniteScroll ? ' Active' : ' Inactive'}</b>
          </Typography>
        }
      />
    </Grid>
  );
}

InfiniteScrollToggle.propTypes = {
  toggleInfiniteScroll: PropTypes.bool,
  setToggleInfiniteScroll: PropTypes.func,
};
