//NOTE For more endpoints, checkout the docs: https://docs.microsoft.com/en-us/graph/api/overview?view=graph-rest-1.0

import { msalInstance } from './index';

export const graphConfig = {
  me: 'https://graph.microsoft.com/v1.0/me',
  myProfile: 'https://graph.microsoft.com/v1.0/me',
  usersList: 'https://graph.microsoft.com/v1.0/users',
  groups: 'https://graph.microsoft.com/v1.0/groups',
  scopes: ['User.Read', 'GroupMember.Read.All','offline_access'],
  //For logged in user to be able to add/remove group members
  // scopes: ['User.Read', 'GroupMember.ReadWrite.All'],
};

/* get access token for the currently signed in user */
export const getToken = async (scopes) => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: scopes,
  });

  return response.accessToken;
};

/* get current user info */
async function userMsGraphRequest(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.myProfile, options)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}

/* get signed-in user groups */
async function userMemberOf() {
  const accessToken = await getToken(graphConfig.scopes);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  headers.append('ConsistencyLevel', 'eventual');

  const options = {
    method: 'GET',
    headers: headers,
    securityEnabledOnly: true,
  };

  const startsWith = 'SG-US-SmarText_';

  //NOTE this is the endpoint that returns the groups the user is a member of; we always want to include $count in the query string if using a $filter query in the field.
  return fetch(
    `https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName, '${startsWith}')&$select=displayName,id`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.error(error));
}

async function getGroupMembers(groupId) {
  const accessToken = await getToken(graphConfig.scopes);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  headers.append('ConsistencyLevel', 'eventual');

  const options = {
    method: 'GET',
    headers: headers,
    securityEnabledOnly: true,
  };

  return fetch(
    `https://graph.microsoft.com/v1.0/groups/${groupId}/members`,
    options
  )
    .then((res) => res.json())
    .catch((error) => console.log(error));
}

/* in case of group overage */
//NOTE overage is when number of groups returned for user exceeds 99; in cases of overage, graph api returns a property field '@odata.nextLink' which contains the next page of groups to be fetched.
async function getNextPage(nextPage) {
  const accessToken = await getToken(graphConfig.scopes);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('ConsistencyLevel', 'eventual');

  const options = {
    method: 'GET',
    headers: headers,
  };

  //NOTE 'nextPage' link MAY have to be refined so that it's following the same filter as userMemberOf()
  return fetch(nextPage, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/* get user profile photo */
async function userPhotoMsGraphRequest(accessToken, userId) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
    'Content-Type': 'image/jpeg',
  };

  return fetch(
    `https://graph.microsoft.com/beta/users/${userId}/photo/$value`,
    options
  )
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(data);
      const userUrl = { blob: blobUrl, user: userId };
      return userUrl;
    })
    .catch((error) => console.error(error));
}

/* get users list in the organization */
async function usersListMsGraphRequest(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.usersList, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

async function addGroupMember(groupId, userId) {
  const accessToken = await getToken(graphConfig.scopes);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);

  const options = {
    method: 'POST',
    headers: headers,
    body: {
      '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${userId}`,
    },
  };

  return fetch(`${graphConfig.groups}/${groupId}/members/$ref`, options);
}

export {
  userMsGraphRequest,
  userPhotoMsGraphRequest,
  usersListMsGraphRequest,
  userMemberOf,
  getNextPage,
  getGroupMembers,
  addGroupMember,
};

//NOTE for possible future use:
/* tasks */
// endpoints
// https://docs.microsoft.com/en-us/graph/api/planneruser-list-tasks?view=graph-rest-1.0&tabs=csharp
// https://docs.microsoft.com/en-us/graph/api/todotask-update?view=graph-rest-1.0&tabs=http
// plannerTask resource type
// https://docs.microsoft.com/en-us/graph/api/resources/plannertask?view=graph-rest-1.0
