import { toRelativeDate } from 'helpers/DateComparer';
import { dateTimeFormatter } from 'helpers/dateTimeFormatter';
import { useEffect, useState } from 'react';

const ProjectMemberAssignedOnFormatter = (props) => {
  const { assignedOn } = props;
  const [finalDateString, setFinalDateString] = useState();

  useEffect(() => {
    let combinedString = assignedOn;
    if (combinedString) {
      let formattedDate = dateTimeFormatter(assignedOn, true);
      let dateTimeObject = new Date(formattedDate);
      let timeLeft = toRelativeDate(dateTimeObject) || null;

      combinedString = `${formattedDate} (${timeLeft})`;
    }

    setFinalDateString(combinedString);
  }, [assignedOn]);

  return <span>{finalDateString}</span>;
};

export default ProjectMemberAssignedOnFormatter;
