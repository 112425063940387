import { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormControlLabel from 'components/FormControlLabel';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import Switch from 'components/Switch';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components';
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import {
  selectProjectMember,
  selectOnClassificationGroupsTab,
} from 'store/Redux/slices/dataEntrySlice';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { config } from 'config';
import Tooltip from 'components/Tooltip';
import Dialog from 'components/Dialog';
import List from 'components/List';
import ListItem from 'components/ListItem';
import ListItemText from 'components/ListItemText';

//#region Styled Components
const BackArrow = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.main} !important;
  width: 28px;
  height: 28px;
  border-radius: 30px;
  float: right;
  color: white;
  position: relative;
  &:hover,
  &:focus {
    background-color: ${(props) =>
      rgba(props.theme.palette.primary.lightest, 0.3)};
  }
  &:disabled {
    color: gray !important;
  }
`;

const ForwardArrow = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.main} !important;
  width: 28px;
  height: 28px;
  border-radius: 30px;
  color: white;
  position: relative;
  &:hover,
  &:focus {
    background-color: ${(props) =>
      rgba(props.theme.palette.primary.lightest, 0.3)};
  }
  &:disabled {
    color: gray !important;
  }
`;

const SFormControlLabel = styled(FormControlLabel)`
  color: ${(props) =>
    props.checked
      ? `${props.theme.palette.primary.main}`
      : 'rgba(255, 215, 0, 1)'};
  .Mui-disabled {
    color: grey !important;
  }
  display: contents;
  margin-bottom: 0 !important;
  float: ${(props) => (props.useForm ? 'right' : null)};
  .MuiSwitch-track {
    background-color: ${(props) =>
      props.checked
        ? `${props.theme.palette.primary.main}`
        : props.disabled
        ? `grey`
        : 'gold'} !important;
  }
  .MuiSwitch-thumb {
    color: ${(props) =>
      props.disabled
        ? `grey`
        : props.checked
        ? `${props.theme.palette.primary.main}`
        : 'gold'} !important;
  }
`;

const DetailsButton = styled(Button)`
  max-height: 100%;
  min-width: 25%;
`;

const ButtonText = styled(Typography)`
  text-transform: none;
`;

const FieldContainer = styled(Grid)`
  max-height: 50dvh;
  overflow: auto;
`;

const StickyGrid = styled(Grid)`
  position: sticky;
  background: white;
  ${(props) => props.position}: 0;
  z-index: 1;
`;
//#endregion Styled Components

export default function PageNavigator(props) {
  const {
    decrementIndex = () => null,
    useForm = false,
    handleDocumentStatusUpdate = () => null,
    incrementIndex = () => null,
    docIndex = 0,
    isToggleManual = false,
    postText = 0,
    preText = 'Page',
    hasShowToggle = false,
    currentDocument = {},
    projectMembers = [],
    pmIndex = 0,
    isReadOnly = false,
    projectMemberIdentificationHeader = '',
    handleOpen = () => null,
    currentMember = {},
    isEveryInputFieldComplete = {},
    isEveryInputFieldReviewed = true,
    organizationName = '',
    projectMemberStatuses = {},
  } = props;

  const onClassificationGroupsTab = useSelector(
    selectOnClassificationGroupsTab
  );
  const currentprojectMember = useSelector(selectProjectMember);
  const documentStatusFreezeForPMs = [
    projectMemberStatuses.COMPLETE,
    projectMemberStatuses.REJECTED,
    projectMemberStatuses.OFF_RAMPED,
  ];

  const [checked, setChecked] = useState(currentDocument?.isComplete);
  const [pmDetailsButtonText, setPmDetailsButtonText] = useState('');
  const [confirmDocumentStatusDialog, toggleConfirmDocumentStatusDialog] =
    useState(false);

  const handleChange = (status) => {
    handleDocumentStatusUpdate(status).then((newStatus) => {
      setChecked(newStatus);
      isToggleManual.current = true;
    });
    toggleConfirmDocumentStatusDialog(false);
  };

  const checkIncompleteFieldsForVA = (e) => {
    if (
      organizationName === config.VAORGANIZATIONNAME &&
      isEveryInputFieldReviewed &&
      !isEveryInputFieldComplete.complete &&
      isEveryInputFieldComplete.incompleteFields.length !== 0 &&
      e.target.checked
    ) {
      toggleConfirmDocumentStatusDialog(true);
    } else {
      handleChange(e.target.checked);
    }
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (!onClassificationGroupsTab) {
        if (e.shiftKey && e.key === 'ArrowLeft' && pmIndex !== 0) {
          decrementIndex('projectMember');
        } else if (
          e.shiftKey &&
          e.key === 'ArrowRight' &&
          pmIndex !== projectMembers?.length - 1
        ) {
          incrementIndex('projectMember');
        }
      }
      if (e.ctrlKey && e.key === 'ArrowLeft' && docIndex !== 0) {
        decrementIndex('doc');
      } else if (
        e.ctrlKey &&
        e.key === 'ArrowRight' &&
        docIndex !== postText - 1
      ) {
        incrementIndex('doc');
      }
    };
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [props]);

  useEffect(() => {
    if (currentDocument) {
      setChecked(currentDocument.isComplete);
    }
  }, [currentDocument]);

  useEffect(() => {
    if (currentMember) {
      const pmDetailsButtonText = currentMember.originalId
        ? `Project Member Details # ${currentMember.originalId}`
        : `Project Member Details: N/A`;
      setPmDetailsButtonText(pmDetailsButtonText);
    }
  }, [currentMember]);

  const documentNavigator = (
    <Grid container alignItems='center'>
      <Grid item sm={3} md={1}>
        <BackArrow
          onClick={() => decrementIndex('doc')}
          disabled={docIndex === 0}
        >
          <ArrowBackIosIcon
            fontSize='medium'
            style={{
              position: 'relative',
              left: '4px',
            }}
          />
        </BackArrow>
      </Grid>
      <Grid
        item
        sm={6}
        md={10}
        style={{
          textAlign: 'center',
          lineHeight: '45px',
        }}
      >
        <Typography>{`${preText} ${docIndex + 1} of  ${postText}`}</Typography>
      </Grid>
      <Grid item sm={3} md={1}>
        <ForwardArrow
          disabled={docIndex === postText - 1}
          onClick={() => incrementIndex('doc')}
        >
          <ArrowForwardIosIcon
            fontSize='medium'
            style={{
              position: 'relative',
              left: '2px',
            }}
          />
        </ForwardArrow>
      </Grid>
    </Grid>
  );

  const projectMembersNavigator = (
    <Grid container alignItems='center'>
      <Grid item sm={3} md={1}>
        <BackArrow
          onClick={() => decrementIndex('projectMember')}
          disabled={pmIndex === 0}
        >
          <KeyboardDoubleArrowLeft
            fontSize='medium'
            style={{
              position: 'relative',
            }}
          />
        </BackArrow>
      </Grid>
      <Grid
        item
        sm={6}
        md={10}
        style={{
          textAlign: 'center',
          lineHeight: '45px',
        }}
      >
        <Typography noWrap title={currentprojectMember?.originalId}>
          {`${projectMemberIdentificationHeader} - ${currentprojectMember?.originalId}`}
        </Typography>
      </Grid>
      <Grid item sm={3} md={1}>
        <ForwardArrow
          onClick={() => incrementIndex('projectMember')}
          disabled={pmIndex === projectMembers?.length - 1}
        >
          <KeyboardDoubleArrowRight
            fontSize='medium'
            style={{
              position: 'relative',
              left: '2px',
            }}
          />
        </ForwardArrow>
      </Grid>
    </Grid>
  );

  const toggleLabel = (
    <Typography variant='caption'>
      {checked ? 'Complete' : 'Incomplete'}
    </Typography>
  );

  const toggleTooltip = () => {
    return organizationName === config.VAORGANIZATIONNAME &&
      !isEveryInputFieldReviewed &&
      !checked
      ? 'You must complete and review all data elements'
      : '';
  };

  const documentStatusChangeDialog = (
    <Dialog
      open={confirmDocumentStatusDialog}
      title='Document Status Change'
      submitLabel='Complete'
      cancelLabel='Cancel'
      handleSubmit={() => handleChange(!checked)}
      handleCancel={() =>
        toggleConfirmDocumentStatusDialog(!confirmDocumentStatusDialog)
      }
      hasActions
    >
      <FieldContainer container spacing={1}>
        <StickyGrid position={'top'} item xs={12}>
          <Typography>There are still incomplete fields:</Typography>
        </StickyGrid>
        <Grid item xs={12}>
          <List>
            {isEveryInputFieldComplete.incompleteFields?.map((field) => (
              <ListItem key={field.id}>
                <ListItemText
                  primary={field.name}
                  secondary={`Type: ${field.type}`}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <StickyGrid position={'bottom'} item xs={12}>
          Complete document anyway?
        </StickyGrid>
      </FieldContainer>
    </Dialog>
  );

  return (
    <>
      <Grid container textAlign='center' alignItems='center' spacing={2}>
        <Grid item xs={useForm ? 8 : 6}>
          {!onClassificationGroupsTab && projectMembersNavigator}
          {documentNavigator}
        </Grid>
        {hasShowToggle ? (
          <Grid item xs checked={checked}>
            <Tooltip title={toggleTooltip()} placement='top'>
              <span>
                <SFormControlLabel
                  disabled={
                    isReadOnly ||
                    (organizationName === config.VAORGANIZATIONNAME &&
                      ((!isEveryInputFieldReviewed && !checked) ||
                        documentStatusFreezeForPMs?.includes(
                          currentMember?.status
                        )))
                  }
                  control={
                    <Switch
                      checked={checked}
                      onChange={checkIncompleteFieldsForVA}
                      name='complete'
                    />
                  }
                  label={toggleLabel}
                  checked={checked}
                  useForm={useForm}
                />
              </span>
            </Tooltip>
          </Grid>
        ) : null}
        <Grid item xs={useForm ? 12 : 4}>
          <DetailsButton
            fullWidth
            primary
            onClick={handleOpen}
            variant='contained'
            title={currentMember?.originalId || 'N/A'}
          >
            <ButtonText variant='button' noWrap>
              {pmDetailsButtonText}
            </ButtonText>
          </DetailsButton>
        </Grid>
      </Grid>
      {documentStatusChangeDialog}
    </>
  );
}

PageNavigator.propTypes = {
  decrementIndex: PropTypes.func,
  useForm: PropTypes.bool,
  handleDocumentStatusUpdate: PropTypes.func,
  hasShowToggle: PropTypes.bool,
  incrementIndex: PropTypes.func,
  docIndex: PropTypes.number,
  isReadOnly: PropTypes.bool,
  isToggleManual: PropTypes.object,
  postText: PropTypes.number,
  preText: PropTypes.string,
  projectMemberIdentificationHeader: PropTypes.string,
  currentDocument: PropTypes.object,
  projectMembers: PropTypes.array,
  pmIndex: PropTypes.number,
  handleOpen: PropTypes.func,
  currentMember: PropTypes.object,
  isEveryInputFieldComplete: PropTypes.object,
  isEveryInputFieldReviewed: PropTypes.bool,
  organizationName: PropTypes.string,
  confirm: PropTypes.func,
  projectMemberStatuses: PropTypes.object,
};
