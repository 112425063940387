const sortTableData = (array, { sortBy, direction }) => {
  let nulls = array.filter((x) => x[sortBy] === null);
  let sortedArray = array
    .filter((x) => x[sortBy] !== null)
    .sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return direction === 'ascending' ? -1 : 1;
      if (a[sortBy] > b[sortBy]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  if (direction === 'ascending') return nulls.concat(sortedArray);
  return sortedArray.concat(nulls);
};

export default sortTableData;
