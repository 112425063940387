import { Cancel } from '@mui/icons-material';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setCurrentClusterFilterText,
  setCurrentFilteredClusterData,
} from 'store/Redux/slices/dataEntrySlice';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SearchBar = styled.div`
  width: 230px;
  height: 30px;
  display: flex;
  float: right;

  & input {
    width: 100%;
    &:focus-visible {
      outline: none;
      border: none;
    }
    &:focus {
      border: ${(props) => `2px solid ${props.theme.palette.primary.main}`};
    }
  }
`;

const ClearButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  height: 30px;
`;

export const TableSearch = (props) => {
  const {
    data = {},
    dataMemo = {},
    currentClusterSearchFilter,
    hasPagination,
    isCustomSearch,
    isSecondTable,
    firstSearchValue,
    secondSearchValue,
    setFirstSearchValue,
    setSecondSearchValue,
    setGlobalFilter,
    refetch,
  } = props;

  let timer;

  const dispatch = useDispatch();
  const [queryValue, setQueryValue] = useState('');
  const hasSearchBeenRendered = useRef(false);

  const handleInstantSearch = (e) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setQueryValue(e.target.value);
    }, 500);
  };

  const clearCustomSearch = () => {
    setGlobalFilter(null);
    isSecondTable ? setSecondSearchValue('') : setFirstSearchValue('');
    dispatch(setCurrentClusterFilterText(''));
    dispatch(setCurrentFilteredClusterData(null));
  };

  const globalSearch = (searchValue) => {
    if (searchValue && dataMemo !== null) {
      let filteredData = data?.filter((c) => {
        if (c.status) {
          return (
            c.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
            c.assignedTo?.toLowerCase().includes(searchValue.toLowerCase()) ||
            c.originalId?.toString().includes(searchValue.toString()) ||
            c.documents?.length?.toString() === searchValue ||
            c.documents?.filter((x) => x?.isComplete)?.length?.toString() ===
              searchValue
          );
        } else {
          return (
            c.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            c.totalDataElements?.toString() === searchValue ||
            c.totalDocuments?.toString() === searchValue ||
            c.totalCompleteDocuments?.toString() === searchValue
          );
        }
      });
      dispatch(setCurrentFilteredClusterData(filteredData));
    }
    dispatch(setCurrentClusterFilterText(searchValue));
  };

  const handleGlobalFilter = (e) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  useEffect(() => {
    if (isCustomSearch) {
      isSecondTable
        ? setSecondSearchValue(currentClusterSearchFilter)
        : setFirstSearchValue(currentClusterSearchFilter);
    }
  }, [currentClusterSearchFilter, isCustomSearch]);

  useEffect(() => {
    if (hasSearchBeenRendered.current) {
      refetch(queryValue);
      let scrollArea = document.getElementById(
        `tableScroll-${data[0]?.__typename}`
      );
      scrollArea?.scrollTo(0, 0);
    } else {
      hasSearchBeenRendered.current = true;
    }
  }, [queryValue]);

  const ClassicSearchBar = (
    <Grid item xs={12}>
      <SearchBar>
        <input
          placeholder='Search'
          value={isSecondTable ? secondSearchValue : firstSearchValue}
          onChange={(e) => {
            isCustomSearch
              ? globalSearch(e.target.value)
              : handleGlobalFilter(e);
            isSecondTable
              ? setSecondSearchValue(e.target.value)
              : setFirstSearchValue(e.target.value);
          }}
        />
        {(isSecondTable ? secondSearchValue : firstSearchValue) && (
          <ClearButton
            icon={<Cancel />}
            onClick={() => {
              clearCustomSearch();
            }}
          />
        )}
      </SearchBar>
    </Grid>
  );

  const InstantSearchBar = (
    <Grid item xs={12}>
      <TextField
        type='search'
        size='small'
        placeholder='Search'
        onChange={(e) => handleInstantSearch(e)}
      />
    </Grid>
  );
  return <>{hasPagination ? ClassicSearchBar : InstantSearchBar}</>;
};

TableSearch.propTypes = {
  data: PropTypes.any,
  dataMemo: PropTypes.any,
  currentClusterSearchFilter: PropTypes.any,
  hasPagination: PropTypes.bool,
  isCustomSearch: PropTypes.bool,
  isSecondTable: PropTypes.bool,
  firstSearchValue: PropTypes.string,
  secondSearchValue: PropTypes.string,
  setFirstSearchValue: PropTypes.func,
  setSecondSearchValue: PropTypes.func,
  setGlobalFilter: PropTypes.func,
  refetch: PropTypes.func,
};
