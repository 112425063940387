import { Tabs } from '@mui/material';
import Box from 'components/Box';
import Tab from 'components/Tab';
import Typography from 'components/Typography';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const STabs = styled(Tabs)`
  .Mui-selected {
    color: ${(props) => props.theme.palette.primary.main};
  }

  .MuiTabs-indicator {
    background-color: ${(props) =>
      props.primary
        ? props.theme.palette.primary.main
        : props.theme.palette.secondary.main};
  }

  .Mui-selected {
    color: ${(props) =>
      props.primary
        ? props.theme.palette.primary.main
        : props.theme.palette.secondary.main};
  }
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const DataEntrySelectTabs = (props) => {
  const {
    tabValue = 0,
    handleTabChange = () => null,
    setClearRowsSelected = () => null,
    availableTabs = [],
    loading = false,
  } = props;

  return (
    <div className='selectPage-clusterSelectTabs'>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: 3,
        }}
      >
        <STabs
          value={tabValue}
          onChange={(event, value) => {
            handleTabChange(value);
          }}
          primary={[0, 2].includes(tabValue)}
          className='selectPage-clusterSelectTabs'
        >
          {availableTabs?.map((t) => (
            <Tab
              key={t.id}
              disabled={loading}
              label={t.label}
              {...a11yProps(t.id)}
              onClick={() => setClearRowsSelected(true)}
            />
          ))}
        </STabs>
      </Box>
    </div>
  );
};

DataEntrySelectTabs.propTypes = {
  tabValue: PropTypes.number,
  handleTabChange: PropTypes.func,
  setClearRowsSelected: PropTypes.func,
  availableTabs: PropTypes.array,
  loading: PropTypes.bool,
};
