import { gql } from '@apollo/client';
import {
  CORE_DELEMENT_FIELDS,
  CORE_DOC_FIELDS,
  CORE_PROJECT_FIELDS,
  CORE_SUPPLEMENTALDATA_FIELDS,
  CORE_TASKITEM_FIELDS,
  CORE_VELEMENT_FIELDS,
  CORE_PROJECTMEMBER_FIELDS,
  CORE_TABLEDATA_FIELDS,
  CORE_CLUSTER_FIELDS,
} from './Fragments';

//#region Projects
export const ADD_PROJECT = gql`
  ${CORE_PROJECT_FIELDS}
  mutation AddProject($project: AddProjectInput!) {
    addProject(input: $project) {
      ...CoreProjectFields
    }
  }
`;

export const ARCHIVE_PROJECT = gql`
  mutation ArchiveProject($id: Uuid!) {
    archiveProject(projectId: $id) {
      id
      isComplete
      name
    }
  }
`;

export const RESTORE_PROJECT = gql`
  mutation RestoreProject($id: Uuid!) {
    restoreProject(projectId: $id) {
      id
      isComplete
      name
    }
  }
`;

export const UPDATE_PROJECT_NAME = gql`
  mutation UpdateProjectName($input: UpdateProjectNameInput!) {
    updateProjectName(input: $input) {
      id
      name
      createdOn
      lastModifiedOn
    }
  }
`;
//#endregion Projects

//#region Data Elements
export const ADD_CLUSTER_DATA_ELEMENT = gql`
  mutation AddClusterDataElement($element: AddClusterDataElementInput!) {
    addClusterDataElement(input: $element) {
      projectDataElementId
      cluster {
        id
        totalDataElements
        dataElements {
          id
          name
          description
          type
          tableData {
            id
            name
            type
            dataElementId
          }
        }
      }
    }
  }
`;

export const BULK_ADD_CLUSTER_DATA_ELEMENT = gql`
  mutation AddClusterDataElementList(
    $inputList: [AddClusterDataElementInput]!
  ) {
    addClusterDataElementList(inputList: $inputList) {
      projectDataElementId
      cluster {
        id
        totalDataElements
        dataElements {
          id
          name
          description
          type
          tableData {
            id
            name
            type
            dataElementId
          }
        }
      }
    }
  }
`;

export const ADD_DATA_ELEMENT = gql`
  ${CORE_DELEMENT_FIELDS}
  mutation AddDataElement($inputs: [AddDataElementInput]!) {
    addDataElement(inputs: $inputs) {
      ...CoreDElementFields
    }
  }
`;

export const ADD_TABLE_COLUMN = gql`
  ${CORE_TABLEDATA_FIELDS}
  mutation AddDataElementTableColumn($input: AddDataElementTableColumnInput!) {
    addDataElementTableColumn(input: $input) {
      ...CoreTableDataFields
    }
  }
`;

export const REMOVE_TABLE_COLUMN = gql`
  mutation RemoveTableColumn($dataElementColumnId: Uuid!) {
    removeDataElementTableColumn(dataElementColumnId: $dataElementColumnId)
  }
`;

export const UPDATE_DATA_ELEMENT_DESCRIPTION = gql`
  ${CORE_DELEMENT_FIELDS}
  mutation UpdateDataElementDescription(
    $input: UpdateDataElementDescriptionInput!
  ) {
    updateDataElementDescription(input: $input) {
      ...CoreDElementFields
    }
  }
`;

export const ADD_PROJECT_DATA_ELEMENT = gql`
  mutation AddProjectDataElement($input: AddProjectDataElementInput!) {
    addProjectDataElement(input: $input) {
      id
      projectId
      dataElementId
    }
  }
`;

export const BULK_ADD_PROJECT_DATA_ELEMENT = gql`
  ${CORE_DELEMENT_FIELDS}
  mutation AddProjectDataElementList(
    $inputList: [AddProjectDataElementInput]!
  ) {
    addProjectDataElementList(inputList: $inputList) {
      id
      projectId
      dataElementId
      dataElement {
        ...CoreDElementFields
      }
    }
  }
`;

export const ADD_GLOBAL_DATA_ELEMENTS = gql`
  ${CORE_DELEMENT_FIELDS}
  mutation AddGlobalDataElement($inputs: [AddDataElementInput]!) {
    addDataElement(inputs: $inputs) {
      ...CoreDElementFields
    }
  }
`;

export const REMOVE_PROJECT_DATA_ELEMENT = gql`
  mutation RemoveProjectDataElement($input: DeleteProjectDataElementInput!) {
    removeProjectDataElement(input: $input)
  }
`;

export const REMOVE_DATA_ELEMENT = gql`
  mutation RemoveDataElement($dataElementId: Uuid!) {
    removeDataElement(dataElementId: $dataElementId)
  }
`;

export const REMOVE_CLUSTER_DATA_ELEMENT = gql`
  mutation RemoveClusterDataElement($input: DeleteClusterDataElementInput!) {
    removeClusterDataElement(input: $input)
  }
`;
//#endregion Data Elements

//#region Clusters
export const ADD_CLUSTER = gql`
  ${CORE_CLUSTER_FIELDS}
  mutation AddCluster($input: AddClusterInput!) {
    addCluster(input: $input) {
      ...CoreClusterFields
    }
  }
`;

export const RENAME_CLUSTER = gql`
  mutation RenameCluster($input: RenameClusterInput!) {
    renameCluster(input: $input) {
      id
      name
    }
  }
`;
//#endregion Clusters

//#region Tasks
export const ADD_TASK_ITEM = gql`
  ${CORE_TASKITEM_FIELDS}
  mutation AddTaskItem($input: AddTaskItemInput!) {
    addTaskItem(input: $input) {
      ...CoreTaskItemFields
    }
  }
`;

export const UPDATE_TASK_STATUS = gql`
  mutation UpdateTaskStatus($input: UpdateTaskItemStatusInput!) {
    updateTaskItemStatus(input: $input) {
      id
      status
    }
  }
`;

export const UPDATE_TASK_ITEM = gql`
  ${CORE_TASKITEM_FIELDS}
  mutation UpdateTaskItem($input: UpdateTaskItemInput!) {
    updateTaskItem(input: $input) {
      ...CoreTaskItemFields
    }
  }
`;

export const ASSIGN_TASK = gql`
  mutation AssignTask($input: AssignTaskItemInput!) {
    assignTaskItem(input: $input) {
      id
      description
      assignedTo
    }
  }
`;

export const REMOVE_TASK_ITEM = gql`
  mutation RemoveTaskItem($taskItemId: Uuid!) {
    removeTaskItem(taskItemId: $taskItemId)
  }
`;
//#endregion Tasks

//#region Value Elements
export const ADD_TABLE_CELL_VALUE_ELEMENT = gql`
  ${CORE_VELEMENT_FIELDS}
  mutation AddTableCellValueElement(
    $input: AddorUpdateValueElementTableTypeValueInput!
  ) {
    addOrUpdateValueElementTableTypeValue(input: $input) {
      valueElement {
        ...CoreVElementFields
      }
    }
  }
`;

export const BULK_ADD_TABLE_CELL_VALUE_ELEMENT = gql`
  ${CORE_VELEMENT_FIELDS}
  mutation BulkAddOrUpdateCellValueElement(
    $inputs: [AddorUpdateValueElementTableTypeValueInput!]!
  ) {
    addOrUpdateValueElementTableTypeValueList(inputs: $inputs) {
      valueElement {
        ...CoreVElementFields
      }
    }
  }
`;

export const UPDATE_VALUE_ELEMENT_VALUE = gql`
  ${CORE_VELEMENT_FIELDS}
  mutation UpdateValueElementValue($input: UpdateValueElementValueInput!) {
    updateValueElementValue(input: $input) {
      ...CoreVElementFields
    }
  }
`;

export const UPDATE_VALUE_ELEMENT_VALUE_USER_LIST = gql`
  ${CORE_VELEMENT_FIELDS}
  mutation UpdateValueElementValueUserList(
    $inputs: [UpdateValueElementValueUserInput!]!
  ) {
    updateValueElementValueUserList(inputs: $inputs) {
      ...CoreVElementFields
    }
  }
`;

export const ADD_OR_UPDATE_VALUE_ELEMENT = gql`
  ${CORE_VELEMENT_FIELDS}
  mutation AddOrUpdateValueElement($input: ValueElementInput!) {
    addOrUpdateValueElement(input: $input) {
      valueElement {
        ...CoreVElementFields
      }
    }
  }
`;

export const RECONCILE_VALUE_ELEMENT = gql`
  ${CORE_VELEMENT_FIELDS}
  mutation ReconcileValueElement(
    $input: UpdateProjectMemberReconciliationInput!
  ) {
    updateProjectMemberReconciliation(input: $input) {
      valueElement {
        ...CoreVElementFields
        document {
          name
          cluster {
            name
          }
        }
      }
    }
  }
`;

export const REMOVE_TABLE_CELL_VALUE_ELEMENT = gql`
  mutation RemoveTableCellValueElement($valueElementId: Uuid!) {
    removeValueElementTableTypeValue(valueElementId: $valueElementId)
  }
`;
//#endregion Value Elements

//#region Documents
export const UPDATE_DOCUMENT_STATUS = gql`
  ${CORE_DOC_FIELDS}
  ${CORE_PROJECTMEMBER_FIELDS}
  mutation UpdateDocumentStatus($input: DocumentStatusInput!) {
    updateDocumentStatus(input: $input) {
      ...CoreDocFields
      projectMember {
        ...CoreProjectMemberFields
      }
    }
  }
`;

export const RECLUSTER_DOCUMENTS = gql`
  ${CORE_DOC_FIELDS}
  mutation ReclusterDocuments($inputs: [UpdateDocumentClusterInput!]!) {
    bulkUpdateDocumentCluster(inputs: $inputs) {
      ...CoreDocFields
    }
  }
`;

export const CHECK_AND_COMPLETE_DOCUMENT_STATUS = gql`
  ${CORE_DOC_FIELDS}
  mutation CheckAndCompleteDocumentStatus($id: ID!) {
    checkAndCompleteDocumentStatusFromCluster(id: $id) {
      documents {
        ...CoreDocFields
        projectMember {
          id
          originalId
          status
          assignedTo
          previouslyAssignedTo
          assignedOn
        }
      }
    }
  }
`;

export const COMPARE_PROJECT_MEM_AND_SUPP_DATA = gql`
  mutation CheckProjectMemberAndSuppData($id: ID!) {
    compareProjectMemberAndSupplementalData(id: $id)
  }
`;

export const REMOVE_TEMPLATE_IN_DOCUMENT = gql`
  mutation RemoveTemplateInDocument($documentId: Uuid!) {
    removeTemplateInDocument(documentId: $documentId)
  }
`;
//#endregion Documents

//#region Project Members
export const ADD_OR_UPDATE_PROJECT_MEMBER = gql`
  mutation AddOrUpdateProjectMember($inputs: [AddProjectMemberInput!]) {
    addOrUpdateProjectMember(inputs: $inputs) {
      originalId
      documents {
        originalName
        project {
          name
          id
        }
      }
    }
  }
`;

export const ADD_OR_UPDATE_SUPPLEMENTAL_DATA = gql`
  ${CORE_SUPPLEMENTALDATA_FIELDS}
  mutation AddorUpdateSupplementalData(
    $inputs: [AddProjectMemberSupplementalDataInput!]
  ) {
    addOrUpdateProjectMemberSupplementalData(inputs: $inputs) {
      ...CoreSupplementalDataFields
    }
  }
`;
export const EXPORT_PROJECTMEMBER_DATA = gql`
  mutation ExportProjectMemberData($input: ExportProjectMemberDataInput!) {
    exportProjectMemberData(input: $input)
  }
`;

export const EXPORT_PROJECT_DATA_EXCEL = gql`
  mutation ExportProjectDataToExcel($input: ExportProjectDataExcelInput!) {
    exportProjectDataToExcel(input: $input)
  }
`;

export const EXPORT_PROJECT_DATA_ELEMENT_EXCEL = gql`
  mutation ExportProjectDataElementExcel(
    $input: ExportProjectMemberDataInput!
  ) {
    exportProjectDataElementExcel(input: $input)
  }
`;

export const EXPORT_PROJECT_DOCUMENT_DATA_EXCEL = gql`
  mutation ExportProjectDocumentDataToExcel($projectId: ID!) {
    exportProjectDocumentDataToExcel(projectId: $projectId)
  }
`;

export const CHECK_AND_COMPLETE_PROJECT_MEMBER_STATUS = gql`
  mutation CheckAndCompleteProjectMemberStatus($id: ID!, $first: Int) {
    checkAndCompleteProjectMemberStatusFromProject(id: $id) {
      projectMembers(first: $first) {
        nodes {
          id
          status
          assignedTo
          previouslyAssignedTo
          assignedOn
        }
      }
    }
  }
`;

export const UPDATE_PROJECT_MEMBER_STATUS = gql`
  ${CORE_PROJECTMEMBER_FIELDS}
  mutation UpdateProjectMemberStatus($input: UpdateProjectMemberStatusInput!) {
    updateProjectMemberStatus(input: $input) {
      ...CoreProjectMemberFields
    }
  }
`;

export const UPDATE_SENT_PROJECTMEMBER_ISEXPORTED_STATUS = gql`
  ${CORE_PROJECTMEMBER_FIELDS}
  mutation UpdateSentProjectMemberIsExportedStatus($id: ID!) {
    updateProjectMemberIsExportedStatus(id: $id) {
      projectMembers {
        ...CoreProjectMemberFields
      }
    }
  }
`;

export const UPDATE_PROJECT_MEMBER_AUTOFLOW = gql`
  mutation UpdateProjectMemberAutoFlow(
    $input: UpdateProjectMemberAutoFlowInput!
  ) {
    updateProjectMemberAutoFlow(input: $input)
  }
`;

export const UPDATE_ASSIGNMENT_PROJECT_MEMBER = gql`
  ${CORE_PROJECTMEMBER_FIELDS}
  mutation UpdateAssignmentProjectMember(
    $input: UpdateAssignmentForProjectMemberInput!
  ) {
    updateAssignmentForProjectMember(input: $input) {
      ...CoreProjectMemberFields
    }
  }
`;

export const BULK_UPDATE_ASSIGNMENT_PROJECT_MEMBER = gql`
  ${CORE_PROJECTMEMBER_FIELDS}
  mutation BulkUpdateAssignmentProjectMember(
    $inputs: [UpdateAssignmentForProjectMemberInput!]!
  ) {
    updateAssignmentForProjectMemberList(inputs: $inputs) {
      ...CoreProjectMemberFields
    }
  }
`;

export const CONFIRMED_DOCUMENT_IN_CLUSTER = gql`
  mutation ConfirmedDocumentInCluster($clusterId: Uuid!) {
    confirmedDocumentInCluster(clusterId: $clusterId) {
      id
      isConfirmed
    }
  }
`;

export const CREATE_REDACTION_FILE_ON_PAGE_COMPLETE = gql`
  mutation CreateFileWhenPageIsComplete(
    $documentId: Uuid!
    $fileName: String!
  ) {
    createFileWhenPageIsComplete(
      documentId: $documentId
      fileNameForRedactionFeedbackComplete: $fileName
    )
  }
`;
//#endregion Project Members

// https://hasura.io/learn/graphql/react/optimistic-update-mutations/3.2-bulk-delete-mutation/

// While query fields are executed in parallel, mutation fields run in series, one after the other.
