import { HourglassDisabled } from '@mui/icons-material';
import Button from 'components/Button';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { config } from 'config';
import styled from 'styled-components';

const Header = styled.h1`
  ${(props) => props.theme.typography.header}
  font-weight: 400;
  font-size: 40px;
  margin: 15px;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default function TimeoutPage() {
  const clearUserCache = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
      window.location.replace('/');
    }
  };

  return (
    <>
      <Header onClick={clearUserCache}> {config.APPNAME}</Header>
      <Grid
        container
        spacing={2}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '95vh' }}
      >
        <Grid item xs={12}>
          <HourglassDisabled sx={{ fontSize: 200 }} />
        </Grid>
        <Grid item xs={12}>
          <h1>Session Timeout</h1>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            You have been inactive for 15 minutes. Click the button below or the
            logo above to return to {config.APPNAME} landing page.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button color='primary' variant='contained' onClick={clearUserCache}>
            Take me back
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
