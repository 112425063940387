import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackbarType: '',
  snackbarIsOpen: false,
  snackbarMessage: '',
};

const snackbarSlice = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    setSnackbarSuccess(state, action) {
      state.snackbarType = 'success';
      state.snackbarIsOpen = true;
      state.snackbarMessage = action.payload;
    },
    setSnackbarInfo(state, action) {
      state.snackbarType = 'info';
      state.snackbarIsOpen = true;
      state.snackbarMessage = action.payload;
    },
    setSnackbarWarn(state, action) {
      state.snackbarType = 'warning';
      state.snackbarIsOpen = true;
      state.snackbarMessage = action.payload;
    },
    setSnackbarError(state, action) {
      state.snackbarType = 'error';
      state.snackbarIsOpen = true;
      state.snackbarMessage = action.payload;
    },
    setSnackbarClear(state) {
      state.snackbarType = '';
      state.snackbarIsOpen = false;
      state.snackbarMessage = false;
    },
  },
});

export const selectSnackbarType = (state) => state.snackbars.snackbarType;
export const selectSnackbarIsOpen = (state) => state.snackbars.snackbarIsOpen;
export const selectSnackbarMessage = (state) => state.snackbars.snackbarMessage;

export const {
  setSnackbarSuccess,
  setSnackbarInfo,
  setSnackbarWarn,
  setSnackbarError,
  setSnackbarClear,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
