import PropTypes from 'prop-types';
import CardHeader from 'components/CardHeader';
import Dialog from 'components/Dialog';
import Grid from 'components/Grid';
import styled from 'styled-components';

const SCardHeader = styled(CardHeader)`
  margin-top: 30px;
  text-align: center;
`;

//#endregion Styled Components

export default function ConfirmationModal(props) {
  const { itemInfo, isOpen, onModalExitClick, onModalConfirmClick, formType } =
    props;

  return (
    <Dialog
      open={isOpen}
      onClose={onModalExitClick}
      title='Confirm'
      handleCancel={onModalExitClick}
      submitLabel='Confirm'
      cancelLabel='Cancel'
      handleSubmit={() => onModalConfirmClick()}
      hasActions
    >
      <Grid container spacing={1}>
        {itemInfo ? (
          <SCardHeader
            title={itemInfo ? `Item to ${formType}:` : false}
            subheader={itemInfo ? itemInfo : false}
          />
        ) : (
          false
        )}
        <Grid item xs={12}>
          Are you sure you want to {formType} this item(s)? <br />
          This action cannot be undone.
        </Grid>
      </Grid>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  itemInfo: PropTypes.string,
  onModalConfirmClick: PropTypes.func,
  onModalExitClick: PropTypes.func,
  formType: PropTypes.string,
};
