import MCheckbox from '@mui/material/Checkbox';
import styled from 'styled-components';

const StyledCheckbox = styled(MCheckbox)`
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default function Checkbox(props) {
  return <StyledCheckbox {...props} />;
}
