import MDialog from '@mui/material/Dialog';
import Button from 'components/Button';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from 'components/DialogActions';
import DialogContent from 'components/DialogContent';
import DialogTitle from 'components/DialogTitle';
import IconButton from 'components/IconButton';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components';

//#region Styled Components
const CancelButton = styled(Button)`
  color: ${(props) => `${props.theme.palette.background.darkest}`};
  border-color: ${(props) => `${props.theme.palette.background.darkest}`};

  &:hover {
    color: inherit;
    border-color: inherit;
    background: ${(props) => rgba(props.theme.palette.background.darkest, 0.1)};
  }
`;

export default function Dialog(props) {
  const {
    cancelLabel,
    children,
    handleSubmit,
    handleCancel,
    submitDisabled,
    submitLabel,
    title,
    hasActions,
    requiredSubmit,
  } = props;

  return (
    <MDialog {...props}>
      <DialogTitle id='alert-dialog-title' sx={{ mr: '2rem' }}>
        {title}
      </DialogTitle>
      {!requiredSubmit && (
        <IconButton
          aria-label='close'
          onClick={handleCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: 'rgba(0,0,0,1)',
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {hasActions ? (
        <DialogActions>
          {!requiredSubmit && (
            <CancelButton variant='outlined' onClick={handleCancel} autoFocus>
              {cancelLabel}
            </CancelButton>
          )}
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={submitDisabled}
            autoFocus
            primary
          >
            {submitLabel}
          </Button>
        </DialogActions>
      ) : null}
    </MDialog>
  );
}

Dialog.propTypes = {
  cancelLabel: PropTypes.string,
  children: PropTypes.any,
  handleSubmit: PropTypes.any,
  handleCancel: PropTypes.any,
  submitDisabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  title: PropTypes.string,
};
