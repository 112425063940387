export const config = {
  APIAZURECLIENTID: process.env.REACT_APP_API_AZURE_CLIENT_ID,
  APISTORAGENAME: process.env.REACT_APP_API_STORAGE_NAME,
  APPNAME: process.env.REACT_APP_NAME,
  AZURECLIENTID: process.env.REACT_APP_AZURE_CLIENT_ID,
  AZURETENANTID: process.env.REACT_APP_AZURE_TENANT_ID,
  ENVIRONMENT: process.env.REACT_APP_ENV,
  FILECONVERTERSITE: process.env.REACT_APP_FILE_CONVERTER_SITE,
  SMARTEXTAPIURL: process.env.REACT_APP_API_URL,
  SMARTEXTAPIFRONTDOORURL: process.env.REACT_APP_API_FRONTDOOR_SERVER_URL,
  SMARTEXTAPPDEFAULTRESOURCEMENU: process.env.REACT_APP_DEFAULT_RESOURCE_MENU,
  SMARTEXTFEURL: process.env.REACT_APP_FE_URL,
  SMARTEXTFEPRODURLAWS: process.env.REACT_APP_FE_PROD_URL_AWS,
  SMARTEXTFEPRODURLAZURE: process.env.REACT_APP_FE_PROD_URL_AZURE,
  VAORGANIZATIONNAME: process.env.REACT_APP_FE_VA_ORGANIZATION_NAME
};
