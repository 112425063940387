import { useState } from 'react';
import Dialog from 'components/Dialog';
import DialogContentText from 'components/DialogContentText';
import { config } from 'config';
import PropTypes from 'prop-types';

export default function AcknowledgementDialog(props) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      title={`${config.APPNAME} Usage and Acknowledgement`}
      submitLabel='Agree'
      handleSubmit={handleClose}
      cancelLabel='Cancel'
      handleCancel={handleClose}
      hasActions
      requiredSubmit
    >
      {config.ENVIRONMENT !== 'production' ? (
        <DialogContentText
          id='alert-dialog-description'
          style={{ color: 'red' }}
        >
          By clicking the <b>Agree</b> button, you are acknowledging that in
          using the SmarText application, you will not use and/or upload any
          client data or documents in any capacity.
        </DialogContentText>
      ) : null}
      {props.children}
    </Dialog>
  );
}

AcknowledgementDialog.propTypes = {
  children: PropTypes.any,
};
