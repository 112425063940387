import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import ReassignClusterModal from 'pages/Cluster/modals/ReassignClusterModal';
import { ImageListGallery } from './ImageListGallery';
import { ImageGalleryToolbar } from './ImageGalleryToolbar';
import { decoder } from 'helpers/graphQL_Encoder';

//#region Styled Components
//#endregion Styled Components

export default function ImageGallery(props) {
  const {
    currentProjectId,
    documents,
    setDocuments,
    currentCluster,
    addReclusterDoc,
    defaultClusterOrder,
    defaultDocOrder,
    docIndex,
    readToken,
    reclusterDocs,
    reclusterMode,
    setDocIndex,
    setNewCluster,
    setReclusterDocs,
    setReclusterMode,
    submitRecluster,
    documentName,
    awsObject,
    hasNextPage,
    totalData,
    getDocuments,
    fetchMoreDocuments,
    unclusteredDocuments,
    docQueryFilters,
    setDocQueryFilters,
    image,
    setImage,
    checkClusterNameExists,
  } = props;

  const dispatch = useDispatch();
  const defaultFilterOptions = [
    {
      id: 0,
      name: 'Value Elements',
      selected: false,
      options: [
        { id: 0, name: 'Value Elements Exists', active: false },
        { id: 1, name: 'No Value Elements Exists', active: false },
      ],
    },
    {
      id: 1,
      name: 'Cluster Document Status',
      selected: false,
      options: [
        { id: 0, name: 'Confirmed', active: false },
        { id: 1, name: 'Unconfirmed', active: false },
      ],
    },
  ];

  const [clusterSelected, setClusterSelected] = useState(null);
  const [galleryDocuments, setGalleryDocuments] = useState(documents);
  const [filtersToolbar, toggleFiltersToolbar] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);

  //#region Functions
  const onClickImageCard = (e, doc, index, reclusterMode) => {
    if (reclusterMode) {
      if (e?.shiftKey) {
        getSelectedDocs(doc);
      } else {
        addReclusterDoc(doc.id);
        dispatch(setDocIndex(index));
      }
    } else {
      dispatch(setDocIndex(index));
    }
  };

  const getSelectedDocs = (doc) => {
    if (galleryDocuments?.nodes) {
      const lastDocId = doc.id;

      let selectedDocs;

      const lastDocIndex = galleryDocuments?.nodes.findIndex(
        (document) => document.id === lastDocId
      );

      dispatch(setDocIndex(lastDocIndex));

      if (lastDocIndex > docIndex) {
        selectedDocs = galleryDocuments?.nodes.slice(
          docIndex,
          lastDocIndex + 1
        );
      } else if (lastDocIndex < docIndex) {
        selectedDocs = galleryDocuments?.nodes.slice(
          lastDocIndex,
          docIndex + 1
        );
      } else {
        selectedDocs = [galleryDocuments?.nodes[docIndex]];
      }
      const docIds = selectedDocs.map((doc) => doc.id);

      setReclusterDocs(docIds);
    }
  };

  function enterReclusterMode() {
    toggleFiltersToolbar(false);
    setReclusterMode(!reclusterMode);
    setOpenReassignModal(true);
    const id = galleryDocuments?.nodes[docIndex]?.id;
    if (id) {
      addReclusterDoc(id);
    }
  }

  const setClusterValues = (value) => {
    setClusterSelected(value);
    setNewCluster(value);
  };

  const handleScroll = (e, hasNextPage) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.clientHeight + 5 >=
        e.currentTarget.scrollHeight &&
      hasNextPage
    ) {
      fetchMoreDocuments();
    }
  };

  const clearFilters = () => {
    setFilterOptions(defaultFilterOptions);
    toggleFiltersToolbar(false);
    setDocQueryFilters(null);
  };

  useEffect(() => {
    if (currentCluster) {
      toggleFiltersToolbar(false);
      let scrollArea = document.getElementById('scrollGallery');
      scrollArea?.scrollTo(0, 0);
    }
  }, [currentCluster]);

  useEffect(() => {
    if (!reclusterMode) setClusterSelected(null);
  }, [reclusterMode]);

  useEffect(() => {
    if (docQueryFilters) {
      getDocuments({
        variables: { sortOrder: defaultDocOrder, filters: docQueryFilters },
      });
    } else if (currentCluster) {
      getDocuments({
        variables: {
          sortOrder: defaultDocOrder,
          filters: { clusterId: { eq: decoder(currentCluster?.id) } },
        },
      });
    }
  }, [docQueryFilters]);

  useEffect(() => {
    setGalleryDocuments({ ...galleryDocuments, nodes: documents?.nodes });
  }, [documents]);
  //#endregion Functions

  //#region Elements

  const gallery = (
    <ImageListGallery
      galleryDocuments={galleryDocuments}
      onClickImageCard={onClickImageCard}
      hasNextPage={hasNextPage}
      awsObject={awsObject}
      reclusterMode={reclusterMode}
      reclusterDocs={reclusterDocs}
      docIndex={docIndex}
      readToken={readToken}
      handleScroll={handleScroll}
      setDocIndex={setDocIndex}
      image={image}
      setImage={setImage}
    />
  );
  //#endregion Elements

  return (
    <Grid container>
      {galleryDocuments?.nodes && (
        <>
          <ImageGalleryToolbar
            documentName={documentName}
            openReassignModal={openReassignModal}
            currentCluster={currentCluster}
            filtersToolbar={filtersToolbar}
            filterOptions={filterOptions}
            toggleFiltersToolbar={toggleFiltersToolbar}
            enterReclusterMode={enterReclusterMode}
            setFilterOptions={setFilterOptions}
            docQueryFilters={docQueryFilters}
            setDocQueryFilters={setDocQueryFilters}
            clearFilters={clearFilters}
          />
          <Grid item xs={12}>
            {gallery}
          </Grid>
          <Grid item xs={12} textAlign='end'>
            Document Count: {galleryDocuments?.nodes?.length} of {totalData}
          </Grid>
        </>
      )}
      <ReassignClusterModal
        submitRecluster={submitRecluster}
        reclusterDocs={reclusterDocs}
        setReclusterDocs={setReclusterDocs}
        modalOpen={openReassignModal}
        documents={documents}
        setDocuments={setDocuments}
        awsObject={awsObject}
        readToken={readToken}
        currentProjectId={currentProjectId}
        defaultClusterOrder={defaultClusterOrder}
        currentCluster={currentCluster}
        docIndex={docIndex}
        setNewCluster={setNewCluster}
        clusterSelected={clusterSelected}
        setClusterSelected={setClusterSelected}
        unclusteredDocuments={unclusteredDocuments}
        handleScroll={handleScroll}
        setClusterValues={setClusterValues}
        hasNextPage={hasNextPage}
        onClickImageCard={onClickImageCard}
        reclusterMode={reclusterMode}
        setReclusterMode={setReclusterMode}
        addReclusterDoc={addReclusterDoc}
        setOpenReassignModal={setOpenReassignModal}
        filtersToolbar={filtersToolbar}
        filterOptions={filterOptions}
        toggleFiltersToolbar={toggleFiltersToolbar}
        setFilterOptions={setFilterOptions}
        docQueryFilters={docQueryFilters}
        setDocQueryFilters={setDocQueryFilters}
        clearFilters={clearFilters}
        image={image}
        setImage={setImage}
        setDocIndex={setDocIndex}
        checkClusterNameExists={checkClusterNameExists}
      />
    </Grid>
  );
}

ImageGallery.propTypes = {
  currentProjectId: PropTypes.any,
  documents: PropTypes.object,
  setDocuments: PropTypes.any,

  clusters: PropTypes.object,
  currentCluster: PropTypes.object,
  defaultClusterOrder: PropTypes.array,
  defaultDocOrder: PropTypes.array,
  docIndex: PropTypes.any,
  newCluster: PropTypes.any,
  readToken: PropTypes.any,
  reclusterDocs: PropTypes.object,
  reclusterMode: PropTypes.any,
  setDocIndex: PropTypes.func,
  setNewCluster: PropTypes.func,
  setNewClusterOpen: PropTypes.func,
  setReclusterDocs: PropTypes.func,
  setReclusterMode: PropTypes.func,
  submitRecluster: PropTypes.any,
  documentName: PropTypes.string,
  isDisableReclusterMode: PropTypes.bool,
  awsObject: PropTypes.string,
  hasNextPage: PropTypes.bool,
  totalData: PropTypes.number,
  getDocuments: PropTypes.func,
  fetchMoreDocuments: PropTypes.func,
  unclusteredDocuments: PropTypes.any,
  docQueryFilters: PropTypes.any,
  setDocQueryFilters: PropTypes.any,
  image: PropTypes.any,
  setImage: PropTypes.any,
};
