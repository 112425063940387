import Grid from 'components/Grid';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { FilterList } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ImageGalleryFiltersToolbar } from './ImageGalleryFilters';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FiltersButton = styled(Button)`
  background-color: white;
  color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: white};

  ${(props) => {
    if (props.filtersToolbar || props.filterEnabled) {
      return `
        background-color: ${props.theme.palette.primary.main};
        color: white;
        &:hover {
          background-color: white;
          color: ${props.theme.palette.primary.main};
        }
        `;
    }
  }}
  }
`;

export const ImageGalleryToolbar = (props) => {
  const {
    documentName = '',
    openReassignModal,
    currentCluster,
    filtersToolbar,
    filterOptions,
    toggleFiltersToolbar,
    enterReclusterMode = () => null,
    setFilterOptions,
    docQueryFilters,
    setDocQueryFilters,
    clearFilters,
    onDataEntry = false,
  } = props;

  return (
    <Grid
      container
      sx={{ padding: '.5vh' }}
      justifyContent='center'
      alignItems='center'
    >
      <Grid container spacing={1} alignItems='center'>
        {openReassignModal ? null : (
          <Grid item xs={4}>
            Current Document:{' '}
            <Typography noWrap>
              {documentName ? documentName : 'none'}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={1}
            sx={onDataEntry && { paddingBottom: '5%' }}
          >
            {filterOptions?.some((f) => f.options?.some((o) => o.active)) && (
              <Button
                variant='contained'
                primary
                onClick={() => clearFilters()}
              >
                <Typography variant='button '>Clear Filters</Typography>
              </Button>
            )}
            {!onDataEntry && (
              <FiltersButton
                filtersToolbar={filtersToolbar}
                filterEnabled={filterOptions?.some((f) =>
                  f.options?.some((o) => o.active)
                )}
                onClick={() => toggleFiltersToolbar(!filtersToolbar)}
              >
                <FilterList />
                <Typography fontSize='1.6dvh'>
                  Filters
                  {filterOptions?.some?.((f) =>
                    f.options?.some((o) => o.active)
                  )
                    ? ' (+1)'
                    : null}
                </Typography>
              </FiltersButton>
            )}

            {!openReassignModal && (
              <Button
                variant='outlined'
                primary
                onClick={() => enterReclusterMode()}
                className='clustersPage-imageGalleryReassignButton'
              >
                <Typography fontSize='1.6dvh'>Reassign</Typography>
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
      {filtersToolbar && (
        <ImageGalleryFiltersToolbar
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          docQueryFilters={docQueryFilters}
          setDocQueryFilters={setDocQueryFilters}
          currentCluster={currentCluster}
        />
      )}
    </Grid>
  );
};

ImageGalleryToolbar.propTypes = {
  documentName: PropTypes.string,
  openReassignModal: PropTypes.bool,
  filtersToolbar: PropTypes.bool,
  filterOptions: PropTypes.object,
  toggleFiltersToolbar: PropTypes.any,
  enterReclusterMode: PropTypes.func,
  setFilterOptions: PropTypes.any,
  docQueryFilters: PropTypes.any,
  setDocQueryFilters: PropTypes.func,
  currentCluster: PropTypes.object,
  clearFilters: PropTypes.func,
  onDataEntry: PropTypes.bool,
};
