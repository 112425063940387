export const resources = {
  technical: {
    ANY_PAGES: {
      CLUSTER: 'Cluster',
      PROJECT_MEMBER: 'Project Member',
      DEFAULT_ENTRY_TAB: 0,
    },
  },
  va: {
    ANY_PAGES: {
      CLUSTER: 'Classification',
      PROJECT_MEMBER: 'Claim',
      DEFAULT_ENTRY_TAB: 1,
    },
  },
};
