import PropTypes from 'prop-types';
import MCard from '@mui/material/Card';
import styled from 'styled-components';

/* Setting up for future card transition animation */
const StyledCard = styled(MCard)`
  ${(props) =>
    props.padding &&
    `
padding: ${props.padding}px
`};
  box-shadow: 0 1px 3px 1px rgba(196, 196, 196, 1);
  border: 1px solid rgba(225, 226, 240, 1);
  border-radius: 8px;
  overflow: visible;
`;

export default function Card({ padding, ...rest }) {
  return <StyledCard sx={{ p: 2 }} padding={padding} {...rest} />;
}

Card.propTypes = {
  padding: PropTypes.number,
};
