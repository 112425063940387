import { Grid } from '@mui/material';
import styled from 'styled-components';

//#region Styled Components
const Container = styled(Grid)`
  min-height: 200px;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled(Grid)`
  height: 150px;
  justify-content: center;
  align-items: center;
  border: ${(props) => `3px solid ${props.theme.palette.primary.main}`};
  border-radius: 5px;
`;

const Message = styled.p`
  ${(props) => props.theme.typography.subheader};
`;
//#endregion Styled Components

export default function NoDocument() {
  return (
    <Container container>
      <TextContainer item container xs={6}>
        <Message>No Document Selected</Message>
      </TextContainer>
    </Container>
  );
}
