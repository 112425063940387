import PropTypes from 'prop-types';
import Card from 'components/Card';
import { config } from 'config';
import Grid from 'components/Grid';
import Grow from 'components/Grow';
import Paper from 'components/Paper';
import Typography from 'components/Typography';
import { useSelector } from 'react-redux';
import { selectCurrentProjectId } from 'store/Redux/slices/projectsSlice';
import styled from 'styled-components';

//#region Styled Components
const SCard = styled(Card)`
  float: left;
  border: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
  padding: 25px;
`;

const BoxText = styled(Typography)`
  font-size: 25px;
  font-family: inherit;
  font-weight: 300;
  line-height: 3.5;
  letter-spacing: 0.00938em;
  color: rgba(127, 127, 127, 1);
  cursor: default;
`;

const WarningText = styled(Typography)`
  font-size: 18px;
  color: red;
  font-weight: bold;
  line-height: 1;
  padding-bottom: 16;
`;

const Container = styled.div`
  display: grid;
  place-items: center;
  margin-top: 10%;
  margin-right: 5%;
`;
//#endregion Styled Components

export default function ProjectSelectedTemplate(props) {
  const {
    children,
    boxText = `Please Select a Project`,
    warningText = `Warning: You are not permitted to add any client related data or documents in any capacity at this time.`,
  } = props;

  const currentProjectId = useSelector(selectCurrentProjectId);

  return (
    <div>
      {currentProjectId ? (
        <div>{children}</div>
      ) : (
        <Grid container>
          <Grid item xl={3} sm={3}></Grid>
          <Grid item xl={6} sm={6} style={{}}>
            <Container>
              <Grow
                in={!currentProjectId}
                style={{ transformOrigin: '0 0 0' }}
                {...(currentProjectId ? {} : { timeout: 1500 })}
              >
                <div>
                  <Paper elevation={4}>
                    <SCard>
                      <BoxText>{boxText}</BoxText>
                      {config.ENVIRONMENT !== 'production' ? (
                        <WarningText>{warningText}</WarningText>
                      ) : null}
                    </SCard>
                  </Paper>
                </div>
              </Grow>
            </Container>
          </Grid>
          <Grid item xl={3} sm={3}></Grid>
        </Grid>
      )}
    </div>
  );
}

ProjectSelectedTemplate.propTypes = {
  warningText: PropTypes.any,
  boxText: PropTypes.any,
  children: PropTypes.element,
};
