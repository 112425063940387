export const applyAWSTokenToDocument = (preSignedUrls, documents) => {
  const updatedDocs = documents.map((d) => {
    let uriPair = preSignedUrls?.find((p) => p.key === d.documentUri);
    if (uriPair?.value) {
      let finalUri = '';
      if (/%20/g.test(uriPair.value)) {
        finalUri = decodeURI(uriPair.value);
      } else {
        finalUri = uriPair.value;
      }

      return { ...d, documentUri: finalUri };
    }
  });

  return updatedDocs;
};

export const handleDecodeURL = (documents) => {
  return documents.map((d) => {
    if (/%20/g.test(d.documentUri)) {
      return { ...d, documentUri: decodeURI(d.documentUri) };
    }
    return d;
  });
};
