import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Box from 'components/Box';
import Divider from 'components/Divider';
import MuiDrawer from 'components/Drawer';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';

// Resource Imports
import { useSelector } from 'react-redux';
import { rgba } from 'polished';
import {
  selectCurrentOrganizationName,
  selectCurrentResourceName,
  defaultResourceMenu,
} from 'store/Redux/slices/projectsSlice';
import { resources } from 'resource';

// Icon Imports
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import InterestsIcon from '@mui/icons-material/Interests';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PublishIcon from '@mui/icons-material/Publish';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styled from 'styled-components';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { NoteAlt } from '@mui/icons-material';
import {config} from 'config'

//#region Styled Components
const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  min-height: 64px;
`;

const closedDrawerWidth = 80;
const openDrawerWidth = 240;

const Drawer = styled(MuiDrawer)`
  width: ${(props) =>
    props.open ? `${openDrawerWidth}px` : `${closedDrawerWidth}px`};
  & .MuiDrawer-paper {
    transition: width 200ms ease-in-out;
    width: ${(props) =>
      props.open ? `${openDrawerWidth}px` : `${closedDrawerWidth}px`};
    z-index: 1001;
  }
  flexshrink: 0;
  white-space: nowrap;
  box-sizing: border-box;
`;

const StyledTreeView = styled(SimpleTreeView)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  &::-webkit-scrollbar {
    display: ${(props) => (props.isDrawerOpen ? 'visible' : 'none')};
    width: 0.6em;
    height: 0.6em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 3px solid transparent;
    background-clip: content-box;
  }
  ,
`;

const StyledTreeItemRoot = styled(TreeItem)`
  margin-top: ${(props) => (props.isChildNode ? '0px' : '10px')};
  padding: ${(props) => (props.isChildNode ? `0px` : `0 10px`)};
  color: black;

  & .MuiTreeItem-content {
    padding: 1%;
    border-radius: ${(props) =>
      props.isDrawerOpen
        ? props.isChildNode
          ? `0 20px 20px 0`
          : `8px`
        : '8px'};

    &.Mui-focused {
      background-color: transparent;
    }

    &:hover {
      ${(props) =>
        props.isChildNode
          ? `
            color: ${props.theme.palette.primary.main};
            background-color: ${rgba(props.theme.palette.primary.main, 0.1)};
          `
          : `color: white;
            background-color: ${props.theme.palette.primary.main};
            `}
    }

    &.Mui-selected,
    &.Mui-expanded,
    &.Mui-selected,
    &.Mui-focused {
      font-weight: bold;
      ${(props) =>
        props.isChildNode
          ? `
            color: ${props.theme.palette.primary.main};
            background-color: ${rgba(props.theme.palette.primary.main, 0.1)};
          `
          : `color: white;
            background-color: ${props.theme.palette.primary.main};
            `}
    }
    & .MuiTreeItem-iconContainer {
      transition: all 200ms ease;
      ${(props) =>
        props.isDrawerOpen
          ? props.isChildNode
            ? `display: none`
            : null
          : `svg {
                width: 0;
              }
            margin: 0;
            width: 0;
      `}
    }
  }

  & ${TreeItem.groupTransition} {
    ${(props) =>
      props.isDrawerOpen && props.isChildNode
        ? `border-left: 1px solid ${props.theme.palette.primary.main}`
        : `
          & .MuiTreeItem-root {
            padding: 0px;
          }
    `};
  }
`;

const TreeBox = styled(Box)`
  display: flex;
  align-items: center;
  transition: padding 200ms ease;
  padding: ${(props) => (props.isDrawerOpen ? '8px' : `8px 0`)};
`;
//#endregion Styled Components

// Tree Item Styling
function StyledTreeItem(props) {
  const {
    labelIcon: LabelIcon,
    labelText,
    isChildNode,
    isDrawerOpen,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      isDrawerOpen={isDrawerOpen}
      isChildNode={isChildNode}
      label={
        <TreeBox isDrawerOpen={isDrawerOpen}>
          <Box
            component={LabelIcon}
            color='inherit'
            sx={{ mr: isDrawerOpen ? 3 : 0 }}
          />
          {isDrawerOpen ? (
            <Typography
              variant='body2'
              sx={{ fontWeight: 'inherit', flexGrow: 1 }}
            >
              {labelText}
            </Typography>
          ) : null}
        </TreeBox>
      }
      {...other}
    />
  );
}

export default function SideNav() {
  const organizationName = useSelector(selectCurrentOrganizationName);
  const resourceMenu = useSelector(selectCurrentResourceName);

  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];

  // Object of Side Navigation Item Data
  const sideNavTree = [
    {
      key: 'navGroup1',
      itemId: '1',
      label: 'Admin',
      labelIcon: AdminPanelSettingsIcon,
      className: 'sidebar-admin',
      visible: true,
      subPages: [
        {
          key: 'page1',
          itemId: '1.1',
          name: 'Records',
          labelIcon: AssignmentIcon,
          path: '/records',
          className: 'sidebar-records',
          visible: organizationName !== config.VAORGANIZATIONNAME
        },
        {
          key: 'page2',
          itemId: '1.2',
          name: 'Assign',
          labelIcon: AssignmentIndIcon,
          path: '/assign',
          className: 'sidebar-records',
          visible: true,
        },
        {
          key: 'page3',
          itemId: '1.3',
          name: 'Document',
          labelIcon: NoteAlt,
          path: '/document',
          className: 'sidebar-records',
          visible: true,
        },
      ],
    },
    {
      key: 'navGroup2',
      itemId: '2',
      label: 'Projects',
      labelIcon: BubbleChartIcon,
      className: 'sidebar-projects',
      visible: organizationName !== config.VAORGANIZATIONNAME,
      subPages: [
        {
          key: 'page3',
          itemId: '2.1',
          name: 'Import',
          labelIcon: PublishIcon,
          path: '/import',
          className: 'sidebar-records',
          visible: organizationName !== config.VAORGANIZATIONNAME
        },
      ],
    },
    {
      key: 'navGroup3',
      itemId: '3',
      label: 'Dashboard',
      labelIcon: ViewQuiltIcon,
      className: 'sidebar-dashboard',
      visible: false,
      subPages: [
        {
          key: 'page4',
          itemId: '3.1',
          name: 'Summary',
          labelIcon: DashboardIcon,
          path: '/summary',
          className: 'sidebar-summary',
          visible: true,
        },
        {
          key: 'page5',
          itemId: '3.2',
          name: 'Task List',
          labelIcon: ChromeReaderModeIcon,
          path: '/tasklist',
          className: 'sidebar-taskList',
          visible: true,
        },
      ],
    },
    {
      key: 'navGroup4',
      itemId: '4',
      label: `${selectedResources.ANY_PAGES.CLUSTER}`,
      labelIcon: AccountTreeIcon,
      className: 'sidebar-classification',
      visible: true,
      subPages: [
        {
          key: 'page6',
          itemId: '4.1',
          name: 'Clusters',
          labelIcon: InterestsIcon,
          path: '/clusters',
          className: 'sidebar-summary',
          visible: true,
        },
        {
          key: 'page7',
          itemId: '4.2',
          name: 'Assign Elements',
          labelIcon: VerticalSplitIcon,
          path: '/elementassign',
          className: 'sidebar-assignElements',
          visible: true,
        },
      ],
    },
    {
      key: 'navGroup5',
      itemId: '5',
      label: 'Data Exceptions',
      labelIcon: AppsOutageIcon,
      className: 'sidebar-dataExceptions',
      visible: true,
      subPages: [
        {
          key: 'page7',
          itemId: '5.1',
          name: 'Select',
          labelIcon: SelectAllIcon,
          path: '/select',
          className: 'sidebar-dataSelect',
          visible: true,
        },
        {
          key: 'page8',
          itemId: '5.2',
          name: 'Entry',
          labelIcon: KeyboardIcon,
          path: '/dataentry',
          className: 'sidebar-dataEntry',
          visible: true,
        },
      ],
    },
    {
      key: 'navGroup6',
      itemId: '6',
      label: 'Status',
      labelIcon: InsightsIcon,
      className: 'sidebar-status',
      visible: true,
      subPages: [
        {
          key: 'page8',
          itemId: '6.1',
          name: 'Validation',
          labelIcon: PlaylistAddCheckIcon,
          path: '/analysis',
          className: 'sidebar-validation',
          visible: true,
        },
        {
          key: 'page9',
          itemId: '6.2',
          name: 'Reports',
          labelIcon: SummarizeIcon,
          path: '/reports',
          className: 'sidebar-reports',
          visible: false,
        },
      ],
    },
  ];

  // Find the current page's main navigation group and the current page the user's on
  function findNavAndSubnav() {
    for (let i = 0; i < sideNavTree.length; i++) {
      for (let j = 0; j < sideNavTree[i].subPages.length; j++) {
        if (sideNavTree[i].subPages[j].path === location.pathname) {
          return {
            navId: sideNavTree[i]?.itemId,
            subNavId: sideNavTree[i].subPages[j].itemId,
          };
        }
      }
    }
  }

  const history = useHistory();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const currentNavigation = findNavAndSubnav();

  // Clear out side navigation selection and reset upon sidebar hover and page change
  useEffect(() => {
    setSelected([]);
    setExpanded([]);
    if (drawerOpen) {
      setSelected([currentNavigation.subNavId]);
      setExpanded([currentNavigation.navId]);
    } else {
      setSelected([currentNavigation.navId]);
      setExpanded([]);
    }
  }, [drawerOpen, currentNavigation.navId, currentNavigation.subNavId]);

  // Navigate to the main navigation group's first page
  const mainNavHandler = (navGroup, navGroupId) => () => {
    const firstVisibleSubPage = navGroup.subPages.find((p) => p.visible);
    history.push(firstVisibleSubPage.path);

    if (expanded.includes(navGroupId)) {
      // Below logic allows main navigation group to be collapsed - removing this will cause navigation group to always be expanded
      setExpanded(
        expanded.filter((i) => {
          return i !== navGroupId && !i.startsWith(`${navGroupId}.`);
        })
      );
    } else {
      setExpanded([navGroupId]);
    }
  };

  const subNavHandler = (subNavId, subNavPath) => {
    if (!selected.includes(subNavId)) {
      setSelected([subNavId]);
    }
    history.push(subNavPath);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <Drawer
      variant='permanent'
      data-cy='side-nav'
      open={drawerOpen}
      onMouseOver={() => openDrawer()}
      onMouseOut={() => closeDrawer()}
    >
      <DrawerHeader />
      <Divider />
      <StyledTreeView
        aria-label='sidebar'
        expandedItems={expanded}
        isDrawerOpen={drawerOpen}
        selectedItems={selected}
        slots={{ collapseIcon: ExpandLessIcon, expandIcon: ExpandMoreIcon }}
      >
        {sideNavTree.map(
          (navGroup) =>
            navGroup.visible && (
              <StyledTreeItem
                isDrawerOpen={drawerOpen}
                isChildNode={false}
                className={navGroup.className}
                key={navGroup.key}
                itemId={navGroup.itemId}
                labelIcon={navGroup.labelIcon}
                labelText={navGroup.label}
                onClick={mainNavHandler(navGroup, navGroup.itemId)}
              >
                {navGroup.subPages.map(
                  (subNav) =>
                    subNav.visible && (
                      <StyledTreeItem
                        isDrawerOpen={drawerOpen}
                        isChildNode={true}
                        className={subNav.className}
                        key={subNav.key}
                        itemId={subNav.itemId}
                        labelIcon={subNav.labelIcon}
                        labelText={subNav.name}
                        onClick={() =>
                          subNavHandler(subNav.itemId, subNav.path)
                        }
                      />
                    )
                )}
              </StyledTreeItem>
            )
        )}
      </StyledTreeView>
    </Drawer>
  );
}

StyledTreeItem.propTypes = {
  isChildNode: PropTypes.bool.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  labelIcon: PropTypes.elementType.isRequired,
  labelText: PropTypes.string.isRequired,
};
