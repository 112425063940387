import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from 'components/Dialog';
import CardContent from 'components/CardContent';
import Fade from 'components/Fade';
import FormControl from 'components/FormControl';
import Grid from 'components/Grid';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStatusJustificationTypes,
  selectCurrentProject,
  selectProjectMemberStatusTypes,
} from 'store/Redux/slices/projectsSlice';
import {
  selectCurrentMember,
  setCurrentMember,
} from 'store/Redux/slices/analysisSlice';
import { selectMsGroupData } from 'store/Redux/slices/usersSlice';
import { UPDATE_PROJECT_MEMBER_STATUS } from 'services/GraphQL/Mutations';
import { decoder } from 'helpers/graphQL_Encoder';
import {
  setSnackbarSuccess,
  setSnackbarError,
} from 'store/Redux/slices/snackbarsSlice';
import { displayElementType } from 'helpers/enumToType';
import TextField from 'components/TextField';
import Autocomplete from 'components/Autocomplete';
import List from 'components/List';
import ListItem from 'components/ListItem';
import ListItemText from 'components/ListItemText';
import { Checkbox, Chip, ListItemButton, Stack } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

//#region Styled Components

const StatusChangeContainer = styled('div')`
  width: 570px;
`;

const PaddedGrid = styled(Grid)`
  padding: 8px;
`;

const InputContainer = styled(CardContent)`
  padding: 8px;
  height: auto;
`;

export default function StatusJustificationModal(props) {
  const {
    isOpen = false,
    onAnalysisPage = false,
    handleModalClose = () => null,
    projectMembers = {},
    setProjectMembers = () => null,
    editableStatuses = [],
  } = props;

  const dispatch = useDispatch();
  const confirm = useConfirm();

  const msGroups = useSelector(selectMsGroupData);
  const currentProject = useSelector(selectCurrentProject);
  const currentMember = useSelector(selectCurrentMember);
  const statusJustificationTypes = useSelector(selectStatusJustificationTypes);
  const projectMemberStatusTypes = useSelector(selectProjectMemberStatusTypes);

  const justificationStatusNotesList = [
    {
      justification: statusJustificationTypes.NOT_APPLICABLE,
      statusNotes: [],
    },
    {
      justification: statusJustificationTypes.MISSING_REQUIRED_DATA,
      statusNotes: [
        'Missing veteran ssn',
        'Missing veteran first name',
        'Missing veteran last name',
      ],
    },
    {
      justification: statusJustificationTypes.CONFLICTING_DATA,
      statusNotes: [
        'Veteran SSN does not agree to VBMS profile',
        'Veteran first and last name do not agree to VBMS profile',
      ],
    },
    {
      justification: statusJustificationTypes.POA_REQUEST,
      statusNotes: [
        'Third party first name present',
        'Third party last name present',
        'Third party signature present',
        'POA signature present',
      ],
    },
    {
      justification: statusJustificationTypes.MISSING_SIGNATURE,
      statusNotes: ['Veteran signature not present'],
    },
    {
      justification: statusJustificationTypes.PA_EXCLUSION,
      statusNotes: [
        'Request for education benefit records',
        'Request for Home Loan Benefit Records',
        'Request for Financial Records',
        'Request for Fiduciary Service Records',
        'Request for Life Insurance Benefit Records',
        'Request for VRE Records',
      ],
    },
    {
      justification: statusJustificationTypes.REQUEST_NOT_VALID,
      statusNotes: [],
    },
    {
      justification: statusJustificationTypes.PA_EXCEPTION,
      statusNotes: [],
    },
    {
      justification: statusJustificationTypes.PA_EXEMPTION,
      statusNotes: [],
    },
    {
      justification: statusJustificationTypes.MULTIPLE,
      statusNotes: [],
    },
    {
      justification: statusJustificationTypes.OTHER,
      statusNotes: [],
    },
  ];

  const initialForm = {
    status: currentMember?.status,
    justification: currentMember?.justification,
    user: currentMember?.assignedTo,
    notes: currentMember?.statusNotes || '',
  };

  //const [justificationDropdownOptions, setJustificationDropdownOptions] = useState([]);
  const [userEmails, setUserEmails] = useState([]);
  const [form, setForm] = useState(initialForm);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedJustification, setSelectedJustification] = useState([]);
  const [isNotesFormatted, setIsNotesFormatted] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  //TODO: This code will be used soon after the first release
  //const onSetJustificationDropdownOptions = (status) => {
  //  if (status === 'OFF_RAMPED') {
  //    let filteredStatusesForOffRamped = Object.keys(statusJustificationTypes).filter(
  //      (v) =>
  //        v === statusJustificationTypes.PA_EXCEPTION ||
  //        v === statusJustificationTypes.POA_REQUEST ||
  //        v === statusJustificationTypes.PA_EXCLUSION ||
  //        v === statusJustificationTypes.PA_EXEMPTION
  //    );
  //    setJustificationDropdownOptions(filteredStatusesForOffRamped);
  //  } else {
  //    let filteredStatusesExcludeOffRamped = Object.keys(statusJustificationTypes).filter(
  //      (v) =>
  //        v !== statusJustificationTypes.PA_EXCEPTION &&
  //        v !== statusJustificationTypes.POA_REQUEST &&
  //        v !== statusJustificationTypes.PA_EXCLUSION &&
  //        v !== statusJustificationTypes.PA_EXEMPTION
  //    );
  //    setJustificationDropdownOptions(filteredStatusesExcludeOffRamped);
  //  }
  //};

  const getAvailableProjectMemberStatuses = (valueToFilter) => {
    let filteredStatuses = [];
    if (
      valueToFilter === projectMemberStatusTypes.DATA_ENTRY_IN_PROGRESS &&
      !onAnalysisPage
    ) {
      // status 0
      filteredStatuses = Object.keys(projectMemberStatusTypes).filter(
        (v) =>
          v === projectMemberStatusTypes.DATA_ENTRY_OFF_RAMPED_FOR_REVIEW ||
          v === valueToFilter
      );
      setStatusOptions(filteredStatuses);
    } else if (
      valueToFilter ===
        projectMemberStatusTypes.DATA_ENTRY_OFF_RAMPED_FOR_REVIEW &&
      !onAnalysisPage
    ) {
      // status 40
      filteredStatuses = Object.keys(projectMemberStatusTypes).filter(
        (v) =>
          v === projectMemberStatusTypes.REJECTED ||
          v === projectMemberStatusTypes.OFF_RAMPED ||
          v === valueToFilter
      );

      setStatusOptions(filteredStatuses);
    } else if (
      valueToFilter ===
        projectMemberStatusTypes.RECONCILIATION_FLAGGED_FOR_REVIEW &&
      onAnalysisPage
    ) {
      // status 70
      filteredStatuses = Object.keys(projectMemberStatusTypes).filter(
        (v) =>
          v === projectMemberStatusTypes.RECONCILIATION_ESCALATED_FOR_REVIEW ||
          v === valueToFilter
      );
      setStatusOptions(filteredStatuses);
    } else if (
      valueToFilter ===
        projectMemberStatusTypes.RECONCILIATION_ESCALATED_FOR_REVIEW &&
      onAnalysisPage
    ) {
      // status 80
      filteredStatuses = Object.keys(projectMemberStatusTypes).filter(
        (v) =>
          v === projectMemberStatusTypes.REJECTED ||
          v === projectMemberStatusTypes.OFF_RAMPED ||
          v === valueToFilter
      );
      setStatusOptions(filteredStatuses);
    } else {
      setStatusOptions([valueToFilter]);
    }
  };

  const onSelectStatus = (e) => {
    let status;
    let user;
    if (initialForm.status === e.target.value) {
      status = initialForm.status;
      user = initialForm.user;
    } else {
      status = e.target.value;
      user = userEmails?.length ? userEmails[0] : 'unassign';
    }
    //onSetJustificationDropdownOptions(e.target.value);
    setForm({ ...form, status: status, user: user });
  };

  const handleSelectNote = (option, note) => {
    let justification = selectedJustification;
    let notes = form.notes?.split('\n')?.map((n) => n.trim()) || [];

    if (note) {
      if (notes.find((n) => n === note)) {
        notes = notes.filter((n) => n !== note);
      } else {
        notes.push(note);
      }

      justification = checkParentNotes(option, notes, justification);
    } else {
      confirm({
        description: 'Changing/Removing justification will remove notes.',
        confirmationText: 'Confirm',
        cancellationText: 'Cancel',
      })
        .then(() => {
          setIsNotesFormatted(false);
          notes = '';

          if (
            selectedJustification.some(
              (j) => j.justification === option.justification
            )
          ) {
            justification = justification?.filter(
              (j) => j.justification !== option.justification
            );
          } else {
            setIsNotesFormatted(true);
            justification = justificationStatusNotesList.filter(
              (j) => j.justification === option.justification
            );
          }

          confirmJustificationChange(justification, notes);
        })
        .catch(() => {
          return false;
        });
    }
    confirmJustificationChange(justification, notes);
  };

  const confirmJustificationChange = (justification, notes) => {
    const finalJustification = justification.map((j) => {
      return {
        ...j,
        statusNotes: notes,
      };
    });
    setSelectedJustification(finalJustification);
    setForm({
      ...form,
      notes: notes ? notes.join('\n') : '',
      justification: finalJustification?.find(() => true)?.justification,
    });
  };

  const checkParentNotes = (option, notes, justification) => {
    const notesFromOneJustification = justificationStatusNotesList.filter(
      (j) =>
        j.statusNotes.length > 0 &&
        j.statusNotes.some((n) => notes.find((x) => x === n))
    );

    if (notesFromOneJustification.length === 1) {
      return notesFromOneJustification;
    } else if (
      justification.length > 0 &&
      justification.some(
        (s) =>
          s.justification !== option.justification &&
          s.justification !== statusJustificationTypes.MULTIPLE
      )
    ) {
      const newSelectedJustification = justificationStatusNotesList.filter(
        (j) => j.justification === statusJustificationTypes.MULTIPLE
      );
      return newSelectedJustification;
    }
    return justification;
  };

  const formatNotes = () => {
    if (isNotesFormatted) {
      return;
    }

    let justification = selectedJustification.find(() => true);
    if (justification.statusNotes.length) {
      if (justification.statusNotes.includes('\n')) {
        justification.statusNotes = justification?.statusNotes
          ?.split('\n')
          ?.map((n) => n.trim());
      }

      const formattedNotes = justificationStatusNotesList
        .filter((j) =>
          j.statusNotes.some((n) =>
            justification.statusNotes.find((x) => x === n)
          )
        )
        .map((j) => {
          let newNotes = [];
          justification.statusNotes.forEach((note) => {
            if (j.statusNotes.includes(note)) {
              newNotes.push(note);
            }
          });
          return {
            [j.justification]: newNotes,
          };
        });

      const finalNotes = JSON.stringify(formattedNotes)
        .replaceAll('},{', ' ')
        .replaceAll(':', ' : ')
        .replaceAll('"', '');

      setForm({
        ...form,
        notes: finalNotes.substring(1, finalNotes.length - 1),
      });
      setIsNotesFormatted(true);
    }
  };

  const onSelectUser = (e) => {
    setForm({ ...form, user: e.target.value });
  };

  const onClickConfirm = () => {
    const updateProjectMemberStatusInput = {
      id: decoder(currentMember?.id),
      status: form.status,
      justification: form.justification,
      statusNotes: form.notes,
      assignedTo: form.user === 'unassign' ? null : form.user,
    };
    updateProjectMemberStatus({
      variables: { input: updateProjectMemberStatusInput },
    }).then(() => {
      setIsNotesFormatted(false);
      setForm(initialForm);
      handleModalClose();
    });
  };

  const onClickCancel = () => {
    setIsNotesFormatted(false);
    setForm(initialForm);
    handleModalClose();
  };

  const [updateProjectMemberStatus] = useMutation(
    UPDATE_PROJECT_MEMBER_STATUS,
    {
      onCompleted: (data) => {
        if (data.updateProjectMemberStatus) {
          if (onAnalysisPage) {
            let updatedProjectMemberList = [...projectMembers.nodes];
            const index = projectMembers?.nodes?.findIndex(
              (pm) => pm.id === data.updateProjectMemberStatus.id
            );

            if (index === -1) {
              updatedProjectMemberList.push(data.updateProjectMemberStatus);
            } else {
              updatedProjectMemberList[index] = data.updateProjectMemberStatus;
            }
            setProjectMembers({
              ...projectMembers,
              nodes: updatedProjectMemberList,
            });
          }

          dispatch(setCurrentMember(data.updateProjectMemberStatus));
          dispatch(
            setSnackbarSuccess(
              `Status Justification has been updated successfully`
            )
          );
        } else {
          dispatch(setSnackbarError(`Failed to update Status Justification`));
        }
      },
      onError: (error) => {
        dispatch(
          setSnackbarError(
            `Error updating Status Justification: ${error.message}`
          )
        );
      },
    }
  );

  const compareEmail = (a, b) => {
    if (a.mail?.toLowerCase() > b.mail?.toLowerCase()) return 1;
    if (a.mail?.toLowerCase() < b.mail?.toLowerCase()) return -1;
    return 0;
  };

  const removeDuplicates = (array) => {
    let unique = [];
    array.forEach((element) => {
      if (!unique.map((u) => u.justification).includes(element.justification)) {
        unique.push(element);
      }
    });
    return unique;
  };

  useEffect(() => {
    if (currentProject) {
      if (msGroups?.length > 0) {
        let projectUsers = msGroups.find(
          (g) => g.displayName === currentProject?.name
        )?.members;
        if (projectUsers) {
          let membersAndNull = [...projectUsers];
          let sorted = membersAndNull.sort(compareEmail);
          // Add email = null to the top of array
          sorted.unshift({ displayName: 'Unassign', mail: 'Unassign' });
          sorted.map((u) =>
            setUserEmails((oldArray) => [
              ...oldArray,
              u.mail?.toLowerCase().replace('@deloitte.com', ''),
            ])
          );
        }
      }
    }
    //onSetJustificationDropdownOptions(currentMember?.status);
    setForm(initialForm);
  }, [currentProject]);

  useEffect(() => {
    if (currentMember?.id) {
      getAvailableProjectMemberStatuses(currentMember.status);
      let newNotes;
      if (currentMember?.statusNotes) {
        newNotes = currentMember.statusNotes
          ?.match(/\[(.+?)\]/g)
          ?.map((n) => {
            return n.substring(1, n.length - 1);
          })
          ?.join(',')
          ?.replaceAll(',', '\n');
      }

      setForm({
        ...initialForm,
        notes: newNotes || '',
      });

      const justification = justificationStatusNotesList
        .filter((j) => j.justification === currentMember?.justification)
        .map((j) => {
          return { ...j, statusNotes: newNotes ? newNotes : [] };
        });
      setSelectedJustification(justification);
      setIsReadOnly(!editableStatuses.includes(currentMember.status));
    }
  }, [currentMember]);

  const projectMemberStatusTypesDropdown = (
    <FormControl size='small' variant='outlined' fullWidth>
      <TextField
        select
        required
        value={form.status}
        onChange={onSelectStatus}
        label='Status'
        displayEmpty
        disabled={isReadOnly}
        disableAnimation
        SelectProps={{
          MenuProps: {
            sx: { maxHeight: '50%' },
          },
        }}
      >
        {statusOptions
          .filter((d) => d !== projectMemberStatusTypes.REJECTED)
          ?.map((p) => (
            <MenuItem key={p} value={p}>
              {displayElementType(p)}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );

  const statusJustificationTypesDropdown = () => {
    const renderOption = (props, option) => (
      <List {...props} component={Stack} direction='column'>
        {option.statusNotes.length ? (
          option.statusNotes.map((note, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton onClick={() => handleSelectNote(option, note)}>
                <Checkbox
                  color='primary'
                  checked={form.notes?.includes(note)}
                />
                <ListItemText>{note}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleSelectNote(option)}>
              <ListItemText>
                {displayElementType(option.justification)}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    );

    const renderTags = (value, getTagProps) => {
      var noDups = removeDuplicates(value);

      return noDups.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          color='secondary'
          key={index}
          label={displayElementType(option.justification)}
          onDelete={() => handleSelectNote(option)}
        />
      ));
    };

    return (
      <Autocomplete
        multiple
        disableClearable
        disableCloseOnSelect
        disabled={initialForm.status === form.status || isReadOnly}
        value={selectedJustification}
        options={justificationStatusNotesList.filter(
          (j) =>
            j.justification !== statusJustificationTypes.NOT_APPLICABLE &&
            j.justification !== statusJustificationTypes.MULTIPLE
        )}
        getOptionLabel={(option) =>
          displayElementType(option.justification ?? option)
        }
        groupBy={(option) => displayElementType(option.justification)}
        renderOption={(props, option) => renderOption(props, option)}
        renderInput={(params) => (
          <TextField {...params} label='Justification' required />
        )}
        renderTags={(value, getTagProps) => renderTags(value, getTagProps)}
      />
    );
  };

  const userAssignmentDropdown = !isReadOnly && (
    <TextField
      fullWidth
      disabled={initialForm.status === form.status || isReadOnly}
      select
      value={form.user || ''}
      onChange={onSelectUser}
      label='User Assignment'
      SelectProps={{
        MenuProps: {
          sx: { maxHeight: '50%' },
        },
      }}
    >
      {userEmails
        .filter((u) => u !== undefined)
        .map((u) => (
          <MenuItem key={u} value={u}>
            {u}
          </MenuItem>
        ))}
    </TextField>
  );

  const justificationNotes = (
    <TextField
      fullWidth
      maxRows={5}
      placeholder={'Justification Notes'}
      label={'Justification Notes'}
      disabled={isReadOnly}
      InputProps={{
        inputComponent: 'textarea',
        readOnly: true,
      }}
      InputLabelProps={{
        shrink: true,
        sx: { backgroundColor: 'white' },
      }}
      value={form.notes}
      rows={isReadOnly ? 10 : 5}
      multiline={isReadOnly ? true : false}
      required={
        selectedJustification?.find(() => true)?.statusNotes.length > 0 &&
        !form.notes
      }
    />
  );

  return (
    <Dialog
      open={isOpen}
      closeAfterTransition
      onClose={onClickCancel}
      title='Reason for Status Change'
      hasActions
      cancelLabel='Cancel'
      handleCancel={onClickCancel}
      submitLabel={isNotesFormatted ? 'Confirm' : 'Preview Notes'}
      handleSubmit={isNotesFormatted ? onClickConfirm : formatNotes}
      submitDisabled={
        form.status === currentMember?.status ||
        !form.user ||
        !form.justification ||
        form.justification === statusJustificationTypes.NOT_APPLICABLE ||
        (justificationStatusNotesList.some(
          (j) =>
            j.justification ===
              selectedJustification?.find(() => true)?.justification &&
            j.statusNotes.length > 0
        ) &&
          !form.notes)
      }
    >
      <Fade in={isOpen}>
        <StatusChangeContainer>
          <Grid container direction='column' spacing={2}>
            <InputContainer>
              <PaddedGrid item lg={12} xs={12}>
                {projectMemberStatusTypesDropdown}
              </PaddedGrid>
              <PaddedGrid item lg={12}>
                {statusJustificationTypesDropdown()}
              </PaddedGrid>
              <PaddedGrid item lg={12}>
                {userAssignmentDropdown}
              </PaddedGrid>
              <PaddedGrid item lg={12}>
                {justificationNotes}
              </PaddedGrid>
            </InputContainer>
          </Grid>
        </StatusChangeContainer>
      </Fade>
    </Dialog>
  );
}

StatusJustificationModal.propTypes = {
  handleModalClose: PropTypes.func,
  isOpen: PropTypes.bool,
  editableStatuses: PropTypes.array,
  projectMembers: PropTypes.shape({
    nodes: PropTypes.array,
    findIndex: PropTypes.func,
  }),
  setProjectMembers: PropTypes.func,
  isReadOnly: PropTypes.bool,
};
