import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import CardContent from 'components/CardContent';
import Dialog from 'components/Dialog';
import Divider from 'components/Divider';
import Fade from 'components/Fade';
import FormControl from 'components/FormControl';
import FormHelperText from 'components/FormHelperText';
import Grid from 'components/Grid';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import InputLabel from 'components/InputLabel';
import { displayElementType } from 'helpers/enumToType';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import {
  selectCurrentProject,
  selectCurrentProjectId,
} from 'store/Redux/slices/projectsSlice';
import {
  selectTableColumnsModalOpen,
  selectTableData,
  setStoreTableData,
  setTableColumnsModalOpen,
} from 'store/Redux/slices/dataEntrySlice';
import {
  setSnackbarSuccess,
  setSnackbarError,
  setSnackbarInfo,
} from 'store/Redux/slices/snackbarsSlice';

import { decoder, encoder } from 'helpers/graphQL_Encoder';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  LOAD_ELEMENT_TYPE_ENUMS,
  LOAD_VALUE_ELEMENTS_BY_TABLE_ID,
} from 'services/GraphQL/Queries';
import {
  ADD_TABLE_COLUMN,
  REMOVE_TABLE_COLUMN,
} from 'services/GraphQL/Mutations';
import styled from 'styled-components';
import ListItem from 'components/ListItem';
import ListItemText from 'components/ListItemText';
import List from 'components/List';

//#region Styled Components

const SmallContainer = styled('div')`
  width: 400px;
`;

const LargeContainer = styled('div')`
  width: 570px;
`;

const SFormHelperText = styled(FormHelperText)`
  display: ${(props) => (props.isHidden ? 'none' : null)};
`;

const ColumnsContainer = styled(FormControl)`
  max-height: 65vh;
  overflow: auto;
`;

const SDivider = styled(Divider)`
  height: 2px;
  margin: 5px 0px;
`;

const ElementRow = styled(Grid)`
  padding: 10px 0 10px 0;
  align-items: center;
`;

const STextField = styled(TextField)`
  &:focus-within {
    label {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const DataTypeFormControl = styled(FormControl)`
  .Mui-focused {
    color: ${(props) => props.theme.palette.primary.main};
    .MuiSelect-filled {
      color: black;
    }
  }

  .MuiFilledInput-root:after {
    border-bottom: ${(props) =>
      `2px solid ${props.theme.palette.primary.main}`};
  }
`;
//#endregion Styled Components

function AddColumnModal(props) {
  const {
    input,
    columns,
    isOpen,
    onChangeInput,
    onChangeDataType,
    onCloseModal,
    onSaveModal,
    dataTypeList,
  } = props;

  const isDuplicateName = () => {
    const elementNames = columns?.map((c) => c.name);

    for (let i = 0; i < elementNames?.length; i++) {
      if (elementNames?.includes(input?.name)) {
        return true;
      }
    }
    return false;
  };

  const isValidEntry = () => {
    if (input.name === '' || input.type === '' || isDuplicateName() === true) {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: 'transparent' },
      }}
      onClose={onCloseModal}
      title='Add a Column'
      hasActions
      handleCancel={onCloseModal}
      cancelLabel='Cancel'
      handleSubmit={() => onSaveModal(input)}
      submitLabel='Add'
      submitDisabled={isValidEntry()}
    >
      <Fade in={isOpen}>
        <SmallContainer>
          <Grid container style={{ alignItems: 'end' }}>
            <Grid item xs={7}>
              <STextField
                value={input.name}
                label='Column Name'
                onChange={onChangeInput}
              />
              <SFormHelperText isHidden={!isDuplicateName()}>
                * Column name already exists
              </SFormHelperText>
            </Grid>

            <Grid item xs={5}>
              <DataTypeFormControl variant='filled' fullWidth>
                <InputLabel>Data Type</InputLabel>
                <Select required value={input.type} onChange={onChangeDataType}>
                  {dataTypeList?.map((type) => (
                    <MenuItem key={type.name} value={type.name}>
                      {displayElementType(type.name)}
                    </MenuItem>
                  ))}
                </Select>
              </DataTypeFormControl>
            </Grid>
          </Grid>
        </SmallContainer>
      </Fade>
    </Dialog>
  );
}

function DeleteConfirmModal(props) {
  const {
    valueElements,
    index,
    handleConfirmOpen,
    handleConfirmClose,
    removeColumn,
  } = props;

  return (
    <Dialog
      open={handleConfirmOpen}
      onClose={handleConfirmClose}
      title='Value Elements Exist'
      hasActions
      cancelLabel='Cancel'
      handleCancel={() => handleConfirmClose()}
      submitLabel='Remove'
      handleSubmit={() => removeColumn(index)}
    >
      <Fade in={handleConfirmOpen}>
        <SmallContainer>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  Do you wish to continue removing Table Type Column? It will
                  also remove the <b>{valueElements.length}</b> Value Element(s)
                  shown below:
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ maxHeight: '50vh', overflow: 'scroll' }}>
                <List>
                  {valueElements?.map((elem) => (
                    <ListItem key={elem.id}>
                      <ListItemText
                        style={{ overflow: 'auto' }}
                        primary={elem.value}
                        secondary={`Document Name: ${elem.document?.originalName}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </SmallContainer>
      </Fade>
    </Dialog>
  );
}

function TableColumnEditModal(props) {
  const { selectedElement } = props;
  const dispatch = useDispatch();
  const columnsData = useSelector(selectTableData);
  const columns =
    columnsData === null ? selectedElement?.tableData : columnsData;
  const currentProject = useSelector(selectCurrentProject);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const isOpen = useSelector(selectTableColumnsModalOpen);

  const [tableTypeValueElements, setTableTypeValueElements] = useState([]);
  const [tableElementToCheck, setTableElementToCheck] = useState({
    col: {},
    index: '',
  });
  const [input, setInput] = useState({ name: '', type: '' });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isAddColumnModalOpen, setIsAddColumnModalOpen] = useState(false);
  const emailName = useSelector(selectCurrentUserEmail);
  const tableDataElementId = useRef(null);
  let sameProject = true;

  const onChangeInput = (e) => setInput({ ...input, name: e.target.value });
  const onChangeDataType = (e) => setInput({ ...input, type: e.target.value });
  const onClickAddColumn = () => setIsAddColumnModalOpen(true);
  const onCloseAddColumnModal = () => setIsAddColumnModalOpen(false);

  const [getElementTypeEnums, { data }] = useLazyQuery(LOAD_ELEMENT_TYPE_ENUMS);

  const [addColumn] = useMutation(ADD_TABLE_COLUMN, {
    onCompleted: (data) => {
      if (data?.addDataElementTableColumn) {
        onAddColumnSuccess(data.addDataElementTableColumn);
      }
    },
  });

  const [deleteColumn] = useMutation(REMOVE_TABLE_COLUMN, {
    onCompleted: (data) => {
      if (data?.removeDataElementTableColumn) {
        dispatch(setSnackbarInfo(data.removeDataElementTableColumn));
        handleClose();
      }
    },
    onError: () => {
      dispatch(setSnackbarError('An error occurred'));
    },
  });

  const [showValueElements] = useLazyQuery(LOAD_VALUE_ELEMENTS_BY_TABLE_ID, {
    onCompleted: (data) => {
      if (data.valueElementByTableId) {
        setTableTypeValueElements(data.valueElementByTableId);
        checkValueElements(data.valueElementByTableId);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getElementTypeEnums();
    tableDataElementId.current = selectedElement?.id;
  }, [selectedElement, currentProject]);

  const removeColumn = (columnIndexToDelete) => {
    let clonedCol = [...columns];

    if (clonedCol[columnIndexToDelete]?.id) {
      deleteColumn({
        variables: {
          dataElementColumnId: clonedCol[columnIndexToDelete].id,
        },
      }).then(() => {
        const updatedTableDataList = columns.filter(
          (t) => t.id !== clonedCol[columnIndexToDelete].id
        );
        dispatch(setStoreTableData(updatedTableDataList));
      });
    }
  };

  const onClickSaveColumn = (newColumn) => {
    let payload = {
      name: newColumn.name,
      type: newColumn.type,
      dataElementId: decoder(tableDataElementId.current),
      username: emailName,
    };
    addColumn({
      variables: {
        input: payload,
      },
    });
  };

  const onAddColumnSuccess = (addedElement) => {
    setInput({ name: '', type: '' });
    // Add column to project-level table element
    const updatedTableDataList = [...columns, addedElement];
    dispatch(setStoreTableData(updatedTableDataList));
    setIsAddColumnModalOpen(false);
    dispatch(
      setSnackbarSuccess(`${addedElement.name} added to table element'`)
    );
  };

  const checkValueElements = (valueElements) => {
    if (valueElements.length > 0) {
      let elements = valueElements.map(function (x) {
        return {
          projectId: x.document.projectId,
        };
      });
      sameProject = elements.every(
        (x) => x.projectId == decoder(currentProjectId)
      );

      if (sameProject) {
        handleOpen();
      } else {
        dispatch(
          setSnackbarError(
            'Error: Table Type Column has Value Elements in other Projects and cannot be deleted.'
          )
        );
      }
    } else {
      removeColumn(tableElementToCheck.index);
    }
  };

  const loadValueElements = (col, index) => {
    showValueElements({
      variables: { tableTypeColumnId: encoder('TableTypeColumn', col.id) },
    });
    setTableElementToCheck({ col: col, index: index });
  };

  const handleOpen = () => setConfirmOpen(true);
  const handleClose = () => setConfirmOpen(false);

  const renderSingleRow = (col, index) => (
    <ElementRow key={col.id} container>
      <Grid item xs={1}>
        <Typography>{index + 1}</Typography>
      </Grid>
      <Grid key={'subTableName' + index} item xs={4}>
        <Typography>{col.name}</Typography>
      </Grid>
      <Grid key={'subTableType' + index} item xs={4}>
        <Typography>{displayElementType(col.type)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Button
          primary
          variant='contained'
          onClick={() => loadValueElements(col, index)}
        >
          Remove
        </Button>
      </Grid>
    </ElementRow>
  );

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => dispatch(setTableColumnsModalOpen(false))}
        title='Edit Columns'
        hasActions
        handleCancel={() => {
          dispatch(setTableColumnsModalOpen(false));
        }}
        cancelLabel='Cancel'
        handleSubmit={onClickAddColumn}
        submitLabel='Add Column'
      >
        <Fade in={isOpen}>
          <LargeContainer>
            <Grid container>
              <ColumnsContainer fullWidth>
                <CardContent style={{ overflowY: 'auto', maxHeight: '40vh' }}>
                  <Grid container>
                    <Grid item xs={1}>
                      <Typography variant='h6'>#</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='h6'>Name</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='h6'>Type</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='h6'>Actions</Typography>
                    </Grid>
                  </Grid>
                  <SDivider />
                  {columns?.map((col, index) => renderSingleRow(col, index))}
                </CardContent>
              </ColumnsContainer>
            </Grid>
          </LargeContainer>
        </Fade>
      </Dialog>

      <AddColumnModal
        input={input}
        columns={columns}
        onChangeInput={onChangeInput}
        onChangeDataType={onChangeDataType}
        isOpen={isAddColumnModalOpen}
        onSaveModal={onClickSaveColumn}
        onCloseModal={onCloseAddColumnModal}
        dataTypeList={data?.elementTypeEnums.enumValues.filter(
          (d) => d.name !== 'TABLE'
        )}
      />
      {confirmOpen && (
        <DeleteConfirmModal
          valueElements={tableTypeValueElements}
          index={tableElementToCheck.index}
          handleConfirmOpen={handleOpen}
          handleConfirmClose={handleClose}
          removeColumn={removeColumn}
        />
      )}
    </>
  );
}

TableColumnEditModal.propTypes = {
  selectedElement: PropTypes.object,
};

AddColumnModal.propTypes = {
  columns: PropTypes.array,
  dataTypeList: PropTypes.array,
  input: PropTypes.object,
  isOpen: PropTypes.bool,
  onChangeDataType: PropTypes.func,
  onChangeInput: PropTypes.func,
  onCloseModal: PropTypes.func,
  onSaveModal: PropTypes.func,
  onSelectDataType: PropTypes.func,
};

DeleteConfirmModal.propTypes = {
  valueElements: PropTypes.array,
  index: PropTypes.string,
  handleConfirmOpen: PropTypes.func,
  handleConfirmClose: PropTypes.func,
  removeColumn: PropTypes.func,
};

const MemoizedColumnEditModal = memo(TableColumnEditModal);
export default MemoizedColumnEditModal;
