import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import DoneIcon from '@mui/icons-material/Done';
import Button from 'components/Button';
import Card from 'components/Card';
import Grid from 'components/Grid';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import { decoder } from 'helpers/graphQL_Encoder';
import CloseIcon from 'icons/Mui/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_CLUSTERS_BY_PROJECT,
  START_DATA_EXTRACTION,
  GET_DOCS_BY_CLUSTER,
  GET_DATA_ELEMENTS_BY_CLUSTER,
  GET_CLAIMS_BY_PROJECT,
  GET_DOCS_BY_CLAIM,
  GET_PRESIGNED_URLS_S3,
  WAS_EXTRACTION_REQUESTED,
} from 'services/GraphQL/Queries';
import { COMPARE_PROJECT_MEM_AND_SUPP_DATA } from 'services/GraphQL/Mutations';
import {
  selectCluster,
  selectDocument,
  setSelectedCluster,
  setSelectedDocument,
  selectProjectMember,
  setSelectedProjectMember,
  selectOnClassificationGroupsTab,
  setOnClassificationGroupsTab,
  setModalOpen,
  selectCurrentTab,
  setSelectedTab,
} from 'store/Redux/slices/dataEntrySlice';
import {
  selectCurrentProject,
  selectCurrentProjectId,
  setCurrentProjectClusters,
  selectCurrentOrganizationId,
  selectCurrentResourceName,
  defaultResourceMenu,
  selectProjectMemberStatusTypes,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarInfo,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled from 'styled-components';
import ReactTable from 'containers/ReactTable/index';
import { resources } from 'resource';
import { displayElementType, statusToEnum } from 'helpers/enumToType';
import {
  applyAWSTokenToDocument,
  handleDecodeURL,
} from 'helpers/UpdateAWSDocumentUriTokens';
import ProjectMemberAssignedOnFormatter from 'containers/ReactTable/TableComponents/ProjectMemberAssignedOnFormatter';
import _cloneDeep from 'lodash.clonedeep';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { isEmptyObject } from 'jquery';
import { DataEntrySelectTabs } from './DataEntrySelectTabs';

//#region Styled Components
const SCard = styled(Card)`
  padding: 20px;
  margin-top: ${(props) => (props.topMargin ? '10px' : null)};
`;

const SDoneIcon = styled(DoneIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const SCloseIcon = styled(CloseIcon)`
  color: rgba(244, 67, 54, 1);
`;

//#endregion Styled Components

export default function DataEntrySelect(props) {
  const sortByDesc = 'Desc_';
  const dataentrySelectSortBy = 'ReactTable_dataentryselect_SortBy';
  const dataentrySelectPMsSortBy = 'ReactTable_dataentryselectpms_SortBy';
  const resourceMenu = useSelector(selectCurrentResourceName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const clusterGroup = `${selectedResources.ANY_PAGES.CLUSTER} Group`;
  const projectMembersClaim = `${selectedResources.ANY_PAGES.PROJECT_MEMBER}`;
  const awsObject = 'amazonaws';
  const templateRequestId = '11111111-1111-1111-1111-111111111111';
  const redactionEditRequestId = '22222222-2222-2222-2222-222222222222';
  const vaSpecificPms = [redactionEditRequestId, templateRequestId];
  const availableTabs = [
    { id: 0, label: clusterGroup },
    { id: 1, label: projectMembersClaim },
    { id: 2, label: 'Template Review' },
    { id: 3, label: 'Redaction Review' },
  ];

  const defaultClusterOrder = [
    { totalDataElements: 'DESC' },
    { totalDocuments: 'DESC' },
  ];
  const defaultPmSortOrder = [{ status: 'ASC' }];
  const defaultDocOrder = [{ isTemplate: 'DESC', isComplete: 'ASC' }];

  const dispatch = useDispatch();
  const history = useHistory();
  const redirected = props?.location?.state?.redirected || false;
  const currentProject = useSelector(selectCurrentProject);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const currentCluster = useSelector(selectCluster);
  const currentDocument = useSelector(selectDocument);
  const currentProjectMember = useSelector(selectProjectMember);
  const onClassificationGroupsTab = useSelector(
    selectOnClassificationGroupsTab
  );
  const projectMemberStatus = useSelector(selectProjectMemberStatusTypes);
  const currentTab = useSelector(selectCurrentTab);

  const organizationId = useSelector(selectCurrentOrganizationId);
  const [clusters, setClusters] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);
  const [dataElements, setDataElements] = useState([]);
  const [clusterPmHeader, setClusterPmHeader] = useState('');
  const [clusterPmCols, setClusterPmCols] = useState([]);
  const [clearRowsSelected, setClearRowsSelected] = useState(false);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  //#region Table Columns
  const classificationGroupNavColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: '# of Data Elements',
      accessor: 'totalDataElements',
    },
    {
      id: 'documentNumber',
      Header: '# of Pages',
      accessor: 'totalDocuments',
    },
    {
      Header: '# of Pages Complete',
      accessor: 'totalCompleteDocuments',
    },
  ];

  const ProjectMemberNavColumns = [
    {
      Header: 'Name',
      accessor: 'originalId',
    },
    {
      id: 'documentNumber',
      Header: '# of Pages',
      accessor: (row) => row?.documents?.length,
    },
    {
      Header: '# of Pages Complete',
      accessor: (row) => row?.documents?.filter((x) => x?.isComplete)?.length,
    },
    {
      Header: 'Status',
      accessor: (row) => displayElementType(row?.status),
    },
    {
      Header: 'Assigned To',
      accessor: 'assignedTo',
    },
    {
      Header: 'Assigned On',
      accessor: 'assignedOn',
      Cell: ({ row }) =>
        row?.original?.assignedOn ? (
          <ProjectMemberAssignedOnFormatter
            assignedOn={row.original.assignedOn}
          />
        ) : null,
    },
  ];

  const documentNavColumns = useMemo(
    () => [
      {
        Header: 'Page Name',
        accessor: 'originalName',
        width: '110',
      },
      {
        Header: 'Total Values to Review',
        accessor: 'totalElementsNeedReview',
      },
      {
        Header: 'Project Member',
        accessor: (row) =>
          row?.projectMemberId === null
            ? 'N/A'
            : `${row?.projectMember?.originalId} (${displayElementType(
                row?.projectMember?.status
              )})`,
      },
      {
        Header: 'Page Complete',
        accessor: 'isComplete',
        sortType: 'basic',
        Cell: ({ value }) =>
          value ? <SDoneIcon color='primary' /> : <SCloseIcon color='error' />,
      },
      {
        Header: 'Template',
        accessor: 'isTemplate',
        sortType: 'basic',
        Cell: ({ value }) =>
          value ? <SDoneIcon color='primary' /> : <SCloseIcon color='error' />,
      },
    ],
    []
  );

  const documentProjectMemberNavColumns = useMemo(
    () => [
      {
        Header: 'Page Name',
        accessor: 'originalName',
        width: '110',
      },
      {
        Header: clusterGroup,
        accessor: (row) => row?.cluster?.name,
      },
      {
        Header: 'Total Values to Review',
        accessor: 'totalElementsNeedReview',
      },
      {
        Header: 'Page Complete',
        accessor: 'isComplete',
        sortType: 'basic',
        Cell: ({ value }) =>
          value ? <SDoneIcon color='primary' /> : <SCloseIcon color='error' />,
      },
      {
        Header: 'Template',
        accessor: 'isTemplate',
        sortType: 'basic',
        Cell: ({ value }) =>
          value ? <SDoneIcon color='primary' /> : <SCloseIcon color='error' />,
      },
    ],
    [clusterGroup]
  );
  //#endregion Table Columns

  //#region GRAPH_QL
  const [
    getClusters,
    { fetchMore: fetchMoreClusters, loading: clustersLoading },
  ] = useLazyQuery(GET_CLUSTERS_BY_PROJECT, {
    onCompleted: (data) => {
      if (data?.clusters?.nodes) {
        const filteredClusters = data.clusters.nodes.filter((c) =>
          c.documents.some((d) => !vaSpecificPms.includes(d.projectMemberId))
        );
        handleWasExtractionRequested({
          ...data.clusters,
          nodes: filteredClusters,
          totalCount: filteredClusters.length,
        });
      }
    },
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getProjectMembers,
    { fetchMore: fetchMoreProjectMembers, loading: pmsLoading },
  ] = useLazyQuery(GET_CLAIMS_BY_PROJECT, {
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getClusterDocuments,
    { fetchMore: fetchMoreClusterDocs, loading: clusterDocsLoading },
  ] = useLazyQuery(GET_DOCS_BY_CLUSTER, {
    onCompleted: (data) => {
      if (data?.documents?.nodes) {
        let decodedDocs = handleDecodeURL(data.documents.nodes);

        let documentsObject = { ...data.documents, nodes: decodedDocs };
        handlePreSignedUrlDocuments(documentsObject);
      }
    },
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getProjectMemberDocuments,
    { fetchMore: fetchMoreProjectMemberDocs, loading: pmDocsLoading },
  ] = useLazyQuery(GET_DOCS_BY_CLAIM, {
    onCompleted: (data) => {
      if (data?.documents?.nodes) {
        let decodedDocs = handleDecodeURL(data.documents.nodes);
        let documentsObject = { ...data.documents, nodes: decodedDocs };
        handlePreSignedUrlDocuments(documentsObject);
      }
    },
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [getDataElements] = useLazyQuery(GET_DATA_ELEMENTS_BY_CLUSTER, {
    onCompleted: (data) => {
      if (data?.clusterById) {
        setDataElements(data?.clusterById.dataElements);
      }
    },
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
  });

  const [startExtraction, { loading: extractionLoading }] = useLazyQuery(
    START_DATA_EXTRACTION,
    {
      onCompleted: (data) => {
        if (data !== null || data !== undefined) {
          dispatch(setSnackbarSuccess('Data Extraction Started'));
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [rerunValueElementStatus] = useMutation(
    COMPARE_PROJECT_MEM_AND_SUPP_DATA,
    {
      variables: { id: currentProjectId },
      onCompleted: (data) => {
        if (data?.compareProjectMemberAndSupplementalData) {
          dispatch(setSnackbarSuccess('Value Element Status Rerun Completed.'));
        } else {
          dispatch(setSnackbarError('Value Element Status Rerun Failed.'));
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
    }
  );

  const [wasExtractionRequested, { loading: extractionRequestedLoading }] =
    useLazyQuery(WAS_EXTRACTION_REQUESTED, {
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
      fetchPolicy: 'no-cache',
    });

  const [getPreSignedUrlList] = useLazyQuery(GET_PRESIGNED_URLS_S3, {
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
  });
  //#endregion GraphQL

  //#region USE_EFFECT
  useEffect(() => {
    if (redirected) {
      dispatch(
        setSnackbarInfo(
          `Must select ${selectedResources.ANY_PAGES.CLUSTER} Group AND Page to access Entry page`
        )
      );
    }
  }, [redirected, dispatch]);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(setSelectedCluster(null));
      dispatch(setSelectedDocument(null));
      dispatch(setSelectedProjectMember(null));

      let state = props?.location?.state;
      if (!isEmptyObject(state?.optionData?.projectMembers)) {
        setIsDataFiltered(true);
        handleTabChange(1, true);
        setProjectMembers(state.optionData.projectMembers);
        // eslint-disable-next-line no-negated-condition
      } else if (!isEmptyObject(state?.optionData?.clusters)) {
        setIsDataFiltered(true);
        handleTabChange(0, true);
        setClusters(state.optionData.clusters);
      } else {
        handleTabChange(currentTab, false);
      }
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (currentProject) {
      const currentClusters = clusters;
      if (
        currentClusters?.nodes?.filter((c) => c.id === 'UNCLUSTERED').length > 0
      ) {
        dispatch(
          setCurrentProjectClusters({
            ...currentClusters,
            nodes: currentClusters?.nodes?.filter(
              (c) => c.id !== 'UNCLUSTERED'
            ),
          })
        );
        return;
      }
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (documents || currentDocument) {
      handleScroll();
    }
  }, [documents, currentDocument]);
  //#endregion USE_EFFECT

  //#region Functions
  const handleWasExtractionRequested = (clusters) => {
    wasExtractionRequested({
      variables: {
        clusterIds: clusters.nodes.map((c) => decoder(c.id)),
      },
    }).then((res) => {
      let updatedClusterNodes = clusters?.nodes?.map((c) => {
        let wasExtracted = res.data.wasExtractionRequested.find(
          (x) => x.key === decoder(c.id)
        );
        if (wasExtracted) {
          let newNode = { ...c, ['wasExtracted']: wasExtracted.value };
          return newNode;
        }
        return c;
      });
      setClusters({ ...clusters, nodes: updatedClusterNodes });
    });
  };

  const handleFetchMoreClusters = () => {
    fetchMoreClusters({
      variables: {
        filters: {
          projectId: { eq: decoder(currentProjectId) },
          totalDocuments: { gt: 0 },
        },
        cursor: clusters.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // we are not using prev because we don't use graphQL cache for our data
        if (!fetchMoreResult) return clusters;
        let newResult = _cloneDeep(fetchMoreResult);
        newResult.clusters.nodes = [
          ...clusters.nodes,
          ...newResult.clusters.nodes,
        ];
        setClusters(newResult.clusters);
      },
    });
  };

  const handleRefetchClusterSearch = (queryValue) => {
    if (queryValue) {
      getClusters({
        variables: {
          sortOrder: defaultClusterOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            totalDocuments: { gt: 0 },
            or: [
              { name: { contains: queryValue.trim() } },
              parseInt(queryValue)
                ? { totalDataElements: { eq: parseInt(queryValue) } }
                : {},
              parseInt(queryValue)
                ? { totalDocuments: { eq: parseInt(queryValue) } }
                : {},
            ],
          },
        },
      });
    } else {
      getClusters({
        variables: {
          sortOrder: defaultClusterOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            totalDocuments: { gt: 0 },
          },
        },
      });
    }
  };

  const handlefetchMoreDocs = () => {
    if (onClassificationGroupsTab) {
      fetchMoreClusterDocs({
        variables: {
          filters: {
            clusterId: { eq: decoder(currentCluster?.id) },
          },
          sortOrder: defaultDocOrder,
          cursor: documents.pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // we are not using prev because we don't use graphQL cache for our data
          updateDocQuery(fetchMoreResult);
        },
      });
    } else {
      fetchMoreProjectMemberDocs({
        variables: {
          filters: {
            projectMemberId: { eq: decoder(currentProjectMember?.id) },
          },
          sortOrder: defaultDocOrder,
          cursor: documents.pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // we are not using prev because we don't use graphQL cache for our data
          updateDocQuery(fetchMoreResult);
        },
      });
    }
  };

  const updateDocQuery = (results) => {
    if (!results) return documents;
    let newResult = _cloneDeep(results);
    newResult.documents.nodes = [
      ...documents.nodes,
      ...newResult.documents.nodes,
    ];
    setDocuments(newResult.documents);
  };

  const handleRefetchDocumentSearch = (queryValue) => {
    if (queryValue) {
      let noPM = 'N/A';
      let notNull = queryValue.toLowerCase() !== noPM.toLowerCase();
      let statuses = statusToEnum(queryValue.trim(), projectMemberStatus);
      onClassificationGroupsTab
        ? getClusterDocuments({
            variables: {
              sortOrder: defaultDocOrder,
              filters: {
                clusterId: { eq: decoder(currentCluster?.id) },
                or: [
                  { originalName: { contains: queryValue.trim() } },
                  {
                    projectMember: {
                      or: [
                        {
                          originalId: notNull
                            ? { contains: queryValue.trim() }
                            : { eq: null },
                        },
                        statuses ? { status: { in: statuses } } : {},
                      ],
                    },
                  },
                ],
              },
            },
          })
        : getProjectMemberDocuments({
            variables: {
              sortOrder: defaultDocOrder,
              filters: {
                projectMemberId: { eq: decoder(currentProjectMember?.id) },
                or: [
                  { originalName: { contains: queryValue.trim() } },
                  { cluster: { name: { contains: queryValue.trim() } } },
                ],
              },
            },
          });
    } else {
      onClassificationGroupsTab
        ? getClusterDocuments({
            variables: {
              sortOrder: defaultDocOrder,
              filters: { clusterId: { eq: decoder(currentCluster?.id) } },
            },
          })
        : getProjectMemberDocuments({
            variables: {
              sortOrder: defaultDocOrder,
              filters: {
                projectMemberId: { eq: decoder(currentProjectMember?.id) },
              },
            },
          });
    }
  };

  const handleFetchMoreProjectMembers = () => {
    fetchMoreProjectMembers({
      variables: {
        filters: {
          projectId: { eq: decoder(currentProjectId) },
          originalId: idValueForReviewTabs(currentTab),
        },
        sortOrder: defaultPmSortOrder,
        cursor: projectMembers?.pageInfo?.endCursor,
        includeDocInfo: true,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // we are not using prev because we don't use graphQL cache for our data
        if (!fetchMoreResult) return projectMembers;
        let newResult = _cloneDeep(fetchMoreResult);
        newResult.projectMembers.nodes = [
          ...projectMembers.nodes,
          ...newResult.projectMembers.nodes,
        ];
        setProjectMembers(newResult?.projectMembers);
      },
    });
  };

  const handleRefetchProjectMemberSearch = (queryValue) => {
    let variables;
    if (queryValue) {
      let statuses = statusToEnum(queryValue, projectMemberStatus);
      variables = {
        sortOrder: defaultPmSortOrder,
        includeDocInfo: true,
        filters: {
          projectId: { eq: decoder(currentProjectId) },
          originalId: idValueForReviewTabs(currentTab),
          or: [
            { originalId: { contains: queryValue.trim() } },
            statuses
              ? {
                  status: {
                    in: statuses,
                  },
                }
              : {},
            { assignedTo: { contains: queryValue.trim() } },
          ],
        },
      };
    } else {
      variables = {
        sortOrder: defaultPmSortOrder,
        includeDocInfo: true,
        filters: {
          projectId: { eq: decoder(currentProject?.id) },
          originalId: idValueForReviewTabs(currentTab),
        },
      };
    }
    getProjectMembers({ variables: variables }).then((res) => {
      setProjectMembers(res?.data?.projectMembers);
    });
  };

  const idValueForReviewTabs = (newValue) => {
    if (newValue === 2) {
      return { eq: templateRequestId };
    } else if (newValue === 3) {
      return { eq: redactionEditRequestId };
    }
    return { nin: vaSpecificPms };
  };

  const handleFetchMoreDataEntry = () => {
    onClassificationGroupsTab
      ? handleFetchMoreClusters()
      : handleFetchMoreProjectMembers();
  };

  const handleRefetchDataSearch = (queryValue) => {
    setIsDataFiltered(false);
    setDocuments([]);
    onClassificationGroupsTab
      ? handleRefetchClusterSearch(queryValue)
      : handleRefetchProjectMemberSearch(queryValue);
  };

  const handlePreSignedUrlDocuments = (documents) => {
    let docs = documents?.nodes ? documents?.nodes : documents;
    let clonedDocs = _cloneDeep(documents);
    let alreadySignedDocs = docs.filter((d) =>
      d.documentUri.includes('AWSAccessKeyId')
    );
    let updatedDocList;

    const documentUris = docs
      .filter((d) => !alreadySignedDocs.includes(d))
      .map((d) => {
        return d.documentUri;
      });
    getPreSignedUrlList({ variables: { urlList: documentUris } }).then(
      (res) => {
        const updatedDocs = applyAWSTokenToDocument(
          res.data.preSignedUrlList,
          docs.filter((d) => !alreadySignedDocs.includes(d))
        );
        const sortedDocs = sortDocuments(updatedDocs);
        let fullDocs = alreadySignedDocs.concat(sortedDocs);

        if (currentProjectMember) {
          let newDocs = fullDocs.filter(
            (x) => x.projectMemberId == decoder(currentProjectMember?.id)
          );
          let newProjectMember = {
            ...currentProjectMember,
            documents: { ...documents, nodes: newDocs },
          };
          updatedDocList = { ...clonedDocs, nodes: newDocs };
          dispatch(setSelectedProjectMember(newProjectMember));
          setDocuments(updatedDocList);
        } else {
          updatedDocList = { ...clonedDocs, nodes: fullDocs };
          setDocuments(updatedDocList);
        }
      }
    );
  };

  const sortDocuments = (documents) => {
    let sortedDocs = [...documents];
    return sortedDocs.sort(
      (a, b) => b.totalElementsNeedReview - a.totalElementsNeedReview
    );
  };

  const handleTabChange = (newValue, isDataFiltered = false) => {
    clearSelectedRowsOnTabSwitch();
    dispatch(setSelectedTab(newValue));

    switch (newValue) {
      case 0:
        setClusterPmCols(classificationGroupNavColumns);
        setClusterPmHeader(`Select ${clusterGroup}`);
        if (isDataFiltered) {
          dispatch(setOnClassificationGroupsTab(!newValue));
          return;
        }
        dispatch(setOnClassificationGroupsTab(true));
        getClusters({
          variables: {
            sortOrder: defaultClusterOrder,
            filters: {
              projectId: { eq: decoder(currentProjectId) },
              totalDocuments: { gt: 0 },
            },
          },
        });

        break;
      case 1:
        setClusterPmCols(ProjectMemberNavColumns);
        setClusterPmHeader(`Select ${projectMembersClaim}s`);
        if (isDataFiltered) {
          dispatch(setOnClassificationGroupsTab(!newValue));
          return;
        }
        dispatch(setOnClassificationGroupsTab(false));
        getProjectMembers({
          variables: {
            filters: {
              projectId: { eq: decoder(currentProject?.id) },
              originalId: idValueForReviewTabs(newValue),
            },
            sortOrder: defaultPmSortOrder,
            includeDocInfo: true,
          },
        }).then((res) => {
          if (res?.data?.projectMembers) {
            setProjectMembers(res.data.projectMembers);
          }
        });

        break;
      case 2:
        setClusterPmCols(classificationGroupNavColumns);
        setClusterPmHeader(`Template Review`);
        dispatch(setOnClassificationGroupsTab(false));
        getProjectMembers({
          variables: {
            filters: {
              projectId: { eq: decoder(currentProject?.id) },
              originalId: { eq: templateRequestId },
            },
            sortOrder: defaultPmSortOrder,
            includeDocInfo: true,
          },
        }).then((res) => {
          if (res?.data?.projectMembers) {
            const formattedData =
              res.data.projectMembers?.nodes[0]?.documents?.map((d) => {
                return {
                  id: d.cluster?.id,
                  name: d.cluster?.name || d.projectMember?.originalId,
                  totalDataElements: d.cluster?.totalDataElements || 0,
                  totalDocuments: d.cluster?.totalDocuments || 0,
                  totalCompleteDocuments:
                    d.cluster?.totalCompleteDocuments || 0,
                };
              });
            setClusters({ ...res.data.projectMembers, nodes: formattedData });
          }
        });

        break;
      case 3:
        setClusterPmCols(ProjectMemberNavColumns);
        setClusterPmHeader(`Redaction Review`);
        dispatch(setOnClassificationGroupsTab(false));
        getProjectMembers({
          variables: {
            filters: {
              projectId: { eq: decoder(currentProject?.id) },
              originalId: { eq: redactionEditRequestId },
            },
            sortOrder: defaultPmSortOrder,
            includeDocInfo: true,
          },
        }).then((res) => {
          if (res?.data?.projectMembers) {
            setProjectMembers(res.data.projectMembers);
          }
        });

        break;

      default:
        break;
    }
  };

  const clearSelectedRowsOnTabSwitch = () => {
    setDocuments([]);
    setProjectMembers([]);
    setClusters([]);
    setClearRowsSelected(true);
    dispatch(setSelectedCluster(null));
    dispatch(setSelectedDocument(null));
  };

  const handleExtraction = () => {
    const extractionInput = dataElements?.map((d) => {
      return {
        clusterId: decoder(currentCluster?.id),
        dataElementId: decoder(d?.id),
        orgId: organizationId,
      };
    });
    startExtraction({
      variables: {
        extractionInput: extractionInput,
      },
    });
  };

  const onGroupProjectMemberSelect = (row) => {
    const newRow = row.original;
    if (onClassificationGroupsTab || currentTab === 2) {
      dispatch(setSelectedCluster(newRow));
      dispatch(setSelectedDocument(null));
      getClusterDocuments({
        variables: {
          sortOrder: defaultDocOrder,
          filters: { clusterId: { eq: decoder(row.original?.id) } },
        },
      });
      getDataElements({ variables: { id: row.original?.id } });
    } else {
      dispatch(setSelectedProjectMember(newRow));
      dispatch(setSelectedDocument(null));
      getProjectMemberDocuments({
        variables: {
          sortOrder: defaultDocOrder,
          filters: { projectMemberId: { eq: decoder(row.original?.id) } },
        },
      });
    }
  };

  const onDocSelect = (row) => {
    const newRow = row.original;
    dispatch(setSelectedDocument(newRow));
    if (!onClassificationGroupsTab) {
      dispatch(setSelectedCluster(newRow?.cluster));
    }
  };

  const handleScroll = () => {
    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onTableSort = (newRows) => {
    const newData = newRows.map((r) => r.original);

    if (newData[0]?.__typename === 'Cluster' && newData.length) {
      setClusters({ ...clusters, nodes: newData });
    }

    if (newData[0]?.__typename === 'Document' && newData.length) {
      setDocuments({ ...documents, nodes: newData });
    }
  };

  const goToDataEntry = () => {
    dispatch(setModalOpen(false));
    history.push('/dataentry', {
      awsObject: awsObject,
      clusters: clusters,
      documents: documents,
      projectMembers: projectMembers,
    });
  };

  const parentTableData = () => {
    let data = [];
    if (onClassificationGroupsTab || currentTab === 2) {
      data = clusters?.nodes;
    } else {
      data = projectMembers?.nodes;
    }
    return data;
  };
  //#endregion Functions

  const ClustersProjectMembersTable = (
    <Grid item xs={12} className='selectPage-clusterSelect'>
      <SCard>
        <DataEntrySelectTabs
          tabValue={currentTab}
          handleTabChange={handleTabChange}
          setClearRowsSelected={setClearRowsSelected}
          availableTabs={availableTabs}
          loading={clustersLoading || pmsLoading || extractionRequestedLoading}
        />
        <ReactTable
          key={currentTab}
          actions={
            (onClassificationGroupsTab || currentTab === 2) && {
              runExtraction: handleExtraction,
            }
          }
          columns={clusterPmCols}
          currentCluster={currentCluster}
          data={parentTableData() || []}
          minRows={0}
          hasMinWidth={'800px'}
          header={clusterPmHeader}
          isExtractionLoading={extractionLoading}
          isTableResetOnSelectedRow={false}
          isTableResetOnSort={false}
          onSelected={onGroupProjectMemberSelect}
          onTableSort={onTableSort}
          useRowId
          hasRerunStatusButton={onClassificationGroupsTab ? true : false}
          rerunValueElementStatus={rerunValueElementStatus}
          useCenterLastHeader={onClassificationGroupsTab}
          useCenterAlign={onClassificationGroupsTab}
          isCustomSearch={true}
          hasSearchBar={true}
          clearRowsSelected={clearRowsSelected}
          setClearRowsSelected={setClearRowsSelected}
          useSkipPageReset={true}
          hasNextPage={
            onClassificationGroupsTab
              ? clusters?.pageInfo?.hasNextPage
              : projectMembers?.pageInfo?.hasNextPage
          }
          totalData={clusters?.totalCount || projectMembers?.totalCount}
          hasPagination={false}
          tableHeight={'35vh'}
          fetchMore={handleFetchMoreDataEntry}
          refetch={handleRefetchDataSearch}
          dataLoading={
            clustersLoading || extractionRequestedLoading || pmsLoading
          }
          pageName={
            onClassificationGroupsTab ? 'dataentryselect' : 'dataentryselectpms'
          }
          defaultSortColumn={
            onClassificationGroupsTab
              ? localStorage.getItem(dataentrySelectSortBy)
              : localStorage.getItem(dataentrySelectPMsSortBy)
          }
          isDescending={
            onClassificationGroupsTab
              ? 'true' ==
                localStorage.getItem(
                  dataentrySelectSortBy +
                    sortByDesc +
                    localStorage.getItem(dataentrySelectSortBy)
                )
              : 'true' ==
                localStorage.getItem(
                  dataentrySelectPMsSortBy +
                    sortByDesc +
                    localStorage.getItem(dataentrySelectPMsSortBy)
                )
          }
          isDataFiltered={isDataFiltered}
        />
      </SCard>
    </Grid>
  );

  const DocumentsTable = (
    <Grid item xs={12}>
      <div className='selectPage-documentSelect'>
        {(currentCluster || currentProjectMember) && documents?.nodes && (
          <SCard topMargin={true}>
            <ReactTable
              key={documents}
              columns={
                onClassificationGroupsTab
                  ? documentNavColumns
                  : documentProjectMemberNavColumns
              }
              data={documents.nodes || []}
              currentCluster={currentCluster}
              hasNoDefaultSort={true}
              header='Page Navigation'
              isTableResetOnSelectedRow={false}
              isTableResetOnSort={false}
              onSelected={onDocSelect}
              onTableSort={onTableSort}
              useCenterLastHeader={true}
              useRowId
              useCenterAlign={true}
              isCustomSearch={false}
              isSecondTable={true}
              hasSearchBar={true}
              hasPagination={false}
              hasNextPage={documents?.pageInfo?.hasNextPage}
              totalData={documents?.totalCount}
              tableHeight={'35vh'}
              fetchMore={handlefetchMoreDocs}
              refetch={handleRefetchDocumentSearch}
              pageName={
                onClassificationGroupsTab ? 'dataentrypage' : 'dataentrypagepms'
              }
              dataLoading={
                onClassificationGroupsTab ? clusterDocsLoading : pmDocsLoading
              }
            />
          </SCard>
        )}
      </div>
    </Grid>
  );

  return (
    <ProjectSelectedTemplate>
      <Grid container>
        <Grid container spacing={2}>
          {ClustersProjectMembersTable}
          {DocumentsTable}
          {currentDocument ? (
            <Grid item container paddingBottom='1%'>
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  primary
                  fullWidth
                  disabled={extractionRequestedLoading}
                  onClick={() => goToDataEntry()}
                >
                  Go to Data Entry
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </ProjectSelectedTemplate>
  );
}
