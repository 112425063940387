import {
  Check,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { Autocomplete, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { ResetButton } from 'components/Button/ResetButton';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import DialogContentText from 'components/DialogContentText';
import Divider from 'components/Divider';
import Grid from 'components/Grid';
import List from 'components/List';
import ListItem from 'components/ListItem';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import InfiniteScrollToggle from 'containers/ReactTable/TableComponents/InfiniteScrollToggle';
import ManualFetchMoreButton from 'containers/ReactTable/TableComponents/ManualFetchMoreButton';
import ProjectMemberAssignedOnFormatter from 'containers/ReactTable/TableComponents/ProjectMemberAssignedOnFormatter';
import { TableSearch } from 'containers/ReactTable/TableComponents/TableSearch';
import { ViewOnPageButtons } from 'containers/ReactTable/TableComponents/ViewOnPageButtons';
import VirtualScrollChild from 'containers/ReactTable/TableComponents/VirtualScrollChild';
import { dateTimeFormatter } from 'helpers/dateTimeFormatter';
import { displayElementType, displayTitleCase } from 'helpers/enumToType';
import ProjectMemberGraphs from 'pages/Assign/ProjectMemberGraphs';
import sortTableData from 'pages/Assign/sorting/SortTableData';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import DialogActions from 'components/DialogActions';
import { TableLoadingOverlay } from 'containers/TableLoadingOverlay';

const SCard = styled(Card)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const HeadersGrid = styled(Grid)`
  padding: 5px 0px 5px 0px;
  border-bottom: 3px solid rgba(225, 226, 240, 1);
  cursor: pointer;
`;

const DataGrid = styled(Grid)`
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? 'rgba(225, 226, 240, 1)' : null};

  &:hover {
    background-color: rgba(225, 226, 240, 1);
  }
`;

const TableTypography = styled(Typography)`
  ${(props) => {
    if (props.header) {
      return `
      font-weight: bold;
      user-select: none;
      `;
    }
  }}
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableGrid = styled(Grid)`
  max-height: 50dvh;
  overflow-y: auto;
  display: flex;
`;

const ListGrid = styled(Grid)`
  max-height: 50vh;
  overflow: auto;
`;

export const DataManagementCards = (props) => {
  const {
    chartHeaderLabel = '',
    tableHeaderLabel = '',
    selectedOption = {},
    handleChartOptionChange = () => null,
    chartOptions = [],
    setChartOptions = () => null,
    defaultSortOrder = [],
    tableData = {},
    chartData = {},
    setData = () => null,
    getData = () => null,
    currentProjectId = '',
    tableFilters = null,
    membersToAssign = [],
    handleOnChartClick = () => null,
    refreshPageData = () => null,
    chartLoading = false,
    allLoading = false,
    onClickSelectAll = () => null,
    setMembersToAssign = () => null,
    getDefaultData = () => null,
    headers = [],
    onShiftClickRow = () => null,
    onClickSingleRow = () => null,
    handleFetchMoreData = () => null,
    handleRefetchDataSearch = () => null,
    chosenUser = null,
    setChosenUser = () => null,
    currentProjectUsers = [],
    confirmAssignUser = () => null,
    open = false,
    onClose = () => null,
    openAssignUsers = () => null,
    toggleUserInReviewData = () => null,
    dateCategories = [],
    setDateCategories = () => null,
    projectMemberStatus = [],
    selectedResources = {},
    getPMsForDocumentView = () => null,
  } = props;

  const [toggleInfiniteScroll, setToggleInfiniteScroll] = useState(true);
  const [uniqueGuid, setUniqueGuid] = useState();
  const [direction, setDirection] = useState();
  const [sortBy, setSortBy] = useState();

  const gridWidth = 12 / headers?.length;

  const onChartFilterScrollIntoView = () => {
    const dataList = document.getElementById('data-list');
    dataList.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSort = (event) => {
    const dir = direction === 'descending' ? 'ascending' : 'descending';
    setDirection(dir);
    setSortBy(event.target.id);
    const sortConfig = { sortBy: event.target.id, direction: dir };
    setData({
      ...tableData,
      nodes: sortTableData([...tableData.nodes], sortConfig),
    });
  };

  const SortButton = ({ direction, id }) => {
    const arrows = {
      ascending: <KeyboardArrowUp />,
      descending: <KeyboardArrowDown />,
    };
    const arrow = sortBy === id ? arrows[direction] : null;

    return (
      <Grid item xs title='Click Arrow to Remove Sort'>
        {arrow}
      </Grid>
    );
  };

  const handleScroll = (e) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.clientHeight + 5 >=
        e.currentTarget.scrollHeight &&
      tableData.pageInfo.hasNextPage
    ) {
      if (toggleInfiniteScroll) {
        handleFetchMoreData();
      }
    }
  };

  const getFormattedVal = (key, val) => {
    let formattedVal = val;
    if (key === 'status') {
      formattedVal = displayElementType(val);
    } else if (key === 'assignedOn') {
      formattedVal = <ProjectMemberAssignedOnFormatter assignedOn={val} />;
    } else if (key === 'createdOn') {
      formattedVal = dateTimeFormatter(val, true);
    } else if (key === 'clusterConfirmed' || key === 'documentStatus') {
      formattedVal = val ? <Check color='success' /> : <Close color='error' />;
    } else {
      formattedVal = val;
    }

    return formattedVal;
  };

  useEffect(() => {
    onChartFilterScrollIntoView();
  }, [tableFilters]);

  const chartOptionsMenu = (
    <Grid
      item
      container
      xs={4}
      md={2}
      textAlign='end'
      sx={{ padding: '16px 16px 0 0' }}
    >
      <Grid item xs={12}>
        <Typography fontWeight='bolder'>Chart Options:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Select value={selectedOption?.name} onChange={handleChartOptionChange}>
          {chartOptions?.map((option) => (
            <MenuItem
              key={option.id}
              value={option.name}
              disabled={option.id === selectedOption?.id}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );

  const chartsCard = (
    <Grid item xs={12}>
      <SCard>
        <Grid container alignItems='center' textAlign='center'>
          <Grid item container xs={12}>
            <Grid item xs={8} md={10} textAlign='start'>
              <CardHeader title={`${chartHeaderLabel} Chart`} />
            </Grid>
            {chartOptionsMenu}
          </Grid>
          <Grid item xs={12}>
            <CardContent>
              <ProjectMemberGraphs
                key={selectedOption?.id}
                rawData={chartData}
                currentProjectId={currentProjectId}
                defaultSortOrder={defaultSortOrder}
                handleOnChartClick={handleOnChartClick}
                onChartFilterScrollIntoView={onChartFilterScrollIntoView}
                selectedOption={selectedOption}
                chartOptions={chartOptions}
                setChartOptions={setChartOptions}
                refreshPageData={refreshPageData}
                chartLoading={chartLoading}
                toggleUserInReviewData={toggleUserInReviewData}
                dateCategories={dateCategories}
                setDateCategories={setDateCategories}
              />
            </CardContent>
          </Grid>
        </Grid>
      </SCard>
    </Grid>
  );

  const toolbar = (
    <Grid
      container
      spacing={1}
      alignItems='center'
      sx={{ padding: '0vh 1vh 0vh 1vh' }}
    >
      <Grid item sm={12} md={6}>
        <Stack
          direction='row'
          justifyContent={{ md: 'flex-start', xs: 'center' }}
          alignItems='center'
          spacing={0.5}
        >
          <Button
            size='small'
            variant='contained'
            color='success'
            disabled={membersToAssign.length === tableData?.nodes?.length}
            onClick={() => onClickSelectAll()}
          >
            Select All
          </Button>
          <Button
            size='small'
            variant='contained'
            color='error'
            disabled={membersToAssign?.length === 0}
            onClick={() => setMembersToAssign([])}
          >
            Clear Selections
          </Button>
          <Button
            size='small'
            variant='contained'
            primary
            disabled={membersToAssign?.length === 0}
            onClick={() => openAssignUsers()}
          >
            Assign {tableHeaderLabel}(s)
          </Button>
        </Stack>
      </Grid>
      <Grid item sm={8} md={4} textAlign='end'>
        <ViewOnPageButtons
          pageData={tableData}
          chartData={chartData}
          getData={getData}
          getPMsForDocumentView={getPMsForDocumentView}
          dataFilters={tableFilters}
          statuses={projectMemberStatus}
          defaultSortOrder={defaultSortOrder}
          currentProjectId={currentProjectId}
          loading={allLoading}
          selectedResources={selectedResources}
        />
      </Grid>
      <Grid item sm={4} md={2} textAlign='end'>
        <ResetButton
          dataFilters={tableFilters}
          getDefaultData={getDefaultData}
          fullWidth={true}
          setUniqueGuid={setUniqueGuid}
        />
      </Grid>
    </Grid>
  );

  const pmHeaders = headers?.map((header) => (
    <>
      <HeadersGrid
        alignItems='center'
        key={header}
        container
        xs={gridWidth}
        onClick={(e) => handleSort(e)}
      >
        <Grid item xs={sortBy === header ? 8 : 12} sx={{ padding: '2%' }}>
          <TableTypography
            header={header}
            id={header}
            title='Click Header to Sort'
          >
            {displayTitleCase(header)}
          </TableTypography>
        </Grid>
        {sortBy === header && (
          <SortButton
            direction={direction}
            id={header}
            sortBy={sortBy}
            onClick={sortBy}
          />
        )}
      </HeadersGrid>
    </>
  ));

  const dataTable = allLoading ? (
    <TableLoadingOverlay />
  ) : tableData?.nodes?.length ? (
    tableData.nodes.map((d, i) => (
      <VirtualScrollChild key={i}>
        <DataGrid
          key={i}
          container
          xs={12}
          onClick={(e) => {
            if (e.shiftKey) {
              onShiftClickRow(d);
            } else {
              onClickSingleRow(d);
            }
          }}
          selected={membersToAssign?.includes(d)}
        >
          {Object.entries(d)
            ?.filter(([key]) => headers?.includes(key))
            .map(([key, val]) => {
              let formattedVal = getFormattedVal(key, val);

              return (
                <Grid item xs={gridWidth} key={key} sx={{ padding: '1%' }}>
                  <Tooltip title={formattedVal}>
                    <div>
                      <TableTypography>{formattedVal}</TableTypography>
                    </div>
                  </Tooltip>
                </Grid>
              );
            })}
        </DataGrid>
      </VirtualScrollChild>
    ))
  ) : (
    <Grid container textAlign='center' sx={{ marginTop: 4 }}>
      <Grid item xs>
        <Typography fontWeight='bold'>No Data Available</Typography>
      </Grid>
    </Grid>
  );

  const footer = (
    <>
      {!toggleInfiniteScroll && (
        <ManualFetchMoreButton
          hasNextPage={tableData?.pageInfo?.hasNextPage}
          fetchMore={handleFetchMoreData}
        />
      )}
      <Grid item xs={12}>
        <Typography variant='body1'>
          Current row count: {tableData?.nodes?.length} of{' '}
          {tableData?.totalCount}
        </Typography>
      </Grid>
    </>
  );

  const tableCard = (
    <Grid item xs={12}>
      <SCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              alignItems='center'
              spacing={1}
              sx={{ padding: '1%' }}
            >
              <Grid item xs={10}>
                <Typography variant='h5'>
                  {tableHeaderLabel} Management
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign='end'>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography>
                      {tableHeaderLabel}(s) Selected: {membersToAssign?.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableSearch
                      key={uniqueGuid}
                      refetch={handleRefetchDataSearch}
                    />
                  </Grid>
                  <InfiniteScrollToggle
                    toggleInfiniteScroll={toggleInfiniteScroll}
                    setToggleInfiniteScroll={setToggleInfiniteScroll}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {toolbar}
          </Grid>
        </Grid>
        <CardContent>
          <Grid container xs={12}>
            {pmHeaders}
          </Grid>
          <TableGrid
            id='data-list'
            onScroll={(e) => handleScroll(e)}
            container
            xs={12}
          >
            {dataTable}
          </TableGrid>
          <Grid container textAlign='end'>
            {footer}
          </Grid>
        </CardContent>
      </SCard>
    </Grid>
  );

  const assignDialog = (
    <Dialog
      open={open}
      onClose={() => onClose()}
      title='Assign User'
      handleCancel={() => onClose()}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <DialogContentText>
              Choose a User to Assign to chosen {tableHeaderLabel}(s):
            </DialogContentText>
            <Autocomplete
              value={chosenUser}
              options={currentProjectUsers}
              getOptionLabel={(options) => options.displayName}
              onChange={(e, value) => setChosenUser(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Project Users'
                />
              )}
            />
          </Grid>
          <ListGrid item xs={12}>
            <List>
              {membersToAssign?.map((member) => (
                <>
                  <Grid item xs={12}>
                    <ListItem key={member.id} disableGutters>
                      <Grid item xs>
                        <b>Id:</b> {member.originalId}
                      </Grid>
                      <Grid item xs>
                        <b>Assigned To:</b> {member.assignedTo || 'N/A'}
                      </Grid>
                    </ListItem>
                    <Divider />
                  </Grid>
                </>
              ))}
            </List>
          </ListGrid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {chosenUser && (
          <Button
            primary
            variant='contained'
            fullWidth
            onClick={confirmAssignUser}
          >
            Assign
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  return (
    <Grid container alignItems='center' spacing={2}>
      {chartsCard}
      {tableCard}
      {assignDialog}
    </Grid>
  );
};

DataManagementCards.propTypes = {
  chartHeaderLabel: PropTypes.string,
  tableHeaderLabel: PropTypes.string,
  selectedOption: PropTypes.object,
  handleChartOptionChange: PropTypes.func,
  chartOptions: PropTypes.array,
  setChartOptions: PropTypes.func,
  defaultSortOrder: PropTypes.array,
  tableData: PropTypes.object,
  chartData: PropTypes.object,
  setData: PropTypes.func,
  getData: PropTypes.func,
  currentProjectId: PropTypes.string,
  tableFilters: PropTypes.object,
  membersToAssign: PropTypes.array,
  handleOnChartClick: PropTypes.func,
  refreshPageData: PropTypes.func,
  chartLoading: PropTypes.bool,
  allLoading: PropTypes.bool,
  onClickSelectAll: PropTypes.func,
  setMembersToAssign: PropTypes.func,
  getDefaultData: PropTypes.func,
  headers: PropTypes.array,
  onShiftClickRow: PropTypes.func,
  onClickSingleRow: PropTypes.func,
  handleFetchMoreData: PropTypes.func,
  handleRefetchDataSearch: PropTypes.func,
  chosenUser: PropTypes.object,
  setChosenUser: PropTypes.func,
  currentProjectUsers: PropTypes.array,
  confirmAssignUser: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  openAssignUsers: PropTypes.func,
  toggleUserInReviewData: PropTypes.func,
  dateCategories: PropTypes.array,
  setDateCategories: PropTypes.func,
  projectMemberStatus: PropTypes.array,
  selectedResources: PropTypes.object,
  getPMsForDocumentView: PropTypes.func,
};
