import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import TextField from 'components/TextField';
import {
  addProject,
  selectCurrentOrganizationId,
} from 'store/Redux/slices/projectsSlice';
import { setSnackbarError } from 'store/Redux/slices/snackbarsSlice';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_PROJECT } from 'services/GraphQL/Mutations';
import FormHelperText from 'components/FormHelperText';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import { GET_PROJECT_BY_NAME_AND_ORGANIZATION_ID } from 'services/GraphQL/Queries';
import { encoder } from 'helpers/graphQL_Encoder';
import { selectMsGroupData } from 'store/Redux/slices/usersSlice';

//#region Styled Components

const SFormHelperText = styled(FormHelperText)`
  display: ${(props) => (props.isHidden ? 'none' : null)};
`;

//#endregion Styled Components

function AddProjectModal(props) {
  const {
    isNewProjectOpen = false,
    setNewProjectOpen = () => null,
    setAddedProject = () => null,
    closeNewProject = () => null,
  } = props;

  const dispatch = useDispatch();
  const organizationId = useSelector(selectCurrentOrganizationId);
  const emailName = useSelector(selectCurrentUserEmail);
  const msGroups = useSelector(selectMsGroupData);

  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectId, setNewprojectId] = useState('');
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [isExistingProjectName, setIsExistingProjectName] = useState(null);
  const [
    hideExistingGroupNameErrorMessage,
    setHideExistingGroupNameErrorMessage,
  ] = useState(true);

  const [addProjectMutation] = useMutation(ADD_PROJECT);
  const [getProjectByName] = useLazyQuery(
    GET_PROJECT_BY_NAME_AND_ORGANIZATION_ID,
    {
      onError: (error) => {
        dispatch(setSnackbarError(`${error.message}`));
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  const submitProject = () => {
    addProjectMutation({
      variables: {
        project: {
          id: newProjectId,
          name: newProjectName,
          organizationId: organizationId,
          username: emailName,
        },
      },
    }).then((res) => {
      dispatch(addProject(res.data.addProject));
      setAddedProject(res.data.addProject);
    });
    handleClose();
  };

  const handleClose = () => {
    setNewProjectOpen(false);
    setNewProjectName('');
    setDisplayConfirm(false);
    setHideExistingGroupNameErrorMessage(true);
  };

  const checkGroupNameExists = (e) => {
    if (e.target.value?.length === 0) {
      setHideExistingGroupNameErrorMessage(true);
      return;
    }
    let groupId = msGroups.find(
      (x) => x?.displayName.toLowerCase() === e.target?.value?.toLowerCase()
    )?.id;
    if (!groupId) {
      setHideExistingGroupNameErrorMessage(false);
    } else {
      setHideExistingGroupNameErrorMessage(true);
    }
    setNewprojectId(groupId);
  };
  const checkExistingProjectName = (e) => {
    setNewProjectName(e.target.value);
    if (e.target.value?.length === 0) {
      setIsExistingProjectName(false);
    }
    getProjectByName({
      variables: {
        organizationId: encoder('Organization', organizationId),
        name: e.target.value,
      },
    }).then((result) => {
      if (
        result?.data?.projectByNameAndOrganizationId?.name === e.target.value
      ) {
        setIsExistingProjectName(true);
      } else {
        setIsExistingProjectName(false);
      }
    });
  };

  const projectForm = (
    <Dialog
      open={isNewProjectOpen}
      onClose={closeNewProject}
      title='Create a new project'
      handleCancel={handleClose}
      submitLabel='Agree'
      cancelLabel='Cancel'
      handleSubmit={() => setDisplayConfirm(true)}
      hasActions
      submitDisabled={
        !newProjectName ||
        isExistingProjectName ||
        !hideExistingGroupNameErrorMessage
      }
    >
      <TextField
        id='new-project-name'
        label='Name'
        variant='standard'
        value={newProjectName}
        onChange={(e) => {
          checkExistingProjectName(e);
          checkGroupNameExists(e);
        }}
      />
      {!newProjectName && (
        <SFormHelperText>Please enter a project name</SFormHelperText>
      )}
      {isExistingProjectName && (
        <SFormHelperText style={{ color: 'red' }}>
          A project with that name already exists
        </SFormHelperText>
      )}
      {!hideExistingGroupNameErrorMessage && (
        <SFormHelperText style={{ color: 'red' }}>
          The project name must be the same as an existing group name.
        </SFormHelperText>
      )}
    </Dialog>
  );

  const confirmScreen = (
    <Dialog
      open={isNewProjectOpen}
      onClose={closeNewProject}
      title='Confirm New Project'
      handleCancel={() => setDisplayConfirm(false)}
      handleSubmit={submitProject}
      submitLabel='Confirm'
      cancelLabel='Go Back'
      hasActions
    >
      {newProjectName}
    </Dialog>
  );

  return (
    <Dialog
      open={isNewProjectOpen}
      onClose={closeNewProject}
      handleCancel={closeNewProject}
    >
      {displayConfirm ? confirmScreen : projectForm}
    </Dialog>
  );
}

AddProjectModal.propTypes = {
  closeNewProject: PropTypes.func,
  isNewProjectOpen: PropTypes.bool,
  setAddedProject: PropTypes.func,
  setNewProjectOpen: PropTypes.func,
};

export default AddProjectModal;
