import { useEffect, useRef, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import BackupIcon from '@mui/icons-material/Backup';
import InfoIcon from '@mui/icons-material/Info';
import { rgba } from 'polished';
import Grid from 'components/Grid';
import Dialog from 'components/Dialog';
import List from 'components/List';
import ListItemText from 'components/ListItemText';
import Typography from 'components/Typography';
import ErrorMessage from 'containers/Messages/ErrorMessage';
import ConfirmationModal from 'containers/Modals/ConfirmationModal';
import { decoder } from 'helpers/graphQL_Encoder';
import ImportErrors from 'pages/Records/modals/ImportErrors';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_PROJECT_MEMBERS,
  LOAD_GLOBAL_ELEMENTS,
} from 'services/GraphQL/Queries';
import {
  ADD_DATA_ELEMENT,
  ADD_OR_UPDATE_PROJECT_MEMBER,
  ADD_OR_UPDATE_SUPPLEMENTAL_DATA,
  UPDATE_VALUE_ELEMENT_VALUE_USER_LIST,
} from 'services/GraphQL/Mutations';
import {
  loadDataElements,
  selectCurrentOrganizationId,
  selectCurrentProjectId,
  setProjectMemberDocuments,
  setProjectMemberElements,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarInfo,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled from 'styled-components';
import ReactTable from 'containers/ReactTable/index';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';

//#region Styled Components
const DropZoneBox = styled(Grid)`
  width: 100%;
  border-style: dashed;
  border-radius: 8px;
  background: ${(props) => rgba(props.theme.palette.secondary.main, 0.1)};
  height: calc(70vh);
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImportControls = styled(Grid)`
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  margin: 0;
`;

const FileInstructions = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 700;
`;

const PreviewTable = styled(ReactTable)`
  height: 300px;
`;

//#endregion Styled Components

// #region  Constants
const typeValues = [
  'TEXT',
  'NUMBER',
  'DECIMAL',
  'DATE_TIME',
  'Currency',
  'SSN',
];

const modalTypes = [
  {
    type: 'supplemental',
  },
  {
    type: 'element',
  },
];

const dataElementCsvTable = [
  {
    Header: 'Title',
    accessor: 'Title',
  },
  {
    Header: 'Type',
    accessor: 'Type',
  },
  {
    Header: 'Description',
    accessor: 'Description',
  },
];

const projectMemberCsvTable = [
  {
    Header: 'Project Member Id',
    accessor: 'ProjectMemberId',
  },
  {
    Header: 'Document Name',
    accessor: 'DocumentName',
  },
];

const supplementaryCsvTable = [
  {
    Header: 'Project Member Id',
    accessor: 'ProjectMemberId',
  },
  {
    Header: 'Data Element Name',
    accessor: 'DataElementName',
  },
  {
    Header: 'Value',
    accessor: 'Value',
  },
];

const ValueElementUserCsvTable = [
  {
    Header: 'Document Name',
    accessor: 'DocumentName',
  },
  {
    Header: 'Data Element Name',
    accessor: 'DataElementName',
  },
  {
    Header: 'Value Element Id',
    accessor: 'ValueElementId',
  },
  {
    Header: 'Current Value',
    accessor: 'CurrentValue',
  },
  {
    Header: 'New Value',
    accessor: 'NewValue',
  },
];
// #endregion

export default function ImportModal(props) {
  const { importOpen, setImportOpen, modalType } = props;

  const dispatch = useDispatch();
  const [importedData, setImportedData] = useState([]);
  const [submitReady, setSubmitReady] = useState(false);
  const [noProjectId, setNoProjectId] = useState(true);
  const [emptyCsvFields, setEmptyCsvFields] = useState(false);
  const [duplicateDocNames, setDuplicateDocNames] = useState(false);
  const [duplicateDataElements, setDuplicateDataElements] = useState(false);
  const [duplicateValueElementIds, setDuplicateValueElementIds] =
    useState(false);
  const [hasIncorrectColumns, setHasIncorrectColumns] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [wrongModal, setWrongModal] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  // Errors
  const [titleError, setTitleError] = useState([]);
  const [typeError, setTypeError] = useState([]);
  const [dataErrors, setDataErrors] = useState([]);
  const [descriptionError, setDescriptionError] = useState([]);
  const [valueMismatchError, setValueMismatchError] = useState([]);
  const [headerError, setHeaderError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);

  const organizationId = useSelector(selectCurrentOrganizationId);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const emailName = useSelector(selectCurrentUserEmail);

  const isSupplementalModal = modalType === 'supplemental';

  const modalRef = useRef();

  //#region GRAPHQL FUNCTIONS
  const [loadElements] = useLazyQuery(LOAD_GLOBAL_ELEMENTS, {
    onCompleted: (data) => {
      dispatch(loadDataElements(data?.dataElements));
    },
  });

  const [addDataElementMutation] = useMutation(ADD_DATA_ELEMENT, {
    onCompleted: (data) => {
      if (data?.addDataElement) {
        loadElements();
        dispatch(setSnackbarSuccess('Element(s) uploaded successfully'));
      }
    },
    onError: (error) => dispatch(setSnackbarError(error.message)),
  });
  const [addOrUpdateProjectMemberMutation] = useMutation(
    ADD_OR_UPDATE_PROJECT_MEMBER,
    {
      onCompleted: (data) => {
        if (data?.addOrUpdateProjectMember) {
          closeImport();
          dispatch(
            setSnackbarSuccess(
              'Document(s) added and assigned to Project Member(s) successfully'
            )
          );
          dispatch(
            setProjectMemberDocuments({
              members: data.addOrUpdateProjectMember,
            })
          );
          getProjectMembers({
            variables: {
              filters: {
                projectId: { eq: decoder(currentProjectId) },
              },
            },
          });
        }
      },
      onError: (error) => dispatch(setSnackbarError(error.message)),
    }
  );
  const [addOrUpdateSupplementalDataMutation] = useMutation(
    ADD_OR_UPDATE_SUPPLEMENTAL_DATA,
    {
      onCompleted: (data) => {
        if (data?.addOrUpdateProjectMemberSupplementalData) {
          closeImport();
          dispatch(
            setSnackbarSuccess(
              'Data Element and Values added and assigned to Project Member(s) successfully'
            )
          );
          dispatch(
            setProjectMemberElements({
              elements: data.addOrUpdateProjectMemberSupplementalData,
            })
          );
        }
      },
      onError: (error) => {
        collectErrors(error.message);
      },
    }
  );

  const [updateValueElementValueFromUser] = useMutation(
    UPDATE_VALUE_ELEMENT_VALUE_USER_LIST,
    {
      onCompleted: (data) => {
        if (data.updateValueElementValueUserList.length) {
          dispatch(setSnackbarInfo('Value Elements partially updated.'));
          const valueElementErrors = data.updateValueElementValueUserList.map(
            (u) => {
              return `ValueElementId: ${decoder(
                u.id
              )} - Potential Current Value: ${u.value}`;
            }
          );
          setValueMismatchError(valueElementErrors);
        } else {
          dispatch(setSnackbarSuccess('Value Elements Updated Successfully'));
          closeImport();
        }
      },
      onError: (error) => dispatch(setSnackbarError(error.message)),
    }
  );

  const [getProjectMembers] = useLazyQuery(GET_PROJECT_MEMBERS);

  const submitElements = () => {
    const dataElementsToAdd = importedData.map(function (row) {
      return {
        orgId: organizationId,
        name: row.Title,
        type: row.Type,
        description: row.Description,
        username: emailName,
      };
    });
    addDataElementMutation({
      variables: {
        inputs: dataElementsToAdd,
      },
    });
  };

  const submitImportData = () => {
    let valueElementImport = importedData.some((x) => {
      if (Object.prototype.hasOwnProperty.call(x, 'ValueElementId')) {
        return true;
      }
    });
    let projectMember = importedData.some((x) => {
      if (Object.prototype.hasOwnProperty.call(x, 'DocumentName')) {
        return true;
      }
    });
    if (projectMember) {
      if (valueElementImport) {
        const valueElementImportData = importedData.map((x) => {
          return {
            valueElementId: x.ValueElementId,
            projectId: decoder(currentProjectId),
            currentValue: x.CurrentValue,
            newValue: x.NewValue,
            dataElementName: x.DataElementName,
            documentName: x.DocumentName,
            currentUser: emailName,
          };
        });

        updateValueElementValueFromUser({
          variables: { inputs: valueElementImportData },
        });
      } else {
        const projectMemberData = importedData.map(function (row) {
          return {
            id: row.ProjectMemberId,
            projectId: decoder(currentProjectId),
            documentName: row.DocumentName,
            username: emailName,
          };
        });
        addOrUpdateProjectMemberMutation({
          variables: {
            inputs: projectMemberData,
          },
        });
      }
    } else {
      let supplementalData = importedData.map(function (row) {
        return {
          id: row.ProjectMemberId,
          projectId: decoder(currentProjectId),
          dataElementName: row.DataElementName,
          value: row.Value,
          username: emailName,
        };
      });
      addOrUpdateSupplementalDataMutation({
        variables: {
          inputs: supplementalData,
        },
      });
    }
  };
  //#endregion

  //#region LIFECYCLE HOOKS
  useEffect(() => {
    clearModal();
  }, [modalType]);

  useEffect(() => {
    checkForProjectId();
    if (currentProjectId) {
      getProjectMembers({
        variables: {
          filters: {
            projectId: { eq: decoder(currentProjectId) },
          },
        },
      });
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (!importOpen) {
      clearModal();
    }
  }, [importOpen]);
  //#endregion

  const closeImport = () => {
    setImportOpen(false);
    clearModal();
  };

  function toggleConfirmModal() {
    setConfirmModal(!confirmModal);
  }

  const checkForEmptyFields = (col) => {
    let valueElementImport = Object.prototype.hasOwnProperty.call(
      col,
      'ValueElementId'
    );
    if (valueElementImport) return;

    let emptyValues = Object.values(col).some((x) => x === 0 || !x.trim());
    if (emptyValues) {
      setEmptyCsvFields(emptyValues);
      return;
    }
  };

  const hasDuplicates = (array) => {
    return array.some((item, idx) => array.indexOf(item) != idx);
  };

  const checkForDuplicateDocNames = (file) => {
    let names = file.map(function (item) {
      return item.DocumentName;
    });

    hasDuplicates(names)
      ? setDuplicateDocNames(true)
      : setDuplicateDocNames(false);
  };

  const checkForDuplicateDataElements = (file) => {
    let lookup = file.reduce((a, e) => {
      a[e.ProjectMemberId] = ++a[e.ProjectMemberId] || 0;
      return a;
    }, {});
    let duplicateIds = file.filter((e) => lookup[e.ProjectMemberId]);

    let foundDup = dataElementsDuplicateLoop(duplicateIds);

    foundDup ? setDuplicateDataElements(true) : setDuplicateDataElements(false);
  };

  const checkForDuplicateValueElementIds = (file) => {
    let lookup = file.reduce((a, e) => {
      a[e.ValueElementId] = ++a[e.ValueElementId] || 0;
      return a;
    }, {});
    let duplicateIds = file.filter((e) => lookup[e.ValueElementId]);

    duplicateIds.length > 1
      ? setDuplicateValueElementIds(true)
      : setDuplicateValueElementIds(false);
  };

  const dataElementsDuplicateLoop = (duplicateIds) => {
    let foundDup = false;
    for (let i = 0; i < duplicateIds.length; i++) {
      // nested for loop
      for (let j = 0; j < duplicateIds.length; j++) {
        // prevents the element from comparing with itself
        if (i !== j) {
          // check if elements' values are equal
          if (
            duplicateIds[i].ProjectMemberId ==
              duplicateIds[j].ProjectMemberId &&
            duplicateIds[i].DataElementName == duplicateIds[j].DataElementName
          ) {
            // duplicate element present
            foundDup = true;
            // terminate inner loop
            break;
          }
        }
      }
      // terminate outer loop
      if (foundDup) {
        break;
      }
    }
    return foundDup;
  };

  const collectErrors = (error) => {
    setDataErrors((prev) => [...prev, error]);
  };

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [dataErrors]);

  const checkImportType = () => {
    toggleConfirmModal();
    switch (modalType) {
      case modalTypes[0].type:
        submitImportData();
        break;
      default:
        submitElements();
        break;
    }
  };

  const checkForProjectId = () => {
    if (isSupplementalModal) {
      return currentProjectId == null
        ? setNoProjectId(true)
        : setNoProjectId(false);
    } else {
      setNoProjectId(false);
    }
  };

  const clearModal = () => {
    setTableHeaders([]);
    setImportedData([]);
    setSubmitReady(false);
    setDuplicateDocNames(false);
    setDuplicateDataElements(false);
    setEmptyCsvFields(false);
    setFileTypeError(false);
    setHeaderError(false);
    setWrongModal(false);
    setHasIncorrectColumns(false);
    setNoProjectId(false);
    setDuplicateValueElementIds(false);
    setDataErrors([]);
    setTitleError([]);
    setValueMismatchError([]);
    setTypeError([]);
  };

  // #region  File upload functionality
  const parseFile = (file) => {
    clearModal();
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setImportedData(result.data);
        validateData(result.data);
        checkForProjectId();
      },
    });
  };

  const validateData = (file) => {
    let titleArray = [];
    let typeArray = [];
    let descriptionArray = [];
    let hasHeaderErrors = false;

    if (
      file[0] === undefined ||
      (file[0].ProjectMemberId === undefined &&
        file[0].Title === undefined &&
        file[0].DocumentName === undefined)
    ) {
      setHasIncorrectColumns(true);
      return;
    }

    file.forEach((item) => {
      if (item.Title) {
        if (isSupplementalModal) {
          setWrongModal(true);
        }
        setTableHeaders(dataElementCsvTable);
        if (item.Title?.length > 50) {
          titleArray.push(item.Title);
        } else if (!typeValues.includes(item.Type)) {
          typeArray.push(item.Type);
        } else if (item.Description.length > 500) {
          descriptionArray.push(item.Description);
        }
      } else if (isSupplementalModal) {
        checkForEmptyFields(item);
        if (item.NewValue) {
          checkForDuplicateValueElementIds(file);
          setTableHeaders(ValueElementUserCsvTable);
        } else if (item.DocumentName) {
          checkForDuplicateDocNames(file);
          setTableHeaders(projectMemberCsvTable);
        } else {
          checkForDuplicateDataElements(file);
          setTableHeaders(supplementaryCsvTable);
        }
      } else if (!item.Title) {
        hasHeaderErrors = true;
        setHeaderError(true);
      }
    });

    setTitleError(titleArray);
    setTypeError(typeArray);
    setDescriptionError(descriptionArray);

    const uploadHasErrors =
      titleArray.length ||
      typeArray.length ||
      descriptionArray.length ||
      hasHeaderErrors;

    if (uploadHasErrors) {
      setSubmitReady(false);
    } else {
      setSubmitReady(true);
    }
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    fileRejections.forEach((rejection) => {
      rejection.file.error = 'File must be .csv format';
      setFileTypeError(rejection.file.error);
    });
    acceptedFiles.forEach((file) => {
      parseFile(file);
    });
  };

  const onDropRejected = (files) => {
    if (files.length > 1) {
      dispatch(
        setSnackbarError('Multiple Files Selected: Please Select ONE File')
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    accept: '.csv',
  });
  // #endregion

  //#region JSX

  const titleErrorMessage = () => <ErrorMessage errorList={titleError} />;
  const typeErrorMessage = () => <ErrorMessage errorList={typeError} />;
  const dataErrorMessage = () => (
    <ErrorMessage
      errorList={dataErrors}
      errorMessage={`${dataErrors.length} Documents do NOT exist in our system`}
    />
  );
  const valueMismatchErrorMessage = () => (
    <ErrorMessage
      errorList={valueMismatchError}
      errorMessage={`The following data did NOT match any value elements in our database:`}
    />
  );
  const fileErrorMessage = () => <ErrorMessage errorList={fileTypeError} />;
  const descriptionErrorMessage = () => (
    <ErrorMessage errorList={descriptionError} />
  );
  const headerErrorMessage = () => (
    <ErrorMessage errorMessage={'Please check the header rows in your file.'} />
  );

  function Preview() {
    return (
      <PreviewTable
        dataCy={'preview-table'}
        columns={tableHeaders}
        data={importedData}
        hasSearchBar={false}
      />
    );
  }

  const modalHeader = () => {
    let elementType = modalType == modalTypes[1].type;
    return (
      <Grid item textAlign='center'>
        <FileInstructions>
          File must be .csv format. For reach row, ALL or NO columns must be
          filled in
          {elementType
            ? ' and contain a Header Row with the following values: Title, Type, Description'
            : null}
        </FileInstructions>
        {elementType ? (
          <Typography>
            <List dense>
              <ListItemText>
                <b>Title: </b> 50 Characters Max
              </ListItemText>
              <ListItemText>
                <b>Type: </b> TEXT, NUMBER, DECIMAL, DATE_TIME, Currency, SSN
              </ListItemText>
              <ListItemText>
                <b>Description: </b>500 Characters Max
              </ListItemText>
            </List>
          </Typography>
        ) : (
          <Typography variant='caption'>
            <InfoIcon /> Make sure CSV has either of the following column
            headers: <br />
            <b> Project Member Document Upload </b>: ProjectMemberId,
            DocumentName <br />
            <b>Supplemental Data Upload </b>: ProjectMemberId, DataElementName,
            Value <br />
            <b>Value Element User Upload </b>: DocumentName, DataElementName,
            ValueElementId, CurrentValue, NewValue
          </Typography>
        )}
      </Grid>
    );
  };
  //#endregion

  function DropZoneText() {
    return (
      <Typography style={{ cursor: 'default' }}>
        {isDragActive
          ? 'Drop the file here ...'
          : 'Drag and drop a file here, or browse.'}
      </Typography>
    );
  }

  const submitButtonIsDisabled =
    duplicateDataElements ||
    !submitReady ||
    noProjectId ||
    duplicateDocNames ||
    emptyCsvFields ||
    duplicateValueElementIds ||
    wrongModal;

  return (
    <Dialog
      open={importOpen}
      onClose={closeImport}
      title='Import Data'
      handleCancel={() => closeImport()}
      submitLabel='Submit'
      cancelLabel='Cancel'
      submitDisabled={submitButtonIsDisabled}
      handleSubmit={() => toggleConfirmModal()}
      hasActions
    >
      <ImportControls container spacing={2}>
        {modalHeader()}
        <Grid item xs={12} textAlign='center' sx={{ width: '100%' }}>
          <Grid container>
            {titleError?.length ? titleErrorMessage() : null}
            {typeError?.length ? typeErrorMessage() : null}
            {descriptionError?.length ? descriptionErrorMessage() : null}
            {dataErrors?.length ? dataErrorMessage() : null}
            {valueMismatchError?.length ? valueMismatchErrorMessage() : null}
          </Grid>
        </Grid>
        <DropZoneBox item xs={12} {...getRootProps()}>
          <input data-cy='pm-upload' {...getInputProps()} />
          <BackupIcon fontSize='large' />
          <DropZoneText isDragActive={isDragActive} />
        </DropZoneBox>
        {submitReady && importedData ? <Preview /> : null}

        <Grid item xs={12}>
          {fileTypeError ? fileErrorMessage : null}
          {headerError ? headerErrorMessage() : null}
          <ImportErrors
            hasDuplicateDataElements={duplicateDataElements}
            hasDuplicateValueElementIds={duplicateValueElementIds}
            hasDuplicateDocNames={duplicateDocNames}
            hasEmptyCsvFields={emptyCsvFields}
            hasIncorrectColumns={hasIncorrectColumns}
            hasNoProjectId={noProjectId}
            importedData={importedData}
            isSupplementalModal={isSupplementalModal}
            isWrongModal={wrongModal}
          />
          <ConfirmationModal
            isOpen={confirmModal}
            onModalConfirmClick={checkImportType}
            onModalExitClick={toggleConfirmModal}
            formType={'add'}
          />
        </Grid>
      </ImportControls>
    </Dialog>
  );
}

ImportModal.propTypes = {
  importOpen: PropTypes.any,
  modalType: PropTypes.any,
  setImportOpen: PropTypes.func,
};
