import { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import LightTooltip from 'components/Tooltip/LightToolTip';
import RightArrow from 'icons/LeftArrow';
import RightArrowDisabled from 'icons/RightArrow_disabled';
import _isEqual from 'lodash.isequal';
import {
  ElementDetailsCard as ElementDetailsCardContent,
  ElementsDropdownCard as DropdownCardContent,
  StagedElementsCard as AssignedElementsCardContent,
} from 'pages/ElementAssignment/cards';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_DATA_ELEMENTS_BY_PROJECT,
  LOAD_GLOBAL_ELEMENTS,
} from 'services/GraphQL/Queries';
import {
  loadDataElements,
  selectCurrentProjectId,
  selectDataTypes,
  selectElements,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled from 'styled-components';
import { decoder } from 'helpers/graphQL_Encoder';
import { BULK_ADD_PROJECT_DATA_ELEMENT } from 'services/GraphQL/Mutations';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';

//#region Styled Components
const RootCard = styled(Card)`
  height: auto;
`;

const FirstCard = styled(Card)`
  border: ${(props) => `3px solid ${props.theme.palette.primary.main}`};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SCard = styled(Card)`
  border: ${(props) => `3px solid ${props.theme.palette.primary.main}`};
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const GridContainer = styled(Grid)`
  justify-content: center;
  padding: 10px;
`;

const StageButton = styled(IconButton)`
  padding: 0;
  background-color: transparent;
  margin-left: 15px;
  margin-block: auto;
  &:hover {
    background-color: transparent;
  }
`;

//#endregion Styled Components

function RightArrowButton(props) {
  const { currentProjectId, handleStageElements } = props;

  return (
    <LightTooltip
      contentText={
        currentProjectId
          ? `Click here to stage elements to the project`
          : `Select a project first to stage elements`
      }
      placement='top'
      lightToolTipTargetItem={
        currentProjectId ? (
          <StageButton
            disableRipple
            size='small'
            onClick={handleStageElements}
            className='elementAssignmentPage-stageButton'
          >
            <RightArrow />
          </StageButton>
        ) : (
          <IconButton style={{ backgroundColor: 'transparent' }} size='small'>
            <RightArrowDisabled />
          </IconButton>
        )
      }
    />
  );
}

export default function ElementAssignment() {
  const dispatch = useDispatch();

  const currentProjectId = useSelector(selectCurrentProjectId);
  const globalElements = useSelector(selectElements);
  const typeList = useSelector(selectDataTypes);
  const emailName = useSelector(selectCurrentUserEmail);

  const [currentProjectElements, setCurrentProjectElements] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // details window
  const [currentlySelectedElement, setCurrentlySelectedElement] = useState('');
  const [hasDetailsWindowOpen, setDetailsWindowToggle] = useState(false);

  const previouslySelectedElement = useRef({});

  //#region graphQL
  const [getProjectDataElements] = useLazyQuery(GET_DATA_ELEMENTS_BY_PROJECT, {
    onCompleted: (data) => {
      if (data?.projects[0]?.dataElements?.nodes) {
        setCurrentProjectElements(data.projects[0].dataElements);
        getGlobalDataElements({
          variables: { id: decoder(currentProjectId) },
        });
      }
    },
    onError: (error) => gqlErrorHandler(error),
    fetchPolicy: 'no-cache',
  });

  const [getGlobalDataElements] = useLazyQuery(LOAD_GLOBAL_ELEMENTS, {
    onCompleted: (data) => {
      if (data?.dataElements?.nodes) {
        dispatch(loadDataElements(data.dataElements));
      }
    },
  });

  const [bulkAddProjectDataElements] = useMutation(
    BULK_ADD_PROJECT_DATA_ELEMENT,
    {
      onCompleted: (data) => {
        if (data?.addProjectDataElementList?.length) {
          let newElements = globalElements?.nodes?.filter((g) =>
            data.addProjectDataElementList.find(
              (d) => d.dataElementId === decoder(g.id)
            )
          );
          setCurrentProjectElements({
            ...currentProjectElements,
            nodes: [...currentProjectElements.nodes, ...newElements],
          });
          setSelectedOptions([]);
          dispatch(setSnackbarSuccess(`Element added to project!`));
        }
      },
      onError: (error) =>
        dispatch(setSnackbarError(`An error occurred ${error.message}`)),
    }
  );

  async function gqlErrorHandler(error) {
    if (currentProjectId) {
      dispatch(setSnackbarError(`An error occurred: ` + error));
    }
  }
  //#endregion graphQL
  useEffect(() => {
    if (currentProjectId) {
      setSelectedOptions([]);
      getProjectDataElements({
        variables: { id: decoder(currentProjectId) },
      });
      setDetailsWindowToggle(false);
    }
  }, [currentProjectId]);

  const renderElementDetailWindow = (selectedElement) => {
    _isEqual(selectedElement, currentlySelectedElement)
      ? setDetailsWindowToggle(!hasDetailsWindowOpen)
      : setDetailsWindowToggle(true);
    setCurrentlySelectedElement(selectedElement);
    Object.assign(previouslySelectedElement.current, selectedElement);
  };

  const handleAssignProjectDataElements = () => {
    if (selectedOptions?.length) {
      const elementsToAdd = selectedOptions.map(function (elem) {
        return {
          projectId: decoder(currentProjectId),
          dataElementId: decoder(elem.id),
          username: emailName,
        };
      });
      bulkAddProjectDataElements({ variables: { inputList: elementsToAdd } });
    }
  };

  const handleSelectedOptions = (values) => setSelectedOptions(values);

  return (
    <>
      <ProjectSelectedTemplate currentProjectId={currentProjectId}>
        <RootCard>
          <CardHeader title='Element Assignment' />
          <GridContainer container spacing={2}>
            <Grid
              item
              lg={hasDetailsWindowOpen ? 4 : 6}
              md={hasDetailsWindowOpen ? 4 : 6}
              xs={12}
            >
              <FirstCard className='elementAssignmentPage-elementDropdownCard'>
                <DropdownCardContent
                  currentProjectElements={currentProjectElements?.nodes}
                  selectedOptions={selectedOptions}
                  options={globalElements?.nodes || []}
                  globalElements={globalElements}
                  dataTypeList={typeList}
                  handleSelectedOptions={handleSelectedOptions}
                  isDetailsWindowOpen={hasDetailsWindowOpen}
                  clusterElementDialogOpen={false}
                  handleAssignDataElements={handleAssignProjectDataElements}
                />
              </FirstCard>
            </Grid>
            <Grid
              item
              lg={hasDetailsWindowOpen ? 4 : 6}
              md={hasDetailsWindowOpen ? 4 : 6}
              xs={12}
            >
              <SCard>
                <AssignedElementsCardContent
                  stagedElements={currentProjectElements?.nodes || []}
                  initialElements={globalElements?.nodes || []}
                  setCurrentProjectElements={setCurrentProjectElements}
                  renderElementDetails={renderElementDetailWindow}
                  hasDetailsWindowOpen={hasDetailsWindowOpen}
                />
              </SCard>
            </Grid>
            {hasDetailsWindowOpen && (
              <Grid item lg={4} md={4} xs={12}>
                <SCard className='elementAssignmentPage-viewDataElement'>
                  <ElementDetailsCardContent
                    dataTypeList={typeList}
                    prevSelectedElement={previouslySelectedElement.current}
                    selectedElement={currentlySelectedElement}
                    setCurrentlySelectedElement={setCurrentlySelectedElement}
                  />
                </SCard>
              </Grid>
            )}
          </GridContainer>
        </RootCard>
      </ProjectSelectedTemplate>
    </>
  );
}

RightArrowButton.propTypes = {
  currentProjectId: PropTypes.any,
  handleStageElements: PropTypes.func,
};
