import { useState } from 'react';
import Button from 'components/Button';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import { decoder } from 'helpers/graphQL_Encoder';
import CheckIcon from 'icons/Mui/Check';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDocument,
  setHighlightedValue,
} from 'store/Redux/slices/dataEntrySlice';
import { selectValueElementStatusTypes } from 'store/Redux/slices/projectsSlice';
import InputAdornment from 'components/InputAdornment';

//#region Styled Components
const SupplementalButton = styled(Button)`
  color: white;
  background-color: ${(props) => props.theme.palette.primary.main};
  text-align: center;
  width: 100%;
  height: 3dvh;
  border-radius: 8px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.primary.darker};
  }
`;

const InputCell = styled(TextField)`
  background-color: ${({
    vElement,
    belowConfidence,
    currentDocument,
    valueElementStatus,
  }) =>
    vElement &&
    belowConfidence &&
    vElement.status == valueElementStatus.REVIEW_REQUIRED &&
    !currentDocument?.isComplete
      ? 'yellow'
      : null};

  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ButtonText = styled(Typography)`
  padding: 2%;
  text-transform: none;
`;

const TableButton = styled.div`
  display: grid;
  justify-content: center;
  background-color: ${({
    vElement,
    element,
    belowConfidence,
    currentDocument,
    valueElementStatus,
  }) =>
    vElement &&
    belowConfidence &&
    vElement.dataElementId == decoder(element.id) &&
    vElement.status == valueElementStatus.REVIEW_REQUIRED &&
    !currentDocument.isComplete
      ? 'yellow'
      : null};
`;
//#endregion Styled Components

function DataEntryCell(props) {
  const {
    belowConfidence,
    checkInputs,
    element,
    vElement,
    handleSuppButtonClick,
    handleType,
    inputIndex,
    inputVal,
    isExtractionLoading,
    isTableButtonDisabled,
    onClickTableButton,
    row,
    suppData,
    isReadOnly,
  } = props;
  const currentDocument = useSelector(selectDocument);
  const valueElementStatus = useSelector(selectValueElementStatusTypes);
  const dispatch = useDispatch();

  const [input, setInput] = useState(vElement?.value);
  const [original] = useState(vElement?.value);

  const handleButtonOnClick = () => {
    handleType(input, vElement.dataElementId);
    checkInputs(
      input,
      decoder(currentDocument?.id),
      vElement?.dataElementId,
      vElement?.coordinates,
      vElement
    );
  };

  const performInputValidationOnChange = (e) => {
    const ssnPlaceholder = '123-45-6789';
    const ssnFormatRegExp = /(\d{0,3})(\d{0,2})(\d{0,4})/;
    const matchRegExp = /\d*/g;
    const replaceRegExp = /-*$/g;
    const numericRegExp = /^[0-9\b]+$/;
    const correctRadioFormat = /^(?:Y\b|y\b|N\b|n\b)/;

    if (element.type === 'SSN') {
      e.target.placeholder = ssnPlaceholder;
      if (e.target.value === '' || numericRegExp.test(e.target.value))
        setInput(e.target.value);
      e.target.value = e.target.value
        .match(matchRegExp)
        .join('')
        .match(ssnFormatRegExp)
        .slice(1)
        .join('-')
        .replace(replaceRegExp, '');
      setInput(e.target.value);
    } else if (
      element.type === 'DATA_PRESENT' ||
      element.type === 'CHECKBOX' ||
      element.type === 'RADIO' ||
      element.type === 'WET_SIGNATURE'
    ) {
      if (e.target.value === '' || correctRadioFormat.test(e.target.value))
        setInput(e.target.value);
    } else setInput(e.target.value);
  };

  const handleInputCellChange = (e) => {
    if (original === e.target.value) return;
    setInput(e.target.value);
    if (e.target.value == '' && original) e.target.value = original;
    handleType(
      e.target.value,
      vElement?.dataElementId ? vElement.dataElementId : decoder(element.id)
    );
    if (vElement?.status !== valueElementStatus.REVIEW_REQUIRED) {
      checkInputs(
        e.target.value,
        decoder(currentDocument.id),
        vElement?.dataElementId ? vElement?.dataElementId : decoder(element.id),
        vElement?.coordinates,
        vElement
      );
    }
  };

  const cell = () => {
    // if cell is a supplemental button
    if (row.original.supplementalButton) {
      return (
        <SupplementalButton
          variant='contained'
          size='small'
          disabled={
            !suppData ||
            isReadOnly ||
            isExtractionLoading ||
            suppData?.value === inputVal[inputIndex]?.value ||
            currentDocument.isComplete
          }
          onClick={() => {
            handleSuppButtonClick(element, suppData);
          }}
        >
          <ButtonText noWrap>{suppData?.value}</ButtonText>
        </SupplementalButton>
      );
    }

    // if cell is a table button
    else if (element.type === 'TABLE') {
      return (
        <TableButton
          vElement={vElement}
          valueElementStatus={valueElementStatus}
          belowConfidence={belowConfidence}
          currentDocument={currentDocument}
          element={element}
        >
          <Button
            primary
            size='small'
            onClick={(e) => onClickTableButton(e, element)}
            variant='contained'
            disabled={isTableButtonDisabled || currentDocument?.isComplete}
          >
            Open Table
          </Button>
        </TableButton>
      );
    }

    return (
      <InputCell
        id={vElement?.coordinates}
        tabIndex={-1}
        placeholder='<Please enter the value>'
        vElement={vElement}
        valueElementStatus={valueElementStatus}
        belowConfidence={belowConfidence}
        currentDocument={currentDocument}
        variant='standard'
        disabled={currentDocument?.isComplete || isReadOnly}
        fullWidth
        value={input}
        InputProps={{
          inputProps: {
            component: element,
          },
          disableUnderline: true,
          endAdornment:
            !currentDocument?.isComplete &&
            belowConfidence &&
            vElement?.status == valueElementStatus.REVIEW_REQUIRED ? (
              <InputAdornment>
                <Button
                  size='small'
                  disabled={
                    vElement.status !== valueElementStatus.REVIEW_REQUIRED ||
                    isReadOnly
                  }
                  primary
                  variant='contained'
                  onMouseDown={() => handleButtonOnClick()}
                >
                  <CheckIcon />
                </Button>
              </InputAdornment>
            ) : null,
        }}
        onBlur={(e) => {
          dispatch(setHighlightedValue([]));
          handleInputCellChange(e);
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            handleInputCellChange(e);
          }
        }}
        onFocus={() => {
          dispatch(
            setHighlightedValue([
              {
                value: vElement?.value,
                coordinates: vElement?.coordinates?.split(','),
                type: element?.type,
                yellowHighlight: 'Yes',
              },
            ])
          );
        }}
        onChange={(e) => performInputValidationOnChange(e)}
      />
    );
  };
  return <>{cell()}</>;
}

DataEntryCell.propTypes = {
  belowConfidence: PropTypes.bool,
  checkInputs: PropTypes.func,
  element: PropTypes.object,
  vElement: PropTypes.object,
  extracted: PropTypes.object,
  handleSuppButtonClick: PropTypes.func,
  handleType: PropTypes.func,
  handleTypeExtracted: PropTypes.func,
  inputIndex: PropTypes.any,
  inputVal: PropTypes.any,
  isExtractionLoading: PropTypes.bool,
  isTableButtonDisabled: PropTypes.bool,
  onClickTableButton: PropTypes.func,
  row: PropTypes.object,
  suppData: PropTypes.object,
  value: PropTypes.any,
  isReadOnly: PropTypes.bool,
};

export default DataEntryCell;
