import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from 'icons/Mui/Check';
import CloseIcon from 'icons/Mui/Close';
import IconButton from 'components/IconButton';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'components/Typography';

const ConfirmButton = styled(IconButton)`
  svg {
    color: ${(props) =>
      props.disabled ? null : props.theme.palette.primary.main};
  }
`;

const CancelButton = styled(IconButton)`
  svg {
    color: red;
  }
`;

export default function ActionButton({
  actions,
  row,
  handleActionClick,
  isSubmitReady,
  isExtractionLoading,
  isReadOnly,
}) {
  //Adding Row - Confirm/Close Button Group
  if (row.original.addedRow) {
    return (
      <ButtonGroup>
        <ConfirmButton
          onClick={(e) => handleActionClick(e, null, 'submit')}
          icon={<CheckIcon />}
          disabled={!isSubmitReady}
        />
        <CancelButton
          onClick={(e) => handleActionClick(e, null, 'closeAddRow')}
          icon={<CloseIcon />}
        />
      </ButtonGroup>
    );
    //No Delete Icon
  } else if (row.original.noDeleteIcon) {
    return null;
  } else if (actions.delete) {
    return (
      <IconButton
        onClick={(e) => handleActionClick(e, row, 'delete')}
        icon={<DeleteIcon />}
      />
    );
    //Choose Value Button - Analysis
  } else if (actions.chooseValue) {
    if (row.values.source === 'Supplemental Data') {
      return null;
    } else {
      return (
        <Button
          primary
          variant='contained'
          onClick={(e) => handleActionClick(e, row, 'choose')}
          disabled={!row.isSelected || isReadOnly}
        >
          Choose Value
        </Button>
      );
    }
    //Run Extraction Button - Data Entry Select
  } else if (actions.runExtraction) {
    return (
      <span>
        <Button
          style={{ width: '100%' }}
          primary
          variant='contained'
          onClick={(e) => handleActionClick(e, row, 'extract')}
          disabled={
            isExtractionLoading ||
            !row.isSelected ||
            row?.original.totalDataElements <= 0
          }
        >
          <Typography noWrap variant='button'>
            {row.original.wasExtracted
              ? 'Extraction Requested'
              : row.original.dataExtractionId
              ? 'Rerun Extraction'
              : 'Run Extraction'}
          </Typography>
        </Button>
      </span>
    );
  } else {
    return null;
  }
}

ActionButton.propTypes = {
  actions: PropTypes.object,
  row: PropTypes.object,
  handleActionClick: PropTypes.func,
  isSubmitReady: PropTypes.bool,
  isExtractionLoading: PropTypes.bool,
  currentCluster: PropTypes.object,
  isReadOnly: PropTypes.bool,
};
