import styled from 'styled-components';
import Button from '.';
import Typography from 'components/Typography';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const SResetButton = styled(Button)`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const ResetButton = (props) => {
  const {
    getDefaultData = () => null,
    dataFilters = null,
    fullWidth = false,
    setUniqueGuid = () => null,
  } = props;

  const buttonText = 'Reset Data to Default';

  const handleResetButtonClick = () => {
    let scrollArea = document.getElementById('data-list');
    scrollArea?.scrollTo(0, 0);
    setUniqueGuid(uuidv4());
    getDefaultData();
  };
  return (
    <SResetButton
      title={buttonText}
      size='small'
      fullWidth={fullWidth}
      visible={dataFilters}
      variant='contained'
      primary
      onClick={() => handleResetButtonClick()}
    >
      <Typography variant='subtitle'>{buttonText}</Typography>
    </SResetButton>
  );
};

ResetButton.propTypes = {
  getDefaultData: PropTypes.func,
  fullWidth: PropTypes.bool,
  dataFilters: PropTypes.object,
  setUniqueGuid: PropTypes.func,
};
