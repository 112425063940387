import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import IconButton from 'components/IconButton';
import PropTypes from 'prop-types';
import {
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import styled from 'styled-components';
import ActionButton from './TableComponents/ActionButton';
import ExportButton from './TableComponents/ExportButton';
// #region Icon Button Imports
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddIcon from '@mui/icons-material/Add';
import Button from 'components/Button';
import Grid from 'components/Grid';
import NumericInput from 'react-numeric-input';
import { useSelector } from 'react-redux';
import {
  selectCurrentClusterFilterText,
  selectCurrentFilteredClusterData,
} from 'store/Redux/slices/dataEntrySlice';
import VirtualScrollChild from './TableComponents/VirtualScrollChild';
import Typography from 'components/Typography';
import { TableSearch } from './TableComponents/TableSearch';
import InfiniteScrollToggle from './TableComponents/InfiniteScrollToggle';
import ManualFetchMoreButton from './TableComponents/ManualFetchMoreButton';
import { ResetButton } from 'components/Button/ResetButton';
import { TableLoadingOverlay } from 'containers/TableLoadingOverlay';
// #endregion Icon Button Imports

const TableContainer = styled.div`
  height: ${(props) =>
    !props.hasPagination && props.tableHeight ? props.tableHeight : null};
  overflow: ${(props) =>
    !props.hasPagination && props.tableHeight ? 'auto' : null};
`;

// #region StyledComponents
const StyledTable = styled.table`
  min-width: ${(props) => props.hasMinWidth} !important;
  width: 100%;
  border-collapse: collapse;

  & td {
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    :last-child {
      text-align: ${(props) => (props.useCenterLastHeader ? 'center' : null)}
    }
    border: 2px solid transparent;

    :focus-within {
      border: ${(props) =>
        props.hasHighlightCell
          ? `2px solid ${props.theme.palette.primary.main}`
          : null};
    }

    padding: ${(props) => {
      if (props.hasRemoveCellPadding) {
        return 0;
      }
    }};
  }

  & th {
    padding: 12px 8px 12px 8px;
    text-align: left;
    color: black;
    border-bottom: 3px solid rgba(221, 221, 221, 1);
  }

  & tbody {
     display: grid;
  }

  & thead {
    & th {
      :last-child {
        text-align: ${(props) => (props.useCenterLastHeader ? 'center' : null)};
      }
     :nth-last-child(2) {
        text-align: ${(props) =>
          props.useCenterLastSecondHeader ? 'center' : null};
      }
    }
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isSubRow ? 'center' : 'space-between')};
  align-items: center;
  width: 100%;
  background-color: ${(props) =>
    props.isSubRow ? props.theme.palette.background.lighter : null};

  & select {
    height: 30px;
    padding-left: 10px;
  }

  .right-aligned {
    display: flex;
    flex-direction: row;
  }
`;

const PageNavArrow = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const GoToPage = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px;
  ${NumericInput} {
    & input {
      width: 50px;
      margin-left: 5px;
      text-align: center;
    }
  }
`;

const PageIndicator = styled.p`
  margin: 0px;
  display: flex;
  align-items: center;
`;

const TableRow = styled.tr`
  ${({ hasPagination }) => !hasPagination && `display: grid;`}
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  background-color: ${(props) =>
    props.expanded ? `${props.theme.palette.primary.main}` : null};
  align-items: center;

  :last-child {
    border-bottom: none;
  }

  ${({ selected }) =>
    selected && `background-color: rgba(223, 227, 231, 1) !important;`}

  :hover {
    background-color: ${(props) =>
      props.isExpandable || !props.hasHoverHighlight
        ? null
        : 'rgba(221, 221, 221, 1)'};
  }
`;

const HeaderCell = styled.th`
  width: ${(props) => (props.headerWidth ? props.headerWidth : null)};
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  p {
    ${(props) => {
      return props.theme.typography.subheader.bold;
    }}
  }
`;

// #endregion StyledComponents

const IndeterminateCheckbox = forwardRef(
  ({ isIndeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = isIndeterminate;
    }, [resolvedRef, isIndeterminate]);

    return (
      <>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </>
    );
  }
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

IndeterminateCheckbox.propTypes = {
  isIndeterminate: PropTypes.bool,
};

function Table({
  actions,
  clusterButton,
  columns,
  currentCluster,
  currentPageIndex = 0,
  customPageSize = 5,
  data,
  defaultSortColumn = null,
  expandedId = {},
  exportButtonName,
  handleProjectMemberDataReportExport = () => null,
  handleSendProjectMembers = () => null,
  handleTableDataReportExport = () => null,
  handleProjectDataElementReportExport = () => null,
  handleProjectDocumentDataReportExport = () => null,
  hasAddRow = false,
  hasAllRowsExpand,
  hasCheckBox = false,
  hasClusterButton = false,
  hasDisableSelectHighlight = false,
  hasExport = false,
  hasGoToPage = true,
  hasHighlightCell = false,
  hasHoverHighlight = true,
  hasMinWidth = {},
  hasNoDefaultSort = false,
  hasPagination = true,
  hasRowSize = true,
  hasSearchBar = true,
  header,
  headerWidth = {},
  initialSelected = {},
  isDescending = true,
  pageName = null,
  isExpandable = false,
  isExportLoading,
  isExtractionLoading,
  isRowSelectionDisabled = false,
  isSortDisabled = false,
  isSubmitReady,
  isSubRow = false,
  isTableResetOnSelectedRow = true,
  isTableResetOnSort = false,
  onClose = () => {},
  onExpand = () => null,
  onSelected = () => null,
  onTableSort = () => {},
  hasRemoveCellPadding = false,
  renderRowSubComponent,
  firstSearchValue,
  secondSearchValue,
  setFirstSearchValue = () => {},
  setSecondSearchValue = () => {},
  isSecondTable = false,
  selected,
  setCurrentPageIndex = () => null,
  useSkipPageReset = false,
  subheader,
  useActionsColumn = true,
  useCenterLastHeader,
  useCenterLastSecondHeader,
  useRowId = false,
  hasRerunStatusButton,
  rerunValueElementStatus,
  hasImportButton,
  openImportModal,
  isCustomSearch,
  dataCy,
  clearRowsSelected = false,
  setClearRowsSelected = () => {},
  dataLoading = false,
  hasToggleDataFilter = false,
  toggleDataFilterButton,
  isReadOnly,
  fetchMore,
  hasNextPage,
  tableHeight,
  totalData,
  refetch = () => null,
  toggleRefetch,
  isDataFiltered = false,
}) {
  const tablePageSize = [5, 10, 15];
  const dataMemo = useMemo(() => data, [data]);
  const columnsMemo = useMemo(() => columns, [columns]);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 0,
      maxWidth: 200,
    }),
    []
  );

  // #region State
  const [toggleInfiniteScroll, setToggleInfiniteScroll] = useState(true);
  const currentClusterSearchFilter = useSelector(
    selectCurrentClusterFilterText
  );
  const currentFilteredClusterData = useSelector(
    selectCurrentFilteredClusterData
  );

  // TODO - Not complete yet. Also update Action State to be useReducer
  const handleActionClick = (e, row, key) => {
    switch (key) {
      case 'add':
        actions.addRow();
        break;
      case 'closeAddRow':
        actions.closeAddRow();
        break;
      case 'submit':
        actions.submit();
        break;
      case 'delete':
        actions.delete(row.original.id);
        break;
      case 'choose':
        actions.chooseValue();
        break;
      case 'extract':
        actions.runExtraction();
        break;
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    rows, // returns the new rows
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy },
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns: columnsMemo,
      data:
        isCustomSearch &&
        currentFilteredClusterData !== null &&
        currentClusterSearchFilter !== ''
          ? currentFilteredClusterData
          : dataMemo,
      defaultColumn,
      disableSortRemove: hasNoDefaultSort,
      disableSortBy: isSortDisabled,
      autoResetPage: !useSkipPageReset,
      initialState: {
        pageSize: hasPagination ? customPageSize : -1,
        pageIndex: currentPageIndex,
        hiddenColumns: ['id'],
        selectedRowIds: initialSelected ? initialSelected : {},
        sortBy:
          defaultSortColumn && defaultSortColumn !== 'null'
            ? [{ id: defaultSortColumn, desc: isDescending }]
            : [],
      },
      autoResetSelectedRows: isTableResetOnSelectedRow,
      autoResetSortBy: isTableResetOnSort,
      getRowId: (row, relativeIndex, parent) => {
        if (useRowId) return row?.id;
        return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
      },
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            expanded: expandedId,
          }),
          [state]
        );
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      useActionsColumn &&
        actions &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) =>
              hasCheckBox ? (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ) : null,
            Cell: ({ row }) =>
              hasCheckBox ? (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ) : null,
            width: hasCheckBox ? 50 : 0,
          },
          ...columns,
          {
            id: 'actions',
            Header: 'Actions',
            Cell: ({ row }) => (
              <ActionButton
                row={row}
                actions={actions}
                handleActionClick={handleActionClick}
                isSubmitReady={isSubmitReady}
                isExtractionLoading={isExtractionLoading}
                currentCluster={currentCluster}
                isReadOnly={isReadOnly}
              />
            ),
          },
        ]);
    }
  );

  useEffect(() => {
    if (!sortBy.length) {
      localStorage.setItem('ReactTable_' + pageName + '_SortBy', null);
      return;
    }
    localStorage.setItem('ReactTable_' + pageName + '_SortBy', sortBy[0].id);
    localStorage.setItem(
      'ReactTable_' + pageName + '_SortByDesc_' + sortBy[0].id,
      sortBy[0].desc
    );
    onTableSort(rows);
  }, [sortBy]);

  useEffect(() => {
    if (clearRowsSelected) {
      let selectedRow = rows.find((x) => x.isSelected == true);
      if (selectedRow && typeof selectedRow.toggleRowSelected !== 'undefined') {
        RowClick(selectedRow);
      } else {
        toggleAllRowsSelected(false);
      }
    }
    setClearRowsSelected(false);
  }, [clearRowsSelected]);

  const RowClick = (row) => {
    if (isRowSelectionDisabled) return;
    if (hasDisableSelectHighlight) {
      onSelected(row);
      return;
    }
    if (hasAllRowsExpand || (isExpandable && row.original.type === 'TABLE')) {
      toggleAllRowsExpanded(false);
      toggleAllRowsSelected(false);
      if (onExpand) {
        Object.prototype.hasOwnProperty.call(expandedId, row.id)
          ? onClose()
          : onExpand(row.original, row.id, row.index);
      }
      return;
    }
    if (row.isSelected) {
      row.toggleRowSelected(!row.isSelected);
    } else {
      toggleAllRowsSelected(false);
      toggleAllRowsExpanded(false);
      row.toggleRowSelected(true);
      if (onSelected) {
        onSelected(row);
      }
    }
  };

  const handleScroll = (e) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.clientHeight + 5 >=
        e.currentTarget.scrollHeight &&
      hasNextPage
    ) {
      if (toggleInfiniteScroll) {
        fetchMore();
      }
    }
  };

  function handlePageChange(action) {
    switch (action) {
      case 'forward':
        nextPage();
        setCurrentPageIndex(pageIndex + 1);
        break;
      case 'back':
        previousPage();
        setCurrentPageIndex(pageIndex - 1);
        break;
      case 'first':
        gotoPage(0);
        setCurrentPageIndex(0);
        break;
      case 'last':
        gotoPage(pageCount - 1);
        setCurrentPageIndex(pageCount - 1);
        break;
    }
  }

  const TableFooter = () => (
    <Pagination isSubRow={isSubRow}>
      {hasRowSize && (
        <div>
          <span>Rows: </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {tablePageSize.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className='right-aligned'>
        <PageNavArrow
          onClick={() => handlePageChange('first')}
          disable={`${canPreviousPage}`}
          aria-label='first-page'
          icon={<FirstPageIcon />}
          disabled={canPreviousPage ? false : true}
        />
        <PageNavArrow
          onClick={() => handlePageChange('back')}
          disable={`${!canPreviousPage}`}
          aria-label='previous-page'
          icon={<KeyboardArrowLeftIcon />}
          disabled={canPreviousPage ? false : true}
        />
        <PageIndicator data-cy='page-count'>
          {pageOptions.length
            ? `${pageIndex + 1} of ${pageOptions.length}`
            : null}
        </PageIndicator>
        <PageNavArrow
          data-cy='next-page'
          onClick={() => handlePageChange('forward')}
          disable={`${!canNextPage}`}
          aria-label='next-page'
          icon={<KeyboardArrowRightIcon />}
          disabled={canNextPage ? false : true}
        />
        <PageNavArrow
          onClick={() => handlePageChange('last')}
          disable={`${!canNextPage}`}
          aria-label='last-page'
          icon={<LastPageIcon />}
          disabled={canNextPage ? false : true}
        />
        {pageCount > 0 && hasGoToPage ? (
          <GoToPage>
            <span>Go to Page: </span>
            <NumericInput
              value={pageIndex + 1}
              onChange={(e) => {
                const page = e ? Number(e) - 1 : 0;
                gotoPage(page);
              }}
              min='1'
              max={pageCount}
            />
          </GoToPage>
        ) : null}
      </div>
    </Pagination>
  );

  return (
    <>
      <Grid container>
        {header || subheader ? (
          <Grid item xs={8} className='heading-container'>
            <Grid item xs={12}>
              <h4>{header}</h4>
            </Grid>
            <Grid item xs={12}>
              <h6>{subheader}</h6>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={4} textAlign='end'>
          <Grid container spacing={1} alignItems='center'>
            {hasSearchBar && (
              <TableSearch
                data={data}
                dataMemo={dataMemo}
                currentClusterSearchFilter={currentClusterSearchFilter}
                hasPagination={hasPagination}
                refetch={refetch}
                toggleRefetch={toggleRefetch}
                isCustomSearch={isCustomSearch}
                isSecondTable={isSecondTable}
                firstSearchValue={firstSearchValue}
                secondSearchValue={secondSearchValue}
                setFirstSearchValue={setFirstSearchValue}
                setSecondSearchValue={setSecondSearchValue}
                setGlobalFilter={setGlobalFilter}
              />
            )}
            {hasClusterButton && clusterButton}
            {hasExport && (
              <ExportButton
                exportButtonName={exportButtonName}
                isExportLoading={isExportLoading}
                handleProjectMemberDataReportExport={
                  handleProjectMemberDataReportExport
                }
                handleSendProjectMembers={handleSendProjectMembers}
                handleTableDataReportExport={handleTableDataReportExport}
                handleProjectDataElementReportExport={
                  handleProjectDataElementReportExport
                }
                handleProjectDocumentDataReportExport={
                  handleProjectDocumentDataReportExport
                }
              />
            )}
            {hasRerunStatusButton && (
              <Grid item xs={12}>
                <Button
                  size='small'
                  primary
                  variant='contained'
                  onClick={rerunValueElementStatus}
                >
                  Rerun Value Element Status
                </Button>
              </Grid>
            )}
            {hasToggleDataFilter && toggleDataFilterButton}
            {hasAddRow && (
              <Grid item xs={12}>
                <Button
                  size='small'
                  primary
                  variant='contained'
                  data-cy='add-row'
                  onClick={(e) => handleActionClick(e, null, 'add')}
                >
                  <AddIcon />
                </Button>
              </Grid>
            )}

            {hasImportButton && (
              <Grid item xs={12}>
                <Button
                  primary
                  variant='contained'
                  onClick={openImportModal}
                  data-cy='table-import-button'
                  className='recordsPage-globalDataElementsButton'
                >
                  Import
                </Button>
              </Grid>
            )}
            {!hasPagination && tableHeight ? (
              <InfiniteScrollToggle
                toggleInfiniteScroll={toggleInfiniteScroll}
                setToggleInfiniteScroll={setToggleInfiniteScroll}
              />
            ) : null}
            {isDataFiltered && (
              <Grid item xs={12} textAlign='end'>
                <ResetButton
                  getDefaultData={refetch}
                  dataFilters={isDataFiltered}
                  fullWidth={false}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <StyledTable
        data-cy={dataCy}
        {...getTableProps()}
        hasMinWidth={hasMinWidth}
        useCenterLastHeader={useCenterLastHeader}
        useCenterLastSecondHeader={useCenterLastSecondHeader}
        hasRemoveCellPadding={hasRemoveCellPadding}
        hasHighlightCell={hasHighlightCell}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={`table_header_row_${index}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <HeaderCell
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: 'Click to Sort' })
                  )}
                  headerWidth={headerWidth}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )
                  ) : (
                    ''
                  )}
                </HeaderCell>
              ))}
            </tr>
          ))}
        </thead>
        {data?.length && columns?.length ? (
          <TableContainer
            id={`tableScroll-${data[0]?.__typename}`}
            tableHeight={tableHeight}
            hasPagination={hasPagination}
            onScroll={(e) => handleScroll(e)}
          >
            <tbody {...getTableBodyProps()}>
              {hasPagination
                ? page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={`table_body_row_${i}`}>
                        <VirtualScrollChild key={i}>
                          <TableRow
                            key={`table_row_${i}`}
                            {...row.getRowProps()}
                            selected={row.isSelected}
                            expanded={row.isExpanded}
                            onClick={() => {
                              !selected && RowClick(row);
                            }}
                            isExpandable={isExpandable}
                            hasHoverHighlight={hasHoverHighlight}
                          >
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  key={`table_cell_${index}`}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </TableRow>
                          {row.isExpanded ? (
                            <TableRow>
                              <td>
                                {typeof renderRowSubComponent === 'function' &&
                                  renderRowSubComponent({ row })}
                              </td>
                            </TableRow>
                          ) : null}
                        </VirtualScrollChild>
                      </React.Fragment>
                    );
                  })
                : rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={`table_body_row_${i}`}>
                        <VirtualScrollChild key={i}>
                          <TableRow
                            key={`table_row_${i}`}
                            {...row.getRowProps()}
                            selected={row.isSelected}
                            expanded={row.isExpanded}
                            onClick={() => {
                              !selected && RowClick(row);
                            }}
                            isExpandable={isExpandable}
                            hasHoverHighlight={hasHoverHighlight}
                          >
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  key={`table_cell_${index}`}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </TableRow>
                          {row.isExpanded ? (
                            <TableRow>
                              <td>
                                {typeof renderRowSubComponent === 'function' &&
                                  renderRowSubComponent({ row })}
                              </td>
                            </TableRow>
                          ) : null}
                        </VirtualScrollChild>
                      </React.Fragment>
                    );
                  })}
            </tbody>
          </TableContainer>
        ) : dataLoading ? (
          <TableLoadingOverlay />
        ) : (
          <NoData>
            <p>No Data Available</p>
          </NoData>
        )}
      </StyledTable>
      <br />
      {hasPagination && tableHeight !== '' ? (
        <TableFooter />
      ) : tableHeight ? (
        <Grid container textAlign='end' spacing={1}>
          {!toggleInfiniteScroll && (
            <ManualFetchMoreButton
              hasNextPage={hasNextPage}
              fetchMore={fetchMore}
            />
          )}
          <Grid item xs={12}>
            <Typography variant='body1'>
              Current row count: {dataMemo?.length} of {totalData}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default Table;

Table.propTypes = {
  actions: PropTypes.exact({
    addRow: PropTypes.func,
    chooseValue: PropTypes.func,
    closeAddRow: PropTypes.func,
    delete: PropTypes.func,
    submit: PropTypes.func,
    update: PropTypes.func,
    runExtraction: PropTypes.func,
    rerunStatus: PropTypes.func,
    importButton: PropTypes.func,
  }),
  clusterButton: PropTypes.element,
  columns: PropTypes.array,
  currentCluster: PropTypes.object,
  currentPageIndex: PropTypes.number,
  customPageSize: PropTypes.number,
  data: PropTypes.array,
  defaultSortColumn: PropTypes.string,
  expandedId: PropTypes.object,
  exportButtonName: PropTypes.string,
  getToggleAllRowsSelectedProps: PropTypes.func,
  handleExport: PropTypes.func,
  handleSendProjectMembers: PropTypes.func,
  handleTableDataExport: PropTypes.func,
  hasAddRow: PropTypes.bool,
  hasAllRowsExpand: PropTypes.bool,
  hasCheckBox: PropTypes.bool,
  hasClusterButton: PropTypes.bool,
  hasDisableSelectHighlight: PropTypes.bool,
  hasExport: PropTypes.bool,
  hasHighlightCell: PropTypes.bool,
  hasHoverHighlight: PropTypes.bool,
  hasMinWidth: PropTypes.bool,
  hasNoDefaultSort: PropTypes.bool,
  hasPagination: PropTypes.bool,
  hasSearchBar: PropTypes.bool,
  header: PropTypes.string,
  headerWidth: PropTypes.string,
  isAddingRow: PropTypes.bool,
  isDescending: PropTypes.bool,
  pageName: PropTypes.string,
  isExpandable: PropTypes.bool,
  isExportLoading: PropTypes.bool,
  isExtractionLoading: PropTypes.bool,
  initialSelected: PropTypes.object,
  isRowSelectionDisabled: PropTypes.bool,
  isSortDisabled: PropTypes.bool,
  isSubmitReady: PropTypes.bool,
  isTableResetOnSelectedRow: PropTypes.bool,
  isTableResetOnSort: PropTypes.bool,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  onSelected: PropTypes.func,
  onTableSort: PropTypes.func,
  hasRemoveCellPadding: PropTypes.bool,
  renderRowSubComponent: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.array,
  setCurrentPageIndex: PropTypes.func,
  useSkipPageReset: PropTypes.bool,
  subheader: PropTypes.string,
  useActionsColumn: PropTypes.bool,
  useCenterLastHeader: PropTypes.bool,
  useCenterLastSecondHeader: PropTypes.bool,
  useRowId: PropTypes.bool,
  hasRerunStatusButton: PropTypes.bool,
  rerunValueElementStatus: PropTypes.func,
  isCustomSearch: PropTypes.bool,
  toggleRefetch: PropTypes.bool,
};
