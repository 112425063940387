import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Button from 'components/Button';
import Grid from 'components/Grid';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Typography from 'components/Typography';
import { decoder } from 'helpers/graphQL_Encoder';
import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Radio from 'components/Radio';

const SubFilterButton = styled(Button)`
  border: 0;
  border-radius: 0;
  color: black;

  ${(props) => {
    if (props.filterEnabled) {
      return `
      background-color: ${props.theme.palette.primary.darker};
      color: white;

      & :hover {
        color: black;
      }
      `;
    }
  }}
`;
export const ImageGalleryFiltersToolbar = (props) => {
  const {
    filterOptions,
    setFilterOptions,
    docQueryFilters,
    setDocQueryFilters,
    currentCluster,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const onFilterClick = (event, filter) => {
    setAnchorEl(event.currentTarget);
    const updatedFilterOptions = filterOptions.map((f) => {
      if (f.id === filter.id) {
        return { ...f, selected: true };
      }
      return { ...f, selected: false };
    });
    setFilterOptions(updatedFilterOptions);
  };

  const filterDocuments = (filter, option) => {
    const updatedFilterOptions = filterOptions.map((f) => {
      if (f.id === filter.id) {
        let updatedOptions = filter.options.map((o) => {
          if (o.id === option.id) {
            return { ...o, active: true };
          }
          return { ...o, active: false };
        });
        return { ...f, options: updatedOptions };
      }
      return f;
    });
    setFilterOptions(updatedFilterOptions);

    let docFilters = {
      ...docQueryFilters,
      clusterId: { eq: decoder(currentCluster?.id) },
    };
    if (filter.id === 0) {
      if (option.id === 0 && !option.active) {
        docFilters = { ...docFilters, totalValueElements: { gt: 0 } };
      } else if (option.id === 1 && !option.active) {
        docFilters = { ...docFilters, totalValueElements: { eq: 0 } };
      } else {
        let { totalValueElements, ...rest } = docFilters;
        docFilters = rest;
      }
    } else if (filter.id === 1) {
      if (option.id === 0 && !option.active) {
        docFilters = { ...docFilters, isConfirmed: { eq: true } };
      } else if (option.id === 1 && !option.active) {
        docFilters = { ...docFilters, isConfirmed: { eq: false } };
      } else {
        let { isConfirmed, ...rest } = docFilters;
        docFilters = rest;
      }
    }
    setDocQueryFilters(docFilters);
    handleFilterClose();
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        backgroundColor: 'rgb(134, 188, 37, 0.4)',
        borderRadius: '5px',
        marginTop: '1%',
      }}
    >
      <Stack direction='row'>
        {filterOptions?.map((filter) => (
          <>
            <SubFilterButton
              filterEnabled={filter.options?.some((o) => o.active)}
              key={filter.id}
              variant='text'
              sx={{ color: 'black' }}
              onClick={(e) => onFilterClick(e, filter)}
            >
              <Typography variant='button'>
                {filter.name}
                {open && filter.selected ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </Typography>
            </SubFilterButton>
            <Menu
              MenuListProps={{
                sx: {
                  width: anchorEl && anchorEl.offsetWidth,
                  '& .MuiMenuItem-root': {
                    whiteSpace: 'normal',
                  },
                },
              }}
              key={filter.id}
              anchorEl={anchorEl}
              open={open && filter.selected}
              onClose={handleFilterClose}
              disableScrollLock
                >
              {filter?.options?.map((opt) => (
                <MenuItem
                  key={opt}
                  onClick={() => filterDocuments(filter, opt)}
                                 >
                  <Radio checked={opt.active} />
                  {opt.name}
                </MenuItem>
                              ))}
            </Menu>
          </>
        ))}
      </Stack>
    </Grid>
  );
};

ImageGalleryFiltersToolbar.propTypes = {
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  docsWithoutVElements: PropTypes.any,
  docQueryFilters: PropTypes.object,
  setDocQueryFilters: PropTypes.func,
  currentCluster: PropTypes.object,
};
