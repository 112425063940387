import { createSlice } from '@reduxjs/toolkit';
import taskData from './taskData';

const initialState = taskData;

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    handleAction(state, action) {
      const { assignedTo, availability, taskId } = action.payload;
      const taskRow = state.tasks.findIndex((task) => task.id === taskId);
      state.tasks[taskRow].assignedTo = assignedTo;
      state.tasks[taskRow].availability = availability;
    },
    handleDeleteTask(state, action) {
      const { taskId } = action.payload;
      state.tasks = state.tasks.filter((task) => task.id !== taskId);
    },
    addNewTask(state, action) {
      const { newTaskObject } = action.payload;
      state.tasks.unshift(newTaskObject);
    },
    changeTaskStatus(state, action) {
      const { taskId, taskStatus } = action.payload;
      const taskRow = state.tasks.findIndex((task) => task.id === taskId);
      state.tasks[taskRow].status = taskStatus;
    },
    updateTask(state, action) {
      const { task } = action.payload;
      const taskRow = state.tasks.findIndex((t) => t.id === task.id);
      state.tasks[taskRow] = task;
    },
    mountInitialTask(state, action) {
      state.tasks = action?.payload?.tasks;
    },
  },
});

export const selectTasks = (state) => state.tasks;

export const {
  handleAction,
  handleDeleteTask,
  addNewTask,
  changeTaskStatus,
  updateTask,
  mountInitialTask,
} = taskSlice.actions;

export default taskSlice.reducer;
