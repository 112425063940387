function CircleDeleteButton() {
  return (
    <svg
      width="1.3em"
      height="1.3em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#DA291C" />
      <path
        d="M6.56078 14.2524C6.57672 14.9398 7.1521 15.4891 7.83942 15.4731L12.8381 15.3572C13.5254 15.3413 14.0747 14.7659 14.0588 14.0786L13.8849 6.58058L6.38689 6.75446L6.56078 14.2524ZM7.99469 9.76799L8.85527 8.86655L10.2169 10.1603L11.5108 8.80497L12.4123 9.66555L11.1183 11.0209L12.4737 12.3148L11.6131 13.2163L10.2577 11.9224L8.96382 13.2777L8.06238 12.4171L9.3563 11.0618L7.99469 9.76799ZM12.2793 4.74231L11.64 4.13197L8.51585 4.20442L7.9055 4.84374L5.71859 4.89445L5.74757 6.14412L14.4952 5.94126L14.4662 4.69159L12.2793 4.74231Z"
        fill="white"
      />
    </svg>
  );
}

export default CircleDeleteButton;
