import { Warning } from '@mui/icons-material';
import Button from 'components/Button';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { config } from 'config';
import styled from 'styled-components';
import theme from 'theme';

const Header = styled.h1`
  ${theme.typography.header}
  font-weight: 400;
  font-size: 40px;
  margin: 15px;
  cursor: pointer;

  :hover {
    color: ${theme.palette.primary.main};
  }
`;

const SButton = styled(Button)`
  background-color: ${theme.palette.secondary.main} !important;
  :hover {
    background-color: ${theme.palette.primary.main} !important;
  }
`;

export default function ErrorPage(props) {
  const { error } = props;

  const clearUserCache = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
      window.location.replace('/');
    }
  };

  return (
    <>
      <Header onClick={clearUserCache}> {config.APPNAME}</Header>
      <Grid
        container
        spacing={2}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '95vh' }}
      >
        <Grid item xs={12}>
          <Warning sx={{ fontSize: 200, color: theme.palette.error.main }} />
        </Grid>
        <Grid item xs={12}>
          <h1>Application Error</h1>
        </Grid>
        <Grid item xs={12} textAlign='center'>
          <Typography variant='h6'>
            Sorry about that, something went wrong.
          </Typography>
          {error && <Typography variant='caption'>{error.message}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <SButton variant='contained' onClick={clearUserCache}>
            Take me back to {config.APPNAME}
          </SButton>
        </Grid>
      </Grid>
    </>
  );
}
