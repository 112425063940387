import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import CropIcon from '@mui/icons-material/Crop';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import Button from 'components/Button';
import DialogActions from 'components/DialogActions';
import DialogContent from 'components/DialogContent';
import DialogTitle from 'components/DialogTitle';
import IconButton from 'components/IconButton';
import Paper from 'components/Paper';
import MetlWindowPortal from 'components/Portal/MetlWindowPortal';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import { TableColumnEditModal } from 'containers/Modals';
import ReactTable from 'containers/ReactTable';
import { decoder } from 'helpers/graphQL_Encoder';
import CancelIcon from 'icons/Mui/Cancel';
import _cloneDeep from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { ResizableBox } from 'react-resizable';
import {
  ADD_TABLE_CELL_VALUE_ELEMENT,
  BULK_ADD_TABLE_CELL_VALUE_ELEMENT,
  REMOVE_TABLE_CELL_VALUE_ELEMENT,
} from 'services/GraphQL/Mutations';
import {
  selectTableCoordinates,
  setCurrentOpenTable,
  setTableColumnsModalOpen,
  selectTableData,
  selectTableCoordinatesCopy,
} from 'store/Redux/slices/dataEntrySlice';
import {
  selectCurrentProjectId,
  selectValueElementStatusTypes,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarInfo,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled, { StyleSheetManager } from 'styled-components';
import TableDialogCell from './ValueElementDialogCell';
import { UnfoldMore } from '@mui/icons-material';
import Dialog from 'components/Dialog';

//#region Styled Components
const SResizableBox = styled(ResizableBox)`
  position: relative;
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 45%;
    right: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    background-size: 8px;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: e-resize;
    transform: rotate(315deg);
  }
`;

const SPaper = styled(Paper)`
  margin: 0;
  width: fit-content;
  position: fixed;
  top: 6em;
  left: 6em;
  right: auto;
  bottom: auto;
  z-index: 1000;
`;

const NoColumnsMessage = styled.div`
  max-width: 500px;
  max-height: 280px;
  display: grid;
  place-items: center;
`;

const ButtonRow = styled(DialogContent)`
  padding: 0 10px 2px 0;
`;

const SDialogActions = styled(DialogActions)`
  padding: 10px 10px 10px 0;
`;

const CancelButton = styled(IconButton)`
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const ShiftButton = styled(Button)`
  margin: 0 5px;
  background-color: ${(props) =>
    props.showCellShiftButtons
      ? `${props.theme.palette.primary.main}`
      : `${props.theme.palette.secondary.main}`};
`;

const CropButton = styled(Button)`
  margin: 0 5px;
  background-color: ${(props) =>
    props.showCrop
      ? `${props.theme.palette.primary.main}`
      : `${props.theme.palette.secondary.main}`};
`;
//#endregion Styled Components

function TableDialog(props) {
  const {
    getValueElements,
    isComplete,
    isTableOpen,
    isTableTypePortalOpen,
    selectedTableId,
    selectedTableName,
    tableDataElement,
    toggleTableTypePortal,
    /* For image cropping */
    crop,
    isCropDisabled,
    onClickCrop,
    onClickRemoveCrop,
    onConfirmCrop,
    onExitCropMode,
    selectedElementField,
    setIsCropDisabled,
    emailName,
    isReadOnly,
    confirm,
    templateExistsInCluster,
    removeTemplateInDocument,
    confirmCroppingMessage,
    currentDocument,
    inputVal,
    setInputVal,
    checkInputCompleteStatus,
  } = props;

  const dispatch = useDispatch();
  const tableCoordinates = useSelector(selectTableCoordinates);
  const tableCoordinatesCopy = useSelector(selectTableCoordinatesCopy);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const tableData = useSelector(selectTableData);
  const valueElementStatus = useSelector(selectValueElementStatusTypes);

  const [tableValues, setTableValues] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [isColumnsModalOpen, toggleColumnsModal] = useState(false);
  const [isRowClicked, toggleIsRowClicked] = useState(false);
  const [showCrop, toggleShowCrop] = useState(false);
  const [showCellShiftButtons, setShowCellShiftButtons] = useState(false);
  const [tableIsDirty, setTableIsDirty] = useState(false);
  const [changesMadeModalOpen, toggleChangesMadeModalOpen] = useState(false);

  const selectedColumn = useRef(null);
  const selectedRow = useRef(null);

  let tableCells = _cloneDeep(tableValues);

  const sortedElements = [...inputVal]
    .filter(
      // value element is of table type and is bound to the selected table id
      (v) =>
        v?.tableTypeColumnId && v?.dataElementId === decoder(selectedTableId)
    )
    .sort((a, b) => a.rowIndex - b.rowIndex);

  const [saveCell] = useMutation(ADD_TABLE_CELL_VALUE_ELEMENT, {
    onCompleted: (data) => {
      if (data?.addOrUpdateValueElementTableTypeValue?.valueElement) {
        let updatedValueElementList = [...inputVal];

        updateValueElementList(
          data.addOrUpdateValueElementTableTypeValue.valueElement,
          updatedValueElementList
        );
        onCompletedSaveTableTypeValues(updatedValueElementList);
      } else {
        dispatch(
          setSnackbarSuccess(
            'Document is Complete. Unable to ADD new elements until document status changes'
          )
        );
      }
    },
    onError: () => {
      dispatch(setSnackbarError('Please try again later'));
    },
  });

  const [saveAllChangesToTable] = useMutation(
    BULK_ADD_TABLE_CELL_VALUE_ELEMENT,
    {
      onCompleted: (data) => {
        if (data?.addOrUpdateValueElementTableTypeValueList?.length > 0) {
          let updatedValueElementList = [...inputVal];

          data.addOrUpdateValueElementTableTypeValueList.forEach(
            (newElement) => {
              updateValueElementList(
                newElement.valueElement,
                updatedValueElementList
              );
            }
          );

          onCompletedSaveTableTypeValues(updatedValueElementList);
        } else {
          dispatch(
            setSnackbarSuccess(
              'Document is Complete. Unable to ADD new elements until document status changes'
            )
          );
        }
      },
      onError: () => {
        dispatch(setSnackbarError('Please try again later'));
      },
    }
  );

  const [deleteCell] = useMutation(REMOVE_TABLE_CELL_VALUE_ELEMENT, {
    onCompleted: (data) => {
      if (data?.removeValueElementTableTypeValue) {
        dispatch(setSnackbarSuccess('Rows deleted'));
        getValueElements({ variables: { id: currentDocument?.id } });
      }
    },
    onError: () => {
      dispatch(setSnackbarError('Please try deleting rows later'));
    },
  });

  const updateValueElementList = (newElement, updatedValueElementList) => {
    const index = inputVal?.findIndex((v) => v.id === newElement.id);

    if (index === -1) {
      updatedValueElementList.push(newElement);
    } else {
      updatedValueElementList[index] = newElement;
    }
  };

  const onCompletedSaveTableTypeValues = (updatedValueElementList) => {
    setInputVal(updatedValueElementList);
    checkInputCompleteStatus(updatedValueElementList);
    dispatch(setSnackbarSuccess('Table updated'));
    setIsCropDisabled(true);
  };

  const getRowIndex = (rowId) => {
    return tableValues?.findIndex((t) => t.id === parseInt(rowId));
  };

  const getColumnIndex = (rowIndex, columnId) => {
    return tableValues[rowIndex]?.values?.findIndex(
      (v) => v.tableTypeColumnId === columnId
    );
  };

  const buildValuesObj = () => {
    return tableData?.map((t) => {
      return {
        tableTypeColumnId: t.id,
        value: null,
        name: t.name,
        valueElementId: null,
        confidenceLevel: null,
        status: null,
        rowIndex: null,
        coordinates: null,
      };
    });
  };

  const onRowClickGetCurrentRow = (row) => {
    selectedRow.current = row;
    toggleIsRowClicked(!isRowClicked);
  };

  // #region UseEffects
  // generate table columns
  useEffect(() => {
    populateTableValues();
  }, []);

  useEffect(() => {
    if (!selectedTableId) return;
    const columns = tableData || [];
    setTableHeaders([]);
    if (!columns || columns.length === 0) {
      setTableHeaders(columns);
    } else {
      const columnsHolder = [
        {
          Header: () => <div style={{ fontSize: 12 }}>Row Index</div>,
          accessor: 'rowIndex',
          Cell: (row) => (
            <div style={{ textAlign: 'center' }}>{row.row.id + 1}</div>
          ),
        },
      ];
      for (const column of columns) {
        columnsHolder.push({
          accessor: column.name.toLowerCase,
          Header: column.name,
          width: 200,
          Cell: ({ value, row }) => {
            return (
              <TableDialogCell
                valueElementStatus={valueElementStatus}
                cell={value}
                column={column}
                getRowIndex={getRowIndex}
                getColumnIndex={getColumnIndex}
                isComplete={isComplete}
                onChangeTableValueInput={onChangeTableValueInput}
                onClickCroppedTextbox={onClickCroppedTextbox}
                row={row}
                selectedColumn={selectedColumn}
                selectedElementField={selectedElementField}
                selectedRow={selectedRow}
                showCrop={showCrop}
                tableValues={tableCells}
                isTableTypePortalOpen={isTableTypePortalOpen}
                /* For image cropping */
                crop={crop}
                isCropDisabled={isCropDisabled}
                onClickCrop={onClickCrop}
                onClickRemoveCrop={onClickRemoveCrop}
                onConfirmCrop={onConfirmCrop}
                onExitCropMode={onExitCropMode}
                selectedTableId={selectedTableId}
                emailName={emailName}
                setTableValues={setTableValues}
                saveCell={saveCell}
                onClickActivateCellShift={onClickActivateCellShift}
                showCellShiftButtons={showCellShiftButtons}
                isReadOnly={isReadOnly}
                currentDocument={currentDocument}
              />
            );
          },
        });
      }
      setTableHeaders(columnsHolder);
    }
  }, [
    crop,
    currentDocument?.id,
    isComplete,
    isTableTypePortalOpen,
    selectedColumn,
    selectedElementField,
    selectedRow,
    selectedTableId,
    showCrop,
    showCellShiftButtons,
    tableDataElement,
    tableValues,
    inputVal,
    tableData,
  ]);

  useEffect(() => {
    toggleShowCrop(false);
    toggleCropModeOff();
  }, [currentProjectId, currentDocument?.id]);

  useEffect(() => {
    if (!isTableTypePortalOpen) {
      dispatch(
        setCurrentOpenTable({ isOpen: false, tableId: null, tableName: '' })
      );
    }
  }, [currentDocument?.id]);

  // Populates table with values; at this point the table is an empty matrix
  useEffect(() => {
    if (!selectedTableId) return;
    if (isTableOpen || isTableTypePortalOpen) {
      if (tableData?.length > 0) {
        const elementId = tableData[0]?.dataElementId;
        const tableHasValues =
          sortedElements.length > 0 &&
          sortedElements[0].dataElementId === elementId;
        setTableValues([]);
        if (tableHasValues) {
          populateTableValues();
        } else {
          createDummyRow();
        }
      }
    }
  }, [selectedTableId, inputVal, currentDocument?.id, tableData]);

  const populateTableValues = useCallback(() => {
    let rows = [];
    let rowIndexList = [];

    for (let k = 0; k < sortedElements?.length; k++) {
      rowIndexList.push(sortedElements[k]?.rowIndex);
    }
    let uniqueRowIndexList = rowIndexList?.filter(
      (x, i, a) => a.indexOf(x) === i
    );
    const rowHeight = uniqueRowIndexList?.length;
    for (let i = 0; i < rowHeight; i++) {
      rows.push({
        id: i,
        values: tableData ? buildValuesObj() : [],
      });
    }
    for (let rowNum = 0; rowNum < rowHeight; rowNum++) {
      const rowValuesObj = rows[rowNum]?.values;
      const arr = sortedElements.filter(
        (e) => e.rowIndex === uniqueRowIndexList[rowNum]
      );
      for (let colNum = 0; colNum < arr?.length; colNum++) {
        const columnIndex = rowValuesObj?.findIndex(
          (v) => v?.tableTypeColumnId === arr[colNum]?.tableTypeColumnId
        );
        if (columnIndex !== -1) {
          rowValuesObj[columnIndex].value = arr[colNum]?.value;
          rowValuesObj[columnIndex].valueElementId = arr[colNum]?.id || null;
          rowValuesObj[columnIndex].confidenceLevel =
            arr[colNum]?.confidenceLevel || null;
          rowValuesObj[columnIndex].status = arr[colNum]?.status || null;
          rowValuesObj[columnIndex].dataElementId = selectedTableId || null;
          rowValuesObj[columnIndex].rowIndex = rowNum;
          rowValuesObj[columnIndex].coordinates =
            arr[colNum]?.coordinates || null;
        }
      }
    }
    setTableValues(rows);
  }, [sortedElements, tableData]);

  const createDummyRow = useCallback(() => {
    let valuesArr = [];
    for (let i = 0; i < tableData?.length; i++) {
      const cell = {
        name: tableData[i].name,
        tableTypeColumnId: tableData[i]?.id,
        value: null,
        coordinates: null,
      };
      valuesArr.push(cell);
    }
    setTableValues([{ id: 0, values: valuesArr }]);
  }, [tableData]);

  // Should only fire when table coordinates are cropped to send
  useEffect(() => {
    if (
      tableCoordinates !== undefined &&
      tableDataElement &&
      selectedTableId !== null
    ) {
      let cellToBeUpdated = selectedRow?.current?.original?.values.find(
        (s) => s.tableTypeColumnId === selectedColumn?.current?.id
      );
      var clusterId = currentDocument?.clusterId;
      if (clusterId) {
        templateExistsInCluster({ variables: { clusterId: clusterId } })
          .then((res) => {
            if (
              res.data.templateExistsInCluster &&
              res.data.templateExistsInCluster !== decoder(currentDocument?.id)
            ) {
              confirm({
                description: confirmCroppingMessage,
                confirmationText: 'Yes',
                cancellationText: 'No',
              })
                .then(() => {
                  removeTemplateInDocument({
                    variables: { documentId: res.data.templateExistsInCluster },
                  }).then((data) => {
                    if (data) {
                      saveCell({
                        variables: {
                          input: {
                            id: cellToBeUpdated?.valueElementId
                              ? decoder(cellToBeUpdated.valueElementId)
                              : '00000000-0000-0000-0000-000000000000',
                            dataElementId: decoder(selectedTableId),
                            documentId: decoder(currentDocument?.id),
                            rowIndex: selectedRow.current.id,
                            tableTypeColumnId: selectedColumn.current?.id,
                            status:
                              valueElementStatus.COMPLETE_NO_REVIEW_REQUIRED,
                            value: tableCoordinates,
                            coordinates: tableCoordinatesCopy,
                            currentUser: emailName,
                          },
                        },
                      });
                    }
                  });
                })
                .catch(() => {
                  return false;
                });
            } else {
              saveCell({
                variables: {
                  input: {
                    id: cellToBeUpdated?.valueElementId
                      ? decoder(cellToBeUpdated.valueElementId)
                      : '00000000-0000-0000-0000-000000000000',
                    dataElementId: decoder(selectedTableId),
                    documentId: decoder(currentDocument?.id),
                    rowIndex: selectedRow.current.id,
                    tableTypeColumnId: selectedColumn.current?.id,
                    status: valueElementStatus.COMPLETE_NO_REVIEW_REQUIRED,
                    value: tableCoordinates,
                    coordinates: tableCoordinatesCopy,
                    currentUser: emailName,
                  },
                },
              });
            }
          })
          .catch((err) => console.error(err));
      }
    }
  }, [tableCoordinates]);

  useEffect(() => {
    if (showCellShiftButtons) {
      updateSelectedCell();
    }
  }, [tableValues]);
  // #endregion UseEffects

  // #region Events
  const updateRowIndices = (currentIndex, operator, tableCells) => {
    // operator must be passed as string. currently only support add and subtract
    var operators = {
      '+': function (a, b) {
        return a + b;
      },
      '-': function (a, b) {
        return a - b;
      },
    };

    for (let i = currentIndex + 1; i < tableCells.length; i++) {
      tableCells[i].id = operators[operator](tableCells[i].id, 1);
    }

    return tableCells;
  };

  const onChangeTableValueInput = (row, cell, val, newValue) => {
    const rowIndex = row?.id;
    const columnIndex = getColumnIndex(rowIndex, val?.id);
    const validCell =
      tableCells[rowIndex]?.values[columnIndex]?.value !== undefined;

    if (validCell) {
      if (
        tableCells[rowIndex].values[columnIndex].status ==
        valueElementStatus.REVIEW_REQUIRED
      ) {
        tableCells[rowIndex].values[columnIndex].changed = true;
        cell
          ? (tableCells[rowIndex].values[columnIndex].status = cell?.status)
          : null;
      }

      tableCells[rowIndex].values[columnIndex].value = newValue;
    } else {
      let rowIndex = row.id;
      let colIndex = getColumnIndex(rowIndex, val?.id);
      tableCells[rowIndex].values[colIndex].value = newValue;
    }

    setTableIsDirty(true);
    setTableValues(tableCells);
  };

  const onClickInsertRow = (buttonId) => {
    let btnAbove = buttonId === 'btnRowAbove';
    let values = [];
    let newRowObject = {
      id: btnAbove ? selectedRow?.current?.id : selectedRow?.current?.id + 1,
    };

    for (let j = 0; j < tableData?.length; j++) {
      values.push({
        value: null,
        tableTypeColumnId: tableData[j]?.id,
        valueElementId: null,
        coordinates: null,
      });
    }

    newRowObject = {
      ...newRowObject,
      values,
    };

    btnAbove
      ? tableCells.splice(selectedRow?.current?.id, 0, newRowObject)
      : tableCells.splice(selectedRow?.current?.id + 1, 0, newRowObject);

    const newTableCells = updateRowIndices(newRowObject.id, '+', tableCells);

    selectedRow.current = null;
    setTableValues(newTableCells);
  };

  const onClickSave = () => {
    setTableIsDirty(false);
    let mergedList = [];
    let listToSave = [];
    // iterate through matrix, and map all "values" field into one array
    for (const row of tableValues) {
      let mappedValueElems = row.values.map((v) => {
        return {
          rowIndex: getRowIndex(row.id),
          value: v.value,
          tableTypeColumnId: v.tableTypeColumnId,
          valueElementId: v.valueElementId,
          status: v.status,
          confidenceLevel: v.confidenceLevel,
          coordinates: v.coordinates,
        };
      });
      mergedList = [...mergedList, mappedValueElems];
    }

    const cellList = mergedList.flat();
    for (const cell of cellList) {
      const element = sortedElements?.find((v) => v.id === cell.valueElementId);

      const cellDidNotChange =
        element &&
        element.id === cell.valueElementId &&
        element.value === cell.value &&
        element.rowIndex === cell.rowIndex &&
        element.status === cell.status &&
        element.coordinates === cell.coordinates;

      if (cellDidNotChange) {
        continue;
      } else if (cell.value || cell.valueElementId) {
        listToSave.push(cell);
      }
    }
    if (listToSave.length > 0) {
      listToSave = listToSave.map((x) => {
        return {
          id:
            x.valueElementId === null || x.valueElementId === undefined
              ? '00000000-0000-0000-0000-000000000000'
              : decoder(x.valueElementId),
          dataElementId: decoder(selectedTableId),
          documentId: decoder(currentDocument?.id),
          rowIndex: x.rowIndex,
          tableTypeColumnId: x.tableTypeColumnId,
          value: x.value,
          coordinates: x.coordinates,
          status: valueElementStatus.COMPLETE_NO_REVIEW_REQUIRED,
          currentUser: emailName,
        };
      });

      saveAllChangesToTable({
        variables: {
          inputs: listToSave,
        },
      });
    }
  };

  const updateSelectedCell = (row, cell) => {
    selectedColumn.current = cell ? cell : null;
    selectedRow.current = row ? row : null;
  };

  const onClickCroppedTextbox = (e, row, cell, cellValue) => {
    onClickCrop(e, cell, cellValue);
    updateSelectedCell(row, cell);
    toggleIsRowClicked(!isRowClicked);
  };

  const onClickActivateCellShift = (row, cell) => {
    updateSelectedCell(row, cell);
    toggleIsRowClicked(!isRowClicked);
  };

  const onClickRemoveRow = () => {
    const cellDeleteList = tableCells[selectedRow?.current?.id].values;
    for (const cellDelete of cellDeleteList) {
      if (cellDelete.value && cellDelete.valueElementId) {
        deleteCell({
          variables: {
            valueElementId: decoder(cellDelete.valueElementId),
          },
        });
      }
    }
    tableCells.splice(selectedRow?.current?.id, 1);
    const newTableCells = updateRowIndices(
      selectedRow?.current?.id - 1,
      '-',
      tableCells
    );
    setTableValues(newTableCells);
    selectedRow.current = null;
  };

  const onClickCropButton = () => {
    if (showCrop) {
      toggleCropModeOff();
      return;
    }
    toggleShowCrop(true);
    dispatch(
      setSnackbarInfo('You can select on cells to view and crop from image')
    );
  };

  const onClickCellShiftButton = () => {
    setShowCellShiftButtons(!showCellShiftButtons);
    dispatch(
      setSnackbarInfo(
        `Cell Shifting is ${showCellShiftButtons ? `in` : ``}active`
      )
    );
  };

  const toggleCropModeOff = () => {
    setIsCropDisabled(true);
    toggleShowCrop(false);
    updateSelectedCell();
  };
  // #endregion Events

  // #region Window/Modal Handlers
  const onExitTableModal = () => {
    toggleCropModeOff();
    setShowCellShiftButtons(false);
    if (!isTableTypePortalOpen && !tableIsDirty) {
      dispatch(
        setCurrentOpenTable({ isOpen: false, tableId: null, tableName: '' })
      );
    } else {
      toggleChangesMadeModalOpen(true);
    }
  };

  const onExitChangesMadeModal = () => {
    dispatch(
      setCurrentOpenTable({
        isOpen: false,
        tableId: null,
        tableName: '',
      })
    );
    toggleChangesMadeModalOpen(false);
    setTableIsDirty(false);
  };

  const onClickOpenWindowPortal = () => {
    toggleView(false);
  };

  const onPortalClose = () => {
    toggleCropModeOff();
    toggleTableTypePortal(!isTableTypePortalOpen);
  };

  const onCloseWindowTable = () => {
    toggleView(true);
  };

  const toggleView = (isOpen) => {
    dispatch(
      setCurrentOpenTable({
        isOpen: isOpen,
        tableId: selectedTableId,
        tableName: selectedTableName,
      })
    );
    toggleTableTypePortal(!isTableTypePortalOpen);
  };
  // #endregion Window/Modal Handlers

  const tableRenderer = useCallback(() => {
    return (
      <>
        {tableHeaders?.length > 0 ? (
          <ReactTable
            columns={tableHeaders}
            data={tableValues}
            hasDisableSelectHighlight={true}
            hasSearchBar={false}
            hasHoverHighlight={false}
            onSelected={onRowClickGetCurrentRow}
            hasRemoveCellPadding={true}
            useSkipPageReset={true}
          />
        ) : (
          <NoColumnsMessage>
            <Typography>No columns were added to this table</Typography>
          </NoColumnsMessage>
        )}
      </>
    );
  }, [
    tableValues,
    tableHeaders,
    tableData,
    selectedElementField,
    isRowClicked,
  ]);

  // #region Top Row Buttons
  const hasNoColumns = tableHeaders?.length === 0;
  const disableCropButton = !tableHeaders || hasNoColumns || isComplete;
  const disableSaveButton = !tableHeaders || hasNoColumns || isComplete;
  const disableAddButton = hasNoColumns || showCrop || isComplete;
  const disbleEditButton = showCrop || isComplete;
  const disableRemoveButton =
    !tableHeaders ||
    hasNoColumns ||
    selectedRow.current === null ||
    showCrop ||
    isComplete;

  const cellShiftToggleButton = (
    <div
      style={{ display: 'initial' }}
      onClick={() => onClickCellShiftButton()}
    >
      <ShiftButton
        primary
        variant='contained'
        showCellShiftButtons={showCellShiftButtons}
      >
        <UnfoldMore />
      </ShiftButton>
    </div>
  );

  const cropToggleButton = (
    <div style={{ display: 'initial' }}>
      <CropButton
        primary
        variant='contained'
        onClick={() => onClickCropButton()}
        style={{ margin: '0 5px' }}
        disabled={disableCropButton}
        showCrop={showCrop}
      >
        <CropIcon />
      </CropButton>
    </div>
  );

  const editColumnsButton = (
    <Button
      primary
      variant='contained'
      onClick={() => {
        dispatch(setTableColumnsModalOpen(true));
        toggleColumnsModal(true);
      }}
      disabled={disbleEditButton}
    >
      Columns
    </Button>
  );

  const removeRowButton = (
    <div style={{ display: 'initial' }}>
      <Button
        primary
        variant='contained'
        onClick={() => onClickRemoveRow()}
        style={{ margin: '0 5px' }}
        disabled={disableRemoveButton}
      >
        (-) row
      </Button>
    </div>
  );

  const addRowAboveButton = (
    <div style={{ display: 'initial' }}>
      <Button
        id='btnRowAbove'
        primary
        variant='contained'
        onClick={(e) => onClickInsertRow(e.target.id)}
        style={{ margin: '0 5px' }}
        disabled={disableAddButton}
      >
        (+) Row Above
      </Button>
    </div>
  );

  const addRowBelowButton = (
    <div style={{ display: 'initial' }}>
      <Button
        id='btnRowBelow'
        primary
        variant='contained'
        onClick={(e) => onClickInsertRow(e.target.id)}
        style={{ margin: '0 5px' }}
        disabled={disableAddButton}
      >
        (+) Row Below
      </Button>
    </div>
  );

  const saveButton = (
    <div style={{ float: 'right' }}>
      <Button
        primary
        variant='contained'
        onClick={() => onClickSave()}
        disabled={disableSaveButton}
      >
        Save
      </Button>
    </div>
  );

  const topButtonRow = (
    <div style={{ float: 'right', paddingLeft: '10px' }}>
      <Tooltip
        title={
          showCellShiftButtons
            ? 'Click to disable cell shifting'
            : 'Click to shift cells up and down'
        }
      >
        {cellShiftToggleButton}
      </Tooltip>
      <Tooltip
        title={
          isCropDisabled
            ? 'Click to view and crop coordinates'
            : 'Click to disable crop'
        }
        isShown={selectedRow.current === null || isTableTypePortalOpen}
        disableHoverListener={disableCropButton}
      >
        {cropToggleButton}
      </Tooltip>
      {editColumnsButton}
      <Tooltip
        title='Select a row to remove'
        isShown={selectedRow.current === null || isTableTypePortalOpen}
      >
        {removeRowButton}
      </Tooltip>
      <Tooltip
        placement='top'
        title={
          tableHeaders?.length > 0
            ? 'To insert a row above a specific location, select the row then click this button'
            : 'Add columns to the table to insert rows'
        }
        disableHoverListener={disableAddButton}
      >
        {addRowAboveButton}
      </Tooltip>
      <Tooltip
        placement='top'
        title={
          tableHeaders?.length > 0
            ? 'To insert a row below a specific location, select the row then click this button'
            : 'Add columns to the table to insert rows'
        }
        disableHoverListener={disableAddButton}
      >
        {addRowBelowButton}
      </Tooltip>
      <Tooltip
        placement='top'
        title={
          isTableTypePortalOpen ? 'Exit window mode' : 'Open in a new window'
        }
        disableHoverListener={disableSaveButton}
      >
        <div style={{ display: 'initial' }}>
          <Button
            primary
            variant='contained'
            style={{ margin: '0 5px' }}
            onClick={() =>
              isTableTypePortalOpen
                ? onCloseWindowTable()
                : onClickOpenWindowPortal()
            }
          >
            {isTableTypePortalOpen ? <OpenInNewOffIcon /> : <OpenInNewIcon />}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
  // #endregion Top Row Buttons

  // to inject styled-components styling into window popout DOM
  // https://styled-components.com/docs/api#stylesheetmanager
  const [newWindowNode, setNewWindowNode] = useState(null);
  const nwRef = useCallback((node) => setNewWindowNode(node), []);

  const windowPopoutModeTable = () => {
    return (
      <StyleSheetManager target={newWindowNode}>
        <MetlWindowPortal
          title='Table Value Elements '
          windowClosing={() => onPortalClose()}
          windowDimensions={'width=700,height=600,left=200,top=200'}
        >
          <div ref={nwRef}>
            <DialogTitle style={{ marginTop: '5px' }}>
              {selectedTableName}
              {topButtonRow}
            </DialogTitle>
            <DialogContent style={{ marginTop: '5%' }}>
              {tableRenderer()}
            </DialogContent>
            <SDialogActions>{saveButton}</SDialogActions>
          </div>
        </MetlWindowPortal>
      </StyleSheetManager>
    );
  };

  const changesMadeModal = () => {
    return (
      <Dialog
        open={changesMadeModalOpen}
        sx={{ zIndex: '1000' }}
        title='Unsaved Changes'
        hasActions
        handleSubmit={() => {
          onClickSave();
          onExitChangesMadeModal();
        }}
        submitLabel='Save'
        cancelLabel='Return to table'
        handleCancel={() => {
          toggleChangesMadeModalOpen(false);
        }}
      >
        Closing this table will result in unsaved changes being lost.
      </Dialog>
    );
  };

  return (
    <>
      {isTableOpen && (
        <Draggable handle='#draggable-dialog-title'>
          <SPaper aria-labelledby='draggable-dialog-title'>
            <SResizableBox
              minConstraints={[460, 600]}
              maxConstraints={[1200, 380]}
              width={600}
            >
              <DialogTitle
                id='draggable-dialog-title'
                style={{ cursor: 'move', marginTop: '5px' }}
              >
                {selectedTableName}
                <CancelButton
                  onClick={() => onExitTableModal()}
                  style={{ float: 'right', cursor: 'pointer', padding: '0px' }}
                  size='large'
                >
                  <CancelIcon fontSize='small' color='primary' />
                </CancelButton>
              </DialogTitle>
              <ButtonRow>{topButtonRow}</ButtonRow>
              <DialogContent>{tableRenderer()}</DialogContent>
              <SDialogActions>{saveButton}</SDialogActions>
            </SResizableBox>
          </SPaper>
        </Draggable>
      )}
      {changesMadeModalOpen && changesMadeModal()}
      {isColumnsModalOpen && (
        <TableColumnEditModal selectedElement={tableDataElement} />
      )}
      {isTableTypePortalOpen && windowPopoutModeTable()}
    </>
  );
}

export default TableDialog;

TableDialog.propTypes = {
  cluster: PropTypes.object,
  crop: PropTypes.object,
  currentDocumentId: PropTypes.string,
  getValueElements: PropTypes.func,
  isComplete: PropTypes.bool,
  isCropDisabled: PropTypes.bool,
  isTableOpen: PropTypes.bool,
  isTableTypePortalOpen: PropTypes.bool,
  onClickCrop: PropTypes.func,
  onClickRemoveCrop: PropTypes.func,
  onConfirmCrop: PropTypes.func,
  onExitCropMode: PropTypes.func,
  PaperComponent: PropTypes.any,
  selectedElementField: PropTypes.object,
  selectedTableId: PropTypes.any,
  selectedTableName: PropTypes.any,
  setIsCropDisabled: PropTypes.func,
  tableColumns: PropTypes.array,
  tableDataElement: PropTypes.array,
  toggleTableTypePortal: PropTypes.func,
  confirm: PropTypes.func,
  templateExistsInCluster: PropTypes.func,
  removeTemplateInDocument: PropTypes.func,
  confirmCroppingMessage: PropTypes.object,
  currentDocument: PropTypes.object,
  emailName: PropTypes.string,
  isReadOnly: PropTypes.bool,
  inputVal: PropTypes.array,
  setInputVal: PropTypes.func,
  checkInputCompleteStatus: PropTypes.func,
};
