import { gql } from '@apollo/client';

export const CORE_PROJECT_FIELDS = gql`
  fragment CoreProjectFields on Project {
    id
    name
    organizationId
    isComplete
    clusteringId
    isClusteringComplete
    createdOn
    lastModifiedOn
    resourceName
  }
`;

export const CORE_CLUSTER_FIELDS = gql`
  fragment CoreClusterFields on Cluster {
    id
    name
    isArchived
    projectId
    dataExtractionId
    totalDataElements
    totalDocuments
    totalCompleteDocuments
  }
`;

export const CORE_DOC_FIELDS = gql`
  fragment CoreDocFields on Document {
    id
    name
    originalName
    isComplete
    isConfirmed
    isTemplate
    documentUri
    projectId
    clusterId
    projectMemberId
    fileType
    totalValueElements
    totalElementsNeedReview
    createdBy
    createdOn
  }
`;

export const CORE_DELEMENT_FIELDS = gql`
  fragment CoreDElementFields on DataElement {
    id
    name
    description
    type
    organizationId
    createdOn
    lastModifiedOn
  }
`;

export const CORE_VELEMENT_FIELDS = gql`
  fragment CoreVElementFields on ValueElement {
    id
    value
    isReconciled
    documentId
    tableTypeColumnId
    dataElementId
    rowIndex
    status
    confidenceLevel
    coordinates
    createdOn
    lastModifiedOn
    status
  }
`;

export const CORE_TABLEDATA_FIELDS = gql`
  fragment CoreTableDataFields on TableTypeColumn {
    id
    name
    type
    dataElementId
    createdOn
    lastModifiedOn
  }
`;

export const CORE_TASKLIST_FIELDS = gql`
  fragment CoreTaskListFields on TaskList {
    id
    totalTasks
    projectId
  }
`;

export const CORE_TASKITEM_FIELDS = gql`
  fragment CoreTaskItemFields on TaskItem {
    id
    type
    status
    description
    assignedTo
    taskListId
    createdOn
    lastModifiedOn
  }
`;

export const CORE_PROJECTMEMBER_FIELDS = gql`
  fragment CoreProjectMemberFields on ProjectMember {
    id
    originalId
    projectId
    status
    justification
    statusNotes
    isExported
    assignedTo
    assignedOn
    previouslyAssignedTo
    createdOn
  }
`;

export const CORE_SUPPLEMENTALDATA_FIELDS = gql`
  fragment CoreSupplementalDataFields on SupplementalData {
    id
    elementName
    value
    projectMemberId
    originalProjectMemberId
    createdOn
    lastModifiedOn
  }
`;
