import { useState } from 'react';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import Fade from 'components/Fade';
import FormControl from 'components/FormControl';
import InputLabel from 'components/InputLabel';
import Grid from 'components/Grid';
import MenuItem from 'components/MenuItem';
import Modal from 'components/Modal';
import Select from 'components/Select';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//#region Styled Components
const SCard = styled(Card)`
  box-shadow: 0 1px 3px 1px rgba(196, 196, 196, 1);
  width: 440px;
  padding: 15px;
`;

const SModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserTitle = styled.span`
  color: slategrey;
  margin-left: auto;
  font-size: 0.9rem;
  float: right;
`;

const SelectField = styled(Select)`
  & fieldset {
    top: 0;
  }

  & legend {
    display: none;
  }
`;

const SInputLabel = styled(InputLabel)`
  &&.Mui-focused {
    color: ${(props) => props.theme.palette.background.lighter};
  }
`;
//#endregion Styled Components

export default function AssignTaskModal(props) {
  const { userDropdownOptions, handleModalClose, handleModalConfirm, isOpen } =
    props;

  const [form, setForm] = useState();

  const onSelectUser = (e) => setForm(e.target.value);

  const onClickConfirm = () => {
    handleModalConfirm(form);
  };

  const onClickCancel = () => {
    setForm('');
    handleModalClose();
  };

  const nameListDropdown = (
    <FormControl variant='outlined' fullWidth>
      <SInputLabel id='user-label'>Select User to Assign</SInputLabel>
      <SelectField
        labelId='user-label'
        disableAnimation
        onChange={onSelectUser}
        value={form}
      >
        {userDropdownOptions?.map((u) => (
          <MenuItem key={u.id} value={u}>
            {`${u.givenName} ${u.surname}`}
            <UserTitle>{u.jobTitle}</UserTitle>
          </MenuItem>
        ))}
      </SelectField>
    </FormControl>
  );

  const actionButtons = (
    <CardActions style={{ textAlign: 'end' }}>
      <Grid item xs={12}>
        <Button
          variant='outlined'
          color='error'
          onClick={onClickCancel}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button
          primary
          disabled={!form}
          variant='contained'
          onClick={onClickConfirm}
        >
          Confirm
        </Button>
      </Grid>
    </CardActions>
  );

  return (
    <SModal open={isOpen} onClose={() => onClickCancel()}>
      <Fade in={isOpen}>
        <SCard>
          <Grid container>
            <Grid item xs={11}>
              <CardHeader title='Assign User to Task' />
            </Grid>
          </Grid>
          <Grid container direction='column' spacing={2}>
            <CardContent>
              <Grid item xs={12}>
                {nameListDropdown}
              </Grid>
            </CardContent>
            {actionButtons}
          </Grid>
        </SCard>
      </Fade>
    </SModal>
  );
}

AssignTaskModal.propTypes = {
  handleModalClose: PropTypes.func,
  handleModalConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  userDropdownOptions: PropTypes.array,
};
