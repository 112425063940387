import MAutocomplete from '@mui/lab/Autocomplete';
import styled from 'styled-components';

const StyledAutocomplete = styled(MAutocomplete)`
  &.Mui-focused {
    fieldset {
      border: ${(props) =>
        `2px solid ${props.theme.palette.primary.main}  !important`};
    }

    label {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export default function Autocomplete(props) {
  return <StyledAutocomplete {...props} />;
}
