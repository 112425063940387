import PropTypes from 'prop-types';
import MBadge from '@mui/material/Badge';
import styled, { keyframes } from 'styled-components';

export default function Badge(props) {
  const badgeProps = Object.assign({}, props);
  delete badgeProps.rowStatus;
  return <MBadge {...badgeProps} />;
}

//#region Styled Components
const ripple = keyframes`
  0% {
      transform: scale(.8);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
`;

const SBadge = styled(MBadge)`
  .MuiBadge-badge {
    background-color: rgba(68, 183, 0, 1);
    color: rgba(68, 183, 0, 1);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: 1.2s ${ripple} infinite ease-in-out;
      border: 1px solid currentColor;
      content: '';
    }
  }
`;

const NewBadge = styled(SBadge)`
  .MuiBadge-badge {
    background-color: slategray;
    color: slategray;
  }
`;

const InProgressBadge = styled(SBadge)`
  .MuiBadge-badge {
    background-color: rgba(255, 136, 0, 1);
    color: rgba(255, 136, 0, 1);
  }
`;

const SuccessBadge = styled(SBadge)`
  .MuiBadge-badge {
    background-color: rgba(0, 154, 68, 1);
    color: rgba(0, 154, 68, 1);
  }
`;
//#endregion Styled Components

export function TaskBadge({ rowStatus }) {
  return (
    <>
      {rowStatus === 'NEW' && <NewBadge variant='dot' />}
      {rowStatus === 'INPROGRESS' && <InProgressBadge variant='dot' />}
      {rowStatus === 'COMPLETE' && <SuccessBadge variant='dot' />}
    </>
  );
}

TaskBadge.propTypes = {
  rowStatus: PropTypes.string,
};
