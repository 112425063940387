import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// We cannot useMemo, because it is not guaranteed to never rerun.
// https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
function useConst(init) {
  const ref = useRef(null);
  if (ref.current === null) {
    ref.current = init();
  }
  return ref.current;
}

export default function MetlWindowPortal({
  title,
  windowDimensions,
  children,
  windowClosing,
}) {
  // initializes variables to hold the title element and the container element
  const titleEl = useConst(() => document.createElement('title'));
  const containerEl = useConst(() => document.createElement('div'));

  // copies the stylesheet from the parent DOM to the window DOM
  function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
      if (styleSheet.cssRules) {
        const newStyleEl = sourceDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule) => {
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });
        targetDoc.head.appendChild(newStyleEl);
      } else if (styleSheet.href) {
        const newLinkEl = sourceDoc.createElement('link');
        newLinkEl.rel = 'stylesheet';
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    });
  }

  // createCache allows for low level customization of how styles are injected by emotion
  // https://emotion.sh/docs/@emotion/cache
  const cache = useConst(() =>
    createCache({ key: 'external', container: containerEl })
  );

  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    const externalWindow = window.open('', '_blank', windowDimensions);

    if (!externalWindow) {
      windowClosing();
      return;
    }

    copyStyles(document, externalWindow.document);

    externalWindow.addEventListener('beforeunload', windowClosing);
    externalWindow.document.head.appendChild(titleEl);
    externalWindow.document.body.appendChild(containerEl);

    setOpened(true);

    return () => {
      externalWindow.close();
    };
  }, []);

  useEffect(() => {
    titleEl.innerText = title;
  }, [title, titleEl]);

  return isOpened
    ? ReactDOM.createPortal(
        <CacheProvider value={cache}>{children}</CacheProvider>,
        containerEl
      )
    : null;
}

/*
The component takes in dimensions and a handler to close the portal
Props that MUST be passed down (or else wonkiness ensues)
porps.windowClosing() : propagate an action to the parent component where the boolean value of the portal is managed
props.windowDimensions : receives portal dimensions from the parent (should look like: 'width=700,height=650,left=200,top=200')
*/
