import { configureStore } from '@reduxjs/toolkit';
import tasksReducer from './slices/taskSlice';
import projectsReducer from './slices/projectsSlice';
import usersReducer from './slices/usersSlice';
import dataEntryReducer from './slices/dataEntrySlice';
import snackBarReducer from './slices/snackbarsSlice';
import analysisReducer from './slices/analysisSlice';

export default configureStore({
  reducer: {
    dataEntry: dataEntryReducer,
    tasks: tasksReducer,
    projects: projectsReducer,
    users: usersReducer,
    snackbars: snackBarReducer,
    analysis: analysisReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
