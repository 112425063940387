const theme = {
  primary: {
    white: 'rgb(255, 255, 255)',
    color: 'rgb(134, 188, 37)',
    black: 'rgb(0, 0, 0)',
  },
  palette: {
    primary: {
      lightest: 'rgb(196, 214, 0)',
      lighter: 'rgb(67, 176, 42)',
      main: 'rgb(134, 188, 37)',
      darker: 'rgb(4, 106, 56)',
      darkest: 'rgb(44, 82, 52)',
    },
    secondary: {
      lightest: 'rgb(98, 181, 229)',
      lighter: 'rgb(0, 163, 224)',
      main: 'rgb(0, 151, 169)',
      darker: 'rgb(0, 118, 168)',
      darkest: 'rgb(1, 33, 105)',
    },
    background: {
      white: 'rgb(225, 225, 225)',
      lightest: 'rgb(208, 208, 206)',
      lighter: ' rgb(231, 230, 225)',
      main: 'rgb(246, 247, 249)',
      darker: 'rgb(117, 120, 123)',
      darkest: 'rgb(83, 86, 90)',
      black: 'rgb(0, 0, 0)',
    },
    error: {
      lighter: 'rgb(229, 115, 115)',
      main: 'rgb(244, 67, 54)',
      darker: 'rgb(211, 47, 47)',
    },
  },
  sidenav: {
    padding: '80px',
  },
  typography: {
    header: {
      fontFamily: 'Open Sans',
      fontWeight: '800',
    },
    subheader: {
      bold: {
        fontFamily: 'Mulish',
        fontWeight: '700',
      },
      regular: {
        fontFamily: 'Mulish',
        fontWeight: '400',
      },
    },
    reading: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
    },
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: '0 1px 3px 1px rgba(196, 196, 196, 1)',
        border: '1px solid rgba(225, 226, 240, 1)',
        borderRadius: '8px',
        overflow: 'visible',
      },
    },
    MuiCardContent: {
      root: {
        height: 'calc(70vh)',
        'min-height': '250px',
        'overflow-y': 'auto',
      },
    },
  },
};

export default theme;
