import { AddCircle } from '@mui/icons-material';
import Autocomplete from 'components/Autocomplete';
import CircularProgress from 'components/CircularProgress';
import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { GET_CLUSTERS_BY_PROJECT } from 'services/GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';
import { decoder } from 'helpers/graphQL_Encoder';
import _cloneDeep from 'lodash.clonedeep';
import Paper from 'components/Paper';
import TableContainer from 'components/TableContainer';
import VirtualScrollChild from 'containers/ReactTable/TableComponents/VirtualScrollChild';
import Grid from 'components/Grid';
import Table from 'components/Table';
import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';
import { tableCellClasses } from '@mui/material';
import styled from 'styled-components';
import TableRow from 'components/TableRow';
import TableBody from 'components/TableBody';
import { NewClusterModal } from 'pages/Cluster/modals';
import InputAdornment from 'components/InputAdornment';

const STableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme?.palette?.common?.black,
    color: theme?.palette?.common?.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: 20,
}));

const STableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme?.palette?.action?.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const ClusterAutoComplete = (props) => {
  const {
    currentProjectId,
    defaultClusterOrder = [],
    clusterSelected,
    currentCluster,
    setClusterValues = () => null,
    fullWidth = false,
    onChange = () => null,
    size = 'small',
    ref = null,
    checkClusterNameExists = () => null,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [galleryClusters, setGalleryClusters] = useState([]);
  const [newClusterOpen, setNewClusterOpen] = useState(false);

  const [getGalleryClusters, { fetchMore: fetchMoreClusters, loading }] =
    useLazyQuery(GET_CLUSTERS_BY_PROJECT, {
      onCompleted: (data) => {
        if (data?.clusters?.nodes) {
          setGalleryClusters(data?.clusters);
        }
      },
      fetchPolicy: 'no-cache',
    });

  let timer;
  const handleInstantSearch = (value) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchValue(value);
    }, 1000);
  };

  const handleGetGalleryClusters = () => {
    getGalleryClusters({
      variables: {
        sortOrder: defaultClusterOrder,
        filters: {
          projectId: { eq: decoder(currentProjectId) },
        },
      },
    });
  };

  const handleFetchMoreClusters = () => {
    fetchMoreClusters({
      variables: {
        sortOrder: defaultClusterOrder,
        filters: {
          projectId: { eq: decoder(currentProjectId) },
        },
        cursor: galleryClusters.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // we are not using prev because we don't use graphQL cache for our data
        if (!fetchMoreResult) return galleryClusters;
        let newResult = _cloneDeep(fetchMoreResult);
        newResult.clusters.nodes = [
          ...galleryClusters.nodes,
          ...newResult.clusters.nodes,
        ];
        setGalleryClusters(newResult.clusters);
      },
    });
  };

  const handleRefetchClusterSearch = (queryValue) => {
    if (queryValue) {
      getGalleryClusters({
        variables: {
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            name: { contains: queryValue.trim() },
          },
        },
      });
    } else {
      getGalleryClusters({
        variables: {
          sortOrder: defaultClusterOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
          },
        },
      });
    }
  };

  const closeNewCluster = () => setNewClusterOpen(false);

  const handleScroll = (e, hasNextPage) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.clientHeight + 5 >=
        e.currentTarget.scrollHeight &&
      hasNextPage
    ) {
      handleFetchMoreClusters();
    }
  };

  useEffect(() => {
    if (galleryClusters?.nodes?.length > 0) {
      if (clusterSelected) {
        let cluster = galleryClusters.nodes.find(
          (x) => x?.id === clusterSelected?.id
        );
        setClusterValues(cluster);
      }
    }
  }, [galleryClusters]);

  useEffect(() => {
    handleRefetchClusterSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleGetGalleryClusters();
  }, []);

  const listboxProps = useMemo(
    () => ({
      onScroll: (event) => {
        handleScroll(event, galleryClusters?.pageInfo?.hasNextPage);
      },
      style: { maxHeight: '300px' },
    }),
    [galleryClusters]
  );

  const renderOption = (renderProps, option) => {
    return (
      <Paper>
        <TableContainer style={{ maxHeight: 400 }}>
          <VirtualScrollChild>
            <Grid container alignItems='center'>
              <Grid
                item
                sx={{
                  width: '100%',
                }}
              >
                <Table stickyHeader={true}>
                  <STableRow>
                    <TableHead
                      style={{
                        display:
                          galleryClusters?.nodes?.indexOf(option) === 0
                            ? 'block'
                            : 'none',
                      }}
                    >
                      <STableCell
                        width='125'
                        align='left'
                        style={{
                          height: '100%',
                        }}
                      >
                        Name
                      </STableCell>
                      <STableCell
                        width='100'
                        align='left'
                        style={{
                          height: '100%',
                        }}
                      >
                        Data Elements
                      </STableCell>
                      <STableCell
                        width='100'
                        align='left'
                        style={{
                          height: '100%',
                        }}
                      >
                        Documents Count
                      </STableCell>
                      <STableCell
                        width='100'
                        align='left'
                        style={{
                          height: '100%',
                        }}
                      >
                        Complete Documents
                      </STableCell>
                    </TableHead>
                  </STableRow>
                  <TableBody>
                    <STableRow {...renderProps} key={option?.id?.toString()}>
                      <STableCell
                        width='150'
                        component='th'
                        align='left'
                        scope='option'
                        key={option.id}
                        style={{
                          height: '100%',
                          paddingLeft: 0,
                        }}
                      >
                        {option?.name}
                      </STableCell>
                      <STableCell
                        width='100'
                        align='left'
                        scope='option'
                        key={option.id}
                        style={{
                          height: '100%',
                          paddingLeft: 0,
                        }}
                        component='th'
                      >
                        {option?.totalDataElements
                          ? option?.totalDataElements
                          : 0}
                      </STableCell>
                      <STableCell
                        width='100'
                        align='letf'
                        component='th'
                        scope='option'
                        key={option.id}
                        style={{
                          paddingLeft: 0,
                          height: '100%',
                        }}
                      >
                        {option?.totalDocuments ? option?.totalDocuments : 0}
                      </STableCell>
                      <STableCell
                        width='100'
                        align='left'
                        component='th'
                        scope='option'
                        key={option.id}
                        style={{
                          paddingLeft: 0,
                          height: '100%',
                        }}
                      >
                        {option.totalCompleteDocuments
                          ? option?.totalCompleteDocuments
                          : 0}
                      </STableCell>
                    </STableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </VirtualScrollChild>
        </TableContainer>
      </Paper>
    );
  };

  return (
    <>
      <Autocomplete
        ref={ref}
        fullWidth={fullWidth}
        value={clusterSelected}
        noOptionsText='No Clusters'
        autoComplete
        size={size}
        onInputChange={(e, value) => handleInstantSearch(value)}
        onChange={(e, value) => {
          onChange(value);
        }}
        filterOptions={(x) => x}
        options={galleryClusters?.nodes || []}
        getOptionDisabled={(option) => option?.id === currentCluster?.id}
        ListboxProps={listboxProps}
        loading={loading}
        getOptionLabel={(options) => options.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Select Cluster'
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment>
                  <IconButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => {
                      setNewClusterOpen(true);
                    }}
                  >
                    <Tooltip title='Add Cluster'>
                      <AddCircle />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={renderOption}
      />
      <NewClusterModal
        newClusterOpen={newClusterOpen}
        closeNewCluster={closeNewCluster}
        galleryClusters={galleryClusters}
        setNewClusterOpen={setNewClusterOpen}
        currentProjectId={currentProjectId}
        setNewCluster={setClusterValues}
        setGalleryClusters={setGalleryClusters}
        checkClusterNameExists={checkClusterNameExists}
      />
    </>
  );
};

ClusterAutoComplete.propTypes = {
  clusterSelected: PropTypes.any,
  currentCluster: PropTypes.object,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  ref: PropTypes.any,
  currentProjectId: PropTypes.any,
  defaultClusterOrder: PropTypes.any,
  setClusterValues: PropTypes.any,
  checkClusterNameExists: PropTypes.func,
};
