import MEdit from '@mui/icons-material/Edit';
import styled from 'styled-components';

const StyledEdit = styled(MEdit)`
  color: ${(props) => props.theme.palette.primary.main};
`;

export default function Edit(props) {
  return <StyledEdit {...props} />;
}
