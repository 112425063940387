import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import CardContent from 'components/CardContent';
import Dialog from 'components/Dialog';
import Fade from 'components/Fade';
import FormControl from 'components/FormControl';
import InputLabel from 'components/InputLabel';
import Grid from 'components/Grid';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import TextField from 'components/TextField';
import FormHelperText from 'components/FormHelperText';
import { displayElementType } from 'helpers/enumToType';
import styled from 'styled-components';

//#region Styled Components
const DialogContain = styled('div')`
  width: 470px;
`;

const AddElementButton = styled(Button)`
  float: right;
`;

const FormArea = styled(CardContent)`
  display: contents;
`;

const ModalGrid = styled(Grid)`
  padding: 8px;
`;

const SFormControl = styled(FormControl)`
  label.Mui-focused {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const SInputLabel = styled(InputLabel)`
  &&.Mui-focused {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const STextField = styled(TextField)`
  label.Mui-focused {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

//#endregion Styled Components

function DialogWindow(props) {
  const { dialogOpen, submitting, onClickCancelDialog, onClickConfirmDialog } =
    props;
  return (
    <Dialog
      open={dialogOpen}
      title={
        submitting
          ? 'Are you sure you want to add element?'
          : 'Are you sure you want to exit?'
      }
      hasActions
      cancelLabel='No'
      handleCancel={onClickCancelDialog}
      submitLabel='Yes'
      handleSubmit={onClickConfirmDialog}
    >
      {submitting
        ? `You will not be able to go back and edit the title or data type of this element once added.
          Please note that administrators have permission to edit element descriptions only; title and data type are final once submitted.`
        : 'Your entry will not be saved.'}
    </Dialog>
  );
}

function ModalActionButtons(props) {
  const { onClickAdd, onModalExitRequest, input, isDuplicate } = props;
  return (
    <Grid container>
      <ModalGrid item xs={6}>
        <Button color='error' variant='outlined' onClick={onModalExitRequest}>
          Cancel
        </Button>
      </ModalGrid>
      <ModalGrid item xs={6}>
        <AddElementButton
          disabled={!input.name || !input.type || isDuplicate}
          variant='contained'
          primary
          onClick={onClickAdd}
        >
          Add Element
        </AddElementButton>
      </ModalGrid>
    </Grid>
  );
}

function DataTitleInput(props) {
  const { onChangeName, input, existingElementNames } = props;

  const existingName = useRef();

  existingElementNames.indexOf(input.name) >= 0
    ? (existingName.current = true)
    : (existingName.current = false);

  return (
    <SFormControl required fullWidth variant='outlined'>
      <TextField
        variant='outlined'
        value={input.name}
        onChange={onChangeName}
        label='Element Name'
        inputProps={{ maxLength: 200 }}
      />
      {!input.name && (
        <FormHelperText>* Please enter an element name</FormHelperText>
      )}
      {existingName.current && (
        <FormHelperText>
          * An element with that name already exists
        </FormHelperText>
      )}
    </SFormControl>
  );
}

function DataTypeSelectInput(props) {
  const { input, onSelectDataType, dataTypeList } = props;

  return (
    <FormControl variant='outlined' fullWidth>
      <SInputLabel>Data Type</SInputLabel>
      <Select
        fullWidth
        required
        value={input.type}
        onChange={onSelectDataType}
        label='Data Type'
        disableAnimation
      >
        {dataTypeList.map((d) => (
          <MenuItem key={d} value={d}>
            {displayElementType(d)}
          </MenuItem>
        ))}
      </Select>
      {!input.type && (
        <FormHelperText>* Please select a data type</FormHelperText>
      )}
    </FormControl>
  );
}

function DataDescriptionInput(props) {
  const { count, input, onChangeDescription } = props;
  const maxCount = 1000;
  return (
    <div>
      <STextField
        fullWidth
        multiline
        rows={8}
        value={input.description}
        onChange={onChangeDescription}
        label='Element Description (optional)'
        variant='outlined'
        inputProps={{ maxLength: `${maxCount}` }}
      />
      <p style={{ textAlign: 'left', fontSize: 12 }}>
        Maximum {count}/{maxCount} characters
      </p>
    </div>
  );
}

export default function AddElementModal(props) {
  const {
    isOpen,
    onModalExitClick,
    onAddElement,
    dataTypeList,
    existingElementNames,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [input, setInput] = useState({
    name: '',
    type: '',
    description: '',
  });
  const [count, setCount] = useState(0);

  const dialogToggler = () => setDialogOpen(!dialogOpen);

  const onSelectDataType = (e) => setInput({ ...input, type: e.target.value });

  const onChangeName = (e) => setInput({ ...input, name: e.target.value });

  const onChangeDescription = (e) => {
    setInput({
      ...input,
      description: e.target.value,
    });
    setCount(e.target.value.length);
  };

  const onClickAdd = () => {
    setSubmitting(true);
    dialogToggler();
  };

  const onModalExitRequest = () => {
    if (input.name || input.type || input.description) {
      setSubmitting(false);
      dialogToggler();
    } else {
      onModalExitClick();
    }
  };

  const onClickCancelDialog = () => dialogToggler();

  const onClickConfirmDialog = () => {
    if (submitting) {
      onAddElement(input);
    }
    dialogToggler();
    setInput('');
    onModalExitClick();
  };

  const checkDuplicate = () => {
    return existingElementNames.includes(input.name);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onModalExitRequest}
        title='Add New Element'
        hasActions
        handleCancel={onModalExitRequest}
        cancelLabel='Cancel'
        handleSubmit={onClickAdd}
        submitLabel='Add Element'
        submitDisabled={!input.name || !input.type || checkDuplicate()}
      >
        <Fade in={isOpen}>
          <DialogContain>
            <Grid container>
              <FormControl fullWidth>
                <FormArea>
                  <ModalGrid item xs={12}>
                    <DataTitleInput
                      input={input}
                      existingElementNames={existingElementNames}
                      onChangeName={onChangeName}
                    />
                  </ModalGrid>
                  <ModalGrid item xs={12}>
                    <DataTypeSelectInput
                      input={input}
                      dataTypeList={dataTypeList}
                      onSelectDataType={onSelectDataType}
                    />
                  </ModalGrid>
                  <ModalGrid item xs={12}>
                    <DataDescriptionInput
                      count={count}
                      input={input}
                      onChangeDescription={onChangeDescription}
                    />
                  </ModalGrid>
                </FormArea>
              </FormControl>
            </Grid>
          </DialogContain>
        </Fade>
      </Dialog>
      <DialogWindow
        dialogOpen={dialogOpen}
        submitting={submitting}
        onClickCancelDialog={onClickCancelDialog}
        onClickConfirmDialog={onClickConfirmDialog}
      />
    </>
  );
}

DataTitleInput.propTypes = {
  input: PropTypes.any,
  onChangeName: PropTypes.func,
  existingElementNames: PropTypes.array,
};

DataTypeSelectInput.propTypes = {
  dataTypeList: PropTypes.array,
  input: PropTypes.any,
  onSelectDataType: PropTypes.func,
};

DataDescriptionInput.propTypes = {
  count: PropTypes.int,
  input: PropTypes.any,
  onChangeDescription: PropTypes.func,
};
DialogWindow.propTypes = {
  dialogOpen: PropTypes.any,
  onClickCancelDialog: PropTypes.func,
  onClickConfirmDialog: PropTypes.func,
  submitting: PropTypes.any,
};

AddElementModal.propTypes = {
  dataTypeList: PropTypes.array,
  isOpen: PropTypes.bool,
  onAddElement: PropTypes.func,
  onModalExitClick: PropTypes.func,
  existingElementNames: PropTypes.array,
};

ModalActionButtons.propTypes = {
  input: PropTypes.any,
  onClickAdd: PropTypes.func,
  onModalExitRequest: PropTypes.func,
  isDuplicate: PropTypes.bool,
};
