import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Box from 'components/Box';
import { config } from 'config';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import DialogContentText from 'components/DialogContentText';
import DialogTitle from 'components/DialogTitle';
import Divider from 'components/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { forwardRef, useState } from 'react';
import Grid from 'components/Grid';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import Slide from 'components/Slide';
import styled from 'styled-components';
import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import Typography from 'components/Typography';

const cookiesData = require('components/Dialog/CookiesData.json');

//#region Styled Components

const CookiesDescriptionTabPanel = styled(TabPanel)`
  overflow-y: auto;
`;

const CookiesDescriptionTabs = styled(Tabs)`
  min-width: 200px;
  border-right: 1;
  border-color: divider;
  & button {
    align-items: flex-end;
    text-align: right;
    text-transform: none;
    &.Mui-selected {
      color: ${(props) => props.theme.palette.primary.main};
      font-weight: bold;
    }
  }
  & .MuiTabs-indicator {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const CookiePolicyLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const CookiesDetailsTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.palette.primary.darker};
  }
`;

const AlwaysActiveGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AlwaysActiveTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
`;
//#endregion Styled Components

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function StrictlyNecessaryCookies() {
  return (
    <Box>
      {Object.entries(cookiesData).map(([groupKey, groupVal]) => (
        <Accordion key={groupKey} disableGutters sx={{ mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='show-cookies-content'
            id='show-cookies-header'
          >
            <Typography>{groupKey}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(groupVal).map(([cookiesKey, cookiesVal]) => (
              <Box
                key={cookiesKey}
                sx={{
                  my: 4,
                  px: 2,
                  pb: 1,
                  background: 'rgba(0,0,0,0.1)',
                  fontSize: 12,
                }}
              >
                {Object.entries(cookiesVal).map(([k, v]) => (
                  <Grid key={k} container spacing={2}>
                    <Grid item xs={4} mb={1}>
                      {k}
                    </Grid>
                    <Grid item xs={8} mb={1}>
                      {v}
                    </Grid>
                  </Grid>
                ))}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default function CookiesDescriptionDialog(props) {
  const [value, setValue] = useState(0);

  // "event", though not used, is needed here for tab animations
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.isClosed}
      fullScreen
      title={`${config.APPNAME}'s Privacy Preference Center`}
      TransitionComponent={Transition}
      aria-labelledby='cookie-selection-title'
      aria-describedby='cookie-selection-description'
      handleCancel={props.isClosed}
      hasActions
      requiredSubmit
      submitLabel='Close'
      handleSubmit={props.isClosed}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          height: '100%',
        }}
      >
        <CookiesDescriptionTabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
        >
          <Tab label='Your Privacy' {...a11yProps(0)} />
          <Tab label='Strictly Necessary Cookies' {...a11yProps(1)} />
        </CookiesDescriptionTabs>
        <CookiesDescriptionTabPanel value={value} index={0}>
          <DialogTitle
            sx={{ fontWeight: 'bold' }}
            id='cookie-selection-privacy'
          >
            {`Your Privacy`}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText id='cookie-selection-privacy-description'>
              When you visit any website, it may store or retrieve information
              on your browser, mostly in the form of cookies. This information
              might be about you, your preferences or your device and is mostly
              used to make the site work as you expect it to. The information
              does not usually directly identify you, but it can give you a more
              personalized web experience.
              <br />
              <br />
              Because we respect your right to privacy, you can choose not to
              allow some types of cookies. Click on the different category
              headings to find out more and change our default settings.
              However, blocking some types of cookies may impact your experience
              of the site and the services {config.APPNAME} is able to offer.
              <br />
              <br />
              <CookiePolicyLink
                href='https://cookienotice.deloitte.com/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Cookie Policy
              </CookiePolicyLink>
            </DialogContentText>
          </DialogContent>
        </CookiesDescriptionTabPanel>
        <CookiesDescriptionTabPanel value={value} index={1}>
          <DialogTitle
            sx={{ fontWeight: 'bold' }}
            id='cookie-selection-necessary'
          >
            <Grid container xs={{ justifyContent: 'space-between' }}>
              <Grid item xs={8}>
                Strictly Necessary Cookies
              </Grid>
              <AlwaysActiveGrid item xs={4}>
                <AlwaysActiveTypography variant='subtitle1'>
                  Always Active
                </AlwaysActiveTypography>
              </AlwaysActiveGrid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText id='cookie-selection-necessary-description'>
              These cookies are necessary for {config.APPNAME} to function and
              cannot be switched off in our systems. They are usually only set
              in response to actions made by you which amount to a request for
              services, such as setting your privacy preferences or filling in
              forms.
            </DialogContentText>
            <br />
            <Accordion disableGutters elevation={0}>
              <AccordionSummary
                aria-controls='show-cookies-groups-content'
                id='show-cookies-groups-header'
                sx={{ padding: 0, width: 'fit-content' }}
                expandIcon={<ExpandMoreIcon />}
              >
                <CookiesDetailsTypography>
                  Cookies Details
                </CookiesDetailsTypography>
              </AccordionSummary>
              <AccordionDetails>
                <StrictlyNecessaryCookies />
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        </CookiesDescriptionTabPanel>
      </Box>
    </Dialog>
  );
}

CookiesDescriptionDialog.propTypes = {
  children: PropTypes.any,
  isClosed: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
