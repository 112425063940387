function SvgConnectIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.272 16.728a3.103 3.103 0 010-4.385l2.829-2.828-1.344-1.343L3.93 11a5.002 5.002 0 000 7.071 5.002 5.002 0 007.071 0l2.829-2.828-1.344-1.344-2.828 2.829a3.103 3.103 0 01-4.385 0zm3.607-2.193l5.657-5.656-1.415-1.415-5.657 5.657 1.415 1.415zM11 3.93L8.172 6.757l1.343 1.344 2.829-2.829a3.103 3.103 0 014.384 0 3.103 3.103 0 010 4.384l-2.829 2.829 1.344 1.343L18.07 11a5.002 5.002 0 000-7.071 5.002 5.002 0 00-7.071 0z"
        fill="#007CB0"
      />
      <defs>
        <linearGradient
          id="connectIcon_svg__grad1"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#15c39a" />
          <stop offset="100%" stopColor="#3f51b5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgConnectIcon;
