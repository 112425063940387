import Button from 'components/Button';
import Grid from 'components/Grid';
import TextField from 'components/TextField';
import { decoder } from 'helpers/graphQL_Encoder';
import { useDispatch } from 'react-redux';
import {
  setHighlightedValue,
  setNotReviewed,
} from 'store/Redux/slices/dataEntrySlice';
import styled from 'styled-components';
import CheckIcon from 'icons/Mui/Check';
import Typography from 'components/Typography';
import MaskedInputComponent from 'helpers/MaskedInputComponent';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import Divider from 'components/Divider';
import InputAdornment from 'components/InputAdornment';
import { ImageCropIconArea } from 'containers/Cropper';

const SupplementalButton = styled(Button)`
  color: white;
  background-color: ${(props) => props.theme.palette.primary.main};
  text-align: center;
  height: inherit;
  border-radius: 8px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.primary.darker};
  }
`;

const InputCell = styled(TextField)`
  background-color: ${({
    belowConfidence,
    currentDocument,
    element,
    valueElementStatus,
  }) =>
    element &&
    belowConfidence &&
    element?.status === valueElementStatus.REVIEW_REQUIRED &&
    !currentDocument?.isComplete
      ? 'yellow'
      : null};

  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ButtonText = styled(Typography)`
  padding: 2%;
  text-transform: none;
`;

const DataEntryFormSets = (props) => {
  const {
    getSortedDataElements,
    inputVal,
    valueElementStatus,
    inputValue,
    currentDocument,
    croppedCoordinates,
    isCropDisabled,
    crop,
    isTableTypePortalOpen,
    supplementalData,
    onClickCrop,
    onClickRemoveCrop,
    onExitCropMode,
    onConfirmCrop,
    selectedElementField,
    isTableButtonDisabled,
    onClickTableButton,
    isReadOnly,
    checkInputs,
    handleType,
    handleSuppButtonClick,
    isExtractionLoading,
    open,
  } = props;

  const dispatch = useDispatch();
  const confidenceWarninglevel = 0.8;

  const generateFormSetElementOrder = () => {
    const elements = getSortedDataElements();
    let orderedElements = [];

    if (elements) {
      for (let element of elements) {
        let vElement = inputVal?.find(
          (x) => x.dataElementId === decoder(element.id)
        );

        if (vElement?.status === valueElementStatus.REVIEW_REQUIRED) {
          orderedElements.unshift(element);
        } else {
          orderedElements.push(element);
        }
      }
    }
    return orderedElements;
  };

  const handleInputCellChange = (e, vElement) => {
    if (vElement?.value === e.target.value) return;
    if (e.target.value === '' && vElement?.value)
      e.target.value = vElement?.value;
    handleType(e.target.value, vElement?.dataElementId);
    if (vElement.status !== valueElementStatus.REVIEW_REQUIRED) {
      checkInputs(
        e.target.value,
        decoder(currentDocument.id),
        vElement?.dataElementId,
        vElement?.coordinates,
        vElement
      );
    }
  };

  const formSets = generateFormSetElementOrder()?.map((item, index) => {
    let vElement = inputVal?.find((x) => x.dataElementId === decoder(item.id));
    let belowConfidence = vElement?.confidenceLevel < confidenceWarninglevel;

    if (
      belowConfidence &&
      vElement.status === valueElementStatus.REVIEW_REQUIRED
    ) {
      dispatch(setNotReviewed(true));
    }

    let value = inputValue(item);
    const suppData = supplementalData?.find(
      (element) => element.elementName === item.name
    );

    const cropIcon = (
      <ImageCropIconArea
        crop={crop}
        croppedCoordinates={croppedCoordinates}
        isCropDisabled={isCropDisabled || isTableTypePortalOpen || open}
        isDocComplete={currentDocument?.isComplete}
        item={item}
        onClickCrop={onClickCrop}
        onClickRemoveCrop={onClickRemoveCrop}
        onConfirmCrop={onConfirmCrop}
        onExitCropMode={onExitCropMode}
        selectedElementField={selectedElementField}
        value={value}
        isReadOnly={isReadOnly}
        currentDocument={currentDocument}
      />
    );

    return (
      <>
        <Grid item key={item.id} textAlign='center' xs={12}>
          <Grid container spacing={2}>
            {item.type === 'TABLE' ? (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  primary
                  onClick={(e) => onClickTableButton(e, item)}
                  variant='contained'
                  disabled={isTableButtonDisabled}
                >
                  {item.name}
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <InputCell
                    id='valueElementInput'
                    disabled={currentDocument.isComplete || isReadOnly}
                    fullWidth
                    value={inputValue(item)}
                    label={item.name.toLocaleUpperCase()}
                    variant='outlined'
                    size='small'
                    currentDocument={currentDocument}
                    belowConfidence={belowConfidence}
                    element={vElement}
                    valueElementStatus={valueElementStatus}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: MaskedInputComponent,
                      inputProps: {
                        component: item,
                      },
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment sx={{ paddingLeft: '1%' }}>
                          {!currentDocument?.isComplete &&
                            belowConfidence &&
                            vElement?.status ==
                              valueElementStatus.REVIEW_REQUIRED && (
                              <Button
                                primary
                                disabled={
                                  vElement?.status !==
                                    valueElementStatus.REVIEW_REQUIRED ||
                                  isReadOnly
                                }
                                variant='contained'
                                onClick={() => {
                                  checkInputs(
                                    value,
                                    decoder(currentDocument.id),
                                    decoder(item.id),
                                    vElement?.coordinates,
                                    vElement
                                  );
                                }}
                              >
                                <CheckIcon />
                              </Button>
                            )}
                          {cropIcon}
                        </InputAdornment>
                      ),
                    }}
                    onBlur={(e) => {
                      dispatch(setHighlightedValue([]));
                      handleInputCellChange(e, vElement);
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        handleInputCellChange(e, vElement);
                      }
                    }}
                    onFocus={() => {
                      setHighlightedValue([
                        {
                          value: vElement?.value,
                          coordinates: vElement?.coordinates?.split(','),
                          type: item?.type,
                          yellowHighlight: 'Yes',
                        },
                      ]);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SupplementalButton
                    primary
                    fullWidth
                    disabled={
                      isReadOnly &&
                      !isExtractionLoading &&
                      suppData &&
                      inputVal !== null &&
                      suppData?.value !== inputVal[index]?.value &&
                      !currentDocument.isComplete
                        ? false
                        : true
                    }
                    onClick={() => {
                      handleSuppButtonClick(item, suppData);
                    }}
                    variant='contained'
                  >
                    <ButtonText noWrap>{suppData?.value || null}</ButtonText>
                  </SupplementalButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  });
  return (
    <>
      {formSets?.length > 0 ? (
        <Stack
          padding='2%'
          direction='column'
          spacing={2}
          divider={<Divider orientation='horizontal' flexItem />}
        >
          {formSets}
        </Stack>
      ) : (
        <Grid container alignItems='center' textAlign='center'>
          <Grid item sm={12}>
            <Typography>No data elements assigned to this cluster.</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

DataEntryFormSets.propTypes = {
  getSortedDataElements: PropTypes.func,
  inputVal: PropTypes.any,
  valueElementStatus: PropTypes.any,
  inputValue: PropTypes.func,
  currentDocument: PropTypes.object,
  croppedCoordinates: PropTypes.any,
  isCropDisabled: PropTypes.bool,
  crop: PropTypes.object,
  isTableTypePortalOpen: PropTypes.bool,
  supplementalData: PropTypes.object,
  onClickCrop: PropTypes.func,
  onClickRemoveCrop: PropTypes.func,
  onExitCropMode: PropTypes.func,
  onConfirmCrop: PropTypes.func,
  selectedElementField: PropTypes.object,
  isTableButtonDisabled: PropTypes.bool,
  onClickTableButton: PropTypes.func,
  isReadOnly: PropTypes.bool,
  checkInputs: PropTypes.func,
  handleType: PropTypes.func,
  handleSuppButtonClick: PropTypes.func,
  isExtractionLoading: PropTypes.bool,
};
export default DataEntryFormSets;
