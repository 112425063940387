import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  msUserData: {},
  msGroupData: [],
  currentGroup: {},
  orgMembers: [],
  currentUserEmail: '',
  isLoggedIn: false,
  profilePhoto: {
    image: false,
    isValid: true,
  },
  allProfilePhotos: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIsLoggedIn(state, action) {
      if (action.payload) {
        state.isLoggedIn = true;
      }
    },
    setMsUserData(state, action) {
      state.msUserData = { ...action.payload, groups: state.msUserData.groups };
    },
    setUserGroups(state, action) {
      state.msUserData.groups = action.payload;
    },
    setGroupMembers(state, action) {
      const { index, members } = action.payload;
      state.msGroupData[index].members = members;
    },
    setCurrentGroup(state, action) {
      state.currentGroup = action.payload;
    },
    setUserProfilePhoto(state, action) {
      if (action.payload === 'false') {
        state.profilePhoto.isValid = false;
      } else if (action.payload !== 'false') {
        state.profilePhoto.image = action.payload.blob;
        state.profilePhoto.isValid = true;
      }
    },
    setTableData(state, action) {
      state.sampleUserData = action.paylod;
    },
    setCurrentUserEmail(state, action) {
      state.currentUserEmail = action.payload;
    },
    setMsGroupData(state, action) {
      const groups = action.payload;
      groups.forEach((group) => {
        let splitGroupName = group.displayName.split('_');
        let parsedGroupName = splitGroupName[splitGroupName.length - 1];
        group.displayName = parsedGroupName;
      });
      state.msGroupData = groups;
    },
    setOrgMembers(state, action) {
      const newMembers = action.payload;
      const existingMembers = state.orgMembers;

      newMembers.forEach((member) => {
        //if member doesn't already exist, add them
        if (!existingMembers.find((mem) => mem.id === member.id)) {
          state.orgMembers = [...state.orgMembers, member];
        }
      });
    },
  },
});

//Selectors
export const selectUserProfilePhoto = (state) => state.users.profilePhoto;
export const selectIsLoggedIn = (state) => state.users.isLoggedIn;
export const selectUserData = (state) => state.users.sampleUserData;
export const selectCurrentUser = (state) =>
  state.users.sampleUserData.find(
    (user) => user.id === state.users.currentUserId
  );
export const selectCurrentMsUser = (state) => state.users.msUserData;
export const selectMsGroupData = (state) => state.users.msGroupData;
export const selectCurrentGroup = (state) => state.users.currentGroup;
export const selectOrgMembers = (state) => state.users.orgMembers;
export const selectCurrentUserEmail = (state) => state.users.currentUserEmail;

export const {
  setIsLoggedIn,
  setMsUserData,
  setMsUSerPhoto,
  setMsUsersList,
  setTableData,
  setUserGroups,
  setUserProfilePhoto,
  setMsGroupData,
  setGroupMembers,
  setCurrentGroup,
  setOrgMembers,
  setCurrentUserEmail,
} = usersSlice.actions;

export default usersSlice.reducer;
