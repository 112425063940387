import Box from 'components/Box';
import Typography from 'components/Typography';
import ErrorIcon from 'icons/Mui/Error';
import PropTypes from 'prop-types';

export default function ImportErrors(props) {
  const {
    hasDuplicateDataElements,
    hasDuplicateDocNames,
    hasEmptyCsvFields,
    hasIncorrectColumns,
    hasDuplicateValueElementIds,
    hasNoProjectId,
    importedData,
    isSupplementalModal,
    isWrongModal,
  } = props;

  return (
    <Box>
      {importedData.length > 0 && isSupplementalModal && hasNoProjectId ? (
        <Typography variant='caption' noWrap style={{ color: 'red' }}>
          <ErrorIcon /> You MUST select a Project in the dropdown located in
          header above before submitting.
        </Typography>
      ) : null}
      {hasDuplicateDocNames ? (
        <Typography variant='caption' noWrap style={{ color: 'red' }}>
          <ErrorIcon /> Duplicate Documents are NOT allowed. Please fix matching
          document names before submitting.
        </Typography>
      ) : null}
      {hasDuplicateDataElements ? (
        <Typography variant='caption' noWrap style={{ color: 'red' }}>
          <ErrorIcon /> Data Elements MUST be unique if Project Member Ids are
          matching. Please fix Data Elements before submitting.
        </Typography>
      ) : null}
      {hasEmptyCsvFields ? (
        <Typography variant='caption' noWrap style={{ color: 'red' }}>
          <ErrorIcon /> One or more fields are empty. All fields must have a
          value.
        </Typography>
      ) : null}
      {isWrongModal ? (
        <Typography variant='caption' noWrap style={{ color: 'red' }}>
          <ErrorIcon /> Only Data that includes ProjectMembers can be uploaded
          in this Modal.
        </Typography>
      ) : null}
      {hasIncorrectColumns ? (
        <Typography variant='caption' noWrap style={{ color: 'red' }}>
          <ErrorIcon /> Columns do NOT match either Project Member Document
          upload or Supplemental Data upload.
        </Typography>
      ) : null}
      {hasDuplicateValueElementIds ? (
        <Typography variant='caption' noWrap style={{ color: 'red' }}>
          <ErrorIcon /> Value Element Ids MUST be unqiue. Please fix Value
          Element Ids before submitting.
        </Typography>
      ) : null}
    </Box>
  );
}

ImportErrors.propTypes = {
  hasDuplicateDataElements: PropTypes.bool,
  hasDuplicateDocNames: PropTypes.bool,
  hasEmptyCsvFields: PropTypes.bool,
  hasIncorrectColumns: PropTypes.bool,
  hasNoProjectId: PropTypes.bool,
  importedData: PropTypes.object,
  isSupplementalModal: PropTypes.bool,
  isWrongModal: PropTypes.bool,
  hasDuplicateValueElementIds: PropTypes.bool,
};
