import theme from 'theme.js';

function SvgRectangle2(props) {
  return (
    <svg
      // width="1em"
      // height="1em"
      viewBox='0 0 1280 546'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity={0.1}
        d='M0 .036h1280V462.48c-308-52.417-327.5 116.591-680.5 72.191-200.406-25.208-409.722 41.853-599.5-7.649V.036z'
        fill='url(#Rectangle2_svg__paint0_linear)'
      />
      <defs>
        <linearGradient
          id='Rectangle2_svg__paint0_linear'
          x1={1207.56}
          y1={476.892}
          x2={332.04}
          y2={-484.406}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.lightest} />
          <stop offset={1} stopColor={theme.palette.secondary.lightest} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgRectangle2;
