import MChip from '@mui/material/Chip';

export default function Chip(props) {
  return <SChip {...props} />;
}

import PropTypes from 'prop-types';
import CloseIcon from 'icons/Mui/Close';
import styled from 'styled-components';

//#region Styled Components
const SChip = styled(MChip)`
  font-style: 'Roboto';
  border-radius: 5px;
  font-size: 16px;
  max-width: auto;
  color: white;
  background-color: ${(props) => props.theme.palette.primary.main};
  & .MuiChip-deleteIcon {
    visibility: hidden;
    margin-left: auto;
  }
  &:hover {
    & .MuiChip-deleteIcon {
      visibility: visible;
      color: white;
      margin-left: auto;
    }
  }
  &:focus {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const ChipContainer = styled.div`
  margin: 5px;
`;
//#endregion Styled Components

export function DeletableChip(props) {
  const { isDeletable, onDeleteChip, elementName } = props;

  const handleDelete = (e) => {
    let chipValue;
    if (e.target.parentElement.textContent === '') {
      chipValue = e.target.parentElement.previousSibling.innerHTML;
    } else {
      chipValue = e.target.parentElement.textContent;
    }
    onDeleteChip(chipValue);
  };

  return (
    <ChipContainer>
      {isDeletable ? (
        <Chip
          deleteIcon={<CloseIcon onClick={handleDelete} />}
          value={elementName}
          label={elementName}
          onDelete={handleDelete}
          size='medium'
        />
      ) : (
        <Chip label={elementName} size='medium' />
      )}
    </ChipContainer>
  );
}

DeletableChip.propTypes = {
  isDeletable: PropTypes.bool,
  elementName: PropTypes.any,
  onDeleteChip: PropTypes.func,
};
