import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import {
  selectSnackbarIsOpen,
  selectSnackbarMessage,
  selectSnackbarType,
  setSnackbarClear,
} from 'store/Redux/slices/snackbarsSlice';
import styled from 'styled-components';

//#region Styled Components
const StyledAlert = styled(MuiAlert)`
  background-color: ${(props) => {
    switch (props.snackbarType) {
      case 'info':
        return 'rgb(28, 139, 199);';
      case 'success':
        return 'rgba(56, 142, 60, 1)';
      case 'warning':
        return 'rgba(214, 169, 34, 1)';
      case 'error':
        return 'rgba(198, 80, 88, 1)';
      default:
        return null;
    }
  }};
`;
//#endregion Styled Components

function Alert(props) {
  return <StyledAlert elevation={6} variant='filled' {...props} />;
}

const MetlSnackbar = forwardRef(function MetlSnackbar(props, ref) {
  const dispatch = useDispatch();

  const snackbarIsOpen = useSelector(selectSnackbarIsOpen);
  const snackbarMessage = useSelector(selectSnackbarMessage);
  const snackbarType = useSelector(selectSnackbarType);
  /* "error" | "success" | "info" | "warn" */

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbarClear());
  }

  return (
    <Snackbar
      key={snackbarMessage}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbarIsOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      transitionDuration={{ entry: 2000, exit: 1 }}
    >
      <div ref={ref}>
        <Alert
          snackbarType={snackbarType}
          severity={snackbarType}
          variant={'filled'}
          onClose={handleClose}
        >
          {snackbarMessage}
        </Alert>
      </div>
    </Snackbar>
  );
});

export default MetlSnackbar;
