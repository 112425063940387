import theme from 'theme.js';

function SvgRectangle3(props) {
  return (
    <svg
      // width="1em"
      // height="1em"
      viewBox='0 0 1280 571'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity={0.25}
        d='M1049 516.096c82.5-6.963 199.47-34.147 231-51.009V0H0v528.787c210.076 109.914 484.057-30.935 690.631-19.047 124.803 7.182 267.869 13.995 358.369 6.356z'
        fill='url(#Rectangle3_svg__paint0_linear)'
      />
      <defs>
        <linearGradient
          id='Rectangle3_svg__paint0_linear'
          x1={1242.25}
          y1={346.431}
          x2={-117.709}
          y2={870.165}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop offset={1} stopColor={theme.palette.secondary.main} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgRectangle3;
