import MFade from '@mui/material/Fade';
import PropTypes from 'prop-types';

export default function Fade(props) {
  return (
    <MFade {...props}>
      <div>{props.children}</div>
    </MFade>
  );
}

Fade.propTypes = {
  children: PropTypes.any,
};
