const STORE = {
  selectedCluster: null,
  selectedDocument: null,
  selectedProjectMember: null,
  clusters: null,
  projectMembers: null,
  clustersWithDocs: null,
  documents: null,
  dataElements: null,
  tableData: null,
  supplementalData: null,
  projectDataElements: null,
  unclusteredDocuments: null,
  documentMember: null,
  modalOpen: false,
  tableColumnsModal: false,
  tableWindowPortal: false,
  tableCoordinates: undefined,
  extractedData: null,
  extractionDone: false,
  notReviewed: false,
  addSpacing: false,
  currentOpenTable: {
    isOpen: false,
    tableId: null,
    tableName: '',
  },
  currentClusterFilterText: '',
  currentFilteredClusterData: null,
  highlightedValue: null,
  tableCoordinatesCopy: undefined,
  onClassificationGroupsTab: true,
  selectedTab: 0,
};

export default STORE;
