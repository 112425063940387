import MCircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

const SCircularProgress = styled(MCircularProgress)`
  color: ${(props) => props.theme.palette.primary.main};
`;

export default function CircularProgress(props) {
  return <SCircularProgress {...props} />;
}
