import Zoom from '@mui/material/Zoom';
import PropTypes from 'prop-types';
import MTooltip from 'components/Tooltip';
import styled from 'styled-components';

//#region Styled Components
const LightTooltip = styled(MTooltip)`
  .MuiTooltip-tooltip {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    font-style: 'Roboto';
    font-size: 15px;
  }

  .MuiTooltip-arrow {
    font-size: 20px;
    color: white;
    &:before {
      background-color: white;
      box-shadow: 0px 3px 1px -3px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;
//#endregion Styled Components

export default function LightToolTip(props) {
  const {
    contentText,
    lightToolTipTargetItem,
    placement = 'top',
    isShown = true,
  } = props;

  return (
    <LightTooltip
      placement={placement}
      title={contentText}
      TransitionComponent={Zoom}
      arrow
      enterDelay={500}
      disableHoverListener={!isShown}
    >
      {lightToolTipTargetItem}
    </LightTooltip>
  );
}

LightToolTip.propTypes = {
  contentText: PropTypes.any,
  lightToolTipTargetItem: PropTypes.any,
  placement: PropTypes.any,
  isShown: PropTypes.bool,
};

/* Placements
  'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top' */
