function capsCase(string) {
  if (string) {
    let capitalized =
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase(); //Capitalizes word
    if (capitalized.includes('_')) {
      let separated = capitalized.split('_');
      return separated
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) //Capitalizes every word in the array
        .join(' ');
    } else return capitalized;
  }
}

function titleCase(string) {
  const spacedString = string.replace(/([A-Z])/g, ' $1');
  const titleCase =
    spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
  return titleCase;
}

function displayTaskStatus(status) {
  return capsCase(status);
}

function displayTaskType(type) {
  return capsCase(type);
}

function displayElementType(type) {
  return capsCase(type);
}

function displayTitleCase(type) {
  return titleCase(type);
}

function statusToEnum(status, statusEnums) {
  let formattedEnums = Object.fromEntries(
    Object.entries(statusEnums).map(([k, v]) => [
      displayElementType(k).toLowerCase(),
      v,
    ])
  );

  let searchStatus = Object.keys(formattedEnums).map((key) => {
    if (key.includes(status.toLowerCase())) {
      return formattedEnums[key];
    }
  });

  return searchStatus.filter((s) => s);
}

export {
  displayTaskStatus,
  displayTaskType,
  displayElementType,
  displayTitleCase,
  statusToEnum,
};
