import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import List from 'components/List';
import Typography from 'components/Typography';
import ErrorIcon from 'icons/Mui/Error';
import styled from 'styled-components';
import ListItem from 'components/ListItem';

const StyledErrorIcon = styled(ErrorIcon)`
  margin-right: 5px;
  color: red;
`;

const ErrorMessageGrid = styled(Grid)`
  font-size: 0.8rem;
  color: 'red';
  max-height: '200px';
  overflow-y: 'auto';
  margin-bottom: '1rem';
  padding: '1rem';
`;

const ErrorList = styled(List)`
  max-height: 250px;
  overflow-y: auto;
  display: inline-block;
`;

const STypography = styled(Typography)`
  color: red;
`;

const SListItem = styled(ListItem)`
  color: red;
  padding: 4px;
`;

function ErrorMessage(props) {
  const { errorList, errorMessage = 'Unknown Error' } = props;

  return errorList ? (
    <ErrorMessageGrid item xs={12}>
      <STypography>{errorMessage}</STypography>
      <ErrorList>
        {errorList.map((error, index) => {
          return <SListItem key={index}>{error}</SListItem>;
        })}
      </ErrorList>
    </ErrorMessageGrid>
  ) : (
    <ErrorMessageGrid item xs={12}>
      <Typography variant='caption'>
        <StyledErrorIcon />
        {errorMessage}
      </Typography>
    </ErrorMessageGrid>
  );
}

ErrorMessage.propTypes = {
  errorList: PropTypes.array,
  errorMessage: PropTypes.string,
};

export default ErrorMessage;
