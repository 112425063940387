import { useEffect, useState } from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import IconButton from 'components/IconButton';
import NoDocument from 'components/Template/NoDocument';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
  selectSourceIndex,
  setSourceIndex,
} from 'store/Redux/slices/analysisSlice';
import { selectDocIndex, setDocIndex } from 'store/Redux/slices/projectsSlice';
import { selectDocuments } from 'store/Redux/slices/dataEntrySlice';
import styled from 'styled-components';

//#region Styled Components
const Header = styled.div`
  ${(props) => props.theme.typography.subheader.regular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ViewArea = styled.div`
  display: flex;
  justify-content: center;
  pointer-events: all;
  && div {
    pointer-events: all;
  }
`;

const CardTitle = styled.p`
  margin: auto 0px auto 5px;
`;

const Tools = styled(CardActions)`
  padding: 0px;
`;

const ToolButton = styled(IconButton)`
  padding: 0px;
  display:${(props)=>props.oneDocOnly?'none':null};
`;

const CurrentImage = styled.img`
  width: 100%;
  height: 100%;
  pointer-events: all !important;
  filter: ${(props) => (props.invertColor ? 'invert(1)' : null)};
`;

const PDF = styled.object`
  height: 750px;
  pointer-events: all !important;
  filter: ${(props) => (props.invertColor ? 'invert(1)' : null)};
`;

const CardWrapper = styled(Card)`
  display: grid;
  align-items: flex-start;
`;
//#endregion Styled Components

export default function CurrentDocument(props) {
  const dispatch = useDispatch();
  const documents = useSelector(selectDocuments);
  const docIndex = useSelector(selectDocIndex);
  const sourceIndex = useSelector(selectSourceIndex);
  const [analysisMode, setAnalysisMode] = useState(false);
  const [inverted, setInverted] = useState(false);
  const [docType, setDocType] = useState('');

  const { image, totalDocs, currentPage } = props;

  useEffect(() => {
    if (documents?.length)
      setDocType(documents[docIndex]?.originalName?.slice(-4));
  }, [docIndex]);

  useEffect(() => {
    currentPage === 'Analysis' ? setAnalysisMode(true) : setAnalysisMode(false);
  }, [currentPage]);

  const pictureUp = () => {
    analysisMode
      ? dispatch(setSourceIndex(sourceIndex + 1))
      : dispatch(setDocIndex(docIndex + 1));
  };

  const pictureDown = () => {
    analysisMode
      ? dispatch(setSourceIndex(sourceIndex - 1))
      : dispatch(setDocIndex(docIndex - 1));
  };

  return (
    <CardWrapper>
      {totalDocs === undefined || docIndex === undefined ? (
        <NoDocument />
      ) : docType === '.pdf' ? (
        <>
          <Header>
            <CardTitle>Current Document</CardTitle>
            <Tools>
              {totalDocs > 0 ? (
                <>
                  <ToolButton
                    disabled={
                      analysisMode
                        ? sourceIndex === 0
                          ? true
                          : false
                        : docIndex === 0
                        ? true
                        : false
                    }
                    onClick={pictureDown}
                    size='large'
                  >
                    <ArrowLeftIcon />
                  </ToolButton>
                  {analysisMode
                    ? `${sourceIndex + 1} / ${totalDocs}`
                    : `${docIndex + 1} / ${totalDocs}`}
                  <ToolButton
                    disabled={
                      analysisMode
                        ? sourceIndex + 1 === totalDocs
                          ? true
                          : false
                        : docIndex + 1 === totalDocs
                        ? true
                        : false
                    }
                    onClick={pictureUp}
                    size='large'
                  >
                    <ArrowRightIcon />
                  </ToolButton>
                </>
              ) : null}
              <ToolButton onClick={() => setInverted(!inverted)} size='large'>
                <InvertColorsIcon />
              </ToolButton>
            </Tools>
          </Header>
          <ViewArea>
            <PDF data={image} invertColor={inverted}>
              No Image Available
            </PDF>
          </ViewArea>
        </>
      ) : (
        <TransformWrapper defaultScale={1}>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Header>
                <CardTitle>Current Document</CardTitle>
                <Tools>
                  {totalDocs > 0 ? (
                    <>
                      <ToolButton
                        disabled={
                          analysisMode
                            ? sourceIndex === 0
                              ? true
                              : false
                            : docIndex === 0
                            ? true
                            : false
                        }
                        onClick={pictureDown}
                        size='large'
                        oneDocOnly={totalDocs===1}
                      >
                        <ArrowLeftIcon />
                      </ToolButton>
                      {totalDocs>1?analysisMode 
                        ? `${sourceIndex + 1} / ${totalDocs}`
                        : `${docIndex + 1} / ${totalDocs}`:null}
                      <ToolButton
                        disabled={
                          analysisMode
                            ? sourceIndex + 1 === totalDocs
                              ? true
                              : false
                            : docIndex + 1 === totalDocs
                            ? true
                            : false
                        }
                        onClick={pictureUp}
                        size='large'
                        oneDocOnly={totalDocs===1}
                      >
                        <ArrowRightIcon />
                      </ToolButton>
                    </>
                  ) : null}
                  <ToolButton size='large'>
                    <ZoomInIcon onClick={() => zoomIn()} />
                  </ToolButton>
                  <ToolButton onClick={() => zoomOut()} size='large'>
                    <ZoomOutIcon />
                  </ToolButton>
                  <ToolButton onClick={() => resetTransform()} size='large'>
                    <ZoomOutMapIcon />
                  </ToolButton>
                  <ToolButton
                    onClick={() => setInverted(!inverted)}
                    size='large'
                  >
                    <InvertColorsIcon />
                  </ToolButton>
                </Tools>
              </Header>
              <ViewArea>
                <TransformComponent>
                  <CurrentImage
                    src={image}
                    alt='No image available'
                    invertColor={inverted}
                  />
                </TransformComponent>
              </ViewArea>
            </>
          )}
        </TransformWrapper>
      )}
    </CardWrapper>
  );
}

CurrentDocument.propTypes = {
  image: PropTypes.string,
  totalDocs: PropTypes.number,
  currentPage: PropTypes.string,
};
