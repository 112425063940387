import MButton from '@mui/material/Button';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components';

const SButton = styled(MButton)`
  ${(props) => {
    if (props.variant === 'contained' && props.$primary) {
      return `
        background-color: ${props.theme.palette.secondary.main};
        color: white;
        &:hover {
          background-color: ${props.theme.palette.secondary.darker};
        }
      `;
    } else if (props.variant === 'text' && props.$primary) {
      return `
        color: ${props.theme.palette.secondary.main};
        &:hover {
          background-color: ${rgba(props.theme.palette.secondary.main, 0.04)};
        }
      `;
    } else if (props.variant === 'outlined' && props.$primary) {
      return `
        color: ${props.theme.palette.secondary.main};
        border: 1px solid ${props.theme.palette.secondary.main};
        &:hover {
          background-color: ${rgba(props.theme.palette.secondary.main, 0.04)};
          border-color: ${props.theme.palette.secondary.darker};
        }
      `;
    } else if (props.variant === 'contained' && props.$secondary) {
      return `
        background-color: ${props.theme.palette.primary.main};
        color: white;
        &:hover {
          background-color: ${props.theme.palette.primary.darker};
        }
      `;
    } else if (props.variant === 'text' && props.$secondary) {
      return `
        color: ${props.theme.palette.primay.main};
        &:hover {
          background-color: ${rgba(props.theme.palette.primary.main, 0.04)};
        }
      `;
    } else if (props.variant === 'outlined' && props.$secondary) {
      return `
        color: ${props.theme.palette.primary.main};
        border: 1px solid ${props.theme.palette.primary.main};
        &:hover {
          background-color: ${rgba(props.theme.palette.primary.main, 0.04)};
          border-color: ${props.theme.palette.primary.darker};
        }
      `;
    }
  }}
`;

export default function Button({ primary, secondary, ...rest }) {
  return <SButton $primary={primary} $secondary={secondary} {...rest} />;
}

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
};
