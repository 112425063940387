import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectTableCoordinates,
    selectTableCoordinatesCopy,
    setTableCoordinates,
    setTableCoordinatesCopy,
} from 'store/Redux/slices/dataEntrySlice';

export default function useImageCropper() {
    const dispatch = useDispatch();
    const tableCoordinates = useSelector(selectTableCoordinates);
    const tableCoordinatesCopy = useSelector(selectTableCoordinatesCopy);

    const [crop, setCrop] = useState(null);
    const [isCropDisabled, setIsCropDisabled] = useState(true);
    const [selectedElementField, setSelectedElementField] = useState(null);
    const [croppedCoordinates, setCroppedCoordinates] = useState({
        value: '',
        field: '',
    });
    const [croppedCoordinatesCopy, setCroppedCoordinatesCopy] = useState({
        value: '',
        field: '',
    });

    const imageAndCroppedImageProperties = useRef(null);
    const imageRef = useRef(null);
    const imageSize = useRef(null);

    const imagePosition = useRef({
        positionX: 0,
        positionY: 0,
        scale: 1,
    });

    const [cropImageCss, setCropImageCss] = useState({
        transform: null,
        imageSize: {
            width: 0,
            height: 0,
            rWidth: 0,
        },
    });

    const getAndSetImageStyle = () => {
        let img = document.getElementById('currentImage');

        if (img) {
            imageSize.current = {
                intrinsicWidth: img.naturalWidth,
                intrinsicHeight: img.naturalHeight,
                renderedWidth: img.width,
                renderedHeight: img.height,
            };
        }

        setCropImageCss({
            transform: `translate(${imageRef?.current?.context.state.positionX}px,
          ${imageRef?.current?.context.state.positionY}px) scale(${imageRef?.current?.context.state.scale})`,
            imageSize: {
                width: img?.naturalWidth,
                height: img?.naturalHeight,
            },
            style: {
                border: '1px dotted gray',
            },
        });
    };

    const cropReset = () => {
        setCrop(null);
        setIsCropDisabled(true);
        imageAndCroppedImageProperties.current = null;
    };

    useEffect(() => {
        getAndSetBoundingBox(tableCoordinates);
        if (tableCoordinates === '') {
            dispatch(setTableCoordinates(undefined));
        }
        if (tableCoordinatesCopy === '') {
            dispatch(setTableCoordinatesCopy(undefined));
        }
        imageAndCroppedImageProperties.current = null;
    }, [tableCoordinates]);

    const getAndSetBoundingBox = (v) => {
        if (v) {
            v.split('');
            const validCoordinates = v[0] === '[' && v[v.length - 1] === ']';
            if (validCoordinates) {
                let values = v.split(',');
                setCrop({
                    x: Number(values[2]),
                    y: Number(values[3]),
                    width: Number(values[4]),
                    height: Number(values[values.length - 1].replace(']', '')),
                    unit: 'px',
                });
            } else {
                setCrop(null);
            }
        } else {
            setCrop(null);
        }
    };

    const onClickCrop = (e, item, value) => {
        getAndSetBoundingBox(value);
        setCroppedCoordinates({ value: undefined, field: item?.__typename });
        setCroppedCoordinatesCopy({ value: undefined, field: item?.__typename });
        setSelectedElementField(item);
        setIsCropDisabled(false);
        if (imageRef.current) {
            imagePosition.current.positionX =
                imageRef.current.context.state.positionX;
            imagePosition.current.positionY =
                imageRef.current.context.state.positionY;
            imagePosition.current.scale = imageRef.current.context.state.scale;
        }
        getAndSetImageStyle();
    };

    const handleOnCompleteCrop = (crop) => {
        if (crop.width && crop.height) {
            imageAndCroppedImageProperties.current = {
                imageDimensions: {
                    intrinsicWidth: imageSize.current.intrinsicWidth,
                    intrinsicHeight: imageSize.current.intrinsicHeight,
                    renderedWidth: imageSize.current.renderedWidth,
                    renderedHeight: imageSize.current.renderedHeight,
                },
                imageTransformation: {
                    imageX: imagePosition.current.positionX,
                    imageY: imagePosition.current.positionY,
                    imageScale: imagePosition.current.scale,
                },
                croppedImage: {
                    startX: crop.x,
                    startY: crop.y,
                    width: crop.width,
                    height: crop.height,
                    unit: crop.unit,
                },
            };
        }
    };

    const onClickRemoveCrop = (field) => {
        if (field === 'TableTypeColumn') {
            dispatch(setTableCoordinates(''));
            dispatch(setTableCoordinatesCopy(''));
            setCrop(null);
            return;
        }
        setCroppedCoordinates({ value: '', field: field });
        setCroppedCoordinatesCopy({ value: '', field: field });
        cropReset();
    };

    const onConfirmCrop = () => {
        const croppedInformation =
            imageAndCroppedImageProperties?.current?.croppedImage;
        const renderedDimension =
            imageAndCroppedImageProperties?.current?.imageDimensions;

        const validatedCoordinates =
            renderedDimension?.renderedWidth &&
            renderedDimension?.renderedHeight &&
            croppedInformation?.startX &&
            croppedInformation?.startY &&
            croppedInformation?.width &&
            croppedInformation?.height;

        if (validatedCoordinates) {
            const coordinatesPayload =
                '[' +
                [
                    renderedDimension?.renderedWidth,
                    renderedDimension?.renderedHeight,
                    croppedInformation?.startX,
                    croppedInformation?.startY,
                    croppedInformation?.width,
                    croppedInformation?.height,
                ] +
                ']';

            const coordinatesPayloadCopy = coordinatesPayload;
            if (croppedCoordinates.field === 'TableTypeColumn') {
                dispatch(setTableCoordinates(coordinatesPayload));
                dispatch(setTableCoordinatesCopy(coordinatesPayloadCopy));
                return;
            }
            
            setCroppedCoordinates((prev) => {
                return { ...prev, value: coordinatesPayload };
            });

            setCroppedCoordinatesCopy((prev) => {
                return { ...prev, value: coordinatesPayloadCopy };
            });
            cropReset();
        }
    };

    return {
        crop,
        cropImageCss,
        croppedCoordinates,
        cropReset,
        handleOnCompleteCrop,
        imagePosition,
        imageRef,
        isCropDisabled,
        onClickCrop,
        onClickRemoveCrop,
        onConfirmCrop,
        selectedElementField,
        setCrop,
        setCroppedCoordinates,
        setIsCropDisabled,
        setSelectedElementField,
        getAndSetImageStyle,
        croppedCoordinatesCopy,
        setCroppedCoordinatesCopy
    };
}

/* This hooks works hand in hand with the react-image-crop library and the image cropper located in containers/Cropper

crop: object holding the current crop dimensions
cropImageCss: any CSS that needs to applied to our <ReactCrop/> component once toggled into crop mode
croppedCoordinates: template literal version of our cropped dimensions going into the DB (also used by pre-processing) / formatted as "[renderedWidth, renderedHeight, startX, startY, cropWidth, cropHeight]"
handleOnCompleteCrop: shows the the most updated cropped coordinates when cropping comes to a halt
onClickCrop: event handler; upon invocation, targets the image with the "imageRef" ref and grabs image dimensions and scale
onClickRemoveCrop: event handler; upon invocation, removes the saved coordinates from the state and also removes the crop box from the display
onComfirmCrop: event handler; upon invocation, reformats the cropped coordinates into a string format to be sent to the backend

*/
