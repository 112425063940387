import { useState } from 'react';
import InputAdornment from 'components/InputAdornment';
import ButtonGroup from 'components/ButtonGroup';
import IconButton from 'components/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from 'icons/Mui/Cancel';
import FormControl from 'components/FormControl';
import { OutlinedInput } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectSourceIndex } from 'store/Redux/slices/analysisSlice';

//#region Styled Components
const ValueInput = styled(OutlinedInput)`
  padding-right: 0px;
  && legend {
    width: 0px;
  }

  &.Mui-focused {
    && fieldset {
      border-color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const DoneButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.main};
  padding: 0;
`;

const CancelButton = styled(IconButton)`
  color: red;
  padding: 0;
`;

//#endregion Styled Components

function TextFieldAnalysis({
  initialValue,
  handleUpdate,
  original,
  values,
  isReadOnly,
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [value, setValue] = useState(initialValue);
  const sourceIndex = useSelector(selectSourceIndex);

  const renderAdornment = () => (
    <InputAdornment position='end'>
      <ButtonGroup style={{ paddingRight: 8 }}>
        <DoneButton
          onClick={() => {
            handleUpdate(value, original.id, sourceIndex);
            setIsUpdating(false);
          }}
        >
          <DoneIcon />
        </DoneButton>
        <CancelButton
          onClick={() => {
            if (isUpdating) {
              setIsUpdating(false);
            }
            setValue(initialValue);
          }}
        >
          <CancelIcon />
        </CancelButton>
      </ButtonGroup>
    </InputAdornment>
  );

  const handleChange = (e) => {
    if (!isUpdating) {
    setIsUpdating(true);
    }
    setValue(e.target.value);
  };

  return (
    <FormControl variant='outlined' hiddenLabel={true}>
      <ValueInput
        disabled={isReadOnly}
        size='small'
        value={value}
        onChange={(e) => handleChange(e)}
        endAdornment={
          isUpdating && values.source !== 'Supplemental Data'
            ? renderAdornment()
            : null
        }
      />
    </FormControl>
  );
}

export default TextFieldAnalysis;

TextFieldAnalysis.propTypes = {
  initialValue: PropTypes.string,
  handleUpdate: PropTypes.func,
  original: PropTypes.object,
  values: PropTypes.object,
};
