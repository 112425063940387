import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import FormHelperText from 'components/FormHelperText';
import { decoder } from 'helpers/graphQL_Encoder';
import { ADD_CLUSTER } from 'services/GraphQL/Mutations';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import Dialog from 'components/Dialog';
import Grid from 'components/Grid';
import TextField from 'components/TextField';
import { setSnackbarSuccess } from 'store/Redux/slices/snackbarsSlice';

//#region Styled Components

//#endregion Styled Components

const NewClusterModal = (props) => {
  const {
    newClusterOpen,
    closeNewCluster,
    galleryClusters,
    setNewClusterOpen,
    currentProjectId,
    setNewCluster,
    setGalleryClusters,
    checkClusterNameExists,
  } = props;
  const [newClusterName, setNewClusterName] = useState('');
  const [nameError, setNameError] = useState(false);

  const dispatch = useDispatch();
  const emailName = useSelector(selectCurrentUserEmail);

  const [addClusterMutation] = useMutation(ADD_CLUSTER, {
    onCompleted: (data) => {
      if (data?.addCluster.name) {
        setGalleryClusters({
          ...galleryClusters,
          nodes: [...galleryClusters.nodes, data.addCluster],
        });
        setNewCluster(data.addCluster);
        dispatch(
          setSnackbarSuccess(`${data?.addCluster.name} added succesfully`)
        );
      }
    },
  });

  const submitCluster = () => {
    addClusterMutation({
      variables: {
        input: {
          name: newClusterName,
          projectId: decoder(currentProjectId),
          username: emailName,
        },
      },
    });
    setNewClusterOpen(false);
    setNewClusterName('');
  };

  const handleClose = () => {
    setNewClusterOpen(false);
    setNewClusterName('');
    setNameError(false);
    closeNewCluster();
  };

  const handleInputChange = (value) => {
    setNewClusterName(value);

    if (value?.length === 0) {
      setNameError(false);
    }
    checkClusterNameExists({
      variables: {
        filters: {
          projectId: { eq: decoder(currentProjectId) },
          name: { eq: value.trim() },
        },
      },
    }).then((res) => {
      const clusterNameExists = res?.data?.clusters?.totalCount > 0;
      if (clusterNameExists) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    });
  };

  return (
    <Dialog
      open={newClusterOpen}
      onClose={() => handleClose()}
      handleCancel={() => handleClose()}
      title='Add New Cluster'
      cancelLabel='Cancel'
      submitLabel='Add'
      handleSubmit={() => submitCluster()}
      submitDisabled={nameError || !newClusterName}
      hasActions
      PaperProps={{
        sx: { width: '30dvw', minWidth: 'fit-content' },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='New Cluster Name'
            value={newClusterName}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          {nameError && (
            <FormHelperText error>
              Cluster with this name already exists
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

NewClusterModal.propTypes = {
  closeNewCluster: PropTypes.func,
  currentProjectId: PropTypes.string,
  newClusterOpen: PropTypes.any,
  setNewClusterOpen: PropTypes.func,
  galleryClusters: PropTypes.array,
  setGalleryClusters: PropTypes.func,
  setNewCluster: PropTypes.func,
  checkClusterNameExists: PropTypes.func,
};

export default NewClusterModal;
