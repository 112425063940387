import { Skeleton } from '@mui/material';
import Grid from 'components/Grid';

export const TableLoadingOverlay = () => {
  const skeletonArray = [...Array(10)];

  return (
    <Grid container spacing={1}>
      {skeletonArray.map((x) => (
        <Grid
          key={x}
          item
          xs={12}
          sx={{
            borderBottom: '1px solid rgba(225, 226, 240, 1)',
          }}
        >
          <Skeleton height={35} />
        </Grid>
      ))}
    </Grid>
  );
};
