import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import { Backdrop, CircularProgress } from '@mui/material';
import Autocomplete from 'components/Autocomplete';
import Drawer from 'components/Drawer';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import MetlWindowPortal from 'components/Portal/MetlWindowPortal';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import { decoder, encoder } from 'helpers/graphQL_Encoder';
import _cloneDeep from 'lodash.clonedeep';
import {
  DocumentsNavigatorDialog,
  PageNavigator,
} from 'pages/DataEntry/dialogs';
import { DataEntryTable, ValueElementDialogTable } from 'pages/DataEntry/table';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_DOCUMENT_STATUS,
  UPDATE_PROJECT_MEMBER_STATUS,
} from 'services/GraphQL/Mutations';
import { GET_TABLEDATA_BY_DATAELEMENT_ID } from 'services/GraphQL/Queries';
import {
  selectCurrentOpenTable,
  setAddSpacing,
  setCurrentOpenTable,
  setExtractedData,
  setExtractionDone,
  setHighlightedValue,
  setModalOpen,
  setNotReviewed,
  setSelectedCluster,
  setSelectedDocument,
  setStoreTableData,
  toggleModalOpen,
} from 'store/Redux/slices/dataEntrySlice';
import {
  setSnackbarError,
  setSnackbarInfo,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled, { css, StyleSheetManager } from 'styled-components';
import {
  selectCurrentMember,
  setCurrentMember,
} from 'store/Redux/slices/analysisSlice';
import DataEntryFormSets from './DataEntryFormSets';

//#region Styled Components
const DrawerTable = styled(Drawer)`
  ${(props) => {
    //`flex-shrink: 0`;
    if (props.useForm) {
      return css`
        .MuiPaper-root {
          height: -webkit-fill-available;
          width: 30%;
          top: 64px;
          overflow-y: auto;
        }
      `;
    } else if (props.isPopout) {
      return css`
        .MuiPaper-root {
          left: 0;
        }
      `;
    } else {
      return css`
        .MuiPaper-root {
          left: 80px;
          z-index: 1000;
        }
      `;
    }
  }}
`;

const DrawerHeader = styled(Grid)`
  ${(props) => {
    if (props.useForm) {
      return `position: sticky;
  top: -15px;
  padding: 1%;
  z-index: 999;
  `;
    }
  }}

  background-color: ${(props) => props.theme.primary.black};
  margin: 0px;
  width: 100%;
`;

const TableWrapper = styled(Grid)`
  align-items: center;
  overflow-x: scroll;
`;

const MoreIcon = styled(IconButton)`
  color: white;
  position: relative;
  bottom: 3px;
`;

const STextField = styled(TextField)`
  & fieldset {
    top: 0;
  }
  & legend {
    display: none;
  }
`;

const ClusterDropdown = styled(Autocomplete)`
  max-height: 100%;
  min-width: 25%;
  background-color: white;
  border-radius: 5px;
  display: block;
`;

const SCircularProgress = styled(CircularProgress)`
  float: right;
  color: inherit;
`;

const FormViewContainer = styled(Grid)`
  height: 100%;
  padding: 0 2% 0 2%;
`;
//#endregion Styled Components

export default function DrawerView(props) {
  const {
    currentCluster,
    currentDocument,
    currentProjectId,
    checkClusterId,
    clusters = [],
    documents,
    setDocuments,
    getDocuments,
    projectMembers,
    setProjectMembers,
    decrementIndex,
    docIndex,
    handleValueElementUpdate,
    incrementIndex,
    isExtractionLoading,
    extractionDone,
    setCheckClusterId,
    setDocIndex,
    setShowForm,
    supplementalData,
    useForm,
    checkDocumentStatus,
    valueElementStatus,
    onClassificationGroupsTab,
    projectMemberStatuses,
    /* For image cropping */
    crop,
    croppedCoordinates,
    isCropDisabled,
    onClickCrop,
    onClickRemoveCrop,
    onConfirmCrop,
    onExitCropMode,
    selectedElementField,
    setIsCropDisabled,
    emailName,
    croppedCoordinatesCopy,
    getValueElements,
    valueElementsLoading,
    inputVal,
    setInputVal,
    shouldTableUpdate,
    setShouldTableRerender,
    pmIndex,
    isReadOnly,
    projectMemberIdentificationHeader,
    confirm,
    templateExistsInCluster,
    removeTemplateInDocument,
    documentById,
    confirmCroppingMessage,
    openReassignModal,
    setOpenReassignModal,
    checkForTemplateInCluster,
    dataElements,
    getDataElements,
    showRadioAndCheckboxHighlights,
    organizationName,
    checkForVASpecificPMWorkflow,
  } = props;

  const dispatch = useDispatch();
  const currentMember = useSelector(selectCurrentMember);
  const isTableOpen = useSelector(selectCurrentOpenTable);
  const open = useSelector(toggleModalOpen);

  const preText = 'Page';

  const [isTableTypePortalOpen, toggleTableTypePortal] = useState(false);
  const [isTableViewPortalOpen, toggleTableViewPortal] = useState(false);
  const [tableDataElement, setTableDataElement] = useState();

  const [isEveryInputFieldComplete, setIsEveryInputFieldComplete] = useState({
    complete: false,
    incompleteFields: [],
  });
  const [isEveryInputFieldReviewed, setIsEveryInputFieldReviewed] =
    useState(true);

  /* to better control document status toggle behavior */
  const isToggleManual = useRef(false);
  const automaticToggle = useRef(false);

  window.addEventListener('beforeunload', function () {
    toggleTableViewPortal(false);
    toggleTableTypePortal(false);
  });

  const isTableButtonDisabled =
    isExtractionLoading ||
    isTableOpen.isOpen ||
    isTableTypePortalOpen ||
    !isCropDisabled;

  //#region GraphQL

  const [getTableDataElements] = useLazyQuery(GET_TABLEDATA_BY_DATAELEMENT_ID, {
    onCompleted: (data) => {
      if (data?.dataElementById) {
        dispatch(setStoreTableData(data.dataElementById.tableData));
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [updateDocumentStatus] = useMutation(UPDATE_DOCUMENT_STATUS, {
    onCompleted: (data) => {
      if (data?.updateDocumentStatus) {
        dispatch(
          setSnackbarSuccess(
            `Document Status set to ${
              data.updateDocumentStatus.isComplete ? 'Complete' : 'Incomplete'
            }.`
          )
        );
      }
    },
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
  });

  const [updateProjectMemberStatus] = useMutation(
    UPDATE_PROJECT_MEMBER_STATUS,
    {
      onCompleted: (data) => {
        if (data?.updateProjectMemberStatus) {
          let updatedProjectMember = {
            ...currentMember,
            status: data.updateProjectMemberStatus.status,
          };
          dispatch(setCurrentMember(updatedProjectMember));
        }
      },
    }
  );
  //#endregion GraphQL

  //#region Component Functions

  const updateDocumentStatusAndDocumentList = () => {
    checkDocumentStatus({ variables: { id: currentCluster?.id } }).then(
      (response) => {
        const currentDocs = _cloneDeep(documents);
        let updatedDocs = currentDocs?.nodes?.map((doc) => {
          let newDoc =
            response?.data?.checkAndCompleteDocumentStatusFromCluster.documents.find(
              (x) => x.id == doc.id
            );
          if (newDoc) {
            newDoc = { ...newDoc, documentUri: doc.documentUri };
          }
          return newDoc ? { ...doc, ...newDoc } : doc;
        });
        setDocuments({ ...documents, nodes: updatedDocs });
        dispatch(
          setSelectedDocument(
            updatedDocs.find((x) => x.id === currentDocument?.id)
          )
        );
        setIsEveryInputFieldComplete({ complete: false, incompleteFields: [] });
      }
    );
  };

  const handleDocumentStatusUpdate = (status) => {
    return new Promise(function (resolve, reject) {
      updateDocumentStatus({
        variables: {
          input: {
            documentId: decoder(currentDocument.id),
            status: status,
          },
        },
      }).then((response) => {
        if (response?.data) {
          let newDoc = {
            ...currentDocument,
            isComplete: response.data.updateDocumentStatus.isComplete,
          };
          dispatch(setSelectedDocument(newDoc));
          const updatedDocs = documents?.nodes?.map((d) => {
            if (d.id === response.data.updateDocumentStatus.id) {
              return {
                ...d,
                isComplete: response.data.updateDocumentStatus.isComplete,
              };
            }
            return d;
          });
          setDocuments({ ...documents, nodes: updatedDocs });
          automaticToggle.current = true;
          setShouldTableRerender(Date.now());
          resolve(response.data.updateDocumentStatus.isComplete);

          if (response.data.updateDocumentStatus?.isComplete) {
            checkForVASpecificPMWorkflow(response.data.updateDocumentStatus);
          }
        } else {
          reject(`Unable to update Document: ${response}`);
        }
      });
    });
  };

  const checkInputCompleteStatus = (newElements) => {
    const dataElementsLeftToFill = getDataElementsLeftToFill(newElements);

    const inputFieldReviewed = newElements.every(
      (v) => v.status !== valueElementStatus.REVIEW_REQUIRED
    );

    setIsEveryInputFieldComplete({
      ...isEveryInputFieldComplete,
      complete: dataElementsLeftToFill.length === 0,
      incompleteFields: dataElementsLeftToFill,
    });
    setIsEveryInputFieldReviewed(inputFieldReviewed);
  };

  const handleType = (e, dataElementId) => {
    let dataElement = dataElements?.find(
      (x) => x.id == encoder('DataElement', dataElementId)
    );
    if (dataElement && dataElementId) {
      let copiedInputVal = _cloneDeep(inputVal);
      let targetInputBox = copiedInputVal.findIndex(
        (v) => v.dataElementId === dataElementId
      );

      if (
        copiedInputVal[targetInputBox]?.status ==
          valueElementStatus.REVIEW_REQUIRED &&
        copiedInputVal[targetInputBox].value !== e
      ) {
        copiedInputVal[targetInputBox].changed = true;
      }

      if (targetInputBox >= 0) {
        copiedInputVal[targetInputBox].value = e;
      } else {
        copiedInputVal.push({
          dataElementId: decoder(dataElement.id),
          documentId: decoder(currentDocument.id),
          value: e,
          name: dataElement.name,
          coordinates: croppedCoordinatesCopy.value,
        });
      }

      setInputVal(copiedInputVal);
      setShouldTableRerender(Date.now());
    } else {
      return;
    }
  };

  const checkInputs = (
    newValue,
    documentId,
    dataElementId,
    newCroppedCoordinates,
    element
  ) => {
    if (newValue === '') {
      dispatch(setSnackbarInfo('Value cannot be empty'));
      return;
    }

    handleValueElementUpdate(
      newValue,
      documentId,
      dataElementId,
      element,
      newCroppedCoordinates
    ).then((res) => {
      if (res && inputVal?.length) {
        let newInputs = inputVal.map((i) => {
          if (i.id === res.data.addOrUpdateValueElement.valueElement.id) {
            return res.data.addOrUpdateValueElement.valueElement;
          }
          return i;
        });
        if (
          newInputs?.length &&
          newInputs?.includes(res.data.addOrUpdateValueElement.valueElement)
        )
          setInputVal(newInputs);
        else
          setInputVal([
            ...newInputs,
            res.data.addOrUpdateValueElement.valueElement,
          ]);
        checkInputCompleteStatus(newInputs);
      }
    });
    setShouldTableRerender(Date.now());
  };

  const inputValue = (dElement) => {
    let vElement = inputVal?.find(
      (v) => v.dataElementId === decoder(dElement.id)
    );
    return vElement ? vElement.value : '';
  };

  const getTableTypeElementsProps = (bool, item) => {
    return { isOpen: bool, tableId: item.id, tableName: item.name };
  };

  const onClickTableButton = (e, item) => {
    if (isTableViewPortalOpen && !isTableTypePortalOpen) {
      dispatch(setCurrentOpenTable(getTableTypeElementsProps(false, item)));
      toggleTableTypePortal(true);
      return;
    }
    if (isTableTypePortalOpen) {
      dispatch(setCurrentOpenTable(getTableTypeElementsProps(false, item)));
    } else {
      dispatch(setCurrentOpenTable(getTableTypeElementsProps(true, item)));
    }
  };

  const getSortedDataElements = () => {
    if (dataElements) {
      let elements = [...dataElements];
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].type === 'TABLE') {
          elements.push(elements.splice(i, 1)[0]);
        }
      }
      return elements;
    }
  };

  const getDataElementsLeftToFill = (inputs) => {
    return dataElements?.filter(
      (d) =>
        d.type !== 'TABLE' &&
        d.type !== 'SETTINGS' &&
        !inputs
          ?.filter((n) => !n.tableTypeColumnId)
          .map((n) => n.dataElementId)
          .includes(decoder(d.id))
    );
  };

  const handleSuppButtonClick = (dataElement, val) => {
    checkInputs(
      val.value,
      decoder(currentDocument.id),
      decoder(dataElement.id),
      croppedCoordinatesCopy.value
    );
  };

  const updateDataEntry = (cluster) => {
    toggleTableTypePortal(false);
    if (cluster && onClassificationGroupsTab) {
      dispatch(setSelectedCluster(cluster));
      getDocuments({
        variables: {
          filters: { clusterId: { eq: decoder(cluster.id) } },
        },
      });
    }
  };

  const toggleDrawerView = () => {
    dispatch(setAddSpacing(useForm));
    setShowForm(!useForm);
    handleClose();
  };

  const handleOpen = () => {
    dispatch(setModalOpen(!open));
  };

  const handleClose = () => {
    dispatch(setModalOpen(false));
  };
  //#endregion Component Functions

  //#region UseEffects
  /*
    To prevent unnecessary, expensive re-rendering, we use a state to control when the data entry table should update
    */
  useEffect(() => {
    if (currentCluster) {
      setCheckClusterId(currentCluster.id);
    }
  }, []);

  useEffect(() => {
    if (inputVal?.length) {
      setIsEveryInputFieldReviewed(
        inputVal.every((v) => v.status !== valueElementStatus.REVIEW_REQUIRED)
      );

      const dataElementsLeftToFill = getDataElementsLeftToFill(inputVal);

      setIsEveryInputFieldComplete({
        ...isEveryInputFieldComplete,
        incompleteFields: dataElementsLeftToFill,
      });
    }
  }, [inputVal]);

  useEffect(() => {
    if (
      showRadioAndCheckboxHighlights &&
      dataElements?.length > 0 &&
      inputVal?.length
    ) {
      let potentialHighlights = inputVal.map((v) => {
        let dElement = dataElements.find(
          (d) => decoder(d.id) === v.dataElementId
        );
        if (dElement) {
          return {
            value: v.value,
            coordinates: v.coordinates?.split(','),
            type: dElement.type,
          };
        }
      });

      if (potentialHighlights) {
        dispatch(setHighlightedValue(potentialHighlights));
      }
    } else {
      dispatch(setHighlightedValue([]));
    }
  }, [showRadioAndCheckboxHighlights]);

  useEffect(() => {
    setShouldTableRerender(Date.now());
  }, [isTableButtonDisabled, currentDocument?.id, currentProjectId]);

  useEffect(() => {
    if (currentProjectId && (isTableTypePortalOpen || isTableViewPortalOpen)) {
      toggleTableViewPortal(false);
      toggleTableTypePortal(false);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (isEveryInputFieldComplete.complete && isEveryInputFieldReviewed) {
      updateDocumentStatusAndDocumentList();
    }
  }, [isEveryInputFieldComplete, isEveryInputFieldReviewed]);

  useEffect(() => {
    isTableViewPortalOpen
      ? dispatch(setAddSpacing(false))
      : dispatch(setAddSpacing(true));
  }, [isTableViewPortalOpen]);

  useEffect(() => {
    if (extractionDone) {
      automaticToggle.current = true;
      getValueElements({ variables: { id: currentDocument?.id } });
    }
  }, [extractionDone]);

  useEffect(() => {
    isToggleManual.current = false;
    automaticToggle.current = false;
  }, [currentDocument?.id]);

  useEffect(() => {
    if (currentDocument) {
      getValueElements({ variables: { id: currentDocument?.id } });
      dispatch(setSelectedCluster(currentDocument?.cluster));
    }
  }, [currentDocument]);

  useEffect(() => {
    if (currentCluster) {
      getDataElements({ variables: { id: currentCluster?.id } });
      if (checkClusterId == currentCluster.id) {
        return;
      } else {
        dispatch(setExtractedData([]));
        dispatch(setExtractionDone(false));
        setCheckClusterId(currentCluster.id);
        dispatch(setNotReviewed(null));
      }
    }
  }, [currentCluster]);

  useEffect(() => {
    const nonTableCoordinates = croppedCoordinates?.field === 'DataElement';
    let currentElement= inputVal?.find((v) => decoder(selectedElementField?.id) === v?.dataElementId);
    
    if (croppedCoordinates?.value && nonTableCoordinates) {
      if (currentDocument?.isTemplate) {
        handleType(currentElement?.value, decoder(selectedElementField?.id));
        handleValueElementUpdate(
          currentElement?.value,
          decoder(currentDocument?.id),
          decoder(selectedElementField?.id),
          selectedElementField,
          croppedCoordinatesCopy?.value
        ).then(() => {
          if (
            isEveryInputFieldComplete.complete &&
            isEveryInputFieldReviewed &&
            !isToggleManual.current
          ) {
            updateDocumentStatusAndDocumentList();
          }
        });
      } else {
        checkForTemplateInCluster(
          currentElement?.value,
          decoder(currentDocument?.id),
          decoder(selectedElementField?.id),
          selectedElementField,
          croppedCoordinatesCopy?.value,
          handleType
        );
      }
    }
  }, [croppedCoordinates]);

  useEffect(() => {
    if (isTableOpen.tableId && dataElements?.length > 0) {
      let tableElement =
        dataElements.find((elem) => elem.id === isTableOpen.tableId) || [];
      setTableDataElement(tableElement);
    }
  }, [isTableOpen, dataElements]);

  useEffect(() => {
    if (tableDataElement && isTableOpen.isOpen) {
      getTableDataElements({ variables: { id: tableDataElement?.id } });
    }
  }, [tableDataElement, isTableOpen]);

  const clusterSelectDropdown = (
    <ClusterDropdown
      readOnly={!onClassificationGroupsTab}
      disableClearable
      value={currentCluster}
      onChange={(event, value) => updateDataEntry(value)}
      options={clusters}
      getOptionLabel={(options) => options.name}
      renderInput={(params) => (
        <STextField {...params} variant='outlined' size='small' />
      )}
    />
  );

  // #region Form View Mode
  const formView = (
    <>
      <FormViewContainer
        container
        alignItems='center'
        textAlign='center'
        spacing={1}
      >
        <Grid item xs={10} style={{ padding: '10px 5px 0 10px' }}>
          {clusterSelectDropdown}
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={'Change to Table View'}>
            <MoreIcon size='large'>
              <MoreVertIcon onClick={toggleDrawerView} />
            </MoreIcon>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sx={{ color: 'white' }}>
          <PageNavigator
            decrementIndex={decrementIndex}
            useForm={useForm}
            handleDocumentStatusUpdate={handleDocumentStatusUpdate}
            hasShowToggle
            incrementIndex={incrementIndex}
            docIndex={docIndex}
            isToggleManual={isToggleManual}
            postText={documents?.nodes?.length}
            preText={preText}
            currentDocument={currentDocument}
            pmIndex={pmIndex}
            isReadOnly={isReadOnly}
            projectMemberIdentificationHeader={
              projectMemberIdentificationHeader
            }
            handleOpen={handleOpen}
            currentMember={currentMember}
            projectMembers={projectMembers}
            isEveryInputFieldComplete={isEveryInputFieldComplete}
            isEveryInputFieldReviewed={isEveryInputFieldReviewed}
            organizationName={organizationName}
            projectMemberStatuses={projectMemberStatuses}
          />
        </Grid>
      </FormViewContainer>
    </>
  );
  // #endregion Form View Mode

  // #region Table View Mode
  const tableView = (
    <>
      <Grid container alignItems='center' textAlign='center'>
        <Grid item xs={4} padding={'1%'}>
          {clusterSelectDropdown}
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ color: 'white', paddingX: '1%' }}
          className='dataEntryPage-pageNavigator'
        >
          <PageNavigator
            decrementIndex={decrementIndex}
            handleDocumentStatusUpdate={handleDocumentStatusUpdate}
            hasShowToggle
            incrementIndex={incrementIndex}
            docIndex={docIndex}
            isToggleManual={isToggleManual}
            postText={documents?.nodes?.length}
            preText={preText}
            currentDocument={currentDocument}
            pmIndex={pmIndex}
            projectMembers={projectMembers}
            isReadOnly={isReadOnly}
            projectMemberIdentificationHeader={
              projectMemberIdentificationHeader
            }
            handleOpen={handleOpen}
            currentMember={currentMember}
            isEveryInputFieldComplete={isEveryInputFieldComplete}
            isEveryInputFieldReviewed={isEveryInputFieldReviewed}
            organizationName={organizationName}
            projectMemberStatuses={projectMemberStatuses}
          />
        </Grid>
        <Grid
          item
          xs={1}
          style={{ paddingTop: 0 }}
          className='dataEntryPage-additionalDocumentOptions'
        >
          <MoreIcon
            title={
              isTableViewPortalOpen ? 'Exit window mode' : 'Open in new window'
            }
            onClick={() => toggleTableViewPortal(!isTableViewPortalOpen)}
          >
            {isTableViewPortalOpen ? <OpenInNewOffIcon /> : <OpenInNewIcon />}
          </MoreIcon>

          {!isTableViewPortalOpen && (
            <MoreIcon title='Change to Form View'>
              <MoreVertIcon onClick={toggleDrawerView} />
            </MoreIcon>
          )}
        </Grid>
      </Grid>
    </>
  );

  const tableSets = (
    <>
      <TableWrapper item xs={12}>
        {dataElements?.length > 0 ? (
          <DataEntryTable
            shouldTableUpdate={shouldTableUpdate}
            checkInputs={checkInputs}
            crop={crop}
            croppedCoordinates={croppedCoordinates}
            currentDocument={currentDocument}
            dataElements={dataElements}
            getSortedDataElements={getSortedDataElements}
            handleSuppButtonClick={handleSuppButtonClick}
            handleType={handleType}
            inputVal={inputVal}
            inputValue={inputValue}
            isCropDisabled={isCropDisabled}
            isExtractionLoading={isExtractionLoading}
            isTableButtonDisabled={isTableButtonDisabled}
            isTableTypePortalOpen={isTableTypePortalOpen}
            onClickCrop={onClickCrop}
            onClickRemoveCrop={onClickRemoveCrop}
            onClickTableButton={onClickTableButton}
            onConfirmCrop={onConfirmCrop}
            onExitCropMode={onExitCropMode}
            selectedElementField={selectedElementField}
            supplementalData={supplementalData}
            croppedCoordinatesCopy={croppedCoordinatesCopy}
            isReadOnly={isReadOnly}
            valueElementsLoading={valueElementsLoading}
          />
        ) : (
          <Grid
            container
            alignItems='center'
            textAlign='center'
            sx={{ height: '17dvh' }}
          >
            <Grid item xs={12}>
              <Typography>
                No data elements assigned to this cluster.
              </Typography>
            </Grid>
          </Grid>
        )}
      </TableWrapper>
    </>
  );
  // #endregion Table View Mode

  const view = (
    <DrawerTable
      useForm={useForm}
      isPopout={isTableViewPortalOpen}
      variant='permanent'
      anchor={useForm ? 'right' : 'bottom'}
    >
      <Grid container spacing={2}>
        <DrawerHeader
          item
          xs={12}
          alignItems='center'
          justifyContent='space-between'
          useForm={useForm}
        >
          {useForm ? formView : tableView}
        </DrawerHeader>
        <Backdrop
          sx={{ color: '#fff', position: 'absolute' }}
          open={isExtractionLoading}
        >
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
          >
            <Grid xs={12}>
              <SCircularProgress />
            </Grid>
            <Grid xs={12}>
              <Typography> Posting Extracted Data...</Typography>
            </Grid>
          </Grid>
        </Backdrop>
        {useForm ? (
          <Grid item xs={12}>
            <DataEntryFormSets
              getSortedDataElements={getSortedDataElements}
              inputVal={inputVal}
              valueElementStatus={valueElementStatus}
              inputValue={inputValue}
              currentDocument={currentDocument}
              croppedCoordinates={croppedCoordinates}
              isCropDisabled={isCropDisabled}
              crop={crop}
              isTableTypePortalOpen={isTableTypePortalOpen}
              supplementalData={supplementalData}
              onClickCrop={onClickCrop}
              onClickRemoveCrop={onClickRemoveCrop}
              onExitCropMode={onExitCropMode}
              onConfirmCrop={onConfirmCrop}
              selectedElementField={selectedElementField}
              isTableButtonDisabled={isTableButtonDisabled}
              onClickTableButton={onClickTableButton}
              isReadOnly={isReadOnly}
              checkInputs={checkInputs}
              handleType={handleType}
              handleSuppButtonClick={handleSuppButtonClick}
              isExtractionLoading={isExtractionLoading}
              open={open}
            />
          </Grid>
        ) : (
          tableSets
        )}
      </Grid>
    </DrawerTable>
  );

  // to inject styled-components styling into window popout DOM
  //https://styled-components.com/docs/api#stylesheetmanager
  const [newWindowNode, setNewWindowNode] = useState(null);
  const nwRef = useCallback((node) => setNewWindowNode(node), []);

  const windowDrawerView = (
    <StyleSheetManager target={newWindowNode}>
      <MetlWindowPortal
        title='Data Entry Table View'
        windowClosing={() => toggleTableViewPortal(!isTableViewPortalOpen)}
        windowDimensions={`width=${
          window.screen.width - 400
        }, height=250, left=0, top=${window.screen.height}`}
      >
        <div ref={nwRef}>{view}</div>
      </MetlWindowPortal>
    </StyleSheetManager>
  );

  return (
    <>
      {isTableViewPortalOpen ? windowDrawerView : view}
      <DocumentsNavigatorDialog
        clusters={clusters}
        documents={documents?.nodes}
        decrementIndex={decrementIndex}
        docIndex={docIndex}
        handleClose={handleClose}
        incrementIndex={incrementIndex}
        inputValue={inputValue}
        isOpen={open}
        setDocIndex={setDocIndex}
        currentMember={currentMember}
        updateProjectMemberStatus={updateProjectMemberStatus}
        projectMemberIdentificationHeader={projectMemberIdentificationHeader}
        isReadOnly={isReadOnly}
        openReassignModal={openReassignModal}
        setOpenReassignModal={setOpenReassignModal}
        projectMemberStatuses={projectMemberStatuses}
        projectMembers={projectMembers}
        setProjectMembers={setProjectMembers}
      />
      <ValueElementDialogTable
        cluster={currentCluster}
        currentDocumentId={currentDocument?.id}
        getDataElements={getDataElements}
        getValueElements={getValueElements}
        getTableDataElements={getTableDataElements}
        isComplete={currentDocument?.isComplete}
        isTableOpen={isTableOpen.isOpen}
        selectedTableId={isTableOpen.tableId}
        selectedTableName={isTableOpen.tableName}
        tableDataElement={tableDataElement}
        tableColumns={tableDataElement?.tableData}
        toggleTableTypePortal={toggleTableTypePortal}
        isTableTypePortalOpen={isTableTypePortalOpen}
        inputVal={inputVal}
        setInputVal={setInputVal}
        checkInputCompleteStatus={checkInputCompleteStatus}
        /* To handle image crop */
        crop={crop}
        isCropDisabled={isCropDisabled}
        onClickCrop={onClickCrop}
        onClickRemoveCrop={onClickRemoveCrop}
        onConfirmCrop={onConfirmCrop}
        onExitCropMode={onExitCropMode}
        selectedElementField={selectedElementField}
        setIsCropDisabled={setIsCropDisabled}
        emailName={emailName}
        isReadOnly={isReadOnly}
        confirm={confirm}
        templateExistsInCluster={templateExistsInCluster}
        removeTemplateInDocument={removeTemplateInDocument}
        documentById={documentById}
        confirmCroppingMessage={confirmCroppingMessage}
        currentDocument={currentDocument}
      />
    </>
  );
}

DrawerView.propTypes = {
  currentCluster: PropTypes.object,
  currentDocument: PropTypes.object,
  currentProjectId: PropTypes.string,
  documents: PropTypes.object,
  setDocuments: PropTypes.any,
  getDocuments: PropTypes.any,
  checkProjectMemberStatus: PropTypes.func,
  checkDocumentStatus: PropTypes.func,
  allPms: PropTypes.any,
  valueElementStatus: PropTypes.any,
  onClassificationGroupsTab: PropTypes.bool,
  openReassignModal: PropTypes.bool,
  setOpenReassignModal: PropTypes.any,
  projectMembers: PropTypes.object,
  extractionDone: PropTypes.bool,
  emailName: PropTypes.string,
  valueElementsLoading: PropTypes.bool,
  pmIndex: PropTypes.number,
  projectMemberIdentificationHeader: PropTypes.string,
  checkClusterId: PropTypes.string,
  clusters: PropTypes.array,
  crop: PropTypes.object,
  croppedCoordinates: PropTypes.any,
  decrementIndex: PropTypes.func,
  docIndex: PropTypes.number,
  handleValueElementUpdate: PropTypes.func,
  incrementIndex: PropTypes.func,
  isCropDisabled: PropTypes.bool,
  isTableOpen: PropTypes.object,
  isExtractionLoading: PropTypes.bool,
  onClickCrop: PropTypes.func,
  onClickRemoveCrop: PropTypes.func,
  onConfirmCrop: PropTypes.func,
  onExitCropMode: PropTypes.func,
  selectedCluster: PropTypes.object,
  selectedElementField: PropTypes.object,
  setCheckClusterId: PropTypes.func,
  setDocIndex: PropTypes.func,
  setIsCropDisabled: PropTypes.func,
  setShowForm: PropTypes.func,
  supplementalData: PropTypes.array,
  useForm: PropTypes.bool,
  val: PropTypes.any,
  croppedCoordinatesCopy: PropTypes.any,
  getValueElements: PropTypes.func,
  inputVal: PropTypes.any,
  setInputVal: PropTypes.func,
  shouldTableUpdate: PropTypes.any,
  setShouldTableRerender: PropTypes.func,
  isReadOnly: PropTypes.bool,
  confirm: PropTypes.func,
  templateExistsInCluster: PropTypes.func,
  removeTemplateInDocument: PropTypes.func,
  documentById: PropTypes.func,
  confirmCroppingMessage: PropTypes.string,
};
