import { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { TaskBadge } from 'components/Badge';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardHeader from 'components/CardHeader';
import Fade from 'components/Fade';
import Grid from 'components/Grid';
import Modal from 'components/Modal';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import Typography from 'components/Typography';
import { ConfirmationModal } from 'containers/Modals';
import { displayTaskStatus } from 'helpers/enumToType';
import { decoder } from 'helpers/graphQL_Encoder';
import {
  AddTaskModal,
  AssignTaskModal,
  UpdateTaskModal,
} from 'pages/TaskList/modals';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ADD_TASK_ITEM,
  ASSIGN_TASK,
  REMOVE_TASK_ITEM,
} from 'services/GraphQL/Mutations';
import {
  LOAD_ITEM_STATUS_ENUMS,
  LOAD_PROJECT_TASKS,
  LOAD_TASK_TYPE_ENUMS,
} from 'services/GraphQL/Queries';
import {
  selectCurrentProjectId,
  selectCurrentResourceName,
  defaultResourceMenu,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarInfo,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import {
  addNewTask,
  handleAction,
  handleDeleteTask,
  mountInitialTask,
  selectTasks,
} from 'store/Redux/slices/taskSlice';
import {
  selectCurrentUserEmail,
  selectOrgMembers,
} from 'store/Redux/slices/usersSlice';
import styled from 'styled-components';
import ReactTable from 'containers/ReactTable/index';
import {
  DescriptionCell,
  AvailabilityCell,
} from 'containers/ReactTable/TableComponents/TaskListCells';
import CardContent from 'components/CardContent';
import List from 'components/List';
import ListItem from 'components/ListItem';
import ListItemText from 'components/ListItemText';
import { ListSubheader } from '@mui/material';
import { resources } from 'resource';

//#region Styled Components
const SCard = styled(Card)`
  padding: 15px;
  overflow: auto;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const AssignGrid = styled(FlexGrid)`
  display: ${(props) => (props.assignedTo ? null : 'none')};
`;

const ClaimModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Paper = styled(Card)`
  width: 400px;
  padding: 24px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  & .MuiCardHeader-root {
    padding: 0;
  }
`;

const CancelButton = styled(Button)`
  float: right;
`;

const Buttons = styled(CardActions)`
  padding-bottom: 20px;
`;

const SCardHeader = styled(CardHeader)`
  font-size: 20px;
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
`;

const SSubHeader = styled(ListSubheader)`
  font-size: 20px;
`;
//#endregion Styled Components

const getTaskDirectory = (row) => {
  let page;
  switch (row?.type) {
    case 'DATA_ENTRY':
      page = '/dataentry';
      break;
    case 'CLASSIFICATION':
      page = '/classification';
      break;
    default:
      page = '/tasklist';
      break;
  }
  return page;
};
//#region Components
function ClaimTaskModal(props) {
  const resourceMenu = useSelector(selectCurrentResourceName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const projectMemberTask = `${selectedResources.ANY_PAGES.PROJECT_MEMBER} Task`;

  const { itemInfo, isOpen, onModalExitClick, onModalConfirmClick } = props;

  let { id, __typename, assignedTo, ...newItem } = itemInfo;

  return (
    <ClaimModal open={isOpen} closeAfterTransition onClose={onModalExitClick}>
      <Fade in={isOpen}>
        <Paper style={{ width: '570px' }}>
          <Grid container>
            <Grid item xs={12}>
              <SCardHeader title={projectMemberTask} />
            </Grid>
            <Grid item xs={12}>
              <CardContent
                style={{
                  overflowY: 'auto',
                  maxHeight: '40vh',
                  textAlign: 'center',
                }}
              >
                <Typography>
                  Are you sure you want to claim this task? <br />
                  You may not unclaim without authorization.
                </Typography>
                <List
                  subheader={
                    <SSubHeader>
                      <b>Task Item Details</b>
                    </SSubHeader>
                  }
                >
                  {newItem &&
                    Object.entries(newItem).map(([key, value]) => (
                      <ListItem key={key} style={{ textAlign: 'center' }}>
                        <ListItemText primary={value} secondary={key} />
                      </ListItem>
                    ))}
                </List>
              </CardContent>
            </Grid>
            <Grid item xs={12}>
              <Buttons>
                <Grid item xs={6}>
                  <CancelButton
                    variant='outlined'
                    color='error'
                    onClick={() => onModalExitClick()}
                  >
                    Cancel
                  </CancelButton>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    primary
                    variant='contained'
                    onClick={() => onModalConfirmClick()}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Buttons>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </ClaimModal>
  );
}
//#endregion Components

export default function TaskList() {
  const dispatch = useDispatch();
  const taskStore = useSelector(selectTasks);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const currentGroupMembers = useSelector(selectOrgMembers);
  const currentUserEmail = useSelector(selectCurrentUserEmail);

  const [rowIndexBeingEdited, setRowIndexBeingEdited] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [updateTaskModal, setUpdateTaskModal] = useState(false);
  const [assignUserModal, setAssignUserModal] = useState(false);
  const [taskClaimModal, setTaskClaimModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [currentTask, setCurrentTask] = useState({});

  const currentTestUser = useRef(null);
  const currentTaskId = useRef(null);
  const currentTaskListId = useRef('');

  //#region graphQL
  const { data: itemStatusEnums } = useQuery(LOAD_ITEM_STATUS_ENUMS);
  const { data: taskTypeEnums } = useQuery(LOAD_TASK_TYPE_ENUMS);

  const [fetchTaskByProject, { refetch }] = useLazyQuery(LOAD_PROJECT_TASKS, {
    onCompleted: (data) => mountCurrentTasksToReduxStore(data),
    onError: (error) => taskFetchError(error),
    fetchPolicy: 'no-cache',
  });

  function mountCurrentTasksToReduxStore(data) {
    if (data) {
      const task = data?.projectById;
      currentTaskListId.current = task.taskList.id;
      dispatch(mountInitialTask(task.taskList));
    }
  }

  const [addTask, { error: addTaskError }] = useMutation(ADD_TASK_ITEM, {
    update(cache, { data: addedTask }) {
      dispatch(setSnackbarSuccess(`Task has been added to Project!`));
      dispatch(addNewTask({ newTaskObject: addedTask.addTaskItem }));
      setNewTaskModal(false);
    },
    onError: () => gqlErrorHandler(addTaskError),
  });

  const [removeTask, { error: removeTaskError }] = useMutation(
    REMOVE_TASK_ITEM,
    {
      update() {
        dispatch(setSnackbarInfo(`Task removed from Project`));
        dispatch(
          handleDeleteTask({
            taskId: currentTaskId.current,
          })
        );
        toggleDeleteConfirmModal();
      },
      onError: () => gqlErrorHandler(removeTaskError),
    }
  );

  function gqlErrorHandler(error) {
    if (currentProjectId) {
      dispatch(
        setSnackbarError(`Please try again as an error occurred: ` + error)
      );
    }
  }
  async function taskFetchError(error) {
    try {
      await refetch();
    } catch {
      console.error(error);
      dispatch(setSnackbarError(`Please try again`));
    }
  }

  const [assignTask] = useMutation(ASSIGN_TASK, {
    onCompleted: (data) => {
      if (data.assignTaskItem) {
        handleActionsAndSetNewState(data.assignTaskItem);
      }
    },
  });

  //#endregion graphQL

  //#region useEffect
  useEffect(() => {
    currentTestUser.current = testUser;
  }, []);

  useEffect(() => {
    if (currentProjectId) {
      fetchTaskByProject({ variables: { id: currentProjectId } });
    }
  }, [currentProjectId]);

  useEffect(() => {
    setRowIndexBeingEdited('');
  }, [taskStore.tasks]);
  //#endregion useEffect

  //#region Functions
  const handleTaskSelected = (row) => {
    setRowIndexBeingEdited(row.index);
    setCurrentTask(taskStore.tasks[row.index]);
    currentTaskId.current = row.original.id;
    setSelectedRow({ [row.id]: true });
  };

  function handleActionsAndSetNewState(assignedUser, availability) {
    dispatch(
      handleAction({
        assignedTo: assignedUser.assignedTo,
        availability: availability,
        taskId: currentTaskId.current,
      })
    );
  }

  const assignUserToTask = (assignedUser) => {
    let userName = assignedUser?.mail.substring(
      0,
      assignedUser.mail.lastIndexOf('@')
    );
    assignTask({
      variables: {
        input: { taskItemId: decoder(currentTask.id), username: userName },
      },
    });

    setAssignUserModal(!assignUserModal);
  };

  const unassignUserFromTask = () => {
    assignTask({
      variables: {
        input: { taskItemId: decoder(currentTask.id), username: null },
      },
    });
  };

  const confirmTaskClaim = () => {
    assignTask({
      variables: {
        input: {
          taskItemId: decoder(currentTask.id),
          username: currentUserEmail,
        },
      },
    });
    setTaskClaimModal(!taskClaimModal);
  };

  function onAddNewTask(newTaskObject) {
    const taskObject = Object.assign(newTaskObject, {
      taskListId: decoder(currentTaskListId.current),
    });
    if (taskObject) {
      addTask({ variables: { input: taskObject } });
    } else {
      console.error(`${taskObject} is invalid`);
    }
  }

  const onDeleteTask = () => {
    if (currentTaskListId.current) {
      removeTask({ variables: { taskItemId: decoder(currentTaskId.current) } });
    } else {
      console.error(`${currentTaskListId.current} is invalid`);
    }
  };

  const taskRenderer = (row) => {
    const page = getTaskDirectory(row);
    return (
      <Typography>
        <Link to={page}>
          <span>Go To Task</span>
        </Link>
      </Typography>
    );
  };

  const statusRenderer = (row) => {
    return (
      <FlexGrid container>
        <Grid item xs={1}>
          <TaskBadge rowStatus={row?.status} />
        </Grid>
        <Grid item xs={11}>
          <Typography>{displayTaskStatus(row?.status)}</Typography>
        </Grid>
      </FlexGrid>
    );
  };

  const assignedToRenderer = (row) => {
    let fullName = currentGroupMembers.find((x) =>
      x.mail.includes(row?.assignedTo)
    )?.displayName;
    return (
      <AssignGrid container assignedTo={fullName}>
        <Grid item xs={10}>
          <Typography>{fullName}</Typography>
        </Grid>
      </AssignGrid>
    );
  };

  const toggleDeleteConfirmModal = () =>
    setDeleteConfirmModal(!deleteConfirmModal);
  const toggleNewTaskModal = () => setNewTaskModal(!newTaskModal);
  const toggleUpdateTaskModal = () => setUpdateTaskModal(!updateTaskModal);
  const toggleAssignUserModal = () => setAssignUserModal(!assignUserModal);
  const toggleClaimModal = () => setTaskClaimModal(!taskClaimModal);
  //#endregion Functions

  const statusOptions = itemStatusEnums?.itemStatusEnums.enumValues.map(
    (e) => e.name
  );
  const typeOptions = taskTypeEnums?.taskTypeEnums.enumValues.map(
    (e) => e.name
  );

  //#region Table Columns
  const taskColumns = [
    {
      Header: 'Description',
      accessor: 'description',
      Cell: (props) => (
        <DescriptionCell
          {...props}
          toggleAssignUserModal={toggleAssignUserModal}
          toggleDeleteConfirmModal={toggleDeleteConfirmModal}
          toggleUpdateTaskModal={toggleUpdateTaskModal}
          unassignUserFromTask={unassignUserFromTask}
          currentUserRole={currentTestUser.current?.role}
          rowIndexBeingEdited={rowIndexBeingEdited}
        />
      ),
      width: 140,
    },
    {
      Header: 'Action',
      accessor: 'page',
      Cell: ({ row }) => taskRenderer(row.original),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => statusRenderer(row.original),
    },
    {
      Header: 'Assigned To',
      accessor: (row) => row.assignedTo,
      Cell: ({ row }) => assignedToRenderer(row.original),
    },
    {
      Header: 'Availability',
      accessor: 'availability',
      Cell: ({ row }) => (
        <AvailabilityCell
          row={row.original}
          rowIndexBeingEdited={rowIndexBeingEdited}
          toggleClaimModal={toggleClaimModal}
          index={row.index}
        />
      ),
    },
  ];
  //#endregion Table Columns

  return (
    <>
      <ProjectSelectedTemplate>
        <SCard>
          <ReactTable
            actions={{
              addRow: toggleNewTaskModal,
            }}
            columns={taskColumns}
            data={taskStore.tasks}
            hasAddRow={true}
            hasMinWidth={'800px'}
            header='Task List'
            onSelected={handleTaskSelected}
            selectedRow={selectedRow}
            useActionsColumn={false}
          />
        </SCard>
      </ProjectSelectedTemplate>

      <AddTaskModal
        handleModalClose={toggleNewTaskModal}
        handleModalConfirm={onAddNewTask}
        isOpen={newTaskModal}
        statusDropdownOptions={statusOptions}
        typeDropdownOptions={typeOptions}
      />
      <AssignTaskModal
        handleModalClose={toggleAssignUserModal}
        handleModalConfirm={assignUserToTask}
        isOpen={assignUserModal}
        userDropdownOptions={currentGroupMembers}
      />
      <UpdateTaskModal
        handleModalClose={toggleUpdateTaskModal}
        isOpen={updateTaskModal}
        selectedTask={currentTask}
        statusDropdownOptions={statusOptions}
        typeDropdownOptions={typeOptions}
      />
      <ClaimTaskModal
        itemInfo={currentTask}
        isOpen={taskClaimModal}
        onModalConfirmClick={confirmTaskClaim}
        onModalExitClick={toggleClaimModal}
      />
      <ConfirmationModal
        formType={'delete'}
        isOpen={deleteConfirmModal}
        onModalConfirmClick={onDeleteTask}
        onModalExitClick={toggleDeleteConfirmModal}
      />
    </>
  );
}

TaskList.propTypes = {
  row: PropTypes.object,
};

ClaimTaskModal.propTypes = {
  itemInfo: PropTypes.object,
  isOpen: PropTypes.bool,
  onModalConfirmClick: PropTypes.func,
  onModalExitClick: PropTypes.func,
};
const testUser = {
  id: 17,
  name: 'Mitchell Bernard',
  image: '',
  role: 'Admin',
};
