import { ResponsiveHeatMap } from '@nivo/heatmap';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import appTheme from 'theme';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Grid from 'components/Grid';
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';
import Button from 'components/Button';
import Typography from 'components/Typography';

const theme = {
  axis: {
    legend: {
      text: {
        fontSize: '18px',
        fontWeight: 'bolder',
      },
    },
    ticks: {
      text: {
        fontSize: '15px',
      },
    },
  },
  legends: {
    text: {
      fontSize: '12px',
    },
  },
};

const ChartToggleGroup = styled(ToggleButtonGroup)`
  & .Mui-selected {
    color: white;
    background-color: ${(props) => props.theme.palette.secondary.main};
    :hover {
      background-color: ${(props) => props.theme.palette.secondary.darker};
    }
  }
`;

const ChartToggleButton = styled(ToggleButton)`
  border-radius: 100px;
`;

const ProjectMemberGraphs = (props) => {
  const {
    rawData = [],
    selectedOption = {},
    chartOptions = [],
    setChartOptions = () => null,
    toggleUserInReviewData = () => null,
    refreshPageData = () => null,
    chartLoading = false,
    setDateCategories = () => null,
    handleOnChartClick = () => null,
  } = props;

  const chartHeaders = [
    'Less than 1 day ago',
    '1-2 days ago',
    '2-3 days ago',
    'More than 3 days ago',
  ];
  const maxUserLabelLength = 11;

  const [chartData, setChartData] = useState([]);
  const [selectedSubChart, setSelectedSubChart] = useState(null);

  const prepareDataForCharts = (rawData, subChart = null) => {
    let dateCategories = [];
    let dataByGroup = Object.groupBy(rawData?.nodes, (r) => {
      if (r.totalDataElements != null) {
        const dElementCategory =
          r.totalDataElements === 0
            ? `${r.totalDataElements} Data Elements`
            : `Data Elements > 0`;
        return dElementCategory;
      } else if (r.assignedTo) {
        return r.assignedTo;
      } else {
        return 'unassigned';
      }
    });

    if (Object.entries(dataByGroup)?.length > 0) {
      let data = Object.entries(dataByGroup).map(([key, rawData]) => {
        let lessThanOneDay = { count: 0, dates: [] };
        let coupleDays = { count: 0, dates: [] };
        let fewDays = { count: 0, dates: [] };
        let morethanFewDays = { count: 0, dates: [] };

        rawData.forEach((rd) => {
          const dateGroup = subChart?.dateGroup;
          let formattedDate = new Date(
            dateGroup ? rd[dateGroup] : rd.createdOn
          );
          let oneDayAgo = new Date(
            new Date().setDate(new Date().getDate() - 1)
          );
          let twoDaysAgo = new Date(
            new Date().setDate(new Date().getDate() - 2)
          );
          let threeDaysAgo = new Date(
            new Date().setDate(new Date().getDate() - 3)
          );

          if (formattedDate < threeDaysAgo) {
            morethanFewDays = {
              count: morethanFewDays.count + 1,
              dates: [...morethanFewDays.dates, rd],
            };
            return;
          } else if (
            formattedDate <= twoDaysAgo &&
            formattedDate >= threeDaysAgo
          ) {
            fewDays = {
              count: fewDays.count + 1,
              dates: [...fewDays.dates, rd],
            };
            return;
          } else if (
            formattedDate <= oneDayAgo &&
            formattedDate >= twoDaysAgo
          ) {
            coupleDays = {
              count: coupleDays.count + 1,
              dates: [...coupleDays.dates, rd],
            };
            return;
          } else {
            lessThanOneDay = {
              count: lessThanOneDay.count + 1,
              dates: [...lessThanOneDay.dates, rd],
            };
          }
        });

        let dateCategory = {
          assignedTo: key,
          dates: [
            {
              [chartHeaders[0]]: lessThanOneDay.dates,
              [chartHeaders[1]]: coupleDays.dates,
              [chartHeaders[2]]: fewDays.dates,
              [chartHeaders[3]]: morethanFewDays.dates,
            },
          ],
        };
        dateCategories = [...dateCategories, dateCategory];

        return {
          id: key,
          data: [
            {
              x: chartHeaders[0],
              y: lessThanOneDay.count,
            },
            {
              x: chartHeaders[1],
              y: coupleDays.count,
            },
            {
              x: chartHeaders[2],
              y: fewDays.count,
            },
            {
              x: chartHeaders[3],
              y: morethanFewDays.count,
            },
          ],
        };
      });

      setDateCategories(dateCategories);

      let unassignedIndex = data.findIndex((u) => u.id === 'unassigned');
      data.unshift(data.splice(unassignedIndex, 1)[0]);
      setChartData(data);
    } else {
      setChartData([]);
    }
  };

  const handleSubChartChange = (e) => {
    const sub = e.target.value;

    const updatedChartOptions = chartOptions?.map((option) => {
      if (option?.subCharts?.length) {
        const updatedSubCharts = option.subCharts.map((s) => {
          if (s.name == sub) {
            const updatedSub = { ...s, selected: true };
            setSelectedSubChart(updatedSub);
            return updatedSub;
          }
          return { ...s, selected: false };
        });
        option.subCharts = updatedSubCharts;
      }
      return option;
    });

    setChartOptions(updatedChartOptions);
  };

  useEffect(() => {
    if (rawData?.nodes?.length) {
      prepareDataForCharts(rawData, selectedSubChart);
    } else {
      setChartData([]);
    }
  }, [rawData]);

  useEffect(() => {
    if (rawData?.nodes?.length) {
      if (selectedSubChart?.useInReviewData) {
        toggleUserInReviewData(true);
      } else {
        toggleUserInReviewData(false);
        prepareDataForCharts(rawData, selectedSubChart);
      }
    }
  }, [selectedSubChart]);

  useEffect(() => {
    if (selectedOption?.subCharts?.length) {
      setSelectedSubChart(selectedOption?.subCharts?.find((s) => s.selected));
    } else {
      toggleUserInReviewData(false);
    }
  }, [selectedOption]);

  const TruncatedTick = ({
    opacity,
    textAnchor,
    textBaseline,
    lineX,
    lineY,
    textX,
    textY,
    value,
    x,
    y,
  }) => {
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <line
          style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: '1' }}
          x1={lineX}
          y1={lineY}
        ></line>
        <text
          title={value}
          alignmentBaseline={textBaseline}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY})`}
        >
          <title>{value}</title>
          {value.length > maxUserLabelLength
            ? value.substring(0, maxUserLabelLength) + '...'
            : value}
        </text>
      </g>
    );
  };

  TruncatedTick.propTypes = {
    opacity: PropTypes.any,
    textAnchor: PropTypes.any,
    textBaseline: PropTypes.any,
    lineX: PropTypes.any,
    lineY: PropTypes.any,
    textX: PropTypes.any,
    textY: PropTypes.any,
    value: PropTypes.any,
    x: PropTypes.any,
    y: PropTypes.any,
  };

  const responsiveRadialBar = (
    <ResponsiveRadialBar
      data={chartData}
      valueFormat='>-.2~f'
      padding={0.4}
      cornerRadius={2}
      enableLabels
      theme={theme}
      labelsTextColor='white'
      borderColor={['#000000']}
      borderWidth={1}
      innerRadius={0}
      labelsSkipAngle={2}
      margin={{ top: 60, right: 40, bottom: 40, left: 40 }}
      radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      colors={[
        `${appTheme.palette.primary.main}`,
        'rgb(255, 170, 51)',
        'rgb(255, 95, 31)',
        `${appTheme.palette.error.darker}`,
      ]}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -60,
          itemsSpacing: 6,
          itemDirection: 'left-to-right',
          itemWidth: 140,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 18,
          symbolShape: 'square',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
          onMouseEnter: (_datum, event) => {
            event.currentTarget.style.cursor = 'pointer';
          },
        },
      ]}
      onMouseEnter={(_datum, event) => {
        event.currentTarget.style.cursor = 'pointer';
      }}
      onClick={(cell, e) => handleOnChartClick(cell, e)}
    />
  );

  const responsiveHeatMap = (
    <ResponsiveHeatMap
      data={chartData}
      theme={theme}
      margin={{ top: 60, right: 90, bottom: 60, left: 150 }}
      valueFormat='>-.2~f'
      labelTextColor='black'
      emptyColor='#555555'
      inactiveOpacity={0.4}
      hoverTarget='cell'
      colors={{
        type: 'sequential',
        colors: [
          `${appTheme.palette.primary.main}`,
          `${appTheme.palette.error.darker}`,
        ],
      }}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: selectedOption?.label,
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -55,
        legend: 'Users',
        legendPosition: 'end',
        legendOffset: -130,
        renderTick: TruncatedTick,
      }}
      legends={[
        {
          anchor: 'bottom',
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: 'row',
          tickPosition: 'after',
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          title: 'Amount of Project Members →',
          titleAlign: 'start',
          titleOffset: 4,
        },
      ]}
      onClick={(cell, e) => handleOnChartClick(cell, e)}
    />
  );

  const chartContainer = (
    <>
      {selectedOption?.subCharts?.length > 0 && (
        <Grid item xs={12} height='auto'>
          <ChartToggleGroup
            exclusive
            value={selectedSubChart?.name}
            onChange={handleSubChartChange}
          >
            {selectedOption.subCharts.map((sub) => (
              <ChartToggleButton key={sub.id} value={sub.name}>
                {sub.label}
              </ChartToggleButton>
            ))}
          </ChartToggleGroup>
        </Grid>
      )}
      <Grid item xs={12} height={'70dvh'}>
        {chartLoading ? (
          <Grid item xs={12} sx={{ height: 'inherit', padding: '2%' }}>
            <Skeleton
              variant='rectangular'
              height={'80%'}
              sx={{ marginBottom: '1%' }}
            />
            <Skeleton variant='rectangular' height={'20%'} />
          </Grid>
        ) : (
          <>
            {selectedOption?.radial ? responsiveRadialBar : responsiveHeatMap}
          </>
        )}
      </Grid>
      <Grid item xs={12} textAlign='end' height='auto'>
        <Button
          variant='contained'
          secondary
          onClick={() => refreshPageData()}
          disabled={rawData?.nodes?.length === 0}
        >
          Refresh Chart and Table Data
        </Button>
      </Grid>
    </>
  );

  const noDataContainer = (
    <Grid item xs={12}>
      <Typography fontWeight='bolder' sx={{ padding: '3%' }}>
        No Data to Display
      </Typography>
    </Grid>
  );

  return (
    <Grid container spacing={1} height='100%'>
      {!chartLoading && chartData?.length === 0
        ? noDataContainer
        : chartContainer}
    </Grid>
  );
};
export default ProjectMemberGraphs;

ProjectMemberGraphs.propTypes = {
  rawData: PropTypes.any,
  selectedOption: PropTypes.object,
  chartOptions: PropTypes.array,
  setChartOptions: PropTypes.func,
  toggleUserInReviewData: PropTypes.func,
  refreshPageData: PropTypes.func,
  chartLoading: PropTypes.bool,
  setDateCategories: PropTypes.func,
  handleOnChartClick: PropTypes.func,
};
