import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import Fade from 'components/Fade';
import FormControl from 'components/FormControl';
import Grid from 'components/Grid';
import InputLabel from 'components/InputLabel';
import MenuItem from 'components/MenuItem';
import Modal from 'components/Modal';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { displayTaskStatus, displayTaskType } from 'helpers/enumToType';
import { decoder } from 'helpers/graphQL_Encoder';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { UPDATE_TASK_ITEM } from 'services/GraphQL/Mutations';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import { updateTask } from 'store/Redux/slices/taskSlice';
import styled from 'styled-components';

//#region Styled Components
const PaddedGrid = styled(Grid)`
  padding: 8px;
`;

const SModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled(CardContent)`
  padding: 16px;
  height: auto;
`;

const ActionsArea = styled(CardActions)`
  display: grid;
  place-items: end;
`;

const ConfirmButton = styled(Button)`
  margin-right: 5px;
`;

const SCard = styled(Card)`
  width: 570px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  padding: 24px;
  & .MuiCardHeader-root {
    padding: 0;
  }
`;
//#endregion Styled Components

export default function UpdateTaskModal(props) {
  const {
    handleModalClose,
    isOpen,
    statusDropdownOptions = [],
    typeDropdownOptions = [],
    selectedTask,
  } = props;

  const dispatch = useDispatch();

  const initialForm = selectedTask;

  const [form, setForm] = useState(initialForm);

  //#region graphQL
  const [updateTaskItem] = useMutation(UPDATE_TASK_ITEM, {
    onCompleted: (data) => {
      dispatch(
        updateTask({
          task: data.updateTaskItem,
        })
      );
      setForm(data.updateTaskItem);
      onUpdateSuccess();
    },
    onError: () => onUpdateError(),
  });

  const onUpdateSuccess = () => {
    handleModalClose();
    dispatch(setSnackbarSuccess(`Task updated`));
  };

  const onUpdateError = () => {
    dispatch(setSnackbarError(`An error occurred`));
  };
  //#endregion graphQL

  const onSelectType = (e) => {
    setForm({ ...form, type: e.target.value });
  };

  const onSelectStatus = (e) => {
    setForm({ ...form, status: e.target.value });
  };

  const onChangeDescription = (e) => {
    setForm({ ...form, description: e.target.value });
  };

  const onClickConfirm = () => {
    updateTaskItem({
      variables: {
        input: {
          taskItemId: decoder(selectedTask.id),
          status: form.status,
          type: form.type,
          description: form.description,
        },
      },
    });
  };

  useEffect(() => {
    setForm(selectedTask);
  }, [selectedTask]);

  const typeDropdown = (
    <FormControl variant='outlined' fullWidth>
      <InputLabel>Type</InputLabel>
      <Select
        defaultValue={selectedTask?.type}
        onChange={onSelectType}
        label='Type'
        disableAnimation
      >
        {typeDropdownOptions.map((p) => (
          <MenuItem key={p.id} value={p}>
            {displayTaskType(p)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const statusDropdown = (
    <FormControl variant='outlined' fullWidth>
      <InputLabel>Status</InputLabel>
      <Select
        defaultValue={selectedTask?.status}
        onChange={onSelectStatus}
        label='Status'
        disableAnimation
      >
        {statusDropdownOptions.map((s) => (
          <MenuItem key={s} value={s}>
            {displayTaskStatus(s)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const actionButtons = (
    <ActionsArea>
      <Grid item xs={12}>
        <ConfirmButton
          primary
          variant='contained'
          onClick={onClickConfirm}
          disabled={initialForm === form}
        >
          Confirm
        </ConfirmButton>
        <Button variant='outlined' color='error' onClick={handleModalClose}>
          Cancel
        </Button>
      </Grid>
    </ActionsArea>
  );

  return (
    <SModal open={isOpen} closeAfterTransition onClose={handleModalClose}>
      <Fade in={isOpen}>
        <SCard>
          <Grid container>
            <Grid item xs={12}>
              <CardHeader title='Edit Task' />
            </Grid>
          </Grid>
          <Grid container direction='column' spacing={2}>
            <InputContainer>
              <PaddedGrid item lg={12} xs={12}>
                {typeDropdown}
              </PaddedGrid>
              <PaddedGrid item lg={12}>
                {statusDropdown}
              </PaddedGrid>
              <PaddedGrid item lg={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={15}
                  label='Description'
                  defaultValue={selectedTask.description}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant='outlined'
                  onChange={onChangeDescription}
                />
              </PaddedGrid>
            </InputContainer>
            {actionButtons}
          </Grid>
        </SCard>
      </Fade>
    </SModal>
  );
}

UpdateTaskModal.propTypes = {
  handleModalClose: PropTypes.func,
  isOpen: PropTypes.bool,
  selectedTask: PropTypes.object,
  statusDropdownOptions: PropTypes.array,
  typeDropdownOptions: PropTypes.array,
};
