import AppBar from 'components/AppBar';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Button from 'components/Button';
import { config } from 'config';
import CookieIcon from '@mui/icons-material/Cookie';
import CookiesDescriptionDialog from 'components/Dialog/CookiesDescriptionDialog';
import CookiesNoticeDialog from 'components/Dialog/CookiesNoticeDialog';
import Divider from 'components/Divider';
import FormControl from 'components/FormControl';
import IconButton from 'components/IconButton';
import InputLabel from 'components/InputLabel';
import { Link } from 'react-router-dom';
import LinearProgress from 'components/LinearProgress';
import ListItemIcon from 'components/ListItemIcon';
import MailIcon from '@mui/icons-material/Mail';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import MuiLink from 'components/Link';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import Select from 'components/Select';
import ShieldIcon from '@mui/icons-material/Shield';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import styled from 'styled-components';
import Toolbar from 'components/Toolbar';
import TourIcon from '@mui/icons-material/Tour';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';

// IntroJS Imports
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../IntroJSThemes/introjs-nassim.css';
import { IntroJsSteps } from '../../helpers/introJsSteps.js';

// Redux Imports
import { setSnackbarError } from 'store/Redux/slices/snackbarsSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentProjectIndex,
  loadProjectsList,
  setCurrentProjectId,
  setCurrentOrganizationName,
  setCurrentOrganizationId,
  setReadToken,
  selectProjectsList,
  selectCurrentProjectId,
  selectCurrentOrganizationId,
  setCurrentClusterIndex,
  selectGlobalLoading,
  setValueElementStatusTypes,
  setCurrentResourceName,
  setProjectMemberStatusTypes,
  setStatusJustificationTypes,
  loadCurrentProject,
  setFileCompleted
} from 'store/Redux/slices/projectsSlice';
import {
  selectCurrentMsUser,
  selectUserProfilePhoto,
  selectMsGroupData,
  setCurrentGroup,
} from 'store/Redux/slices/usersSlice';

// GraphQL Imports
import { decoder, encoder } from 'helpers/graphQL_Encoder';
import {
  LOAD_ORGANIZATIONS,
  LOAD_PROJECTS_BY_ORGANIZATION_ID,
  LOAD_PROJECT_MEMBER_STATUS_TYPE_ENUMS,
  LOAD_STATUS_JUSTIFICATION_TYPE_ENUMS,
  LOAD_USER_PROJECTS,
  LOAD_VALUE_ELEMENT_STATUS_TYPE_ENUMS,
  READ_TOKEN,
} from 'services/GraphQL/Queries';

//#region Styled Components
const SFormControl = styled(FormControl)`
  & div {
    color: white;
  }
  & svg {
    color: white;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid white;
  }
  & .MuiInputLabel-root,
  .MuiOutlinedInput-notchedOutline {
    border-color: white;
    color: white;
  }
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid white !important;
    opacity: 1;
  }
`;

const SAppBar = styled(AppBar)`
  z-index: 1002;
  background-color: ${(props) => props.theme.palette.background.black};
`;

const ViewCookiesButton = styled(Button)`
  width: 100%;
  text-transform: none;
  text-decoration: underline;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.darker};
  &:hover {
    text-decoration: underline;
    background-color: ${(props) => rgba(props.theme.palette.primary.main, 0.1)};
  },
`;

const AvatarIconButton = styled(IconButton)`
  padding: 4px;
  &:hover {
    background-color: ${(props) => rgba(props.theme.palette.primary.main, 0.3)};
  }
`;

const LogoButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.3em;
  font-weight: bold;
  text-transform: none;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: inherit;
  }
`;

const NameMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  opacity: 1 !important;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const CookiesDropdownLink = styled(MuiLink)`
  &:hover {
    color: inherit;
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      minWidth: 45,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 18,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
      '& .MuiList-root': {
        maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
        flexGrow: 1,
        overflowY: 'auto',
        // Scrollbar Styling
        '&::-webkit-scrollbar': {
          width: '0.6em',
          height: '0.6em',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: 'rgba(0,0,0,0.2)',
          border: '3px solid transparent',
          backgroundClip: 'content-box',
        },
      },
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
};

const PaperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 20,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};
//#endregion Styled Components

export default function TopMenuItems() {
  const dispatch = useDispatch();

  const availableProjects = useSelector(selectProjectsList);
  const [cookiesNoticeOpen, setcookiesNoticeOpen] = useState(false);
  const [cookiesSelectionOpen, setcookiesSelectionOpen] = useState(false);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const currentMsUser = useSelector(selectCurrentMsUser);
  const history = useHistory();
  const loading = useSelector(selectGlobalLoading);
  const msGroups = useSelector(selectMsGroupData);
  const organizationId = useSelector(selectCurrentOrganizationId);
  const profilePhoto = useSelector(selectUserProfilePhoto);

  const [getSasToken] = useLazyQuery(READ_TOKEN, {
    onCompleted: (data) => dispatch(setReadToken(data.storageAccountReadToken)),
  });

  /* Use for ALL PROJECTS */
  const [loadProjectsByOrganizationId] = useLazyQuery(
    LOAD_PROJECTS_BY_ORGANIZATION_ID,
    {
      onCompleted: (allProjects) => {
        if (allProjects?.organizationById) {
          mountProjectListToReduxStore(allProjects.organizationById.projects);
          if (allProjects.organizationById.projects.length === 1) {
            dispatch(
              setCurrentProjectId(allProjects.organizationById.projects[0].id)
            );
            dispatch(setCurrentProjectIndex(0));
            dispatch(setCurrentClusterIndex(undefined));
          }
        }
      },
      errorPolicy: 'ignore',
    }
  );

  /* Use for Limited Projects */
  const [loadUserProjects] = useLazyQuery(LOAD_USER_PROJECTS, {
    onCompleted: (limitedProjects) => {
      if (limitedProjects?.projectsByUserGroups) {
        // dispatch(
        //   setCurrentOrganizationId(
        //     limitedProjects?.projectsByUserGroups[0].organizationId
        //   )
        // );
        mountProjectListToReduxStore(limitedProjects.projectsByUserGroups);
        if (limitedProjects.projectsByUserGroups.length === 1) {
          dispatch(
            setCurrentProjectId(limitedProjects.projectsByUserGroups[0].id)
          );
          dispatch(setCurrentProjectIndex(0));
          dispatch(setCurrentClusterIndex(undefined));
        }
      }
    },
    errorPolicy: 'ignore',
  });

  const [loadOrganizations] = useLazyQuery(LOAD_ORGANIZATIONS, {
    onCompleted: (data) => {
      if (data?.organizations?.length > 0) {
        // For now, we will hardcode demo org because we only have one organization with all our data
        // At some point we will implement this with multiple organizations by user
        let org = data.organizations.find(
          (o) => decoder(o.id) === '0669628ddc1344afbe97f917f2c8e588'
        );
        if (org) {
          dispatch(setCurrentOrganizationName(org.name));
          dispatch(setCurrentOrganizationId(decoder(org.id)));
        } else {
          dispatch(setSnackbarError('Organization ID could not be found!'));
        }
      }
    },
  });

  useQuery(LOAD_VALUE_ELEMENT_STATUS_TYPE_ENUMS, {
    onCompleted: (data) => {
      if (data?.valueElementStatusTypeEnums.enumValues) {
        dispatch(
          setValueElementStatusTypes(
            data.valueElementStatusTypeEnums.enumValues
          )
        );
      }
    },
  });

  useQuery(LOAD_PROJECT_MEMBER_STATUS_TYPE_ENUMS, {
    onCompleted: (data) => {
      if (data?.projectMemberStatusTypeEnums.enumValues) {
        dispatch(
          setProjectMemberStatusTypes(
            data.projectMemberStatusTypeEnums.enumValues
          )
        );
      }
    },
  });

  useQuery(LOAD_STATUS_JUSTIFICATION_TYPE_ENUMS, {
    onCompleted: (data) => {
      if (data?.statusJustificationTypeEnums.enumValues) {
        dispatch(
          setStatusJustificationTypes(
            data.statusJustificationTypeEnums.enumValues
          )
        );
      }
    },
  });

  useEffect(() => {
    if (organizationId) {
      getSasToken({
        variables: {
          id: `${encoder('SasTokenQueries', organizationId)}`,
        },
      });
    }
  }, [organizationId]);

  useEffect(() => {
    if (currentMsUser) {
      loadOrganizations();
      if (
        config.ENVIRONMENT == 'staging' ||
        config.ENVIRONMENT == 'production'
      ) {
        const groups = getUserGroupNames();
        if (groups.length > 0) {
          loadUserProjects({ variables: { groups: groups } });
        }
      } else if (organizationId) {
        loadProjectsByOrganizationId({
          variables: {
            id: encoder('Organization', organizationId),
          },
        });
      }
    }
  }, [currentMsUser, organizationId]);

  useEffect(() => {
    const currentProject = availableProjects.find(
      (project) => project.id === currentProjectId
    );

    dispatch(
      setCurrentResourceName(currentProject?.resourceName?.toLowerCase())
    );
    dispatch(loadCurrentProject(currentProject));

    const currentGroup = msGroups.find(
      (group) => group.displayName === currentProject?.name
    );

    if (currentGroup) {
      dispatch(setCurrentGroup(currentGroup));
    } else {
      dispatch(setCurrentGroup({}));
    }
  }, [availableProjects, currentProjectId, msGroups]);

  /* Use for Limited Projects */
  const getUserGroupNames = () => {
    let userGroupNames = [];
    if (currentMsUser.groups) {
      currentMsUser.groups.forEach((groupName) => {
        userGroupNames.push({ displayName: groupName });
      });
    }
    return userGroupNames;
  };

  const mountProjectListToReduxStore = (projects) => {
    const projectDropdown = projects.map((project) => ({
      id: project.id,
      name: project.name,
      organizationId: project.organizationId,
      createdOn: project.createdOn,
      lastModifiedOn: project.lastModifiedOn,
      isComplete: project.isComplete,
      resourceName: project.resourceName,
    }));
    projectDropdown.sort((a, b) => a.name.localeCompare(b.name));
    dispatch(loadProjectsList(projectDropdown));
  };

  const handleProjectChange = (event, index) => {
    dispatch(setCurrentProjectId(event.target.value));
    dispatch(setCurrentProjectIndex(index.props.index));
    dispatch(setCurrentClusterIndex(undefined));
    dispatch(setFileCompleted([]));
    history.push('/clusters');
  };

  const handleCookiesNoticeDialog = () => {
    setcookiesNoticeOpen(true);
  };
  const handleCookiesDescriptionDialog = () => {
    setcookiesSelectionOpen(true);
  };

  /* Start IntroJS Functions */
  const handleSteps = () => {
    if (currentProjectId === 0) {
      if (location.pathname == '/records') return IntroJsSteps.recordsPage;
      else if (location.pathname == '/assign') return IntroJsSteps.assignPage;
      else return IntroJsSteps.landingPage;
    } else {
      switch (location.pathname) {
        case '/records':
          return IntroJsSteps.recordsPage;
        case '/assign':
          return IntroJsSteps.assignPage;
        case '/import':
          return IntroJsSteps.importPage;
        case '/clusters':
          return IntroJsSteps.clustersPage;
        case '/elementassign':
          return IntroJsSteps.assignElementsPage;
        case '/select':
          return IntroJsSteps.selectPage;
        case '/dataentry':
          return IntroJsSteps.dataEntryPage;
        case '/analysis':
          return IntroJsSteps.validationPage;
        default:
          return IntroJsSteps.error;
      }
    }
  };
  const toggleSteps = introJs();
  toggleSteps.setOptions({
    initialStep: 0,
    stepsEnabled: true,
    showStepNumbers: true,
    scrollTo: 'tooltip',
    steps: handleSteps(),
  });

  toggleSteps.onbeforechange(function () {
    if (
      this._currentStep === this._introItems.length - 1 ||
      location.pathname === '/dataentry'
    ) {
      window.scrollTo(0, 0);
    }
  });
  /* End IntroJS Functions */

  const profileDropdown = () => {
    return (
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <>
            <AvatarIconButton size='small' {...bindTrigger(popupState)}>
              <Avatar src={profilePhoto.isValid ? profilePhoto.image : ''} />
            </AvatarIconButton>
            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={PaperProps}
            >
              <NameMenuItem disabled>{currentMsUser?.displayName}</NameMenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  popupState.close();
                  toggleSteps.start();
                }}
              >
                <ListItemIcon>
                  <TourIcon fontSize='small' />
                </ListItemIcon>
                Walkthrough
              </MenuItem>
              <MenuItem
                href={'https://cookienotice.deloitte.com/'}
                component={CookiesDropdownLink}
                rel='noopener noreferrer'
                target='_blank'
                onClick={() => popupState.close()}
              >
                <ListItemIcon>
                  <CookieIcon fontSize='small' />
                </ListItemIcon>
                Cookies
              </MenuItem>
              <MenuItem
                containerElement={
                  <Link to='https://cookienotice.deloitte.com/' />
                }
                onClick={() => {
                  popupState.close();
                  handleCookiesNoticeDialog();
                }}
              >
                <ListItemIcon>
                  <ShieldIcon fontSize='small' />
                </ListItemIcon>
                Privacy
              </MenuItem>
              <MenuItem onClick={() => {}}>
                <ListItemIcon>
                  <StickyNote2Icon fontSize='small' />
                </ListItemIcon>
                Release Notes
              </MenuItem>
              <MenuItem onClick={() => {}}>
                <ListItemIcon>
                  <MailIcon fontSize='small' />
                </ListItemIcon>
                Email Support
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    );
  };

  return (
    <SAppBar data-cy='app-bar' position='fixed'>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <LogoButton disableRipple to={'/clusters'} component={Link}>
          SmarText
        </LogoButton>
        <StyledBox>
          <SFormControl
            sx={{ m: 1, minWidth: 120 }}
            size='small'
            className='landingPage-projectDropDown'
          >
            <InputLabel id='select-small'>Project</InputLabel>
            <Select
              autoWidth
              data-cy='project-dropdown'
              labelId='select-small'
              id='demo-select-small'
              value={currentProjectId ? currentProjectId : ''}
              label='Project'
              onChange={(event, index) => handleProjectChange(event, index)}
              MenuProps={MenuProps}
            >
              {availableProjects.map((project, index) => (
                <MenuItem
                  key={project.id}
                  value={project.id}
                  index={index}
                  selected={currentProjectId === project.id}
                >
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </SFormControl>
          {profileDropdown()}
        </StyledBox>
      </Toolbar>
      <CookiesNoticeDialog
        isOpen={cookiesNoticeOpen}
        isClosed={(event, reason) => {
          if (reason && reason == 'backdropClick') return;
          setcookiesNoticeOpen(false);
        }}
      >
        <CookiesDescriptionDialog
          isOpen={cookiesSelectionOpen}
          isClosed={() => setcookiesSelectionOpen(false)}
        />
        <ViewCookiesButton
          variant='text'
          onClick={() => {
            handleCookiesDescriptionDialog();
          }}
        >
          View Cookies
        </ViewCookiesButton>
      </CookiesNoticeDialog>

      {loading && (
        <Box style={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
    </SAppBar>
  );
}

TopMenuItems.propTypes = {
  logout: PropTypes.func,
};
