import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import CircularProgress from 'components/CircularProgress';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import DialogTitle from 'components/DialogTitle';
import Grid from 'components/Grid';
import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import LightToolTip from 'components/Tooltip/LightToolTip';
import { TableColumnEditModal } from 'containers/Modals';
import EditableCell from 'containers/ReactTable/TableComponents/EditableCell';
import ReactTable from 'containers/ReactTable/index';
import CurrentDocumentCard from 'containers/Views/CurrentDocument';
import { decoder } from 'helpers/graphQL_Encoder';
import useClusterer from 'hooks/useClusterer';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  BULK_ADD_CLUSTER_DATA_ELEMENT,
  RECLUSTER_DOCUMENTS,
  REMOVE_CLUSTER_DATA_ELEMENT,
  RENAME_CLUSTER,
  CONFIRMED_DOCUMENT_IN_CLUSTER,
} from 'services/GraphQL/Mutations';
import {
  GET_CLUSTERS_BY_PROJECT,
  GET_PROJECT_DOCUMENTS,
  REQUEST_CLUSTER,
  GET_DOCS_BY_CLUSTER,
  GET_DATA_ELEMENTS_BY_CLUSTER,
  GET_TABLEDATA_BY_DATAELEMENT_ID,
  GET_DATA_ELEMENTS_BY_PROJECT,
  GET_PRESIGNED_URLS_S3,
  LOAD_ELEMENT_TYPE_ENUMS,
} from 'services/GraphQL/Queries';
import {
  selectTableColumnsModalOpen,
  setTableColumnsModalOpen,
  setSelectedCluster,
  selectCluster,
  setStoreDataElements,
  setStoreTableData,
  selectTableData,
  setStoreUnclustedDocuments,
} from 'store/Redux/slices/dataEntrySlice';
import {
  selectClusterDone,
  selectCurrentProject,
  selectCurrentProjectId,
  selectCurrentResourceName,
  defaultResourceMenu,
  selectDocIndex,
  selectReadToken,
  setCurrentClusterIndex,
  setDocIndex,
  setDataTypes,
  selectDataTypes,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import './Cluster.css';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import { resources } from 'resource';
import FormatClusterName from 'helpers/FormatClusterName';
import {
  applyAWSTokenToDocument,
  handleDecodeURL,
} from 'helpers/UpdateAWSDocumentUriTokens';
import ElementDropdown from 'pages/ElementAssignment/cards/ElementDropdown';
import Cancel from 'icons/Mui/Cancel';
import IconButton from 'components/IconButton';
import StagedElement from 'pages/ElementAssignment/cards/StagedElements';
import Draggable from 'react-draggable';
import Paper from 'components/Paper';
import { ResizableBox } from 'react-resizable';
import _cloneDeep from 'lodash.clonedeep';
import Typography from 'components/Typography';
import { CheckCircle, ErrorOutline } from '@mui/icons-material';
import Switch from 'components/Switch';
import ImageGallery from './cards/ImageGallery/ImageGallery';
import { isEmptyObject } from 'jquery';
import { SvgIcon } from '@mui/material';
import Tooltip from 'components/Tooltip';

//#region Styled Components
const TableContainer = styled.div`
  padding: 20px;
`;

const STabs = styled(Tabs)`
  .MuiTabs-scrollButtons {
    width: 18px;
  }
  .Mui-selected {
    color: ${(props) => props.theme.palette.primary.main};
  }

  .MuiTabs-indicator {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const EditTableButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const NoCluster = styled.div`
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SPaper = styled(Paper)`
  margin: 0;
  width: fit-content;
  position: fixed;
  top: 6em;
  left: 6em;
  right: auto;
  bottom: auto;
  z-index: 999;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
`;

const SCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SResizableBox = styled(ResizableBox)`
  position: relative;
  min-width: 60vh;
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 45%;
    right: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    background-size: 8px;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: e-resize;
    transform: rotate(315deg);
  }
`;

const ClusteringButton = styled(Button)`
  background-color: ${(props) =>
    props.currentStatus.error
      ? props.theme.palette.error.main
      : props.theme.palette.secondary.main};

  &:hover {
    background-color: ${(props) =>
      props.currentStatus.error
        ? props.theme.palette.error.darker
        : props.theme.palette.secondary.darker};
  }
`;

const ClusterStatusSwitch = styled(Switch)`
width: 70px;
& .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(32px);
}
.MuiFormControlLabel-label {
  font-weight: bolder;
 }
 .MuiSvgIcon-root {
  color: ${(props) =>
    props.checked ? props.theme.palette.primary.main : 'goldenrod'} !important;
      background-color: white;
      border-radius: 20px;
}
 .MuiSwitch-track {
   background-color: ${(props) =>
     props.checked
       ? `${props.theme.palette.primary.darker}`
       : 'goldenrod'} !important;   
 }
 .MuiSwitch-thumb {
   color: ${(props) =>
     props.checked
       ? `${props.theme.palette.primary.main}`
       : 'goldenrod'} !important;

     
`;

const ClusterStatusIcon = styled(SvgIcon)`
  color: ${(props) =>
    props.isConfirmed ? props.theme.palette.primary.main : 'goldenrod'};
`;
//#endregion Styled Components

const getStatusDisplays = (currentStatus) => {
  let button, status;

  if (currentStatus.unclustered) {
    (button = 'Start Clustering'), (status = 'Unclustered');
  } else if (currentStatus.complete) {
    (button = 'Re-cluster'), (status = 'Clustered');
  } else if (currentStatus.repull) {
    (button = 'Pull Cluster Status'), (status = 'Repull to view status');
  } else if (currentStatus.error) {
    (button = 'Try Again'),
      (status =
        'Clustering process is not working at the moment. If error persists, contact SmarText Administration.');
  } else if (currentStatus.inProgress) {
    (button = (
      <>
        <div>Please Wait</div>
        <CircularProgress
          size={13}
          thickness={5}
          style={{ marginLeft: '7px' }}
        />
      </>
    )),
      (status = 'In Progress');
  }
  return { button, status };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Cluster = (props) => {
  const resourceMenu = useSelector(selectCurrentResourceName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const projectMemberIdentificationHeader = `${selectedResources.ANY_PAGES.PROJECT_MEMBER} Identification`;
  const clustersSortBy = 'ReactTable_clusters_SortBy';
  const dispatch = useDispatch();
  const awsObject = 'amazonaws';
  const viewUnclusteredDocs = useRef(null);
  const defaultClusterOrder = [{ totalDataElements: 'DESC' }, { name: 'ASC' }];
  const defaultDocOrder = [{ isConfirmed: 'ASC' }];
  //Selectors
  const currentProject = useSelector(selectCurrentProject);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const docIndex = useSelector(selectDocIndex);
  const currentCluster = useSelector(selectCluster);
  const readToken = useSelector(selectReadToken);
  const isTableColumnEditOpen = useSelector(selectTableColumnsModalOpen);
  const tableData = useSelector(selectTableData);
  const clusterDone = useSelector(selectClusterDone);
  const emailName = useSelector(selectCurrentUserEmail);
  const dataTypes = useSelector(selectDataTypes);
  //State
  const [clusters, setClusters] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [unclusteredDocuments, setUnclusteredDocuments] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [projectDataElements, setProjectDataElements] = useState([]);
  const [reclusterMode, setReclusterMode] = useState(false);
  const [reclusterDocs, setReclusterDocs] = useState([]);
  const [newCluster, setNewCluster] = useState(null);
  const [image, setImage] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [selectedElement, setSelectedElement] = useState(null);
  const [expanded, setExpanded] = useState(undefined);
  const [uniqueGuid, setUniqueGuid] = useState(uuidv4());
  const [newElement, setNewElement] = useState({});
  const [isAddingElement, setIsAddingElement] = useState(false);
  const [documentName, setDocumentName] = useState(undefined);
  const [selectedClusterName, setSelectedClusterName] = useState(undefined);
  const [clusterElementDialogOpen, setClusterElementDialogOpen] =
    useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [clusterStatusConfirmed, toggleClusterStatusConfirmed] =
    useState(false);
  const [docQueryFilters, setDocQueryFilters] = useState(null);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  //#region GraphQL
  useQuery(LOAD_ELEMENT_TYPE_ENUMS, {
    onCompleted: (data) =>
      dispatch(
        setDataTypes(data?.elementTypeEnums?.enumValues?.map((e) => e.name))
      ),
  });

  const [reclusterDocuments] = useMutation(RECLUSTER_DOCUMENTS);
  const [renameCluster] = useMutation(RENAME_CLUSTER, {
    onCompleted: (data) => {
      if (data?.renameCluster) {
        const updatedClusters = clusters?.nodes?.map((c) => {
          if (c.id === data.renameCluster.id) {
            setSelectedClusterName(data.renameCluster.name);
            return { ...c, name: data.renameCluster.name };
          }
          return c;
        });
        setClusters({ ...clusters, nodes: updatedClusters });
      }
    },
  });

  const [bulkAddClusterDataElement] = useMutation(
    BULK_ADD_CLUSTER_DATA_ELEMENT,
    {
      onCompleted: (data) => {
        if (data.addClusterDataElementList.length) {
          updateClusterDataElementsState(
            data.addClusterDataElementList.find(() => true).cluster
          );
          dispatch(
            setSnackbarSuccess('Cluster Data Elements successfully added!')
          );
          setSelectedOptions([]);
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
    }
  );

  const [removeDataElement] = useMutation(REMOVE_CLUSTER_DATA_ELEMENT);

  const [
    getClusters,
    { loading: clustersLoading, fetchMore: fetchMoreClusters },
  ] = useLazyQuery(GET_CLUSTERS_BY_PROJECT, {
    onCompleted: (data) => {
      if (data?.clusters?.nodes) {
        setClusters(data?.clusters);

        getUnclusteredDocs({
          variables: {
            id: currentProjectId,
          },
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [checkClusterNameExists] = useLazyQuery(GET_CLUSTERS_BY_PROJECT, {
    fetchPolicy: 'no-cache',
  });

  const [getDocuments, { fetchMore: fetchMoreDocs, loading: docsLoading }] =
    useLazyQuery(GET_DOCS_BY_CLUSTER, {
      onCompleted: (data) => {
        if (data?.documents?.nodes) {
          toggleClusterStatusConfirmed(
            data.documents.nodes.every((d) => d.isConfirmed)
          );
          prepareDocumentsForPreSign(data.documents);
        }
      },
      fetchPolicy: 'no-cache',
    });

  const [getDataElements, { loading: dataElementsLoading }] = useLazyQuery(
    GET_DATA_ELEMENTS_BY_CLUSTER,
    {
      onCompleted: (data) => {
        if (data?.clusterById) {
          setAttributes(data.clusterById.dataElements);
          dispatch(setStoreDataElements(data.clusterById.dataElements));
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  const [getTableDataElements] = useLazyQuery(GET_TABLEDATA_BY_DATAELEMENT_ID, {
    onCompleted: (data) => {
      if (data?.dataElementById) {
        dispatch(setStoreTableData(data.dataElementById.tableData));
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getProjectDataElements] = useLazyQuery(GET_DATA_ELEMENTS_BY_PROJECT, {
    onCompleted: (data) => {
      if (data?.projects[0]?.dataElements?.nodes) {
        setProjectDataElements(data?.projects[0]?.dataElements);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [getUnclusteredDocs, { fetchMore: fetchMoreUnclustered }] =
    useLazyQuery(GET_PROJECT_DOCUMENTS, {
      onCompleted: (data) => {
        if (data?.projectById?.unclusteredDocuments?.nodes) {
          const unclusteredDocs = data.projectById?.unclusteredDocuments;
          if (unclusteredDocs?.nodes.length) {
            const unclustered = {
              id: 'UNCLUSTERED',
              name: 'Unclustered',
              projectId: decoder(currentProjectId),
              documents: unclusteredDocs,
              totalDocuments: unclusteredDocs?.totalCount,
            };
            let encodedUnclusteredDocs = handleDecodeURL(unclusteredDocs.nodes);
            let unclusteredDocsObject = {
              ...unclusteredDocs,
              nodes: encodedUnclusteredDocs,
            };
            handlePreSignedUrlDocuments(true, unclusteredDocsObject);
            let clustersCopy = _cloneDeep(clusters);
            clustersCopy.nodes.unshift(unclustered);
            let updatedClusterList = {
              ...clustersCopy,
              nodes: clustersCopy.nodes,
            };

            setClusters(updatedClusterList);

            if (currentCluster) {
              dispatch(
                setCurrentClusterIndex({
                  id: currentCluster.id,
                })
              );
            }
          } else {
            dispatch(setStoreUnclustedDocuments([]));
          }
        }
      },
      fetchPolicy: 'no-cache',
    });

  const [requestClustering] = useLazyQuery(REQUEST_CLUSTER, {
    onCompleted: (data) => onFetchProjectByIdSuccess(data),
    fetchPolicy: 'no-cache',
  });

  const [getPreSignedUrlList] = useLazyQuery(GET_PRESIGNED_URLS_S3);

  const [confirmedDocumentInCluster] = useMutation(
    CONFIRMED_DOCUMENT_IN_CLUSTER,
    {
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
    }
  );
  // #endregion GraphQL

  //#region Table Columns
  const attributeTableColumns = [
    {
      id: 'expander',
      Header: () => null,
      Cell: ({ row }) =>
        row.original.type === 'TABLE' ? (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </span>
        ) : null,
      maxWidth: 30,
    },
    {
      Header: 'Data Element',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
  ];

  const expandedRowColumns = [
    {
      Header: 'Column',
      accessor: (row, index) => Number(index + 1),
    },
    {
      Header: 'Data Element',
      accessor: (row) => row.name,
    },
    {
      id: 'table button',
      Header: (
        <EditTableButton onClick={() => onClickTableButton()}>
          Edit Table
        </EditTableButton>
      ),
      accessor: () => null,
    },
  ];

  const clusterColumns = [
    {
      Header: `${selectedResources.ANY_PAGES.CLUSTER} Group`,
      accessor: 'name',
      Cell: (props) => <EditableCell {...props} updateValue={handleRename} />,
    },
    {
      id: 'totalDataElements',
      Header: 'Total Data Elements',
      accessor: 'totalDataElements',
    },
    {
      id: 'totalDocuments',
      Header: 'Total Pages',
      accessor: 'totalDocuments',
    },
    {
      Header: 'Cluster Status',
      accessor: (row) => getClusterStatus(row),
      Cell: ({ value }) => clusterStatusIcon(value),
      sortType: 'basic',
    },
  ];
  //#endregion Table Columns

  //#region Clusterer
  const {
    clusterDialogOpen,
    currentStatus,
    onClickCancelDialog,
    onClickClusterButton,
    onClickConfirmDialog,
    onClickRepullButton,
    switchButtonToClustered,
    switchButtonToPullClusterStatus,
    switchButtonToUnclustered,
  } = useClusterer({ getClusters });

  const onFetchProjectByIdSuccess = (data) => {
    const project = data?.projectById;
    if (project) {
      if (project.clusteringId === null) {
        switchButtonToUnclustered();
      } else if (project.clusteringId && !project.isClusteringComplete) {
        switchButtonToPullClusterStatus();
      } else if (project.clusteringId && project.isClusteringComplete) {
        switchButtonToClustered();
      }
    }
  };

  const { button, status } = getStatusDisplays(currentStatus);

  //#endregion Clusterer

  //#region useEffect
  useEffect(() => {
    dispatch(setCurrentClusterIndex(undefined));
  }, []);

  useEffect(() => {
    if (currentProjectId) {
      let state = props?.location?.state;

      if (isEmptyObject(state?.optionData?.clusters)) {
        getClusters({
          variables: {
            sortOrder: defaultClusterOrder,
            filters: {
              projectId: { eq: decoder(currentProjectId) },
              totalDocuments: { gt: 0 },
            },
          },
        });
      } else {
        setIsDataFiltered(true);
        setClusters(state.optionData.clusters);
        getUnclusteredDocs({
          variables: {
            id: currentProjectId,
          },
        });
      }

      requestClustering({ variables: { id: currentProjectId } });
    }
    setTabValue(0);
    setDocumentName(undefined);
    setDocuments([]);
    dispatch(setSelectedCluster(null));
  }, [currentProjectId]);

  useEffect(() => {
    if (clusterDone) {
      getUnclusteredDocs({ variables: { id: currentProjectId } });
    }
  }, [clusterDone]);

  useEffect(() => {
    disableReclusterMode();
    setDocumentName(undefined);
    setSelectedClusterName(currentCluster?.name);
  }, [currentCluster]);

  useEffect(() => {
    if (documents?.nodes?.length && documents?.nodes[docIndex]?.documentUri) {
      toggleClusterStatusConfirmed(documents.nodes.every((d) => d.isConfirmed));
      const image = documents.nodes[docIndex]?.documentUri?.includes(awsObject)
        ? `${documents.nodes[docIndex].documentUri}`
        : `${documents.nodes[docIndex]?.documentUri}?${readToken}`;
      setImage(image);
      setDocumentName(documents.nodes[docIndex]?.originalName);
    } else {
      setImage('');
    }
  }, [docIndex, documents]);

  useEffect(() => {
    if (currentProject && !isTableColumnEditOpen) {
      setExpanded(undefined);
    }
  }, [currentProject, currentCluster]);

  useEffect(() => {
    if (clusters?.nodes) {
      if (isAddingElement) {
        setIsAddingElement(false);
      }
    }
  }, [currentCluster, clusters]);

  useEffect(() => {
    if (isAddingElement) {
      getProjectDataElements({ variables: { id: decoder(currentProjectId) } });
    }
  }, [isAddingElement]);

  useEffect(() => {
    const updatedProjElements = projectDataElements?.nodes?.filter(
      (element) => {
        const attrNames = attributes?.map((attr) => attr.name);
        return !attrNames?.includes(element.name);
      }
    );

    updatedProjElements?.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
    setUniqueGuid(uuidv4());
  }, [attributes, projectDataElements]);

  useEffect(() => {
    if (currentCluster?.name === 'Unclustered') {
      setTabValue(0);
    }
  }, [currentCluster]);

  const prepareDocumentsForPreSign = (documents) => {
    let decodedDocs = handleDecodeURL(documents.nodes);

    let documentsObject = { ...documents, nodes: decodedDocs };
    handlePreSignedUrlDocuments(false, documentsObject);
  };

  const handlePreSignedUrlDocuments = (unclusteredDocs, newDocs) => {
    let docs = newDocs?.nodes ? newDocs?.nodes : newDocs;
    let alreadySignedDocs = docs.filter((d) =>
      d.documentUri.includes('AWSAccessKeyId')
    );
    const documentUris = docs
      .filter((d) => !alreadySignedDocs.includes(d))
      .map((d) => {
        return d.documentUri;
      });
    getPreSignedUrlList({ variables: { urlList: documentUris } }).then(
      (res) => {
        const updatedDocs = applyAWSTokenToDocument(
          res.data.preSignedUrlList,
          docs.filter((d) => !alreadySignedDocs.includes(d))
        );
        let fullDocs = alreadySignedDocs.concat(updatedDocs);
        unclusteredDocs
          ? setUnclusteredDocuments({
              ...newDocs,
              nodes: fullDocs,
            })
          : setDocuments({ ...newDocs, nodes: fullDocs });
      }
    );
  };

  const handleUpdateDocumentListIsConfirmed = () => {
    if (documents?.nodes?.length) {
      confirmedDocumentInCluster({
        variables: { clusterId: decoder(currentCluster?.id) },
      }).then((res) => {
        if (res?.data?.confirmedDocumentInCluster) {
          updateClusterStatus(res.data.confirmedDocumentInCluster);
        }
      });
    }
  };

  const updateClusterStatus = (confirmedDocs) => {
    const updatedDocs = [...documents.nodes].map((doc) => {
      const updatedDoc = confirmedDocs.find((d) => d.id === doc.id);

      if (updatedDoc) {
        return { ...doc, isConfirmed: updatedDoc.isConfirmed };
      }
      return doc;
    });
    const updatedClusters = clusters?.nodes?.map((c) => {
      if (c.id === currentCluster?.id) {
        return { ...c, documents: confirmedDocs };
      }
      return c;
    });
    setClusters({ ...clusters, nodes: updatedClusters });
    setDocuments({ ...documents, nodes: updatedDocs });
  };

  const updateClusterDataElementsState = (cluster) => {
    setAttributes(cluster.dataElements);
    setIsAddingElement(false);
    setNewElement({});

    let updatedClusterList = clusters?.nodes?.map((c) => {
      if (c.id === cluster.id) {
        return {
          ...c,
          totalDataElements: cluster.dataElements.length,
        };
      }
      return c;
    });
    let newCluster = { ...clusters, nodes: updatedClusterList };
    setClusters(newCluster);
  };

  const updateClustersState = (response) => {
    const updatedCount = response.data.bulkUpdateDocumentCluster.length;

    let clusterList = [...clusters.nodes];
    if (
      newCluster &&
      !clusters?.nodes.map((c) => c.id).includes(newCluster?.id)
    ) {
      clusterList = [...clusters.nodes, newCluster];
    }
    const updatedClustersList = clusterList.map((c) => {
      if (c.id === newCluster?.id) {
        return {
          ...c,
          documents: [
            ...c.documents,
            ...response.data.bulkUpdateDocumentCluster,
          ],
          totalDocuments: c.totalDocuments + updatedCount,
        };
      }
      if (c.id === currentCluster?.id) {
        return {
          ...c,
          documents: c.documents.filter(
            (d) =>
              !response.data.bulkUpdateDocumentCluster
                .map((r) => r.id)
                .includes(d.id)
          ),
          totalDocuments: c.totalDocuments - updatedCount,
        };
      }
      return c;
    });
    setClusters({ ...clusters, nodes: updatedClustersList });
  };

  const addReclusterDoc = (id) => {
    let array = [...reclusterDocs];
    if (array.includes(id)) {
      const newArray = array?.filter((item) => item !== id);
      setReclusterDocs(newArray);
    } else {
      array.push(id);
      setReclusterDocs(array);
    }
  };

  const submitRecluster = () => {
    if (unclusteredDocuments?.length > 0) {
      let leftUnclustered = unclusteredDocuments?.nodes?.filter(
        (u) => !reclusterDocs.includes(u.id)
      );

      if (leftUnclustered.length < 1) {
        return dispatch(
          setSnackbarError(
            "You MUST leave at least ONE document in the 'unclustered' cluster."
          )
        );
      }
    }
    if (reclusterDocs?.length) {
      const reclusterInput = reclusterDocs.map((doc) => {
        return {
          documentId: decoder(doc),
          newClusterId: decoder(newCluster?.id),
        };
      });

      reclusterDocuments({
        variables: {
          inputs: reclusterInput,
        },
      })
        .then((response) => {
          updateClustersState(response);
          dispatch(
            setSnackbarSuccess(
              `${response?.data?.bulkUpdateDocumentCluster.length} Documents Successfully Reclustered`
            )
          );
        })
        .catch((error) => console.log(error));
    }
  };

  const disableReclusterMode = (cluster) => {
    setReclusterMode(false);
    setReclusterDocs([]);
    cluster ? setNewCluster(cluster) : setNewCluster(null);
  };

  const handleRename = (oldName, newName) => {
    if (oldName === 'Unclustered') {
      dispatch(setSnackbarError('Unclustered Cannot be Renamed'));
    } else {
      checkClusterNameExists({
        variables: {
          sortOrder: defaultClusterOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            name: { eq: newName.trim() },
          },
        },
      }).then((res) => {
        const clusterNameExists = res?.data?.clusters?.totalCount > 0;
        if (clusterNameExists) {
          dispatch(setSnackbarError('Cluster with this name already exists'));
        } else {
          renameCluster({
            variables: {
              input: {
                clusterId: decoder(currentCluster?.id),
                rename: newName,
                username: emailName,
              },
            },
          });
        }
      });
    }
  };

  const clusterSelect = (row) => {
    const { id } = row.original;

    if (id === 'UNCLUSTERED') {
      viewUnclusteredDocs.current = true;
      dispatch(setSelectedCluster(row.original));
    } else if (id !== currentCluster?.id) {
      getDataElements({ variables: { id: id } });
      getDocuments({
        variables: {
          sortOrder: defaultDocOrder,
          filters: { clusterId: { eq: decoder(id) } },
        },
      });
      dispatch(setSelectedCluster(row.original));
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAssignClusterDataElements = () => {
    if (selectedOptions?.length) {
      const elementsToAdd = selectedOptions.map(function (elem) {
        return {
          clusterId: decoder(currentCluster.id),
          dataElementId: decoder(elem.id),
          username: emailName,
        };
      });
      bulkAddClusterDataElement({ variables: { inputList: elementsToAdd } });
    }
  };

  const handleDelete = (id) => {
    removeDataElement({
      variables: {
        input: {
          clusterId: decoder(currentCluster.id),
          dataElementId: decoder(id),
        },
      },
    }).then(() => {
      const updatedDataElementList = attributes?.filter((d) => d.id !== id);
      setAttributes(updatedDataElementList);

      const updatedClusterList = clusters?.nodes?.map((c) => {
        if (c.id === currentCluster.id) {
          return { ...c, totalDataElements: updatedDataElementList.length };
        }
        return c;
      });

      let newCluster = { ...clusters, nodes: updatedClusterList };
      setClusters(newCluster);
    });
  };

  const closeAddRow = () => {
    setIsAddingElement(false);
    setNewElement({});
  };

  const toggleClusterElementDialogOpen = () => {
    setIsAddingElement(!isAddingElement);
    setClusterElementDialogOpen(!clusterElementDialogOpen);
  };

  const handleSelectedOptions = (values) => setSelectedOptions(values);

  const onClickTableButton = () => {
    dispatch(setTableColumnsModalOpen(true));
  };

  const handleExpand = (row, id) => {
    setSelectedElement(row);
    getTableDataElements({ variables: { id: row.id } });
    setExpanded({ [id]: true });
  };

  const handleClose = () => {
    setExpanded(undefined);
  };

  const handleFetchMoreClusters = () => {
    fetchMoreClusters({
      variables: {
        cursor: clusters.pageInfo.endCursor,
        sortOrder: defaultClusterOrder,
        filters: {
          projectId: { eq: decoder(currentProjectId) },
          totalDocuments: { gt: 0 },
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // we are not using prev because we don't use graphQL cache for our data
        if (!fetchMoreResult) return clusters;
        let newResult = _cloneDeep(fetchMoreResult);
        newResult.clusters.nodes = [
          ...clusters.nodes,
          ...newResult.clusters.nodes,
        ];
        setClusters(newResult.clusters);
      },
    });
  };

  const handleRefetchClusterSearch = (queryValue) => {
    setIsDataFiltered(false);
    if (queryValue) {
      getClusters({
        variables: {
          sortOrder: defaultClusterOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            totalDocuments: { gt: 0 },
            or: [
              { name: { contains: queryValue.trim() } },
              parseInt(queryValue)
                ? { totalDataElements: { eq: parseInt(queryValue) } }
                : {},
              parseInt(queryValue)
                ? { totalDocuments: { eq: parseInt(queryValue) } }
                : {},
            ],
          },
        },
      });
    } else {
      getClusters({
        variables: {
          sortOrder: defaultClusterOrder,
          filters: {
            projectId: { eq: decoder(currentProjectId) },
            totalDocuments: { gt: 0 },
          },
        },
      });
    }
  };

  const handleFetchMoreDocs = () => {
    if (currentCluster?.name === 'Unclustered') {
      fetchMoreUnclustered({
        variables: {
          id: currentProjectId,
          after: unclusteredDocuments.pageInfo.endCursor,
          filters: docQueryFilters ? docQueryFilters : null,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // we are not using prev because we don't use graphQL cache for our data
          if (!fetchMoreResult) return unclusteredDocuments;
          let newResult = _cloneDeep(fetchMoreResult);
          newResult.projectById.unclusteredDocuments.nodes = [
            ...unclusteredDocuments.nodes,
            ...newResult.projectById.unclusteredDocuments.nodes,
          ];
          prepareDocumentsForPreSign(newResult.documents);
        },
      });
    } else {
      fetchMoreDocs({
        variables: {
          sortOrder: defaultDocOrder,
          filters: docQueryFilters
            ? docQueryFilters
            : {
                clusterId: { eq: decoder(currentCluster?.id) },
              },
          cursor: documents?.pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // we are not using prev because we don't use graphQL cache for our data
          if (!fetchMoreResult) return documents;
          let newResult = _cloneDeep(fetchMoreResult);
          newResult.documents.nodes = [
            ...documents.nodes,
            ...newResult.documents.nodes,
          ];
          prepareDocumentsForPreSign(newResult?.documents);
        },
      });
    }
  };

  const getClusterStatus = (cluster) => {
    if (cluster?.id === 'UNCLUSTERED') {
      return 'N/A';
    }
    const isConfirmed = cluster?.documents?.every((d) => d.isConfirmed);
    return isConfirmed;
  };
  //#endregion Functions

  //#region Components

  const clusterStatusIcon = (isConfirmed) => {
    const icon = isConfirmed ? <CheckCircle /> : <ErrorOutline />;
    const title = isConfirmed
      ? 'Cluster Documents Confirmed'
      : 'Unconfirmed Documents Exist';
    return (
      <Tooltip title={title} placement='right'>
        <ClusterStatusIcon isConfirmed={isConfirmed}>{icon}</ClusterStatusIcon>
      </Tooltip>
    );
  };

  const clusterConfirmationDialog = (
    <Dialog
      open={clusterDialogOpen}
      title='Clustering project'
      cancelLabel='Cancel'
      handleCancel={onClickCancelDialog}
      submitLabel='Confirm'
      handleSubmit={onClickConfirmDialog}
      hasActions
    >
      Are you sure you want to cluster all documents? Make sure you have all
      your documents uploaded; you can only do this once per project.
    </Dialog>
  );

  const clusterButton = (
    <LightToolTip
      placement={'top'}
      isShown={documents?.length > 0 && currentStatus.unclustered}
      contentText={`You must first upload documents to the project before you can begin clustering.`}
      lightToolTipTargetItem={
        <Grid item xs={12}>
          <ClusteringButton
            currentStatus={currentStatus}
            size='small'
            className='clustersPage-clustersTableClusterButton'
            disabled={
              (!currentStatus.repull && currentStatus.inProgress) ||
              (!currentStatus.repull && unclusteredDocuments?.length <= 0)
            }
            variant='contained'
            onClick={
              currentStatus.repull ? onClickRepullButton : onClickClusterButton
            }
          >
            {button}
          </ClusteringButton>
        </Grid>
      }
    />
  );

  const renderRowSubComponent2 = useCallback(
    ({ row }) => {
      return (
        <>
          {tableData && row && (
            <ReactTable
              data={tableData || []}
              columns={expandedRowColumns || []}
              hasSearchBar={false}
              hasPagination={tableData.length > 5 ? true : false}
              tableHeight={''}
              hasGoToPage={false}
              hasRowSize={false}
              isSubRow={true}
              currentCluster={currentCluster}
            />
          )}
        </>
      );
    },
    [tableData]
  );

  const clusterDataElementAssignmentDialog = (
    <Draggable handle='#draggable-dialog-title' bounds='parent'>
      <SPaper
        aria-labelledby='draggable-dialog-title'
        open={clusterElementDialogOpen}
      >
        <SResizableBox
          minConstraints={[460, 600]}
          maxConstraints={[1200, 380]}
          width={600}
        >
          <Grid
            container
            alignItems='center'
            id='draggable-dialog-title'
            style={{ cursor: 'move' }}
          >
            <Grid item xs={10}>
              <DialogTitle>{'Add Cluster Element(s)'}</DialogTitle>
            </Grid>
            <Grid item xs={2} textAlign='end'>
              <IconButton
                onClick={toggleClusterElementDialogOpen}
                color='error'
                variant='contained'
              >
                <Cancel />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SCard>
                  <ElementDropdown
                    currentProjectElements={attributes}
                    options={
                      projectDataElements?.nodes
                        ? projectDataElements.nodes
                        : []
                    }
                    selectedOptions={selectedOptions}
                    handleSelectedOptions={handleSelectedOptions}
                    dataTypeList={dataTypes}
                    clusterElementDialogOpen={clusterElementDialogOpen}
                    handleAssignDataElements={handleAssignClusterDataElements}
                  />
                </SCard>
              </Grid>
              <Grid item xs={6}>
                <SCard>
                  <StagedElement
                    stagedElements={attributes}
                    initialElements={projectDataElements?.nodes || []}
                    clusterElementDialogOpen={clusterElementDialogOpen}
                    setAttributes={setAttributes}
                    updateClusterDataElementsState={
                      updateClusterDataElementsState
                    }
                  />
                </SCard>
              </Grid>
            </Grid>
          </DialogContent>
        </SResizableBox>
      </SPaper>
    </Draggable>
  );
  //#endregion Components

  const clusterStatusMessage = `Cluster Status: ${status}`;

  return (
    <>
      <ProjectSelectedTemplate>
        {clusterElementDialogOpen && clusterDataElementAssignmentDialog}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              style={{ padding: '20px' }}
              className='clustersPage-clustersTable'
            >
              {clusters?.nodes ? (
                <ReactTable
                  hasClusterButton
                  clusterButton={clusterButton}
                  columns={clusterColumns}
                  data={clusters?.nodes}
                  header={projectMemberIdentificationHeader}
                  subheader={
                    currentCluster?.name
                      ? `Current Cluster - ${selectedClusterName}`
                      : clusterStatusMessage
                  }
                  onSelected={clusterSelect}
                  isExpandable={false}
                  isTableResetOnSort={false}
                  useRowId
                  isTableResetOnSelectedRow={!currentCluster}
                  useSkipPageReset={true}
                  fetchMore={handleFetchMoreClusters}
                  hasNextPage={clusters?.pageInfo?.hasNextPage}
                  totalData={clusters?.totalCount}
                  hasPagination={false}
                  tableHeight={'32vh'}
                  dataLoading={clustersLoading}
                  refetch={handleRefetchClusterSearch}
                  pageName='clusters'
                  defaultSortColumn={localStorage.getItem(clustersSortBy)}
                  isDescending={
                    'true' ==
                    localStorage.getItem(
                      clustersSortBy +
                        'Desc_' +
                        localStorage.getItem(clustersSortBy)
                    )
                  }
                  isDataFiltered={isDataFiltered}
                />
              ) : (
                <div>No Clusters in Project</div>
              )}
            </Card>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className='clustersPage-imageGalleryAndDataElements'>
                <Grid container alignItems='center'>
                  <Grid item xs={8}>
                    <STabs
                      value={tabValue}
                      onChange={handleTabChange}
                      variant='scrollable'
                      scrollButtons='auto'
                    >
                      <Tab
                        label='Image Gallery'
                        className='clustersPage-imageGalleryTab'
                      />
                      <Tab
                        label='Data Element Attribution'
                        disabled={
                          currentCluster?.name === 'Unclustered' ||
                          !clusters?.nodes?.length
                        }
                        className='clustersPage-dataElementAttributionTab'
                      />
                    </STabs>
                  </Grid>
                  <Grid item xs={4} textAlign='end' paddingRight='2%'>
                    {documents?.nodes?.length > 0 &&
                      currentCluster?.id !== 'UNCLUSTERED' && (
                        <Grid container alignItems='center'>
                          <Grid item xs={8}>
                            <Typography fontSize='14px'>
                              {`${
                                clusterStatusConfirmed
                                  ? 'Confirmed'
                                  : 'Confirm Cluster Documents'
                              }`}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <ClusterStatusSwitch
                              title={
                                clusterStatusConfirmed
                                  ? 'All documents confirmed '
                                  : 'Click to confirm documents within Cluster'
                              }
                              checked={clusterStatusConfirmed}
                              icon={<ErrorOutline />}
                              checkedIcon={<CheckCircle />}
                              disabled={clusterStatusConfirmed}
                              onChange={() =>
                                handleUpdateDocumentListIsConfirmed()
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <TabPanel value={tabValue} index={0}>
                  <ImageGallery
                    currentProjectId={currentProjectId}
                    documents={
                      currentCluster?.name === 'Unclustered'
                        ? unclusteredDocuments
                        : documents
                    }
                    setDocuments={
                      currentCluster?.name === 'Unclustered'
                        ? setUnclusteredDocuments
                        : setDocuments
                    }
                    setNewCluster={setNewCluster}
                    newCluster={newCluster}
                    addReclusterDoc={addReclusterDoc}
                    submitRecluster={submitRecluster}
                    reclusterMode={reclusterMode}
                    currentCluster={currentCluster}
                    defaultClusterOrder={defaultClusterOrder}
                    defaultDocOrder={defaultDocOrder}
                    reclusterDocs={reclusterDocs}
                    docIndex={docIndex}
                    setDocIndex={setDocIndex}
                    readToken={readToken}
                    setReclusterMode={setReclusterMode}
                    setReclusterDocs={setReclusterDocs}
                    documentName={documentName}
                    isDisableReclusterMode={disableReclusterMode}
                    awsObject={awsObject}
                    docsLoading={docsLoading}
                    hasNextPage={
                      currentCluster?.name === 'Unclustered'
                        ? unclusteredDocuments?.pageInfo?.hasNextPage
                        : documents?.pageInfo?.hasNextPage
                    }
                    totalData={
                      currentCluster?.name === 'Unclustered'
                        ? unclusteredDocuments?.totalCount
                        : documents?.totalCount
                    }
                    fetchMoreDocuments={handleFetchMoreDocs}
                    getDocuments={getDocuments}
                    unclusteredDocuments={unclusteredDocuments}
                    docQueryFilters={docQueryFilters}
                    setDocQueryFilters={setDocQueryFilters}
                    image={image}
                    setImage={setImage}
                    checkClusterNameExists={checkClusterNameExists}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {currentCluster &&
                  currentCluster.name !== 'Unclustered' &&
                  attributes ? (
                    <TableContainer>
                      <ReactTable
                        key={uniqueGuid}
                        columns={attributeTableColumns}
                        data={attributes}
                        header={
                          currentCluster?.name
                            ? FormatClusterName(currentCluster.name, false)
                            : 'No Cluster Selected'
                        }
                        actions={{
                          delete: handleDelete,
                          addRow: toggleClusterElementDialogOpen,
                          closeAddRow: closeAddRow,
                        }}
                        isExpandable={true}
                        expandedId={expanded}
                        onExpand={handleExpand}
                        onClose={handleClose}
                        hasAddRow={true}
                        renderRowSubComponent={renderRowSubComponent2}
                        isAddingRow={isAddingElement}
                        isSubmitReady={!!Object.keys(newElement).length}
                        currentCluster={currentCluster}
                        dataLoading={dataElementsLoading}
                      />
                      <TableColumnEditModal
                        selectedElement={selectedElement}
                        cluster={currentCluster}
                        getDataElements={getDataElements}
                        getTableDataElements={getTableDataElements}
                      />
                    </TableContainer>
                  ) : (
                    <NoCluster>No Cluster Selected</NoCluster>
                  )}
                </TabPanel>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'grid' }}>
              <div className='clustersPage-viewSelectedDocument'>
                <CurrentDocumentCard
                  key={docIndex}
                  image={image}
                  currentPage='Clusters'
                  totalDocs={documents?.nodes?.length}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </ProjectSelectedTemplate>
      {clusterDialogOpen && clusterConfirmationDialog}
    </>
  );
};

export default Cluster;

Cluster.propTypes = {
  row: PropTypes.object,
};
