function LeftArrow() {
  return (
    <svg width='24' height='42' viewBox='0 0 24 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.25005 5.62472L16.7179 21.0927L1.25005 36.5606C-0.812344 38.623 1.24993 40.6855 1.24993 40.6855C1.24993 40.6855 3.31245 42.7477 5.37484 40.6854L22.9051 23.1552C23.9363 22.1241 23.9363 20.0617 22.9051 19.0303L5.37484 1.49993C3.31245 -0.562462 1.24993 1.49999 1.24993 1.49999C1.24993 1.49999 -0.812346 3.56227 1.25005 5.62472Z'
        fill='black'
      />
    </svg>
  );
}

export default LeftArrow;
