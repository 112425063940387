import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import Checkbox from 'components/Checkbox';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import List from 'components/List';
import ListItem from 'components/ListItem';
import ListItemText from 'components/ListItemText';
import LightTooltip from 'components/Tooltip/LightToolTip';
import Typography from 'components/Typography';
import { displayElementType } from 'helpers/enumToType';
import { decoder } from 'helpers/graphQL_Encoder';
import CircleDeleteButton from 'icons/CircleDeleteButton';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  REMOVE_CLUSTER_DATA_ELEMENT,
  REMOVE_PROJECT_DATA_ELEMENT,
} from 'services/GraphQL/Mutations';
import { selectCurrentProjectId } from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import styled from 'styled-components';
import { Help } from '@mui/icons-material';
import { selectCluster } from 'store/Redux/slices/dataEntrySlice';

//#region Styled Components
const SListItemText = styled(ListItemText)`
  margin: 0 0 0 3px;
  color: ${(props) =>
    props.nullDescription && !props.clusterElementDialogOpen
      ? 'rgba(218, 41, 28, 1)'
      : null};

  & span {
    display: contents;
  }

  & p {
    color: rgba(0, 0, 0, 0.87);
    float: right;
    margin: 4px 3px 0 0;
  }
`;

const ElementsTitle = styled(Typography)`
  font-size: 0.9em;
  font-weight: bolder;
  text-decoration: underline;
`;

const DataElementHeader = styled(ElementsTitle)`
  margin-left: 3px;
`;

const TypeHeader = styled(ElementsTitle)`
  float: right;
`;

const SListItem = styled(ListItem)`
  padding: 0;
  margin: 3px 0;
  background-color: ${(props) =>
    props.selectedRow ? 'rgba(191, 221, 216, 1)' : null};
`;

const DeleteIconContainer = styled(Grid)`
  display: grid;
  place-items: center;
`;

const AssignedCardContent = styled(CardContent)`
  display: ${(props) => (props.noElements ? 'grid' : null)};
  place-items: ${(props) => (props.noElements ? 'center' : null)};
  max-height: 60vh;
  overflow: auto;
  @media screen and (max-height: 1280px) {
    max-height: ${(props) =>
      props.clusterElementDialogOpen ? '30vh' : '40vh'};
  }
`;

const DeleteCheckbox = styled(Checkbox)`
  color: rgba(218, 41, 28, 1);
  padding: 0;
`;

const DeleteButton = styled(IconButton)`
  background-color: transparent;
  float: right;
  padding: 0;

  @media screen and (max-width: 1350px) {
    float: center;
  }
`;

const AssignButton = styled(Button)`
  float: right;
  display: ${(props) => (props.noElements ? 'none' : null)};
`;

const QuestionSpan = styled.div`
  text-align: center;
  display: ${(props) => (props.elementsValidated ? 'none' : null)};
  width: 100%;
`;

const SCloseIcon = styled(CloseIcon)`
  float: right;
`;

const SCircleDeleteButton = styled(CircleDeleteButton)`
  float: right;
`;

const SCardHeader = styled(CardHeader)`
  font-size: 1.4rem;

  @media screen and (max-width: 1350px) {
    font-size: 1.2rem;
  }
`;

const DeleteButtonArea = styled(CardActions)`
  @media screen and (max-width: 960px) {
    padding-bottom: 50px;
  }
`;
//#endregion Styled Components

function ElementsDeleteIcon(props) {
  const {
    hasDetailsWindowOpen,
    deleteIsEnabled,
    onClickCloseDeleteIcon,
    onClickDeleteIcon,
  } = props;

  return (
    <DeleteIconContainer item xs={2}>
      {!hasDetailsWindowOpen && (
        <>
          <DeleteButton
            onClick={
              deleteIsEnabled ? onClickCloseDeleteIcon : onClickDeleteIcon
            }
            size='small'
          >
            {deleteIsEnabled ? <SCloseIcon /> : <SCircleDeleteButton />}
          </DeleteButton>
        </>
      )}
    </DeleteIconContainer>
  );
}

function AssignedElementsList(props) {
  const {
    currentElement,
    nameValues,
    onClickSingleCheckbox,
    onClickElementShowDetail,
    stagedElements,
    checkedItemsForRemoval,
    onClickSelectAllCheckbox,
    deleteIsEnabled,
    hasDetailsWindowOpen,
    clusterElementDialogOpen,
  } = props;

  const sortedElements = () => {
    if (stagedElements) {
      let sortedList = [...stagedElements];
      for (let i = 0; i < stagedElements.length; i++) {
        if (stagedElements[i].type === 'TABLE') {
          sortedList.push(sortedList.splice(i, 1)[0]);
        }
      }
      var sortedArray = sortedList.sort(function (a, b) {
        return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
      });
      return sortedArray;
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DataElementHeader>Data Element</DataElementHeader>
        <TypeHeader>Type</TypeHeader>
      </div>

      <List dense>
        {deleteIsEnabled && (
          <ListItem onClick={onClickSelectAllCheckbox} disableGutters button>
            <SListItemText primary={'Select All'} />
            <DeleteCheckbox
              checked={checkedItemsForRemoval?.length === nameValues?.length}
              edge='end'
            />
          </ListItem>
        )}

        {sortedElements()?.map((element) => {
          return (
            <SListItem
              key={element.name}
              selectedRow={
                element.name === currentElement && hasDetailsWindowOpen
              }
              disableRipple
              disableGutters
              button={!clusterElementDialogOpen}
              onClick={
                deleteIsEnabled
                  ? onClickSingleCheckbox(element)
                  : clusterElementDialogOpen
                  ? null
                  : () => onClickElementShowDetail(element)
              }
            >
              <SListItemText
                nullDescription={element.description === ''}
                primary={element.name}
                secondary={displayElementType(element.type)}
                clusterElementDialogOpen={clusterElementDialogOpen}
              />
              {deleteIsEnabled && (
                <DeleteCheckbox
                  fontSize='small'
                  edge='end'
                  checked={checkedItemsForRemoval?.indexOf(element.name) !== -1}
                />
              )}
            </SListItem>
          );
        })}
      </List>
    </>
  );
}

function CardActionArea(props) {
  const {
    stagedElements,
    elementsValidated,
    isButtonDisabled,
    onClickRemoveConfirm,
  } = props;

  return (
    <Grid container alignItems='center' textAlign='center'>
      <Grid item xs={2}>
        <LightTooltip
          placement='top'
          contentText={`For admins only: Elements in red contain incomplete information.
        They can still be assigned to the project but it is advised you validate them. 
        You can click on invalid entries (marked red) and make appropriate changes.`}
          lightToolTipTargetItem={
            <QuestionSpan elementsValidated={elementsValidated}>
              <Help color='error' fontSize='medium' />
            </QuestionSpan>
          }
        />
      </Grid>
      <Grid item xs={10} className='elementAssignmentPage-assignButton'>
        <AssignButton
          primary
          disabled={isButtonDisabled}
          fullWidth
          noElements={stagedElements?.length < 1}
          variant='contained'
          onClick={onClickRemoveConfirm}
        >
          {'Remove selected items'}
        </AssignButton>
      </Grid>
    </Grid>
  );
}

export default function StagedElement(props) {
  const {
    initialElements,
    stagedElements,
    setCurrentProjectElements,
    renderElementDetails,
    hasDetailsWindowOpen,
    clusterElementDialogOpen,
    updateClusterDataElementsState,
  } = props;
  const dispatch = useDispatch();
  const currentProjectId = useSelector(selectCurrentProjectId);
  const currentCluster = useSelector(selectCluster);
  // const emailName = useSelector(selectCurrentUserEmail);

  const [checkedItemsForRemoval, setCheckedItemsForRemoval] = useState([]);
  const [deleteIsEnabled, toggleDeleteIsEnabled] = useState(false);
  const [elementsValidated, setElementsValidated] = useState(false);

  const currentElement = useRef(null);
  const newElementsList = useRef([]);

  //#region graphQL

  const [removeElementFromProject] = useMutation(REMOVE_PROJECT_DATA_ELEMENT, {
    update() {
      onRemovalSuccess();
    },
    onError: () => dispatch(setSnackbarError(`An error occurred`)),
  });

  const [removeElementFromCluster] = useMutation(REMOVE_CLUSTER_DATA_ELEMENT, {
    onCompleted: () => {
      onRemovalSuccess();
    },
    onError: () => dispatch(setSnackbarError(`An error occurred`)),
  });
  //#endregion graphQL

  function onRemovalSuccess() {
    toggleDeleteIsEnabled(!deleteIsEnabled);
    setCheckedItemsForRemoval([]);
    dispatch(setSnackbarSuccess(`Elements removed from project!`));
  }

  useEffect(() => {
    toggleDeleteIsEnabled(false);
  }, [currentProjectId]);

  useEffect(() => {
    setElementsValidated(
      stagedElements?.every((element) => element.description !== '')
    );
  }, [stagedElements]);

  // #region Events
  const onClickCloseDeleteIcon = () => {
    setCheckedItemsForRemoval([]);
    toggleDeleteIsEnabled(!deleteIsEnabled);
  };

  const onClickDeleteIcon = () => toggleDeleteIsEnabled(!deleteIsEnabled);

  const onClickElementShowDetail = (selectedElementObject) => {
    if (!deleteIsEnabled) {
      currentElement.current = selectedElementObject.name;
      renderElementDetails(selectedElementObject);
    }
  };

  const onClickSelectAllCheckbox = () => {
    stagedElements === checkedItemsForRemoval
      ? setCheckedItemsForRemoval([])
      : setCheckedItemsForRemoval(stagedElements);
  };

  const onClickSingleCheckbox = (value) => () => {
    const newCheckedElementsList = [...checkedItemsForRemoval];
    const elementIds = checkedItemsForRemoval.map((item) => item.id);
    const elementIndex = elementIds.indexOf(value.id);

    elementIndex === -1
      ? newCheckedElementsList.push(value)
      : newCheckedElementsList.splice(elementIndex, 1);

    setCheckedItemsForRemoval(newCheckedElementsList);
  };

  const onClickRemoveConfirm = () => {
    newElementsList.current = stagedElements.filter(function (o) {
      return !checkedItemsForRemoval.some(function (o2) {
        return o.id === o2.id;
      });
    });

    const elementsToRemove = stagedElements.filter(function (o) {
      return checkedItemsForRemoval.some(function (o2) {
        return o.id === o2.id;
      });
    });

    const elementsInDatabase = elementsToRemove.filter(({ id: id1 }) =>
      initialElements.some(({ id: id2 }) => id2 === id1)
    );

    for (let i = 0; i < elementsToRemove.length; i++) {
      if (elementsInDatabase.includes(elementsToRemove[i])) {
        let payload = {};
        if (clusterElementDialogOpen) {
          payload = {
            dataElementId: decoder(elementsToRemove[i].id),
            clusterId: decoder(currentCluster?.id),
          };
          removeElementFromCluster({ variables: { input: payload } });
        } else {
          payload = {
            dataElementId: decoder(elementsToRemove[i].id),
            projectId: decoder(currentProjectId),
          };
          removeElementFromProject({ variables: { input: payload } });
        }
      } else {
        onRemovalSuccess();
      }
    }
    if (clusterElementDialogOpen) {
      updateClusterDataElementsState({
        id: currentCluster?.id,
        totalDataElements: newElementsList?.current?.length,
        dataElements: newElementsList.current,
      });
    } else {
      setCurrentProjectElements({
        ...stagedElements,
        nodes: newElementsList.current,
      });
    }
  };
  // #endregion Events

  return (
    <>
      <Grid
        container
        style={{
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <Grid item xs={10}>
          <SCardHeader title='Assigned Elements' />
        </Grid>
        {stagedElements?.length ? (
          <ElementsDeleteIcon
            deleteIsEnabled={deleteIsEnabled}
            hasDetailsWindowOpen={hasDetailsWindowOpen}
            onClickDeleteIcon={onClickDeleteIcon}
            onClickCloseDeleteIcon={onClickCloseDeleteIcon}
          />
        ) : null}
      </Grid>
      <Grid container direction='row' style={{ flexGrow: 1 }}>
        <Grid item xs={12} className='elementAssignmentPage-assignedElements'>
          <AssignedCardContent
            noElements={stagedElements?.length < 1}
            clusterElementDialogOpen={clusterElementDialogOpen}
          >
            {stagedElements?.length > 0 ? (
              <AssignedElementsList
                deleteIsEnabled={deleteIsEnabled}
                hasDetailsWindowOpen={hasDetailsWindowOpen}
                checkedItemsForRemoval={checkedItemsForRemoval?.map(
                  (elem) => elem.name
                )}
                currentElement={currentElement.current}
                nameValues={stagedElements?.map((elem) => elem.name)}
                stagedElements={stagedElements}
                onClickElementShowDetail={onClickElementShowDetail}
                onClickSingleCheckbox={onClickSingleCheckbox}
                onClickSelectAllCheckbox={onClickSelectAllCheckbox}
                clusterElementDialogOpen={clusterElementDialogOpen}
              />
            ) : (
              <Typography>No Assigned Elements</Typography>
            )}
          </AssignedCardContent>
        </Grid>
      </Grid>
      {!clusterElementDialogOpen || deleteIsEnabled ? (
        <DeleteButtonArea>
          <CardActionArea
            isDeleteEnabled={deleteIsEnabled}
            elementsValidated={elementsValidated}
            hasDetailsWindowOpen={hasDetailsWindowOpen}
            stagedElements={stagedElements}
            onClickRemoveConfirm={onClickRemoveConfirm}
            isButtonDisabled={
              !deleteIsEnabled || !checkedItemsForRemoval?.length > 0
            }
          />
        </DeleteButtonArea>
      ) : null}
    </>
  );
}

StagedElement.propTypes = {
  renderElementDetails: PropTypes.func,
  stagedElements: PropTypes.array,
  hasDetailsWindowOpen: PropTypes.bool,
  initialElements: PropTypes.array,
};
ElementsDeleteIcon.propTypes = {
  deleteIsEnabled: PropTypes.any,
  hasDetailsWindowOpen: PropTypes.any,
  onClickCloseDeleteIcon: PropTypes.any,
  onClickDeleteIcon: PropTypes.any,
  stagedElements: PropTypes.any,
};

AssignedElementsList.propTypes = {
  checkedItemsForRemoval: PropTypes.any,
  currentElement: PropTypes.any,
  deleteIsEnabled: PropTypes.any,
  hasDetailsWindowOpen: PropTypes.any,
  nameValues: PropTypes.any,
  onClickSelectAllCheckbox: PropTypes.any,
  onClickElementShowDetail: PropTypes.func,
  stagedElements: PropTypes.any,
  onClickSingleCheckbox: PropTypes.func,
};

CardActionArea.propTypes = {
  checkedItemsForRemoval: PropTypes.array,
  elementsValidated: PropTypes.any,
  onClickAssign: PropTypes.func,
  onClickRemoveConfirm: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  stagedElements: PropTypes.array,
  isDeleteEnabled: PropTypes.bool,
};
