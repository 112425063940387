import { useEffect, useState } from 'react';
import AccordionList from 'components/AccordionList';
import TaskBadge from 'components/Badge';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import Divider from 'components/Divider';
import FormControl from 'components/FormControl';
import Grid from 'components/Grid';
import InputLabel from 'components/InputLabel';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Typography from 'components/Typography';
import { displayTaskStatus } from 'helpers/enumToType';
import { decoder } from 'helpers/graphQL_Encoder';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

//#region Styled Components
const RightButton = styled(Grid)`
  float: right;
`;

const ConfirmButtonGrid = styled(Grid)`
  display: grid;
  place-items: center;
`;

const FilterGrid = styled(Grid)`
  display: grid;
  place-items: center;
  max-width: 185px;
`;

const Description = styled(Typography)`
  margin-left: 10px;
  margin-right: 5px;
`;

const TaskCard = styled(CardContent)`
  height: 95%;
  padding: 0;
`;

const FilterDropdown = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 14.5px 12px;
  }
`;

const ExpandedSection = styled(Grid)`
  min-height: 125px;
  align-content: center;
`;

const AccordionStatus = styled(FormControl)`
  margin-left: 10px;

  @media screen and (max-width: 1350px) {
    width: 85%;
  }
`;

const ConfirmButton = styled(Button)`
  @media screen and (max-width: 1350px) {
    width: 85%;
  }
`;

const SCardHeader = styled(CardHeader)`
  .MuiCardHeader-title {
    @media screen and (max-width: 1350px) {
      font-size: 1.3em;
    }
  }
`;

const SInputLabel = styled(InputLabel)`
  &&.Mui-focused {
    color: ${(props) => props.theme.palette.background.lighter};
  }
`;
//#endregion Styled Components

function TaskStatusFilter(props) {
  const { selectedStatus, onFilterStatusType, statusOptions = [] } = props;

  const uniqueOptionValues = new Set();
  uniqueOptionValues.add(['Show All']);
  uniqueOptionValues.add(['Show Active']);
  uniqueOptionValues.add([...statusOptions]);
  const options = [...uniqueOptionValues].flat();

  return (
    <FormControl variant='outlined'>
      <SInputLabel id='filter-dropdown'>Filter</SInputLabel>
      <FilterDropdown
        labelId='filter-dropdown'
        label='Filter'
        defaultValue='Show All'
        fullWidth
        value={selectedStatus}
        onChange={onFilterStatusType}
      >
        {options?.map((s) => (
          <MenuItem key={s} value={s}>
            {displayTaskStatus(s)}
          </MenuItem>
        ))}
      </FilterDropdown>
    </FormControl>
  );
}

function TaskRowItem(props) {
  const { description, status } = props;
  return (
    <Grid container>
      <Grid item xs={8}>
        <Description>{description}</Description>
      </Grid>
      <Grid item xs={1}>
        <TaskBadge rowStatus={status} />
      </Grid>
      <Grid item xs={3}>
        <Typography>{displayTaskStatus(status)}</Typography>
      </Grid>
    </Grid>
  );
}

function TaskItemAccordion(props) {
  const { onTaskStatusChange, dropdownOptions, taskStatus, Id } = props;
  const [status, setStatus] = useState();

  const filteredDropdownList = dropdownOptions.filter(
    (status) => status !== taskStatus
  );

  useEffect(() => {
    setStatus(taskStatus);
  }, [taskStatus]);

  const onSelect = (e) => setStatus(e.target.value);
  const handleConfirm = () => onTaskStatusChange(status, Id);

  return (
    <ExpandedSection container>
      <Grid item xs={8}>
        <Box>
          <AccordionStatus variant='outlined' fullWidth>
            <SInputLabel>Status</SInputLabel>
            <FilterDropdown onChange={onSelect} label='Status' displayEmpty>
              {filteredDropdownList.map((s) => (
                <MenuItem key={s} value={s}>
                  {displayTaskStatus(s)}
                </MenuItem>
              ))}
            </FilterDropdown>
          </AccordionStatus>
        </Box>
      </Grid>
      <ConfirmButtonGrid item xs={4}>
        <ConfirmButton
          primary
          disabled={status === taskStatus}
          variant='contained'
          onClick={handleConfirm}
        >
          Confirm
        </ConfirmButton>
      </ConfirmButtonGrid>
    </ExpandedSection>
  );
}

export default function TaskList(props) {
  const {
    currentProjectId,
    currentUserTasks,
    itemStatusEnums,
    updateTaskStatus,
  } = props;
  const history = useHistory();

  const [filteredTasks, setFilteredTasks] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const [selectedStatus, setSelectedStatus] = useState('Show All');
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setSelectedStatus('Show All');
    setFilteredTasks(false);
  }, [currentProjectId]);

  /* #region  Task Status Logic */
  function sortTaskByStatus(taskObject) {
    let newTaskList = [];
    let inProgressList = [];
    let completeList = [];

    for (const task of taskObject) {
      const status = task.status;
      if (status === 'NEW') {
        newTaskList.push(task);
      } else if (status === 'INPROGRESS') {
        inProgressList.push(task);
      } else if (status === 'COMPLETE') {
        completeList.push(task);
      }
    }
    return newTaskList.concat(inProgressList).concat(completeList);
  }

  const onFilterStatusType = (e) => {
    const selectedFilter = e.target.value;
    if (selectedFilter === 'Show All') {
      taskAndStatusSetter(false, selectedFilter);
      return;
    } else if (selectedFilter === 'Show Active') {
      const filteredTaskList = currentUserTasks.current.filter(
        (ut) => ut.status === 'NEW' || ut.status === 'INPROGRESS'
      );
      taskAndStatusSetter(filteredTaskList, selectedFilter);
      return;
    }
    const filteredTaskList = currentUserTasks.current.filter(
      (ut) => ut.status === selectedFilter
    );
    taskAndStatusSetter(filteredTaskList, selectedFilter);
    setExpanded(false);
  };

  const handleTaskStatusChange = (taskStatus, taskId) => {
    updateTaskStatus({
      variables: {
        input: {
          statusUpdated: taskStatus,
          taskItemId: decoder(taskId),
        },
      },
    });
    if (filteredTasks) {
      const filteredTaskList = filteredTasks.filter((ut) => ut.id !== taskId);
      setFilteredTasks(filteredTaskList);
    }
    setExpanded(!expanded);
  };

  function taskAndStatusSetter(taskList, filterStatus) {
    setFilteredTasks(taskList);
    setSelectedStatus(filterStatus);
  }
  /* #endregion */

  const onSelectTaskAccordion = (panel) => (event, newExpanded) => {
    const clickedTask = currentUserTasks.current.find((tl) => tl.id === panel);
    setSelectedTask(clickedTask);
    setExpanded(newExpanded ? panel : false);
  };

  function AccordionTaskList() {
    let displayedList = filteredTasks
      ? sortTaskByStatus(filteredTasks)
      : sortTaskByStatus(currentUserTasks?.current);

    return (
      <TaskCard>
        {displayedList?.map((task) => (
          <AccordionList
            key={task?.id}
            Id={task?.id}
            Summary={
              <TaskRowItem
                description={task?.description}
                status={task?.status}
              />
            }
            Key={task.id}
            Details={
              <TaskItemAccordion
                key={task?.id}
                Id={task?.id}
                onTaskStatusChange={handleTaskStatusChange}
                dropdownOptions={itemStatusEnums?.itemStatusEnums.enumValues.map(
                  (e) => e.name
                )}
                taskStatus={task?.status}
              />
            }
            isExpanded={expanded === task?.id}
            accordionClick={onSelectTaskAccordion}
          />
        ))}
      </TaskCard>
    );
  }

  return (
    <Card style={{ height: '100%' }}>
      <Grid container spacing={2} style={{ justifyContent: 'space-between' }}>
        <Grid item xl={8} lg={6} md={6} xs={12}>
          <SCardHeader title='My Task List' />
        </Grid>
        <FilterGrid item xl={4} xs={6}>
          <TaskStatusFilter
            selectedStatus={selectedStatus}
            statusOptions={itemStatusEnums?.itemStatusEnums.enumValues.map(
              (e) => e.name
            )}
            onFilterStatusType={onFilterStatusType}
          />
        </FilterGrid>
      </Grid>
      <Divider />
      <Grid item>
        <AccordionTaskList />
      </Grid>
      <RightButton item md={12}>
        <CardActions>
          <Button
            style={currentUserTasks.current ? { display: 'none' } : false}
            disabled={!expanded}
            variant='contained'
            onClick={() => history?.push(selectedTask.type)}
          >
            Go to Task
          </Button>
        </CardActions>
      </RightButton>
    </Card>
  );
}

TaskList.propTypes = {
  currentProjectId: PropTypes.string,
  currentUserTasks: PropTypes.object,
  itemStatusEnums: PropTypes.object,
  updateTaskStatus: PropTypes.func,
};

TaskRowItem.propTypes = {
  description: PropTypes.string,
  status: PropTypes.string,
};
TaskStatusFilter.propTypes = {
  selectedStatus: PropTypes.string,
  onFilterStatusType: PropTypes.func,
  statusOptions: PropTypes.array,
};

TaskItemAccordion.propTypes = {
  onTaskStatusChange: PropTypes.func,
  dropdownOptions: PropTypes.array,
  taskStatus: PropTypes.string,
  Id: PropTypes.string,
};
