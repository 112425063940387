import MIconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

export default function IconButton({ icon, ...rest }) {
  return (
    <>
      {icon ? (
        <MIconButton {...rest}>{icon}</MIconButton>
      ) : (
        <MIconButton {...rest} />
      )}
    </>
  );
}

IconButton.propTypes = {
  icon: PropTypes.element,
};
