import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import HighlightOutlinedIcon from '@mui/icons-material/HighlightOutlined';
import Box from 'components/Box';
import Button from 'components/Button';
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import ProjectSelectedTemplate from 'components/Template/ProjectSelectedTemplate';
import Typography from 'components/Typography';
import { decoder, getGuid } from 'helpers/graphQL_Encoder';
import useImageCropper from 'hooks/useImageCropper';
import { DrawerView } from 'pages/DataEntry/views';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
  ADD_OR_UPDATE_VALUE_ELEMENT,
  CHECK_AND_COMPLETE_PROJECT_MEMBER_STATUS,
  CHECK_AND_COMPLETE_DOCUMENT_STATUS,
  UPDATE_ASSIGNMENT_PROJECT_MEMBER,
  REMOVE_TEMPLATE_IN_DOCUMENT,
  RECLUSTER_DOCUMENTS,
  CREATE_REDACTION_FILE_ON_PAGE_COMPLETE,
} from 'services/GraphQL/Mutations';
import {
  GET_DOCUMENT_MEMBER,
  PULL_DATA_EXTRACTION,
  LOAD_VALUE_ELEMENTS_BY_DOC_ID,
  GET_PRESIGNED_URLS_S3,
  TEMPLATE_EXISTS_IN_CLUSTER,
  GET_DOCS_BY_CLUSTER,
  GET_DATA_ELEMENTS_BY_CLUSTER,
} from 'services/GraphQL/Queries';
import {
  selectAddSpacing,
  selectCluster,
  selectDocument,
  selectExtractionDone,
  selectSupplementalData,
  setExtractionDone,
  setSelectedCluster,
  setSelectedDocument,
  setHighlightedValue,
  setSelectedProjectMember,
  selectOnClassificationGroupsTab,
  selectProjectMember,
  setSupplementalData,
  setOnClassificationGroupsTab,
} from 'store/Redux/slices/dataEntrySlice';
import {
  selectCurrentProject,
  selectCurrentProjectId,
  selectReadToken,
  selectValueElementStatusTypes,
  setCurrentProjectId,
  updateValueElementValue,
  setValueElements,
  selectProjectMemberStatusTypes,
  selectCurrentResourceName,
  defaultResourceMenu,
} from 'store/Redux/slices/projectsSlice';
import {
  setSnackbarError,
  setSnackbarInfo,
  setSnackbarSuccess,
} from 'store/Redux/slices/snackbarsSlice';
import { selectCurrentUserEmail } from 'store/Redux/slices/usersSlice';
import styled from 'styled-components';
import Highlighter from 'containers/Cropper/Highlighter';
import { UPDATE_PROJECT_MEMBER_AUTOFLOW } from 'services/GraphQL/Mutations';
import { setCurrentMember } from 'store/Redux/slices/analysisSlice';
import { displayElementType } from 'helpers/enumToType';
import { isPast24Hours } from 'helpers/DateComparer';
import { resources } from 'resource';
import {
  applyAWSTokenToDocument,
  handleDecodeURL,
} from 'helpers/UpdateAWSDocumentUriTokens';
import { useConfirm } from 'material-ui-confirm';
import { ReassignClusterModal } from 'pages/Cluster/modals';
import ButtonGroup from 'components/ButtonGroup';
import Dialog from 'components/Dialog';
import { selectCurrentOrganizationName } from 'store/Redux/slices/projectsSlice';
import { config } from '../../config';

//#region Styled Components
const SIconButton = styled(IconButton)`
  cursor: pointer;
  color: white;
`;

const Root = styled.main`
  flex-grow: 1;
  padding: 24px;
  background-color: ${(props) => props.theme.palette.background.main};

  .react-transform-wrapper {
    overflow: visible;
  }
`;

const ControlPanel = styled(ButtonGroup)`
  background-color: black;
  opacity: 60%;
  border-radius: 16px;
  height: 6dvh;
`;

const ExtractButton = styled(Button)`
  height: 6dvh;
  border-radius: 16px;
`;

const Transform = styled(Grid)`
  .react-transform-component {
    margin: 0 auto;
  }
`;

const PDFObject = styled.object`
  width: 724px;
  filter: ${(props) => (props.inverted ? 'invert(1)' : null)};
`;

const Image = styled.img`
  width: 724px;
  height: auto;
  filter: ${(props) => (props.inverted ? 'invert(1)' : null)};
`;

const SReactCrop = styled(ReactCrop)`
  & > div {
    width: 724px;
  }
`;

const ViewArea = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const CropModeImage = styled(Box)`
  margin: 0 auto;
  display: grid;
  flex-wrap: wrap;
  place-items: center;
  width: fit-content;
  height: fit-content;
  padding: 0;
  transform-origin: 0% 0%;
`;
//#endregion Styled Components

export default function DataEntry(props) {
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const currentDocument = useSelector(selectDocument);
  const currentCluster = useSelector(selectCluster);
  const currentProjectMember = useSelector(selectProjectMember);
  const supplementalData = useSelector(selectSupplementalData);
  const readToken = useSelector(selectReadToken);
  const extractionDone = useSelector(selectExtractionDone);
  const addSpacing = useSelector(selectAddSpacing);
  const emailName = useSelector(selectCurrentUserEmail);
  const valueElementStatus = useSelector(selectValueElementStatusTypes);
  const onClassificationGroupsTab = useSelector(
    selectOnClassificationGroupsTab
  );
  const projectMemberStatuses = useSelector(selectProjectMemberStatusTypes);
  const resourceMenu = useSelector(selectCurrentResourceName);
  const organizationName = useSelector(selectCurrentOrganizationName);
  const selectedResources =
    resources[
      resourceMenu === null || resourceMenu === undefined
        ? defaultResourceMenu
        : resourceMenu
    ];
  const projectMemberIdentificationHeader = `${selectedResources.ANY_PAGES.PROJECT_MEMBER} `;
  const allPms = 9999;
  const redactionEditRequestId = '22222222-2222-2222-2222-222222222222';

  const [clusters, setClusters] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);
  const [dataElements, setDataElements] = useState([]);
  const [file, setFile] = useState(undefined);
  const [showForm, setShowForm] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [docIndex, setDocIndex] = useState(null);
  const [pmIndex, setPmIndex] = useState(null);
  const [prevProjectId, setPrevProjectId] = useState(currentProjectId);
  const [inverted, setInverted] = useState(false);
  const [docType, setDocType] = useState('');
  const [checkClusterId, setCheckClusterId] = useState();
  const [isExtractionLoading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState([]);
  const [shouldTableUpdate, setShouldTableRerender] = useState(Date.now());
  const [isReadOnly, toggleIsReadOnly] = useState(false);
  const [reclusterDocs, setReclusterDocs] = useState([]);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [reassignModalDocs, setReassignModalDocs] = useState([]);
  const [destinationCluster, setDestinationCluster] = useState(null);
  const [showRadioAndCheckboxHighlights, setShowRadioAndCheckboxHighlights] =
    useState(false);
  const [showConfirmChangePMDialog, setShowConfirmChangePMDialog] =
    useState(false);
  const [direction, setDirection] = useState(null);
  const [awsObject, setAwsObject] = useState('');

  const {
    crop,
    cropImageCss,
    croppedCoordinates,
    cropReset,
    handleOnCompleteCrop,
    imagePosition,
    imageRef,
    isCropDisabled,
    onClickCrop,
    onClickRemoveCrop,
    onConfirmCrop,
    selectedElementField,
    setCrop,
    setIsCropDisabled,
    getAndSetImageStyle,
    croppedCoordinatesCopy,
  } = useImageCropper();

  //#region GraphQL

  const [getDocumentMember] = useLazyQuery(GET_DOCUMENT_MEMBER, {
    onCompleted: (data) => {
      if (data?.documentById) {
        dispatch(setCurrentMember(data.documentById.projectMember));
        dispatch(
          setSupplementalData(data.documentById.projectMember.supplementalDatas)
        );
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [addOrUpdateValueElement] = useMutation(ADD_OR_UPDATE_VALUE_ELEMENT, {
    onCompleted: (data) => {
      if (data?.addOrUpdateValueElement?.valueElement)
        dispatch(setSnackbarSuccess('Value Element updated successfully.'));
    },
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
  });

  const [pullExtractionValues] = useLazyQuery(PULL_DATA_EXTRACTION, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.dataElementExtractionValues) {
        dispatch(setExtractionDone(data.dataElementExtractionValues));
        dispatch(setSnackbarSuccess('Extracted Data posted successfully!'));
        checkDocumentStatus({ variables: { id: currentCluster?.id } });
      } else {
        dispatch(setSnackbarError('Extraction Failed: Results were null.'));
      }
    },
    onError: (networkError) => {
      setLoading(false);
      dispatch(setSnackbarError(`${networkError}. Results returned null.`));
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const [checkDocumentStatus] = useMutation(
    CHECK_AND_COMPLETE_DOCUMENT_STATUS,
    {
      onCompleted: (data) => {
        if (data?.checkAndCompleteDocumentStatusFromCluster) {
          const updatedCurrentDoc =
            data.checkAndCompleteDocumentStatusFromCluster?.documents?.find(
              (d) => d.id === currentDocument?.id
            );
          if (updatedCurrentDoc) {
            dispatch(setSelectedDocument(updatedCurrentDoc));

            if (updatedCurrentDoc.isComplete) {
              checkForVASpecificPMWorkflow(updatedCurrentDoc);
            }
          }
        }
      },
    }
  );

  const [checkProjectMemberStatus] = useMutation(
    CHECK_AND_COMPLETE_PROJECT_MEMBER_STATUS,
    {
      onCompleted: (data) => {
        if (
          data.checkAndCompleteProjectMemberStatusFromProject?.projectMembers
            ?.nodes.length > 0
        ) {
          const pm =
            data.checkAndCompleteProjectMemberStatusFromProject.projectMembers.nodes.find(
              (p) => p.id === currentDocument?.projectMember?.id
            );

          if (pm) {
            let updatedPm = { ...currentDocument?.projectMember };
            dispatch(
              setCurrentMember({
                ...updatedPm,
                assignedTo: pm.assignedTo,
                previouslyAssignedTo: pm.previouslyAssignedTo,
                assignedOn: pm.assignedOn,
                status: pm.status,
              })
            );
            dispatch(
              setSnackbarInfo(
                'This Project Member has been updated. Open Project Member Details button to view.'
              )
            );
            if (
              pm.status ===
              projectMemberStatuses.RECONCILIATION_FLAGGED_FOR_REVIEW
            ) {
              const autoFlowInput = {
                projectMemberId: decoder(pm?.id),
                fileName: `DataEntryComplete/Reconciled_${getGuid(
                  decoder(currentProjectId)
                )}_${currentDocument?.projectMember?.originalId}.json`,
              };
              updateProjectMemberAutoFlow({
                variables: { input: autoFlowInput },
              });
            }
          }
        }
      },
      onError: (error) => {
        dispatch(setSnackbarError(error.message));
      },
    }
  );

  const [getValueElements, { loading: valueElementsLoading }] = useLazyQuery(
    LOAD_VALUE_ELEMENTS_BY_DOC_ID,
    {
      onCompleted: (data) => {
        if (data?.documentById?.valueElements) {
          setInputVal(data?.documentById?.valueElements);
          dispatch(setValueElements(data?.documentById.valueElements));
          setShouldTableRerender(Date.now());
        }
      },
      onError: () => {
        dispatch(setSnackbarError('Failed to retrieve value elements'));
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [getDataElements] = useLazyQuery(GET_DATA_ELEMENTS_BY_CLUSTER, {
    onCompleted: (data) => {
      if (data?.clusterById) {
        setDataElements(data.clusterById?.dataElements);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getDocuments] = useLazyQuery(GET_DOCS_BY_CLUSTER, {
    onCompleted: (data) => {
      if (data?.documents?.nodes?.length > 0) {
        let decodedDocs = handleDecodeURL(data.documents.nodes);
        handlePreSignedUrlDocuments(currentDocument, decodedDocs);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const [updateProjectMemberAutoFlow] = useMutation(
    UPDATE_PROJECT_MEMBER_AUTOFLOW,
    {
      onCompleted: (data) => {
        if (data.updateProjectMemberAutoFlow) {
          getDocumentMember({
            variables: { id: currentDocument?.id },
          })
            .then((response) => {
              const status = displayElementType(
                response?.data.documentById.projectMember.status
              );
              dispatch(
                setSnackbarSuccess(
                  `Reconciliation Complete. Project Member status has been moved to: ${status}`
                )
              );
            })
            .catch((error) => {
              dispatch(setSnackbarError(error.message));
            });
        }
      },
      onError: (error) => {
        dispatch(
          setSnackbarError(`Error updating Project Member: ${error.message}`)
        );
      },
    }
  );

  const [updateProjectMemberAssignment] = useMutation(
    UPDATE_ASSIGNMENT_PROJECT_MEMBER,
    {
      onCompleted: (data) => {
        if (data?.updateAssignmentForProjectMember) {
          dispatch(setCurrentMember(data.updateAssignmentForProjectMember));
        }
        dispatch(
          setSnackbarSuccess(
            `${data.updateAssignmentForProjectMember.assignedTo} is now assigned to Project Member ${data.updateAssignmentForProjectMember.originalId}.`
          )
        );
      },
      onError: (error) => {
        dispatch(
          setSnackbarError(
            `Project Member assignment failed: ${error.message} `
          )
        );
      },
    }
  );

  const [createRedactionFileOnPageComplete] = useMutation(
    CREATE_REDACTION_FILE_ON_PAGE_COMPLETE,
    {
      onError: (error) => {
        dispatch(setSnackbarError(error.messsage));
      },
    }
  );

  const [getPreSignedUrlList] = useLazyQuery(GET_PRESIGNED_URLS_S3);

  const confirmCroppingMessage = (
    <Box textAlign='center'>
      <Typography variant='body1'>
        Another document in the same cluster is already used for templating.
        Would you like to proceed with making the current document the new
        template for the cluster?
      </Typography>
      <br />
      <Typography variant='caption'>
        Potential New Coordinates: {croppedCoordinates?.value}
      </Typography>
    </Box>
  );

  const changePMConfirmationDialog = (
    <Dialog
      open={showConfirmChangePMDialog}
      title='Confirm'
      submitLabel='Yes'
      handleSubmit={() => {
        direction === 'decrement'
          ? decrementIndex('projectMember')
          : incrementIndex('projectMember');
        setShowConfirmChangePMDialog(false);
      }}
      cancelLabel='No'
      handleCancel={() => {
        setShowConfirmChangePMDialog(false);
      }}
      hasActions
    >
      {`Are you sure you want to move to the ${
        direction === 'decrement' ? 'previous' : 'next'
      } ${projectMemberIdentificationHeader}?`}
    </Dialog>
  );

  const confirm = useConfirm();

  const [templateExistsInCluster] = useLazyQuery(TEMPLATE_EXISTS_IN_CLUSTER, {
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [removeTemplateInDocument] = useMutation(REMOVE_TEMPLATE_IN_DOCUMENT, {
    onError: (error) => {
      dispatch(setSnackbarError(error.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [reclusterDocuments] = useMutation(RECLUSTER_DOCUMENTS);

  // #endregion

  // #region COMPONENT FUNCTIONS
  const decrementIndex = (indexType) => {
    let index = indexType === 'doc' ? docIndex : pmIndex;
    setDirection('decrement');
    if (checkIfIsVAPMNotComplete(indexType)) {
      setShowConfirmChangePMDialog(true);
      return;
    }
    let list = indexType === 'doc' ? documents?.nodes : projectMembers?.nodes;
    if (index > 0) {
      let newIndex = index - 1;
      updateIndex(list[newIndex]);
    }
  };

  const checkIfIsVAPMNotComplete = (indexType) => {
    return (
      indexType === 'projectMember' &&
      !showConfirmChangePMDialog &&
      organizationName === config.VAORGANIZATIONNAME &&
      (currentProjectMember?.status ==
        projectMemberStatuses.DATA_ENTRY_IN_PROGRESS ||
        currentProjectMember?.status ==
          projectMemberStatuses.DATA_ENTRY_OFF_RAMPED_FOR_REVIEW)
    );
  };

  const incrementIndex = (indexType) => {
    setDirection('increment');
    let index = indexType === 'doc' ? docIndex : pmIndex;
    if (checkIfIsVAPMNotComplete(indexType)) {
      setShowConfirmChangePMDialog(true);
      return;
    }
    let list = indexType === 'doc' ? documents?.nodes : projectMembers?.nodes;
    if (index < list.length - 1) {
      let newIndex = index + 1;
      updateIndex(list[newIndex]);
    }
  };

  const updateIndex = (object) => {
    if (object) {
      let objectIsDoc = object.__typename === 'Document';
      let list = objectIsDoc ? documents?.nodes : projectMembers?.nodes;
      let newIndex = list.findIndex((x) => x.id === object.id);

      if (objectIsDoc) {
        setDocIndex(newIndex);
        dispatch(setSelectedDocument(object));
      } else {
        setDocIndex(0);
        setPmIndex(newIndex);
        dispatch(setSelectedProjectMember(object));
        let decodedDocs = handleDecodeURL(object.documents);
        handlePreSignedUrlDocuments(object.documents, decodedDocs);
      }
    } else return;
  };

  const rotate = () => {
    let newRotation = rotation + 90;
    if (newRotation === 360) {
      newRotation = 0;
    }
    setRotation(newRotation);
  };

  const invertColors = () => setInverted(!inverted);

  const addOrUpdateValueElementOnPromise = (
    newValue,
    documentId,
    dataElementId,
    element,
    newCroppedCoordinates,
    resolve,
    reject
  ) => {
    addOrUpdateValueElement({
      variables: {
        input: {
          value: newValue,
          documentId: documentId,
          dataElementId: dataElementId,
          currentUser: emailName,
          status: element?.changed
            ? valueElementStatus.REVIEWED_CHANGES_MADE
            : !element?.changed &&
              element?.status == valueElementStatus.REVIEW_REQUIRED
            ? valueElementStatus.REVIEWED_NO_CHANGES_MADE
            : valueElementStatus.COMPLETE_NO_REVIEW_REQUIRED,
          coordinates: newCroppedCoordinates,
        },
      },
    })
      .then((data) => {
        dispatch(
          updateValueElementValue({
            form: data.data.addOrUpdateValueElement.valueElement,
          })
        );
        if (data) {
          resolve(data);
        } else {
          reject(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleValueElementUpdate = (
    newValue,
    documentId,
    dataElementId,
    element,
    newCroppedCoordinates
  ) => {
    return new Promise(function (resolve, reject) {
      var clusterId = currentDocument?.clusterId;
      if (clusterId) {
        // Update the ValueElement
        addOrUpdateValueElementOnPromise(
          newValue,
          documentId,
          dataElementId,
          element,
          newCroppedCoordinates,
          resolve,
          reject
        );
      }
    });
  };

  const checkForTemplateInCluster = (
    newValue,
    documentId,
    dataElementId,
    dataElement,
    newCroppedCoordinates,
    handleType
  ) => {
    let newDocs = [...documents.nodes];
    templateExistsInCluster({
      variables: { clusterId: currentDocument?.clusterId },
    })
      .then((res) => {
        if (
          res.data.templateExistsInCluster &&
          res.data.templateExistsInCluster !== decoder(currentDocument?.id)
        ) {
          confirm({
            content: confirmCroppingMessage,
            confirmationText: 'Yes',
            cancellationText: 'No',
          })
            .then(() => {
              removeTemplateInDocument({
                variables: {
                  documentId: res.data.templateExistsInCluster,
                },
              }).then((res2) => {
                handleType(newValue, dataElementId);
                handleValueElementUpdate(
                  newValue,
                  documentId,
                  dataElementId,
                  dataElement,
                  newCroppedCoordinates
                ).then(() => {
                  newDocs = documents?.nodes?.map((doc) => {
                    if (doc.id === currentDocument?.id) {
                      return {
                        ...doc,
                        isTemplate: res2.data.removeTemplateInDocument,
                      };
                    } else if (
                      decoder(doc.id) === res.data.templateExistsInCluster
                    ) {
                      return {
                        ...doc,
                        isTemplate: !res2.data.removeTemplateInDocument,
                      };
                    }
                    return doc;
                  });
                  setDocuments({ ...documents, nodes: newDocs });
                });
              });
            })
            .catch(() => {
              return false;
            });
        } else {
          handleType(newValue, dataElementId);
          handleValueElementUpdate(
            newValue,
            documentId,
            dataElementId,
            dataElement,
            newCroppedCoordinates
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const handlePullExtractionValues = () => {
    setLoading(true);
    pullExtractionValues({
      variables: {
        clusterId: decoder(currentCluster?.id),
        currentUser: emailName,
      },
    });
  };

  const handlePreSignedUrlDocuments = (newDoc, documents) => {
    const documentUris = documents.map((d) => {
      return d.documentUri;
    });
    getPreSignedUrlList({ variables: { urlList: documentUris } }).then(
      (res) => {
        const updatedDocs = applyAWSTokenToDocument(
          res.data.preSignedUrlList,
          documents
        );
        let currentDoc = updatedDocs.find((u) => u.id === newDoc?.id);
        dispatch(setSelectedDocument(currentDoc || updatedDocs[0]));
        updateDocuments(updatedDocs);
      }
    );
  };

  const updateDocuments = (docs) => {
    let sortedDocs = [...docs];
    sortedDocs.sort(
      (a, b) => b.totalElementsNeedReview - a.totalElementsNeedReview
    );
    setDocuments({ ...documents, nodes: sortedDocs });
  };

  const checkAssignmentForProjectMember = (projectMember) => {
    const expired = isPast24Hours(projectMember.assignedOn);
    if (emailName === projectMember.assignedTo) {
      toggleIsReadOnly(false);
    } else if (expired || projectMember.assignedTo === null) {
      toggleIsReadOnly(false);
      updateProjectMemberAssignment({
        variables: {
          input: {
            projectMemberId: decoder(projectMember?.id),
            username: emailName,
          },
        },
      });
    } else {
      toggleIsReadOnly(true);
    }
  };

  const toggleReclusterDoc = (e, doc, index, reclusterMode = true) => {
    if (reclusterMode && reclusterDocs?.includes(doc.id)) {
      setReclusterDocs([]);
      setDocIndex(index);
    } else {
      setReclusterDocs(doc.id);
    }
  };

  const reassignCurrentDocument = () => {
    if (reclusterDocs?.length > 0) {
      const reclusterInput = reclusterDocs.map((doc) => {
        return {
          documentId: decoder(doc),
          newClusterId: decoder(destinationCluster?.id),
        };
      });

      reclusterDocuments({
        variables: {
          inputs: reclusterInput,
        },
      }).then(() => {
        setOpenReassignModal(false);
        dispatch(setSnackbarSuccess('Document Successfully Reclustered'));
        dispatch(setSelectedCluster(destinationCluster));
        getDocuments({
          variables: {
            filters: {
              clusterId: {
                eq: decoder(destinationCluster?.id),
              },
            },
          },
        });
      });
    }
  };

  const setClusterValues = (value) => {
    setDestinationCluster(value);
  };

  const checkForVASpecificPMWorkflow = (document) => {
    if (document?.projectMember?.originalId === redactionEditRequestId) {
      const guidProjectId = getGuid(decoder(currentProjectId));
      const guidDocumentId = getGuid(decoder(document.id));
      const fileName = `RedactionFeedbackComplete/RedactionFeedback_${guidProjectId}_${currentProjectMember.originalId}_${guidDocumentId}.json`;

      createRedactionFileOnPageComplete({
        variables: {
          documentId: decoder(document.id),
          fileName: fileName,
        },
      }).then((res) => {
        if (res?.data?.createFileWhenPageIsComplete) {
          dispatch(
            setSnackbarSuccess(`File ${fileName} uploaded successfully.`)
          );
        } else {
          dispatch(setSnackbarError('Error creating redaction file.'));
        }
      });
    } else {
      checkProjectMemberStatus({
        variables: { id: currentProjectId, first: allPms },
      });
    }
  };
  // #endregion

  //#region USE_EFFECTS
  useEffect(() => {
    setCheckClusterId(currentCluster?.id);
    dispatch(setCurrentProjectId(prevProjectId));
  }, []);

  useEffect(() => {
    let state = props?.location?.state;
    if (state?.clusters?.nodes?.length) {
      dispatch(setOnClassificationGroupsTab(true));
      setClusters(state.clusters);
    }
    if (state?.projectMembers?.nodes?.length) {
      setProjectMembers(state.projectMembers);
    }
    if (state?.documents?.nodes?.length) {
      setDocuments(state.documents);
    }
    if (state?.awsObject) {
      setAwsObject(state?.awsObject);
    }
  }, [props?.location?.state]);

  useEffect(() => {
    if (onClassificationGroupsTab) {
      if (clusters?.nodes?.length > 0 && currentCluster) {
        let currentClusterExists = clusters.nodes.find(
          (x) => x?.id == currentCluster.id
        );
        const newCluster = currentClusterExists
          ? currentClusterExists
          : clusters.nodes[0];
        dispatch(setSelectedCluster(newCluster));
      }
    }
  }, [clusters]);

  useEffect(() => {
    if (onClassificationGroupsTab) {
      if (documents?.nodes?.length > 0 && currentDocument) {
        let newDoc = documents?.nodes?.find((x) => x.id === currentDocument.id);
        if (!newDoc) {
          newDoc = documents?.nodes[0];
        }
        let newIndex = documents?.nodes?.findIndex((x) => x.id === newDoc?.id);
        setDocIndex(newIndex);
        setDocuments(documents);

        dispatch(setSelectedDocument(newDoc));
      }
    }
  }, [documents]);

  useEffect(() => {
    if (!onClassificationGroupsTab) {
      if (projectMembers?.nodes?.length > 0) {
        let currentProjectMemberExists = projectMembers?.nodes?.find(
          (x) => x.id === currentProjectMember?.id
        );
        const newProjectMember = currentProjectMemberExists
          ? currentProjectMember
          : projectMembers?.nodes[0];
        let newIndex = projectMembers?.nodes?.findIndex(
          (x) => x.id === newProjectMember?.id
        );
        setPmIndex(newIndex);
        dispatch(setSelectedProjectMember(newProjectMember));
      }
      if (documents?.nodes?.length > 0) {
        let docId = null;
        let newDoc = null;
        let newIndex = null;
        if (
          currentDocument?.projectMemberId === decoder(currentProjectMember?.id)
        ) {
          docId = currentDocument?.id;
          newDoc = documents?.nodes?.find((x) => x.id === docId);
          newIndex = documents?.nodes?.findIndex((x) => x.id === newDoc?.id);
          setDocuments({
            ...documents,
            nodes: currentProjectMember?.documents?.nodes,
          });
          dispatch(setSelectedDocument(newDoc));
        } else {
          docId = currentProjectMember?.documents?.nodes[0]?.id;
          newDoc = currentProjectMember?.documents?.nodes?.find(
            (x) => x.id === docId
          );
          newIndex = currentProjectMember?.documents?.nodes?.findIndex(
            (x) => x.id === newDoc?.id
          );
          let decodedDocs = handleDecodeURL(
            currentProjectMember?.documents?.nodes
          );
          handlePreSignedUrlDocuments(newDoc, decodedDocs);
        }
        setDocIndex(newIndex);
      }
    }
  }, [projectMembers]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentProjectId, currentDocument]);

  useEffect(() => {
    if (currentProject?.id && currentProjectId !== currentProject.id) {
      setPrevProjectId(currentProject.id);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (currentDocument?.documentUri) {
      const file = currentDocument.documentUri.includes(awsObject)
        ? currentDocument.documentUri
        : `${currentDocument.documentUri}?${readToken}`;
      setFile(file);
    } else {
      setFile(undefined);
    }
    if (currentDocument?.projectMemberId) {
      getDocumentMember({
        variables: {
          id: currentDocument?.id,
        },
      }).then((res) => {
        checkAssignmentForProjectMember(res.data.documentById.projectMember);
      });
    } else {
      dispatch(setCurrentMember({}));
    }
    dispatch(setHighlightedValue([]));
  }, [currentDocument?.id]);

  useEffect(() => {
    if (currentCluster) {
      var currentClusterDocs = documents?.nodes?.some(
        (x) => x?.clusterId == decoder(currentCluster?.id)
      );
      if (currentClusterDocs) {
        setDocType(documents?.nodes[docIndex]?.originalName?.slice(-4));
      }
      if (checkClusterId == currentCluster?.id) {
        return;
      } else {
        dispatch(setExtractionDone(false));
      }
    }
  }, [currentCluster, docIndex]);

  useEffect(() => {
    if (openReassignModal) {
      let reassignDoc = currentDocument;
      setReassignModalDocs({
        ...documents,
        nodes: [reassignDoc],
        totalCount: 1,
      });
      setReclusterDocs([reassignDoc.id]);
    }
  }, [openReassignModal]);

  useEffect(() => {
    if (destinationCluster) {
      setReclusterDocs([]);
    }
  }, [destinationCluster]);
  //#endregion

  const handleOnCropChange = (crop) => setCrop(crop);
  const onExitCropMode = () => cropReset();

  const renderImage = () => (
    <>
      {docType === '.pdf' ? (
        <PDFObject
          size={cropImageCss?.imageSize}
          data={file}
          inverted={inverted}
          onLoad={() => {
            getAndSetImageStyle();
          }}
        >
          No Image Available
        </PDFObject>
      ) : (
        <TransformComponent ref={(n) => (imageRef.current = n)}>
          <Box
            style={{
              transform: `rotate(${rotation}deg)`,
              zIndex: 1,
              textAlignLast: 'center',
              backgroundColor: '#fff',
            }}
          >
            <Image
              size={cropImageCss?.imageSize}
              onLoad={() => {
                getAndSetImageStyle();
              }}
              id='currentImage'
              src={file}
              inverted={inverted}
            />
            <Highlighter currentDocument={currentDocument} />
          </Box>
        </TransformComponent>
      )}
    </>
  );

  const renderExtractButton = () => (
    <ExtractButton
      primary
      variant='contained'
      disabled={
        extractionDone ||
        currentDocument?.isComplete ||
        currentCluster?.dataExtractionId == null ||
        isReadOnly
      }
      onClick={() => handlePullExtractionValues()}
      endIcon={<ImageSearchIcon />}
      className='dataEntryPage-extractDataButton'
    >
      <Typography variant='button' noWrap>
        Extract Data
      </Typography>
    </ExtractButton>
  );

  const controlPanel = (zoomIn, zoomOut, resetTransform) => (
    <div className='dataEntryPage-controlPanel'>
      <ControlPanel variant='contained'>
        <SIconButton onClick={() => zoomIn()}>
          <ZoomInIcon />
        </SIconButton>
        <SIconButton onClick={() => zoomOut()}>
          <ZoomOutIcon />
        </SIconButton>
        <SIconButton
          onClick={() =>
            setShowRadioAndCheckboxHighlights(!showRadioAndCheckboxHighlights)
          }
        >
          <HighlightOutlinedIcon />
        </SIconButton>
        <SIconButton onClick={() => resetTransform()}>
          <ZoomOutMapIcon />
        </SIconButton>
        <SIconButton onClick={rotate}>
          <RotateLeftIcon />
        </SIconButton>
        <SIconButton onClick={invertColors}>
          <InvertColorsIcon />
        </SIconButton>
      </ControlPanel>
    </div>
  );

  return (
    <ProjectSelectedTemplate>
      {currentDocument ? (
        <>
          <Root
            style={showForm ? { marginRight: '30%' } : {}}
            className='dataEntryPage-documentOptions'
          >
            {isCropDisabled ? (
              <TransformWrapper
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={0}
              >
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <Transform container spacing={2} alignItems='center'>
                    <Grid item xs={6} textAlign='end'>
                      {controlPanel(zoomIn, zoomOut, resetTransform)}
                    </Grid>
                    <Grid item xs textAlign='start'>
                      {renderExtractButton()}
                    </Grid>
                    <ViewArea item xs={12}>
                      {renderImage()}
                    </ViewArea>
                  </Transform>
                )}
              </TransformWrapper>
            ) : (
              <Transform container spacing={2}>
                <Grid item xs={12} style={{ marginTop: '68px' }}>
                  <CropModeImage
                    style={{
                      transform: cropImageCss?.transform,
                    }}
                  >
                    <SReactCrop
                      size={cropImageCss?.imageSize}
                      style={cropImageCss?.style}
                      imageStyle={
                        cropImageCss?.imageSize?.width >
                        cropImageCss?.imageSize?.height
                          ? { width: '100%' }
                          : { height: '100%' }
                      }
                      src={file}
                      crop={crop}
                      zoom={imagePosition.current.scale}
                      rotate={rotation > 180 ? -90 : rotation}
                      spin={rotation > 180 ? -90 : rotation}
                      onChange={handleOnCropChange}
                      onComplete={handleOnCompleteCrop}
                      disabled={isCropDisabled}
                    />
                  </CropModeImage>
                </Grid>
              </Transform>
            )}
            {addSpacing && <div style={{ height: '240px' }} />}
          </Root>

          <DrawerView
            currentCluster={currentCluster}
            currentDocument={currentDocument}
            currentProjectId={currentProjectId}
            checkClusterId={checkClusterId}
            clusters={clusters?.nodes}
            documents={documents}
            setDocuments={setDocuments}
            getDocuments={getDocuments}
            projectMembers={projectMembers?.nodes?.filter(
              (c) => c?.documents?.length > 0
            )}
            setProjectMembers={setProjectMembers}
            decrementIndex={decrementIndex}
            docIndex={docIndex}
            handleValueElementUpdate={handleValueElementUpdate}
            incrementIndex={incrementIndex}
            isExtractionLoading={isExtractionLoading}
            extractionDone={extractionDone}
            selectedCluster={currentCluster}
            setCheckClusterId={setCheckClusterId}
            setDocIndex={setDocIndex}
            setShowForm={setShowForm}
            supplementalData={supplementalData}
            useForm={showForm}
            checkDocumentStatus={checkDocumentStatus}
            valueElementStatus={valueElementStatus}
            onClassificationGroupsTab={onClassificationGroupsTab}
            crop={crop}
            croppedCoordinates={croppedCoordinates}
            onClickRemoveCrop={onClickRemoveCrop}
            onClickCrop={onClickCrop}
            onConfirmCrop={onConfirmCrop}
            onExitCropMode={onExitCropMode}
            selectedElementField={selectedElementField}
            isCropDisabled={isCropDisabled}
            setIsCropDisabled={setIsCropDisabled}
            emailName={emailName}
            croppedCoordinatesCopy={croppedCoordinatesCopy}
            getValueElements={getValueElements}
            valueElementsLoading={valueElementsLoading}
            inputVal={inputVal}
            setInputVal={setInputVal}
            shouldTableUpdate={shouldTableUpdate}
            setShouldTableRerender={setShouldTableRerender}
            pmIndex={pmIndex}
            awsObject={awsObject}
            isReadOnly={isReadOnly}
            projectMemberIdentificationHeader={
              projectMemberIdentificationHeader
            }
            getPreSignedUrlList={getPreSignedUrlList}
            confirm={confirm}
            templateExistsInCluster={templateExistsInCluster}
            removeTemplateInDocument={removeTemplateInDocument}
            confirmCroppingMessage={confirmCroppingMessage}
            handlePreSignedUrlDocuments={handlePreSignedUrlDocuments}
            openReassignModal={openReassignModal}
            setOpenReassignModal={setOpenReassignModal}
            checkForTemplateInCluster={checkForTemplateInCluster}
            dataElements={dataElements}
            getDataElements={getDataElements}
            showRadioAndCheckboxHighlights={showRadioAndCheckboxHighlights}
            setShowRadioAndCheckboxHighlights={
              setShowRadioAndCheckboxHighlights
            }
            projectMemberStatuses={projectMemberStatuses}
            organizationName={organizationName}
            checkForVASpecificPMWorkflow={checkForVASpecificPMWorkflow}
          />
          <ReassignClusterModal
            currentProjectId={currentProjectId}
            submitRecluster={reassignCurrentDocument}
            reclusterDocs={reclusterDocs}
            setReclusterDocs={setReclusterDocs}
            modalOpen={openReassignModal}
            setOpenReassignModal={setOpenReassignModal}
            documents={reassignModalDocs}
            setDocuments={setReassignModalDocs}
            currentCluster={currentCluster}
            clusterSelected={destinationCluster}
            setClusterValues={setClusterValues}
            onDataEntry={true}
            onClickImageCard={toggleReclusterDoc}
            image={file}
            setImage={setFile}
          />
          {changePMConfirmationDialog}
        </>
      ) : (
        <Redirect to={{ pathname: '/select', state: { redirected: true } }} />
      )}
    </ProjectSelectedTemplate>
  );
}
