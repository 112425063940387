import { useEffect, useState } from 'react';
import Typography from 'components/Typography';
import PropTypes from 'prop-types';
import { selectSnackbarIsOpen } from 'store/Redux/slices/snackbarsSlice';
import { useSelector } from 'react-redux';
import FormatClusterName from 'helpers/FormatClusterName';
import styled from 'styled-components';
import IconButton from 'components/IconButton';
import Edit from 'components/Edit';
import Box from 'components/Box';

const SIconButton = styled(IconButton)`
  padding: 0;
  padding-left: 5px;
  display: ${(props) => (props.isHidden ? 'none' : null)};
  & svg {
    color: ${(props) => (props.disabled ? 'gray !important' : null)};
  }
`;

const EditableCell = ({ value: initialValue, updateValue, row }) => {
  const [value, setValue] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  const snackbarOpen = useSelector(selectSnackbarIsOpen);

  const input = document.getElementById('clusterName');

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (input && !input['pressed']) {
      if (initialValue === value) {
        return;
      }
      updateValue(initialValue, value);
    }
    setEditing(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      input['pressed'] = true;
      setValue(initialValue);
      setEditing(false);
    }
    if (e.key === 'Enter') {
      if (initialValue === e.target.value) {
        setEditing(false);
        return;
      }
      input['pressed'] = true;
      updateValue(initialValue, value);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (snackbarOpen) {
      setEditing(false);
      setValue(initialValue);
    }
  }, [snackbarOpen]);

  return (
    <div>
      {editing ? (
        <input
          id='clusterName'
          value={FormatClusterName(value, true)}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          style={{ width: 'fit-content' }}
          autoFocus={true}
        />
      ) : (
        <Box sx={{ display: 'flex' }}>
          <Typography noWrap sx={{ display: 'flex', alignItems: 'center' }}>
            {FormatClusterName(value, false)}
          </Typography>
          <SIconButton
            size='small'
            title={
              row.isSelected
                ? 'Click to Edit Cluster Name'
                : 'Click Row to Enable Edit Button'
            }
            isHidden={!row.isSelected}
            onClick={() => setEditing(true)}
          >
            <Edit />
          </SIconButton>
        </Box>
      )}
    </div>
  );
};

export default EditableCell;

EditableCell.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.string,
  row: PropTypes.object,
};
