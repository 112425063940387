import { createSlice } from '@reduxjs/toolkit';
import { decoder } from 'helpers/graphQL_Encoder';
import { config } from 'config';

export const defaultResourceMenu = config.SMARTEXTAPPDEFAULTRESOURCEMENU;

const initialState = {
  currentProject: {},
  projectMembers: [],
  projectsList: [],
  globalDataElements: [],
  valueElements: [],
  currentProjectDataElements: [],
  currentProjectId: null,
  setCurrentOrganizationName: '',
  currentOrganizationId: null,
  currentResourceName: defaultResourceMenu,
  currentElementId: 0,
  selectedDocIndex: 0,
  selectedRightDocIndex: 0,
  selectedLeftDocIndex: 0,
  selectedLeft: [],
  currentClusterIndex: undefined,
  currentProjectIndex: 0,
  currentDocURI: '',
  readToken: '',
  projectMemberElements: [],
  projectMemberDocuments: [],
  projectTableIndex: 0,
  clusterDone: false,
  globalLoading: false,
  valueElementStatusTypes: {},
  projectMemberStatusTypes: {},
  dataTypes: {},
  filesAreUploading: 0,
  fileCompleted: [],
  uploadingFiles: [],
  uploadedCount: 0,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    loadCurrentProject(state, action) {
      state.currentProject = action.payload;
    },
    setCurrentProjectClusterDocuments(state, action) {
      const { newState, currentCluster, currentProject } = action.payload;
      const currentClusterIndex = currentProject.clusters.findIndex(
        (x) => x.id === currentCluster.id
      );
      state.currentProject.clusters[currentClusterIndex].documents = newState;
    },
    setCurrentProjectClusters(state, action) {
      state.currentProject.clusters = action?.payload;
    },
    loadProjectsList(state, action) {
      state.projectsList = action.payload;
    },
    loadProjectMembers(state, action) {
      state.projectMembers = action.payload;
    },
    loadDataElements(state, action) {
      state.globalDataElements = action.payload;
    },
    addProject(state, action) {
      const {
        id,
        name,
        createdOn,
        lastModifiedOn,
        isComplete,
        organizationId,
        resourceName,
      } = action.payload;
      state.projectsList.push({
        id,
        name,
        isComplete,
        createdOn,
        lastModifiedOn,
        organizationId,
        resourceName,
      });

      state.projectsList.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
    },
    addCluster(state, action) {
      state.currentProject.clusters.push(action.payload);
    },
    addUnclustered(state, action) {
      state.currentProject.clusters.unshift(action.payload);
    },
    removeUnclustered(state) {
      state.currentProject.clusters.shift();
    },
    updateClusterName(state, action) {
      const { clusterId, newName } = action.payload;
      state.currentProject.clusters.find((c) => c.id === clusterId).name =
        newName;
    },
    updateProjectName(state, action) {
      const { newName } = action.payload;
      state.projectsList[state.currentProjectIndex].name = newName;
    },
    setCurrentOrganizationName(state, action) {
      state.currentOrganizationName = action.payload;
    },
    setCurrentOrganizationId(state, action) {
      state.currentOrganizationId = action.payload;
    },
    setCurrentResourceName(state, action) {
      state.currentResourceName = action.payload;
    },
    deleteProject(state, action) {
      const id = action.payload;
      const updatedProjects = state.projectsList.filter(
        (project) => project.id !== id
      );
      state.projectsList = updatedProjects;
    },
    archiveProject(state, action) {
      const id = action.payload;
      const targetProject = state.projectsList.find((p) => p.id === id);
      targetProject.isComplete = true;
    },
    restoreProject(state, action) {
      const id = action.payload;
      const targetProject = state.projectsList.find((p) => p.id === id);
      targetProject.isComplete = false;
    },
    setCurrentProjectId(state, action) {
      state.currentProjectId = action.payload;
    },
    setCurrentElementId(state, action) {
      state.currentElementId = action.payload;
    },
    setValueElements(state, action) {
      let vElements = action.payload.map((v) => {
        if (v) {
          return {
            ...v,
            coordinates: v.coordinates === '' ? null : v.coordinates,
          };
        }
      });
      state.valueElements = vElements;
    },
    addDataElement(state, action) {
      const newElement = action.payload;
      state.globalDataElements.push(newElement);
    },
    handleAssignedElements(state, action) {
      const { stagedElements, projectId, newTableElement, type, elementId } =
        action.payload;
      if (projectId && type === 'TABLE') {
        const targetElement = state.currentProject.dataElements.findIndex(
          (d) => d.id === elementId
        );
        state.currentProject.dataElements[targetElement].tableData.push(
          newTableElement
        );
      } else {
        state.currentProject.dataElements = stagedElements;
      }
    },
    updateCurrentClusterTableTypeColumns(state, action) {
      const { stagedElements, clusterId, type, elementId, newTableElement } =
        action.payload;

      const clusterIndex = state.currentProject.clusters.findIndex(
        (c) => c.id === clusterId
      );

      if (type === 'newColumn') {
        const targetElement = state.currentProject.clusters[
          clusterIndex
        ].dataElements.findIndex((d) => d.id === elementId);

        state.currentProject.clusters[clusterIndex].dataElements[
          targetElement
        ].tableData.push(newTableElement);
        return;
      }

      state.currentProject.clusters[clusterIndex].dataElements = stagedElements;
    },
    updateDataElementDescription(state, action) {
      const { form } = action.payload;

      const targetElementId = state.currentProject.dataElements.findIndex(
        (elem) => elem.id === form.id
      );
      state.currentProject.dataElements[targetElementId].description =
        form.description;
    },
    updateValueElementValue(state, action) {
      const { form } = action.payload;

      const targetElement = state.valueElements.find((v) => v.id === form?.id);
      if (targetElement) {
        targetElement.value = form.value;
      } else {
        state.valueElements.push(form);
      }
    },
    updateDocumentComplete(state, action) {
      const { form, clusterId, docId } = action.payload;

      const targetCluster = state.currentProject.clusters.find(
        (c) => c.id === clusterId
      );
      const targetDocument = targetCluster.documents.find(
        (d) => d.id === docId
      );

      targetDocument.isComplete = form;
    },
    updateClusters(state, action) {
      state.selectedDocIndex = 0;

      const removedDocIds = action.payload.docs;
      const newClusterId = action.payload.newCluster;

      const docsToMove = state.currentProject.clusters[
        state.currentClusterIndex
      ].documents.filter((doc) => removedDocIds.includes(doc.id));

      //Remove documents from original cluster
      const updatedOldDocs = state.currentProject.clusters[
        state.currentClusterIndex
      ].documents.filter((doc) => !removedDocIds.includes(doc.id));

      state.currentProject.clusters[state.currentClusterIndex].documents =
        updatedOldDocs;

      //Add documents to new cluster
      const targetClusterIndex = state.currentProject.clusters.findIndex(
        (cluster) => cluster.id === newClusterId
      );

      const docsNewClusterId = docsToMove.map((doc) => {
        return { ...doc, clusterId: decoder(newClusterId) };
      });

      docsNewClusterId.forEach((doc) => {
        state.currentProject.clusters[targetClusterIndex].documents.push(doc);
      });
    },
    setCurrentProjectIndex(state, action) {
      const index = action.payload;
      state.currentProjectIndex = index;
    },
    setCurrentClusterIndex(state, action) {
      if (action?.payload?.none) {
        state.currentClusterIndex = undefined;
        return;
      }

      state.currentClusterIndex = state.currentProject?.clusters?.findIndex(
        (c) => c?.id === action?.payload?.id
      );
    },
    setDocIndex(state, action) {
      const index = action.payload;
      if (state.selectedDocIndex !== index) {
        state.selectedDocIndex = action.payload;
      }
    },
    setRightDocIndex(state, action) {
      const index = action.payload;
      if (state.selectedRightDocIndex !== index) {
        state.selectedRightDocIndex = action.payload;
      }
    },
    setLeftDocIndex(state, action) {
      const index = action.payload;
      if (state.selectLeftDocIndex !== index) {
        state.selectLeftDocIndex = action.payload;
      }
    },
    setLeft(state, action) {
      state.selectedLeft = action.payload;
    },
    setCurrentDocURI(state, action) {
      state.currentDocURI = action.payload;
    },
    setReadToken(state, action) {
      state.readToken = action.payload;
    },
    setProjectMemberElements(state, action) {
      const { elements } = action.payload;
      if (elements == undefined) {
        state.projectMemberElements = action.payload;
      } else {
        state.projectMemberElements = elements;
      }
    },
    setProjectMemberDocuments(state, action) {
      const { members } = action.payload;

      if (members == undefined) {
        state.projectMemberDocuments = action.payload;
      } else {
        /*
          In order to simplify the object to make it easier to map, the below code creates an object with the 3 columns we need for the table:
          originalName, projectName, originalId
        */
        state.projectMemberDocuments = initialState.projectMemberDocuments;
        let newMembers = [];
        members.forEach((memb) => {
          let originalId = memb.originalId;
          memb.documents.forEach((doc) => {
            let mem = {
              originalName: doc.originalName,
              projectName: doc.project.name,
              originalId: originalId,
            };
            newMembers.push(mem);
          });
        });
        state.projectMemberDocuments = newMembers;
      }
    },
    setProjectTableIndex(state, action) {
      const index = action.payload;
      state.projectTableIndex = index;
    },
    updateClusterElements(state, action) {
      state.currentProject.clusters[state.currentClusterIndex].dataElements =
        action.payload;
    },
    setGlobalLoading(state, action) {
      state.globalLoading = action.payload;
    },
    setClusterDone(state, action) {
      state.clusterDone = action.payload;
    },
    setValueElementStatusTypes(state, action) {
      let formatted = {};
      action.payload.forEach((e) => {
        formatted[e.name] = e.name;
      });
      state.valueElementStatusTypes = formatted;
    },
    setProjectMemberStatusTypes(state, action) {
      let formatted = {};
      action.payload.forEach((e) => {
        formatted[e.name] = e.name;
      });
      state.projectMemberStatusTypes = formatted;
    },
    setStatusJustificationTypes(state, action) {
      let formatted = {};
      action.payload.forEach((e) => {
        formatted[e.name] = e.name;
      });
      state.statusJustificationTypes = formatted;
    },
    setDataTypes(state, action) {
      state.dataTypes = action.payload;
    },
    setFilesAreUploading(state, action) {
      state.filesAreUploading = action.payload;
    },
    setFileCompleted(state, action) {
      state.fileCompleted = action.payload;
    },
    setUploadingFiles(state, action) {
      state.uploadingFiles = action.payload;
    },
    setUploadedCount(state, action) {
      state.uploadedCount = action.payload;
    },
  },
});

//Selectors
export const selectCurrentProject = (state) => state.projects.currentProject;
export const selectActiveProjects = (state) =>
  state.projects.projectsList.filter((project) => !project.isComplete);
export const selectArchivedProjects = (state) =>
  state.projects.projectsList.filter((project) => project.isComplete);
export const selectProjectsList = (state) => state.projects.projectsList;
export const selectProjectMembers = (state) => state.projects.projectMembers;
export const selectCurrentOrganizationName = (state) =>
  state.projects.currentOrganizationName;
export const selectCurrentOrganizationId = (state) =>
  state.projects.currentOrganizationId;
export const selectCurrentResourceName = (state) =>
  state.projects.currentResourceName;
export const selectCurrentProjectId = (state) =>
  state.projects.currentProjectId;
export const selectCurrentElementId = (state) =>
  state.projects.currentElementId;
export const selectElements = (state) => state.projects.globalDataElements;
export const selectValueElements = (state) => state.projects.valueElements;
export const selectCurrentClusterIndex = (state) =>
  state.projects.currentClusterIndex;
export const selectDocIndex = (state) => state.projects.selectedDocIndex;
export const selectRightDocIndex = (state) =>
  state.projects.selectedRightDocIndex;
export const selectLeft = (state) => state.projects.selectedLeft;
export const selectLeftDocIndex = (state) =>
  state.projects.selectedLeftDocIndex;
export const selectReadToken = (state) => state.projects.readToken;
export const selectProjectMemberElements = (state) =>
  state.projects.projectMemberElements;
export const selectProjectMemberDocuments = (state) =>
  state.projects.projectMemberDocuments;
export const selectProjectsTableIndex = (state) =>
  state.projects.projectTableIndex;
export const selectClusterDone = (state) => state.projects.clusterDone;
export const selectGlobalLoading = (state) => state.projects.globalLoading;
export const selectValueElementStatusTypes = (state) =>
  state.projects.valueElementStatusTypes;
export const selectProjectMemberStatusTypes = (state) =>
  state.projects.projectMemberStatusTypes;
export const selectStatusJustificationTypes = (state) =>
  state.projects.statusJustificationTypes;
export const selectDataTypes = (state) => state.projects.dataTypes;
export const selectFilesAreUploading = (state) =>
  state.projects.filesAreUploading;
export const selectFileCompleted = (state) => state.projects.fileCompleted;
export const selectUploadingFiles = (state) => state.projects.uploadingFiles;
export const selectUploadedCount = (state) => state.projects.uploadedCount;
//#endregion Selectors

//#region Action Creators
export const {
  addCluster,
  addClusterDataElement,
  addDataElement,
  addProject,
  addUnclustered,
  archiveProject,
  deleteProject,
  handleAssignedElements,
  loadCurrentProject,
  loadDataElements,
  loadProjectMembers,
  loadProjectsList,
  removeClusterDataElement,
  removeUnclustered,
  restoreProject,
  setCurrentClusterIndex,
  setCurrentDocURI,
  setCurrentElementId,
  setCurrentOrganizationName,
  setCurrentOrganizationId,
  setCurrentResourceName,
  setCurrentProjectClusterDocuments,
  setCurrentProjectClusters,
  setCurrentProjectId,
  setCurrentProjectIndex,
  setDocIndex,
  setProjectMemberDocuments,
  setProjectMemberElements,
  setReadToken,
  setProjectTableIndex,
  setValueElements,
  setGlobalLoading,
  setValueElementStatusTypes,
  setProjectMemberStatusTypes,
  setStatusJustificationTypes,
  updateClusterName,
  updateClusters,
  updateCurrentClusterTableTypeColumns,
  updateDataElementDescription,
  updateDocumentComplete,
  updateProjectName,
  updateValueElementValue,
  updateClusterElements,
  setClusterDone,
  setDataTypes,
  setFilesAreUploading,
  setFileCompleted,
  setUploadingFiles,
  setUploadedCount,
  setRightDocIndex,
  setLeftDocIndex,
  setLeft,
} = projectsSlice.actions;
//#endregion Action Creators

export default projectsSlice.reducer;
