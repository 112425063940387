import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import CheckIcon from 'icons/Mui/Check';
import CropIcon from '@mui/icons-material/Crop';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components';
import Grid from 'components/Grid';

//#region Styled Components
const STooltip = styled(Tooltip)`
  display: ${(props) => (props.isHidden ? 'none' : null)};
`;

const CropButton = styled(IconButton)`
  display: ${(props) => (props.isHidden ? 'none' : null)};
  border-radius: 10px;
  padding: 6px;
`;

const ButtonContainer = styled(Grid)`
  display: ${(props) =>
    props.isHidden ? 'none' : props.hasIcon ? 'flex' : null};
  color: cadetblue;
  font-size: larger;
`;

const ClearButton = styled(IconButton)`
  display: ${(props) => (props.isHidden ? 'none' : null)};

  &:hover {
    background-color: transparent;
  }
`;

const SIconButton = styled(IconButton)`
  padding: 0px 5px;

  &:hover {
    background-color: transparent;
  }
`;
//#endregion Styled Components

export const ImageCropIconArea = (props) => {
  const {
    value,
    crop,
    item,
    croppedCoordinates,
    selectedElementField,
    onClickCrop,
    onClickRemoveCrop,
    onConfirmCrop,
    onExitCropMode,
    isDocComplete,
    isCropDisabled,
    isWindowMode,
    isIconHidden,
    isCellMode,
    isReadOnly,
  } = props;
  return (
    <>
      {isCropDisabled ? (
        <ButtonContainer item xs textAlign='end'>
          <STooltip
            isHidden={isCellMode}
            title={`Crop from image`}
            placement='top'
            disableHoverListener={isWindowMode}
            disableTouchListener={isWindowMode}
          >
            <CropButton
              isHidden={isCellMode}
              disabled={item.type === 'TABLE' || isDocComplete || isReadOnly}
              variant='contained'
              onClick={(e) => onClickCrop(e, item, value)}
              size='large'
            >
              <CropIcon fontSize='small' />
            </CropButton>
          </STooltip>
        </ButtonContainer>
      ) : (
        <ButtonContainer
          item
          xs={isCropDisabled ? 2 : 6}
          textAlign='end'
          isHidden={item.id !== selectedElementField.id || isIconHidden}
          hasIcon={isCellMode && !isIconHidden}
        >
          <Tooltip
            disableHoverListener={isWindowMode}
            disableTouchListener={isWindowMode}
            title='Confirm crop'
            placement='top'
          >
            <SIconButton
              disabled={crop?.width === 0 && crop?.height === 0}
              onClick={onConfirmCrop}
              size='large'
              isHidden={isDocComplete}
            >
              <CheckIcon fontSize='small' />
            </SIconButton>
          </Tooltip>
          |
          <Tooltip
            disableHoverListener={isWindowMode}
            disableTouchListener={isWindowMode}
            title='Exit crop mode'
            placement='top'
          >
            <ClearButton
              style={{ padding: '0 3px' }}
              onClick={onExitCropMode}
              size='large'
              isHidden={isCellMode}
            >
              <ClearIcon fontSize='small' />
            </ClearButton>
          </Tooltip>
          {isCellMode ? '' : '|'}
          <Tooltip
            disableHoverListener={isWindowMode}
            disableTouchListener={isWindowMode}
            title='Remove cropped area'
            placement='top'
          >
            <SIconButton
              disabled={!value}
              onClick={() => onClickRemoveCrop(croppedCoordinates.field)}
              size='large'
              isHidden={isDocComplete}
            >
              <DeleteOutlineIcon fontSize='small' />
            </SIconButton>
          </Tooltip>
        </ButtonContainer>
      )}
    </>
  );
};

ImageCropIconArea.propTypes = {
  crop: PropTypes.object,
  value: PropTypes.string,
  item: PropTypes.object,
  selectedElementField: PropTypes.object,
  isWindowMode: PropTypes.bool,
  isDocComplete: PropTypes.bool,
  isCropDisabled: PropTypes.bool,
  onClickRemoveCrop: PropTypes.func,
  onClickCrop: PropTypes.func,
  onConfirmCrop: PropTypes.func,
  onExitCropMode: PropTypes.func,
  croppedCoordinates: PropTypes.object,
  isIconHidden: PropTypes.bool,
  isCellMode: PropTypes.bool,
  isHidden: PropTypes.bool,
  hasIcon: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};
