import MRadio from '@mui/material/Radio';
import styled from 'styled-components';

const SRadio = styled(MRadio)`
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default function Radio(props) {
  return <SRadio {...props} />;
}
